import React, { Component } from 'react'
import "./ap-parameters.css";
import { IonPage, IonContent, withIonLifeCycle, IonAlert, IonLoading } from '@ionic/react';
import BackIcon from '../assets/img/go-back-icon-img.png'
import Link from "../assets/img/linkbudget.png"

class linkbudget extends React.Component {
    state: any = {};
    props: any = {};
    constructor(props: any) {

        super(props);
        this.state = {

            freq: "",
            dist: "",
            fspl: "",
            pow: "",
            w1: "",
            w2: "",
            g1: "",
            g2: "",
            l1: "",
            l2: "",
            p1x: "",
            p2x: ""

        };
        this.calculateFspl = this.calculateFspl.bind(this)
        this.distDropdownChange = this.distDropdownChange.bind(this)
        this.freqDropdownChange = this.freqDropdownChange.bind(this)
        this.calculate = this.calculate.bind(this)
        this.calculatePower = this.calculatePower.bind(this)
        this.calculateFspl = this.calculateFspl.bind(this)
        this.reset = this.reset.bind(this)
    }
    distDropdownChange = (e: any) => {
        this.setState({
            distUnit: e.target.value
        })

    }
    freqDropdownChange = (e: any) => {
        this.setState({
            freqUnit: e.target.value
        })

    }
    inputChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    calculate() {
        let miles: any = ""
        let freq: any = ""
        miles = this.state.dist
        freq = this.state.freq
        if (this.state.distUnit == "2") {
            miles = parseInt(this.state.dist) * 1.609
        }

        if (this.state.freqUnit == "2") {
            freq = parseInt(this.state.freq) * 1000
        }

        this.calculateFspl(miles, freq)


    }
    calculateFspl = (miles: any, freq: any) => {
        let fspl: any = ""
        fspl = 20 * (Math.log10(miles)) + 20 * (Math.log10(freq)) + 36.6
        this.setState({
            fspl: fspl.toFixed(2)
        }, () => this.calculatePower())
    }
    calculatePower() {
        console.log(this.state.p1x, this.state.p2x, this.state.g1, this.state.g2, this.state.l1, this.state.l2, this.state.fspl)
        let pow = ((parseInt(this.state.p1x) + parseInt(this.state.g1) - parseInt(this.state.l1)) + (parseInt(this.state.p2x) + parseInt(this.state.g2) - parseInt(this.state.l2))) - parseInt(this.state.fspl)
        console.log(pow)
        this.setState({
            pow: pow.toFixed(2)
        })

    }

    reset() {
        this.setState({
            freq: "",
            dist: "",
            fspl: "",
            pow: "",
            w1: "",
            w2: "",
            g1: "",
            g2: "",
            l1: "",
            l2: "",
            p1x: "",
            p2x: ""
        })
    }
    render() {
        return (
            <div className="mobonly">
                <IonPage>
                    <IonContent>
                        <div className='col-12 text-center searchborder' style={{ padding: '10px' }}>
                            <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={this.props.hideOverlay} src={BackIcon} />
                            <span className="apheader">Link Budget</span>
                        </div>
                        <div className='container-fluid apdash calcfont' style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 47px)' }}>
                            <div className="card speedtestcard" style={{ height: "max-content", marginBottom: "10px" }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div className="col-12 p-0 m-t-10">
                                        <img src={Link} />
                                    </div>
                                    <h6 className="font14 m-t-20">Frequency (f)</h6>
                                    <div className="row">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" id="dist" value={this.state.dist} onChange={(e: any) => { this.inputChange(e) }} name="dist" className="calculatorinput" />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => this.freqDropdownChange(e)} className="form-control calculatorselect calcinlineselect">
                                                <option value="1">MHz</option>
                                                <option value="2">GHz</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Receiver Distance (D)</h6>
                                    <div className="row">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" id="freq" name="freq" value={this.state.freq} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => this.distDropdownChange(e)} className="form-control calculatorselect calcinlineselect">
                                                <option value="1">Miles</option>
                                                <option value="2">Km</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 p-r-10">
                                            {/* <h6 className="font14 m-t-10 text-center">CBSD 1</h6> */}
                                            <h6 className="font14 m-t-20">Transmitter T<sub>x</sub> Power</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="p1x" name="p1x" value={this.state.p1x} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dBm" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-l-10">
                                            {/* <h6 className="font14 m-t-10 text-center">CBSD 2</h6> */}
                                            <h6 className="font14 m-t-20">Receiver R<sub>x</sub> Power</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="p2x" name="p2x" value={this.state.p2x} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dBm" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 p-r-10">
                                            <h6 className="font14 m-t-10">Transmitter Antenna Gain</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="g1" name="g1" value={this.state.g1} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dBi" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-l-10">
                                            <h6 className="font14 m-t-10">Receiver Antenna Gain</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="g2" name="g2" value={this.state.g2} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dBi" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 p-r-10">
                                            <h6 className="font14 m-t-10"> Transmitter Loss</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="l1" name="l1" value={this.state.l1} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dB" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-l-10">
                                            <h6 className="font14 m-t-10">Receiver Loss</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="l2" name="l2" value={this.state.l2} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dB" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-6 p-r-10">
                                            <h6 className="font14 m-t-10">CBSD Antenna Height 1</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="h1" name="h1" value={this.state.h1} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="ft" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-l-10">
                                            <h6 className="font14 m-t-10">CBSD Antenna Height 2</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="h2" name="h2" value={this.state.h2} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="ft" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 p-r-10">
                                            <h6 className="font14 m-t-10">CBSD Vertical Beamwidth</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="bw1" name="bw1" value={this.state.bw1} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dB" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-l-10">
                                            <h6 className="font14 m-t-10">CBSD Vertical Beamwidth</h6>
                                            <div className="row">
                                                <div className="col-8 p-r-0 calculator  ">
                                                    <input type="number" id="bw2" name="bw2" value={this.state.bw2} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-4 p-l-0">
                                                    <input type="text" disabled id="antheight" name="antheight" value="dB" className="calculatorinput calcinlineselect text-center p-0" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="row m-t-20 text-center">
                                        <div className="col-6 p-r-5">
                                            <button className="btn modalbtn" onClick={this.calculate} >Calculate</button>
                                        </div>
                                        <div className="col-6 p-l-5">
                                            <button className="lightbtnmob modalbtn" onClick={this.reset}>Reset</button>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Free Space Path Loss(FSPL)</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="fspl" value={this.state.fspl} name="fspl" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="antheight" name="antheight" value="db" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Expected Signal Power</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="pow" name="pow" value={this.state.pow} className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="antheight" name="antheight" value="dBm" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IonContent>
                </IonPage>
            </div >
        );
    }
}

export default withIonLifeCycle(linkbudget);