import React, { Component } from "react";
import { IonPage, IonLoading, IonAlert, IonContent } from "@ionic/react";
import { requestAPICall } from "../service/apiHandler";
import logo from "../assets/img/Spectra_logo.png";
import {
  Link,
  useRouteMatch,
  Switch,
  Route,
  useParams,
  Redirect,
} from "react-router-dom";
const INITIAL_STATE = {
  text: "",
  userId: "",
  is_loader: false,
  token: "",
  alertbox: false,
  warnMessage: "",
};

export default class forgotChange extends Component {
  state: any = {};
  props: any = {};
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.change = this.change.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.callUpdatePassword = this.callUpdatePassword.bind(this);
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    //console.log(params);
  }
  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  setShowLoading = (st: any) => {
    this.setState({ is_loader: st });
  };

  submitForm = (e: any) => {
    this.formValidation();
    e.preventDefault();
    this.callUpdatePassword();
  };
  formValidation() {
    if (this.state.text == "") {
      this.setState({
        warnMessage: "Please enter new password",
      });
      this.setState({
        alertbox: true,
      });
    } else {
      this.setState({
        submit: true,
      });
    }
  }

  callUpdatePassword = () => {
    //console.log(this.props.location.state.token);
    let requestBody = {
      token: this.props.location.state.token,
      newPassword: this.state.text,
    };

    //console.log(requestBody);
    requestAPICall("forgot_password", {}, requestBody).then((data) => {
      //console.log(data);
      this.setState({
        warnMessage: data.message,
        alertbox: true,
      });
      this.props.history.push("/login");
    });
  };
  change = (e: any) => {
    this.setState({
      text: e.target.value,
    });
  };
  render() {
    return (
      <IonPage>
        <IonContent>
          {!this.state.is_loader ? (
            <div>
              <div className="webonly">
                <div
                  className="app-body img-responsive"
                  style={{ marginTop: "0px" }}
                >
                  <div className="row m-r-0 m-l-0">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-7 col-sm-12 login-bg">
                          <div className="row bg-text bg-slogan">
                            <div className="col-md-12">
                              <span>Your Signal.</span>
                              <br />
                              <span style={{ lineHeight: "1.8" }}>
                                Your Company.
                              </span>
                              <br />
                              <span style={{ lineHeight: "1.8" }}>
                                Your Promise to Customers.
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 col-sm-12 login-logo">
                          <div className="row m-r-0 m-l-0">
                            <div className="col-md-12 ">
                              <img className="img-center img" src={logo} />
                            </div>
                          </div>
                          <div
                            className="row m-r-0 m-l-0"
                            style={{ paddingTop: "14%" }}
                          >
                            <div className="col-md-12 homecontent">
                              <span className="img-center fontw500 font20">
                                Enter your New Password
                              </span>
                              <div className="row justify-content-center m-t-20">
                                <div className="form-label-group m-0">
                                  <input
                                    type="text"
                                    style={{
                                      borderRadius: "6px",
                                      border: "0.5px solid #8F9FAC",
                                    }}
                                    className="text-user"
                                    id="reset"
                                    placeholder="New Password"
                                    onChange={this.change}
                                  />
                                  <label htmlFor="reset">New Password</label>
                                </div>
                              </div>
                              {this.state.text === "" ? (
                                <div className="img-center">
                                  <button
                                    className="btn btn-log m-t-20"
                                    disabled={true}
                                  >
                                    Submit
                                  </button>
                                </div>
                              ) : (
                                <div className="img-center">
                                  <button
                                    className="btn btn-log m-t-20"
                                    onClick={this.submitForm}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobonly">
                <div
                  className="app-body img-responsive"
                  style={{ marginTop: "0px" }}
                >
                  <div className="row m-r-0 m-l-0">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-7 col-sm-12 login-bg">
                          <div className="row bg-text bg-slogan">
                            <div className="col-md-12">
                              <span>Your Signal.</span>
                              <br />
                              <span style={{ lineHeight: "1.8" }}>
                                Your Company.
                              </span>
                              <br />
                              <span style={{ lineHeight: "1.8" }}>
                                Your Promise to Customers.
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 col-sm-12 login-logo">
                          {/* <div className='col-md-12 '>
                                                        <img className='backicon' src={BackIcon} />
                                                    </div> */}
                          <div className="row m-r-0 m-l-0">
                            <div className="col-md-12 ">
                              <img className="img-center img" src={logo} />
                            </div>
                          </div>
                          <div
                            className="row m-r-0 m-l-0"
                            style={{ paddingTop: "14%" }}
                          >
                            <div
                              className="col-md-12 homecontent"
                              style={{ bottom: "42px" }}
                            >
                              <span className="img-center fontw500 font20">
                                Enter your New Password
                              </span>
                              <div className="col-md-12 p-0 justify-content-center m-t-20">
                                <div className="form-label-group m-0">
                                  <input
                                    type="text"
                                    style={{
                                      borderRadius: "6px",
                                      border: "0.5px solid #8F9FAC",
                                    }}
                                    className="text-user"
                                    id="resetmob"
                                    placeholder="New Password"
                                    onChange={this.change}
                                  />
                                  <label htmlFor="resetmob">New Password</label>
                                </div>
                              </div>
                              {this.state.text === "" ? (
                                <div className="img-center">
                                  <button
                                    className="btn btn-log m-t-20"
                                    disabled={true}
                                  >
                                    Submit
                                  </button>
                                </div>
                              ) : (
                                <div className="img-center">
                                  <button
                                    className="btn btn-log m-t-20"
                                    onClick={this.submitForm}
                                  >
                                    Submit
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <IonAlert
            isOpen={this.state.alertbox}
            onDidDismiss={() => this.setShowAlert(false)}
            message={this.state.warnMessage}
            buttons={["OK"]}
          />

          <IonLoading
            isOpen={this.state.is_loader === true}
            onDidDismiss={() => this.setShowLoading(false)}
            message={"Loading..."}
            duration={5000}
          />
        </IonContent>
      </IonPage>
    );
  }
}