import React, { Component } from 'react'
import Back from '../assets/img/left-arrow.svg'
import Heat from '../assets/img/heaticon.png'
import "./measure.css"
import BackIcon from '../assets/img/go-back-icon-img.png'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFabButton, IonFabList, IonIcon, IonFab, IonGrid, IonRow, IonCol } from '@ionic/react';
import calc from '../assets/img/calculate.svg'
import AP from '../assets/img/Apicon.png'
import close from '../assets/img/close.png'
// import measure from '../assets/img/Measureicon.png'
import SearchIcon from '../assets/img/search_icon.png'
import checkSuccess from '../assets/img/check-success.png'
import measureIcon from '../assets/img/Measure-icon-img-white.png'
import addCPEIcon from '../assets/img/add-cpe-icon-img.png'
import addAPIcon from '../assets/img/add-ap-icon-img.png'
import geo from '../assets/img/target-icon.png'
import heat from '../assets/img/heaticon.png'
import calcmob from '../assets/img/align-icon.png'
import arch from '../assets/img/arch.png'
import heatact from '../assets/img/heaticonactive.png'
import { Chart } from 'react-google-charts'
import { requestAPICall } from "../service/apiHandler";
import { isBrowser } from 'react-device-detect'
import store from '../store';

const INITIAL_STATE = {
    mesh: '0',
    mesh1: '0',
    distance: 0.0,
    bar: true,
    val: '0'
}

var elevator = new google.maps.ElevationService;

export default class Measure extends React.Component {
    state: any = {};
    props: any = {};
    reduxStore: any = store.getState()['general']
    constructor(props: any) {
        super(props)
        this.state = { ...INITIAL_STATE, mesh3: this.props.temp }
    }
    componentDidMount() {
        console.log(this.props);
        console.log(this.reduxStore)

    }

    change = () => {
        this.setState({
            mesh3: this.props.temp
        })
    }
    switch = () => {
        this.setState({
            bar: !this.state.bar
        })
    }
    click = (val: any) => {
        this.setState({
            val: val.target.id
        })
    }
    setCurrentPosition = () => {
        this.props.setCurrentPosition();
    }
    graph = () => {
        // var requestBody = {
        //     "vertex": this.props.measure_pair
        // }
        // requestAPICall("eprofile", {}, requestBody)
        //     .then(data => {
        //         console.log(data)
        //         if (data.responseCode == 200) {
        //             this.setState({
        //                 Egraph: data.elevation
        //             }, () => { this.plot_elevation() })
        //         }
        //     })
        elevator.getElevationAlongPath({
            'path': this.props.measure_pair,
            'samples': 500
        }, this.plot_elevation);
    }
    plot_elevation = (elevations: any, status: any) => {
        // var chart_data: any[][] = []
        // chart_data.push(['x', 'Elevation'])
        // this.state.Egraph.features.map((feature: any) => {
        //     var set_values = []
        //     //console.log(feature.id)
        //     set_values.push(parseInt(feature.id))
        //     set_values.push(feature.properties.first)
        //     chart_data.push(set_values)
        // })
        // this.setState({
        //     chartVal: chart_data
        // }, () => console.log(this.state.chartVal))
        var chart_data: any[][] = []
        chart_data.push(['x', 'Elevation', { type: 'string', role: 'tooltip' }])
        elevations.map((data: any, index: any) => {
            var set_values = []
            set_values.push(index)
            set_values.push(parseFloat(data.elevation.toFixed(1)))
            set_values.push("Elevation: " + data.elevation.toFixed(1) + ' m')
            chart_data.push(set_values)
        })
        this.setState({
            chartVal: chart_data
        }, () => console.log(this.state.chartVal))
    }
    render() {
        return (
            <div>
                {isBrowser ?
                    <div className="webonly">
                        <div className='row'>
                            <div className='col-md-9'>
                                <div className="card searchcard">
                                    <div className="apcardbody">
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <img className='backicon' src={BackIcon} onClick={this.props.back} />
                                                <button type='button' className='backbutton' ></button>
                                                {/* <img className='backicon' src={Back} onClick={this.props.back} /> */}
                                                {/* <button type='button' className='backbutton material-icons' onClick={this.props.back}>keyboard_backspace </button> */}

                                                {/* <button type='button' className='backbutton'></button> */}
                                            </div>
                                            <div className='col-md-6' style={{ padding: "13px 0", marginLeft: "-5px" }}>
                                                <span className='apheader'>MEASURING</span>
                                            </div>
                                        </div>
                                        <div className='row' style={{ padding: "0px 35px" }}>
                                            <div className='col-md-12 apborder'>
                                                <span className='apnote'>Measure distances between points</span>
                                            </div>
                                        </div>
                                        {/* <i className="fas fa-circle measure" id='1' onClick={this.click1} style={this.state.mesh === '1' ? { bottom: '20%', left: '42%', position: 'fixed', color: '#f59343' } : { bottom: '20%', left: '42%', position: 'fixed' }}></i>
                                    <i className="fas fa-circle measure" id='2' onClick={this.click2} style={this.state.mesh1 === '2' ? { bottom: '40%', left: '40%', position: 'fixed', color: '#f59343' } : { bottom: '40%', left: '40%', position: 'fixed' }}></i> */}
                                        {/* <i className="fas fa-circle measure" style={{ bottom: '56%', left: '45%', position: 'fixed' }}></i> */}

                                        <div >
                                            <div className='row m-t-10' style={{ padding: "0px 35px" }} >
                                                <span className='measurecontent' style={this.props.temp === 0 ? { color: '#f59343' } : {}} >1. Select first CBSD</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='row m-t-10' style={{ padding: "0px 35px" }}>
                                                <span className='measurecontent' style={this.props.temp === 1 ? { color: '#f59343' } : {}}>2. Select Second CBSD</span>
                                            </div>
                                        </div>
                                        {/* <div style={{ bottom: '120px', position: 'absolute' }}>
                                            {this.state.chartVal && window.google ?
                                                <Chart
                                                    width={'290px'}
                                                    height={'200px'}
                                                    chartType="LineChart"
                                                    loader={<div>Loading Chart</div>}
                                                    data={this.state.chartVal}
                                                    options={{
                                                        title: 'Elevation Profile',
                                                        vAxis: {
                                                            title: 'Elevation(m)',
                                                        },
                                                        hAxis: {
                                                            textPosition: 'none'
                                                        },
                                                        curveType: 'function',
                                                        legend: 'none'
                                                    }}
                                                    rootProps={{ 'data-testid': '1' }}

                                                /> : null
                                            }
                                        </div> */}
                                        {/* <div className='row justify-content-center' style={{ padding: "0px 35px" }}>
                                        <span className='distance' style={{ position: "fixed", bottom: "100px" }}>Distance :</span>
                                    </div> */}
                                        <div>
                                            <div className='' style={{ padding: "0px 35px", position: "fixed", bottom: "20px" }}>

                                                <div className='row justify-content-center'>
                                                    <div>
                                                        <span style={{ color: "#8F9FAC" }}> Distance:</span>
                                                    </div>
                                                </div>
                                                <div className="row measurecard-web" style={{ marginTop: "15px" }}>

                                                    <div className="card-body text-center measure-text justify-col-center">
                                                        {/* <span className='measuredist'>2m</span> */}
                                                        {(() => {
                                                            // console.log('df', this.props.dist)
                                                            if (this.props.temp === 0)
                                                                return <span >-</span>
                                                            if (this.props.temp === 1)
                                                                return <span >-</span>
                                                            if (this.props.temp === 2)
                                                                return <span >&nbsp;<span style={{ color: '#f59343', fontWeight: 500, fontSize: "20px" }}>{this.props.distance} m</span></span>
                                                            else
                                                                return <span >&nbsp;<span style={{ color: '#f59343', fontWeight: 500, fontSize: "20px" }}></span></span>
                                                        })()}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3' >
                                <div className="col-md-12 justify-content-flex-end">
                                    <div className="heaticon text-center" style={{ display: 'none' }}>
                                        <img className='heat' src={Heat} />
                                    </div>
                                </div>

                                <div className="col-md-12 float-right">
                                    <div className="geoicon text-center">
                                        <img style={{ padding: '5px' }} src={geo} onClick={() => this.setCurrentPosition()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.temp === 2 && this.state.chartVal ?
                            <div className="row">
                                <div className="col-md-3">
                                </div>
                                <div className="col-md-8">
                                    <div className="card elevationcard">
                                        <div className="apcardbody" style={{ padding: '5px' }}>
                                            <div >
                                                {this.state.chartVal && window.google ?
                                                    <Chart
                                                        width={'900px'}
                                                        height={'200px'}
                                                        chartType="ColumnChart"
                                                        loader={<div>Loading Chart</div>}
                                                        data={this.state.chartVal}
                                                        options={{
                                                            title: 'Elevation Profile',
                                                            vAxis: {
                                                                title: 'Elevation(m)',
                                                            },
                                                            hAxis: {
                                                                textPosition: 'none'
                                                            },
                                                            //curveType: 'function',
                                                            legend: 'none'
                                                        }}
                                                        rootProps={{ 'data-testid': '1' }}

                                                    /> : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1">
                                </div>

                            </div>
                            : null
                        }
                    </div>
                    :
                    <div className='mobonly'>
                        {(() => {
                            if (this.props.temp === 0)
                                return <div style={{ position: 'fixed', bottom: '120px', right: '0px', filter: 'contrast(0.9)' }}>
                                    <IonFab horizontal="end" style={{ position: 'relative', display: 'none' }}>
                                        {this.state.heatMap ?
                                            <IonFabButton className='backg' color="danger" size="small" style={{ marginBottom: '10px' }}>
                                                <img className='heat-mob' style={{ padding: "0px", marginTop: '3px' }} src={heatact} />
                                            </IonFabButton>
                                            :
                                            <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }}>
                                                <img className='heat-mob' style={{ marginTop: "3px", padding: "0px" }} src={heat} />
                                            </IonFabButton>
                                        }
                                    </IonFab>
                                    <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                        <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }} onClick={() => this.setCurrentPosition()}>
                                            <img style={{ padding: '5px' }} src={geo} />
                                        </IonFabButton>
                                    </IonFab>
                                    {localStorage.getItem('userRole') == 'Installer' ? null : <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                        <IonFabButton className='backg' color='light' size="small" style={{ marginBottom: '10px' }}>
                                            <img className='calcicon' src={calcmob} />
                                        </IonFabButton>
                                    </IonFab>}
                                    <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                        <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }}>
                                            <img className='search-mob' src={SearchIcon} />
                                        </IonFabButton>
                                    </IonFab>
                                </div>
                            if (this.props.temp === 1)
                                return <div style={{ position: 'fixed', bottom: '120px', right: '0px', filter: 'contrast(0.9)' }}>
                                    <IonFab horizontal="end" style={{ position: 'relative', display: 'none' }}>
                                        {this.state.heatMap ?
                                            <IonFabButton className='backg' color="danger" size="small" style={{ marginBottom: '10px' }}>
                                                <img className='heat-mob' style={{ padding: "0px", marginTop: '3px' }} src={heatact} />
                                            </IonFabButton>
                                            :
                                            <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }}>
                                                <img className='heat-mob' style={{ marginTop: "3px", padding: "0px" }} src={heat} />
                                            </IonFabButton>
                                        }
                                    </IonFab>
                                    <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                        <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }} onClick={() => this.setCurrentPosition()}>
                                            <img style={{ padding: '5px' }} src={geo} />
                                        </IonFabButton>
                                    </IonFab>
                                    {localStorage.getItem('userRole') == 'Installer' ? null : <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                        <IonFabButton className='backg' color='light' size="small" style={{ marginBottom: '10px' }}>
                                            <img className='calcicon' src={calcmob} />
                                        </IonFabButton>
                                    </IonFab>}
                                    <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                        <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }}>
                                            <img className='search-mob' src={SearchIcon} />
                                        </IonFabButton>
                                    </IonFab>
                                </div>
                            if (this.props.temp === 2)
                                if (this.reduxStore['tabType'] == 'outdoor') {
                                    return <div style={{ position: 'fixed', bottom: '270px', right: '0px', filter: 'contrast(0.9)' }}>
                                        <IonFab horizontal="end" style={{ position: 'relative', display: 'none' }}>
                                            {this.state.heatMap ?
                                                <IonFabButton className='backg' color="danger" size="small" style={{ marginBottom: '10px' }}>
                                                    <img className='heat-mob' style={{ padding: "0px", marginTop: '3px' }} src={heatact} />
                                                </IonFabButton>
                                                :
                                                <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }}>
                                                    <img className='heat-mob' style={{ marginTop: "3px", padding: "0px" }} src={heat} />
                                                </IonFabButton>
                                            }
                                        </IonFab>
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }} onClick={() => this.setCurrentPosition()}>
                                                <img style={{ padding: '5px' }} src={geo} />
                                            </IonFabButton>
                                        </IonFab>
                                        {localStorage.getItem('userRole') == 'Installer' ? null : <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' color='light' size="small" style={{ marginBottom: '10px' }}>
                                                <img className='calcicon' src={calcmob} />
                                            </IonFabButton>
                                        </IonFab>}
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }}>
                                                <img className='search-mob' src={SearchIcon} />
                                            </IonFabButton>
                                        </IonFab>
                                    </div>
                                }
                                else {
                                    return <div style={{ position: 'fixed', bottom: '120px', right: '0px', filter: 'contrast(0.9)' }}>
                                        <IonFab horizontal="end" style={{ position: 'relative', display: 'none' }}>
                                            {this.state.heatMap ?
                                                <IonFabButton className='backg' color="danger" size="small" style={{ marginBottom: '10px' }}>
                                                    <img className='heat-mob' style={{ padding: "0px", marginTop: '3px' }} src={heatact} />
                                                </IonFabButton>
                                                :
                                                <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }}>
                                                    <img className='heat-mob' style={{ marginTop: "3px", padding: "0px" }} src={heat} />
                                                </IonFabButton>
                                            }
                                        </IonFab>
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }} onClick={() => this.setCurrentPosition()}>
                                                <img style={{ padding: '5px' }} src={geo} />
                                            </IonFabButton>
                                        </IonFab>
                                        {localStorage.getItem('userRole') == 'Installer' ? null : <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' color='light' size="small" style={{ marginBottom: '10px' }}>
                                                <img className='calcicon' src={calcmob} />
                                            </IonFabButton>
                                        </IonFab>}
                                        <IonFab horizontal="end" style={{ position: 'relative', marginBottom: '10px' }}>
                                            <IonFabButton className='backg' color="light" size="small" style={{ marginBottom: '10px' }}>
                                                <img className='search-mob' src={SearchIcon} />
                                            </IonFabButton>
                                        </IonFab>
                                    </div>
                                }
                        })()}
                        {(() => {
                            if (this.props.temp === 0)
                                return <IonRow className="bottom-menu">
                                    <IonCol className="d-none" size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                        {/* <i className="fa fa-circle cpeicon"></i> */}
                                        <img className='cpeicon' src={addCPEIcon} style={{ width: '38px', filter: 'contrast(0)' }} />
                                        <button type='button' className='cardbutton p-l-25' disabled>Add CPE</button>
                                    </IonCol>
                                    <IonCol size='6' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                        <img className='apicon' src={AP} style={{ filter: 'contrast(0)' }} />
                                        <button type='button' className='cardbutton p-l-25' disabled >Add CBSD</button>
                                    </IonCol>
                                    <IonCol size='6' style={{ padding: '5px' }}>
                                        <img className='measureiconmob' src={measureIcon} />
                                        <button type='button' className='cardbutton p-l-25' style={{ backgroundColor: '#fd9344', color: '#ffffff', border: "none" }} onClick={this.props.back}>Finish</button>
                                    </IonCol>
                                </IonRow>
                            if (this.props.temp === 1)
                                return <IonRow className="bottom-menu">
                                    <IonCol className="d-none" size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                        {/* <i className="fa fa-circle cpeicon"></i> */}
                                        <img className='cpeicon' src={addCPEIcon} style={{ width: '38px', filter: 'contrast(0)' }} />
                                        <button type='button' className='cardbutton p-l-25' disabled>Add CPE</button>
                                    </IonCol>
                                    <IonCol size='6' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                        <img className='apicon' src={AP} style={{ filter: 'contrast(0)' }} />
                                        <button type='button' className='cardbutton p-l-25' disabled >Add CBSD</button>
                                    </IonCol>
                                    <IonCol size='6' style={{ padding: '5px' }}>
                                        <img className='measureiconmob' src={measureIcon} />
                                        <button type='button' className='cardbutton p-l-25' style={{ backgroundColor: '#fd9344', color: '#ffffff', border: "none" }} onClick={this.props.back}>Finish</button>
                                    </IonCol>
                                </IonRow>
                            if (this.props.temp === 2)
                                if (this.reduxStore['tabType'] == 'outdoor') {
                                    return <IonRow className="bottom-menu" style={{ bottom: '230px' }}>
                                        <IonCol className="d-none" size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            {/* <i className="fa fa-circle cpeicon"></i> */}
                                            <img className='cpeicon' src={addCPEIcon} style={{ width: '38px', filter: 'contrast(0)' }} />
                                            <button type='button' className='cardbutton p-l-25' disabled>Add CPE</button>
                                        </IonCol>
                                        <IonCol size='6' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            <img className='apicon' src={AP} style={{ filter: 'contrast(0)' }} />
                                            <button type='button' className='cardbutton p-l-25' disabled >Add CBSD</button>
                                        </IonCol>
                                        <IonCol size='6' style={{ padding: '5px' }}>
                                            <img className='measureiconmob' src={measureIcon} />
                                            <button type='button' className='cardbutton p-l-25' style={{ backgroundColor: '#fd9344', color: '#ffffff', border: "none" }} onClick={this.props.back}>Finish</button>
                                        </IonCol>
                                    </IonRow>
                                }
                                else {
                                    return <IonRow className="bottom-menu" >
                                        <IonCol className="d-none" size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            {/* <i className="fa fa-circle cpeicon"></i> */}
                                            <img className='cpeicon' src={addCPEIcon} style={{ width: '38px', filter: 'contrast(0)' }} />
                                            <button type='button' className='cardbutton p-l-25' disabled>Add CPE</button>
                                        </IonCol>
                                        <IonCol size='6' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                            <img className='apicon' src={AP} style={{ filter: 'contrast(0)' }} />
                                            <button type='button' className='cardbutton p-l-25' disabled >Add CBSD</button>
                                        </IonCol>
                                        <IonCol size='6' style={{ padding: '5px' }}>
                                            <img className='measureiconmob' src={measureIcon} />
                                            <button type='button' className='cardbutton p-l-25' style={{ backgroundColor: '#fd9344', color: '#ffffff', border: "none" }} onClick={this.props.back}>Finish</button>
                                        </IonCol>
                                    </IonRow>
                                }
                        })()}

                        {/* <i className="fas fa-circle measure" id='1' onClick={this.click1} style={this.state.mesh === '1' ? { bottom: '20%', left: '20%', position: 'fixed', color: '#f59343' } : { bottom: '20%', left: '20%', position: 'fixed' }}></i>
                    <i className="fas fa-circle measure" id='2' onClick={this.click2} style={this.state.mesh1 === '2' ? { bottom: '40%', left: '40%', position: 'fixed', color: '#f59343' } : { bottom: '40%', left: '40%', position: 'fixed' }}></i> */}
                        {/* <i className="fas fa-circle measure" id='1' onClick={this.click} style={this.state.val === '1' || this.state.val === '2' ? { bottom: '60%', left: '30%', position: 'fixed', color: '#f59343' } : { bottom: '60%', left: '30%', position: 'fixed' }}></i>
                    <i className="fas fa-circle measure" id='2' onClick={this.click} style={this.state.val === '2' ? { bottom: '50%', left: '20%', position: 'fixed', color: '#f59343' } : { bottom: '50%', left: '20%', position: 'fixed' }}></i> */}


                        {(() => {
                            if (this.props.temp === 0)
                                return <div className="col-12 measuredot">
                                    <i className="fas fa-circle p-r-5" style={{ fontSize: '6px', color: '#f59343' }}></i>
                                    <i className="fas fa-circle p-r-5" style={{ fontSize: '6px', color: '#cccccc' }}></i>
                                    <i className="fas fa-circle" style={{ fontSize: '6px', color: '#cccccc' }}></i>
                                </div>
                            if (this.props.temp === 1)
                                return <div className="col-12 measuredot">
                                    <i className="fas fa-circle p-r-5" style={{ fontSize: '6px', color: '#fdeada' }}></i>
                                    <i className="fas fa-circle p-r-5" style={{ fontSize: '6px', color: '#f59343' }}></i>
                                    <i className="fas fa-circle" style={{ fontSize: '6px', color: '#cccccc' }}></i>
                                </div>
                            if (this.props.temp === 2)
                                if (this.reduxStore['tabType'] == 'outdoor') {
                                    return <div className="col-12 measuredot" style={{ bottom: '207px' }}>
                                        <i className="fas fa-circle p-r-5" style={{ fontSize: '6px', color: '#fdeada' }}></i>
                                        <i className="fas fa-circle p-r-5" style={{ fontSize: '6px', color: '#fdeada' }}></i>
                                        <i className="fas fa-circle" style={{ fontSize: '6px', color: '#f59343' }}></i>
                                    </div>
                                }
                                else {
                                    return <div className="col-12 measuredot" >
                                        <i className="fas fa-circle p-r-5" style={{ fontSize: '6px', color: '#fdeada' }}></i>
                                        <i className="fas fa-circle p-r-5" style={{ fontSize: '6px', color: '#fdeada' }}></i>
                                        <i className="fas fa-circle" style={{ fontSize: '6px', color: '#f59343' }}></i>
                                    </div>
                                }
                        })()}

                        {(() => {
                            if (this.props.temp === 0)
                                return <div>
                                    <div className="arch">
                                        <img src={arch} style={{ height: "30px" }}></img>
                                    </div>
                                    <div className="card measuremob" style={{ position: 'fixed' }}>
                                        <div className="card-body text-center" style={{ padding: '15px', fontSize: '14px' }}>
                                            {(() => {
                                                if (this.props.temp === 0)
                                                    return <span >Select first CBSD</span>
                                                if (this.props.temp === 1)
                                                    return <span >Select Second CBSD</span>
                                                if (this.props.temp === 2)
                                                    return <span >Distance:<span style={{ color: '#f59343' }}> {this.props.distance} m
                                                    </span></span>
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            if (this.props.temp === 1)
                                return <div>
                                    <div className="arch">
                                        <img src={arch} style={{ height: "30px" }}></img>
                                    </div>
                                    <div className="card measuremob" style={{ position: 'fixed' }}>
                                        <div className="card-body text-center" style={{ padding: '15px', fontSize: '14px' }}>
                                            {(() => {
                                                if (this.props.temp === 0)
                                                    return <span >Select first CBSD</span>
                                                if (this.props.temp === 1)
                                                    return <span >Select Second CBSD</span>
                                                if (this.props.temp === 2)
                                                    return <span >Distance:<span style={{ color: '#f59343' }}> {this.props.distance} m
                                                        {/* {this.props.distance} */}
                                                    </span></span>
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            if (this.props.temp === 2) {
                                if (this.reduxStore['tabType'] == 'outdoor') {
                                    return <div>
                                        <div className="arch" style={{ bottom: '207px' }}>
                                            <img src={arch} style={{ height: "30px" }}></img>
                                        </div>
                                        <div className="card measuremob v-h-center" style={{ position: 'fixed', height: '212px', }}>
                                            <div className="col-12 p-0 m-t-10" style={{ height: '150px', zIndex: -1 }}>
                                                {this.state.chartVal && window.google ?
                                                    <Chart
                                                        width={'100%'}
                                                        height={'180px'}
                                                        chartType="ColumnChart"
                                                        loader={<div><br />Loading Chart</div>}
                                                        data={this.state.chartVal}
                                                        options={{
                                                            title: 'Elevation Profile',
                                                            vAxis: {
                                                                title: 'Elevation(m)',
                                                            },
                                                            hAxis: {
                                                                textPosition: 'none'
                                                            },
                                                            chartArea: { width: '80%', height: '180px', left: '15%' },
                                                            //curveType: 'function',
                                                            legend: 'none'
                                                        }}
                                                        rootProps={{ 'data-testid': '1' }}

                                                    /> : null
                                                }
                                            </div>
                                            <div className="card-body text-center" style={{ padding: '15px', fontSize: '14px', position: 'absolute', marginTop: '75px' }}>
                                                {(() => {
                                                    if (this.props.temp === 0)
                                                        return <span >Select first CBSD</span>
                                                    if (this.props.temp === 1)
                                                        return <span >Select Second CBSD</span>
                                                    if (this.props.temp === 2)
                                                        return <span >Distance:<span style={{ color: '#f59343' }}> {this.props.distance} m
                                                            {/* {this.props.distance} */}
                                                        </span></span>
                                                })()}
                                            </div>
                                        </div>
                                    </div>
                                }
                                else {
                                    return <div>
                                        <div className="arch" >
                                            <img src={arch} style={{ height: "30px" }}></img>
                                        </div>
                                        <div className="card measuremob v-h-center" style={{ position: 'fixed' }}>
                                            <div style={{ height: '150px', zIndex: -1 }}>
                                                {this.state.chartVal && window.google ?
                                                    <Chart
                                                        width={'100%'}
                                                        height={'180px'}
                                                        chartType="ColumnChart"
                                                        loader={<div><br />Loading Chart</div>}
                                                        data={this.state.chartVal}
                                                        options={{
                                                            title: 'Elevation Profile',
                                                            vAxis: {
                                                                title: 'Elevation(m)',
                                                            },
                                                            hAxis: {
                                                                textPosition: 'none'
                                                            },
                                                            chartArea: { width: '80%', height: '180px', left: '15%' },
                                                            //curveType: 'function',
                                                            legend: 'none'
                                                        }}
                                                        rootProps={{ 'data-testid': '1' }}

                                                    /> : null
                                                }
                                            </div>
                                            <div className="card-body text-center" style={{ padding: '15px', fontSize: '14px' }}>
                                                {(() => {
                                                    if (this.props.temp === 0)
                                                        return <span >Select first CBSD</span>
                                                    if (this.props.temp === 1)
                                                        return <span >Select Second CBSD</span>
                                                    if (this.props.temp === 2)
                                                        return <span >Distance:<span style={{ color: '#f59343' }}> {this.props.distance} m
                                                            {/* {this.props.distance} */}
                                                        </span></span>
                                                })()}
                                            </div>
                                        </div>
                                    </div>

                                }
                            }
                        })()}

                        {/* <div className="arch">
                            <img src={arch} style={{ height: "30px" }}></img>
                        </div> */}

                        {/* <div className="card measuremob" style={{ position: 'fixed' }}>
                            <div>
                                {this.state.chartVal && window.google ?
                                    <Chart
                                        width={'400px'}
                                        height={'200px'}
                                        chartType="LineChart"
                                        loader={<div>Loading Chart</div>}
                                        data={this.state.chartVal}
                                        options={{
                                            title: 'Elevation Profile',
                                            vAxis: {
                                                title: 'Elevation(m)',
                                            },
                                            hAxis: {
                                                textPosition: 'none'
                                            },
                                            curveType: 'function',
                                            legend: 'none'
                                        }}
                                        rootProps={{ 'data-testid': '1' }}

                                    /> : null
                                }
                            </div>
                            <div className="card-body text-center" style={{ padding: '15px', fontSize: '14px' }}>
                                {(() => {
                                    if (this.props.temp === 0)
                                        return <span >Select first CBSD or CPE</span>
                                    if (this.props.temp === 1)
                                        return <span >Select Second CBSD or CPE</span>
                                    if (this.props.temp === 2)
                                        return <span >Distance:<span style={{ color: '#f59343' }}> {this.props.distance} m
                                        </span></span>
                                })()}
                            </div>
                        </div> */}
                    </div>
                }
            </div>

        );
    }
}
