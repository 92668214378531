import React, { Component } from "react";
import Dropz from "./DropZ";
import "./ap-parameters.css";
import Back from "../assets/img/left-arrow.svg";
import { IonPage, IonContent, withIonLifeCycle, IonAlert, IonLoading } from "@ionic/react";
import camicon from "../assets/img/camera-icon-img.png";
import checkSuccess from "../assets/img/check-success.png";
import BackIcon from "../assets/img/go-back-icon-img.png";
import Cookies from "js-cookie";
import { requestAPICall } from "../service/apiHandler";
import store from "../store";
import Dropzone from "react-dropzone";
import Modal from "./modal";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import { file } from "@babel/types";

const { Camera } = Plugins;

var sector_property: any = [];
var cbsd_list: any = [];
var installation_list: any = [];
var optional_list: any = [];
var select_vendor = "";
var unit: any = "";
var sector_ids: any = [];
var element_list: any = [];
var global_list: any = [];
var other_list: any = [];
var vendor_model_list: any = [];
var property_vendor_model_list: any = [];
class params extends React.Component {
  state: any = {};
  props: any = {};
  paramFocus: any = [];
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.state = {
      files: [],
      p12cert: [],
      alertbox: false,
      warnMessage: "",
      isParameterSubmitted: false,
      asset_installation_property: [],
      cpeVendorList: [],
      assetVendorList: [],
      CPEparams: {
        installation: [],
        cbsd: [],
        global: [],
        optional: [],
      },
      assetId: "",
      assetTypeId: 0,
      sectorIds: [],
      vendor_id: 0,
      cpiPassword: "",
      cpiId: "",
      cpiName: "",
      p12Path: "",
      isLoading: false,
      override_cpi: false,
      is_indoor_deployment: false,
      global_list_value: [],
      optional_list_value: [],
      cbsd_list_value: [],
      installation_list_value: [],
      other_list_value: [],
      sector_id: "",
      selectedVendorId: "",
      selected_vendor: "",
      defaultAPVendorId: "",
      defaultCpeVendorId: "",
      defaultProviderId: "",
      submitToCloud: false,
      dtimg: false,
      heightimg: false,
      azimuthimg: false,
      siteId: "",
      enb: "",
      paramStat: 0,
      certificateSubmitted: false,
      isUploadImage: false,
      edit_vendor: true,
      isEditParam: false,
      sasSubmission: false,
      sectorParamStatus: "",
    };
    this.getUserProfile = this.getUserProfile.bind(this);
    this.param_actions = this.param_actions.bind(this);
  }

  componentDidMount() {
    // //console.log("====componentDidMount====")
    this.reduxStore = store.getState()["general"];
    //console.log(this.props);
    //console.log(this.reduxStore);
    this.setState({
      isParameterSubmitted: false,
    });
    if (this.reduxStore["assetId"] !== undefined) {
      this.initialize();
    }
  }

  initialize() {
    //console.log(this.props);
    cbsd_list = [];
    installation_list = [];
    optional_list = [];
    global_list = [];
    other_list = [];
    if (!this.isEmpty(this.props.assetInfo["sasStatus"])) {
      //console.log("====== SAS Registration found====");
      this.setState({
        edit_vendor: false,
      });
    }
    // //console.log(this.reduxStore['NOS'])
    this.getDefaultSettings();

    this.setState(
      {
        assetId: this.reduxStore["assetId"],
        assetTypeId: this.reduxStore["assetTypeId"],
        // NOS: this.reduxStore['NOS'],
        sectorIds: this.reduxStore["sectorId"],
        vendor_id: this.reduxStore["assetVendorId"],
        CPEparams: this.reduxStore["CPEparams"],
        siteId: this.props.assetInfo.assetIdentifierId,
      },
      () => {
        //console.log(this.state);
        this.setState({
          isLoading: true,
        });

        if (this.state.assetTypeId == 1) {
          this.getEnb(this.props["assetInfo"]["properties"]);
          this.get_sector_properties(this.state.sectorIds[0].sector_id, this.state.sectorIds[0].sector_value);
          // this.getDefaultSettings()
          this.listVendorAPI();
        } else {
          installation_list = this.state.CPEparams["installation"];
          optional_list = this.state.CPEparams["optional"];
          cbsd_list = this.state.CPEparams["cbsd"];
          global_list = this.state.CPEparams["global"];
          other_list = this.state.CPEparams["others"];
          let cbsd_category = this.state.CPEparams["optional"].filter(
            (property: any) => property.propertyName === "cbsd_category"
          );
          this.setState(
            {
              asset_installation_property: this.state.CPEparams,
              cbsd_category: cbsd_category[0]["value"],
            },
            () => {
              this.calculateHAAT();
            }
          );
          this.listVendorAPI();
        }
        this.param_actions(optional_list);
        // this.setIndoorDeplyment(installation_list)
        this.parameterStatus();
        this.getUserProfile();
      }
    );
  }
  isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };
  getEnb = (prop: any) => {
    prop.forEach((property: any) => {
      if (property.propertyName == "enb_id") {
        this.setState({
          enb: property.value,
        });
      }
    });
  };
  setIndoorDeplyment = (installation_list: any) => {
    if (this.state.assetTypeId == 1 || this.state.assetTypeId == 2) {
      this.setState({
        is_indoor_deployment: false,
      });
      installation_list.forEach((property: any) => {
        if (property.propertyName == "indoor_deployment") {
          property.value = 0;
        }
      });
    }
    if (this.state.assetTypeId == 3 || this.state.assetTypeId == 4) {
      this.setState({
        is_indoor_deployment: true,
      });
      installation_list.forEach((property: any) => {
        if (property.propertyName == "indoor_deployment") {
          property.value = 1;
        }
      });
    }
  };
  getDefaultSettings() {
    requestAPICall("get_default_setings", {}, {}).then((data) => {
      if (data.responseCode === 200) {
        this.setState(
          {
            defaultCpeVendorId: data.defaultCpeVendorId,
            defaultApVendorId: data.defaultApVendorId,
            defaultProviderId: data.defaultProviderId,
          },
          () => {
            this.listVendorAPI();
          }
        );
      }
    });
  }
  param_actions = (optional_list: any) => {
    //console.log(optional_list);
    optional_list.map((optional: any) => {
      if (optional.propertyName === "cbsd_category") {
        this.setState({
          get_cbsd_category: optional.value,
        });
        //console.log(optional.value);
        //console.log(localStorage.getItem("userRole"));
        if (optional.value === "DEVICE_CATEGORY_A" && localStorage.getItem("userRole") === "Installer") {
          this.setState({
            submit_param: true,
          });
        } else if (optional.value !== "DEVICE_CATEGORY_A" && localStorage.getItem("userRole") === "Installer") {
          this.setState({
            submit_param: false,
          });
        } else if (localStorage.getItem("userRole") !== "Installer") {
          this.setState({
            submit_param: true,
          });
        }
      }
    });
  };
  listVendorAPI() {
    var listVendorUrl = "list_particular_vendor";
    var listVendorInput: any = {
      asset_type_id: this.props["assetInfo"]["assetTypeId"],
    };
    if (this.reduxStore["assetTypeId"] == 1 || this.reduxStore["assetTypeId"] == 6) {
      listVendorUrl = "list_directional_vendor";
      listVendorInput = {
        asset_vendor_type: this.props.assetInfo.properties.filter(
          (check: any) => check["propertyName"] == "ap_mounting_type"
        )[0]["value"],
        vendor_type: this.props.assetInfo.properties.filter((check: any) => check["propertyName"] == "vendor_type")[0][
          "value"
        ],
      };
    }
    //console.log("data for asset props ", this.props.assetInfo.properties);
    //console.log("data for api ", listVendorUrl);
    //console.log("data for api request ", listVendorInput);
    requestAPICall(listVendorUrl, listVendorInput, {}).then((data) => {
      //console.log(data);
      if (data["vendor_list"] !== undefined && data["vendor_list"].length !== 0) {
        let vendor_model = cbsd_list.filter((property: any) => property.propertyName === "cbsd_vendor_model");
        try {
          //console.log(this.state.defaultApVendorId, "vvvvvvvvvvv");
          if (vendor_model[0].value === "" || vendor_model[0].value === undefined) {
            if (this.state.defaultApVendorId !== null) {
              var model_value = data["vendor_list"].filter((property: any) => {
                if (property.vendorModalId === this.state.defaultApVendorId) {
                  return property;
                }
              });
              vendor_model_list = model_value[0];
            }
            if (this.state.defaultCpeVendorId !== null) {
              var model_value = data["vendor_list"].filter((property: any) => {
                if (property.vendorModalId === this.state.defaultCpeVendorId) {
                  return property;
                }
              });
              vendor_model_list = model_value[0];
            }
          }
        } catch (error) {}

        this.setState(
          {
            assetVendorList: data["vendor_list"],
          },
          () => {
            if (data["vendor_list"].length > 0) {
              this.set_dropdown_label("cbsdmodel1", data["vendor_list"][0]);
            }
          }
        );
      }
    });
  }

  set_ap_cbsd_value = (
    cbsd_list_value: any,
    optional_list_value: any,
    installation_list_value: any,
    global_list_value: any
  ) => {
    if (vendor_model_list != undefined) {
      cbsd_list_value.forEach((property: any) => {
        if (property.propertyName == "cbsd_software_version") {
          property.value = vendor_model_list["software_version"];
        } else if (property.propertyName == "cbsd_hardware_version") {
          property.value = vendor_model_list["hardware_version"];
        } else if (property.propertyName == "cbsd_firmware_version") {
          property.value = vendor_model_list["firmware_version"];
        } else if (property.propertyName == "cbsd_vendor_model") {
          property.value = vendor_model_list["name"];
        }
      });
      installation_list_value.forEach((property: any) => {
        if (property.propertyName == "antenna_beamwidth") {
          property.value = vendor_model_list["antenna_beamwidth"];
        } else if (property.propertyName == "antenna_gain") {
          property.value = vendor_model_list["antenna_gain"];
        } else if (property.propertyName == "eirp_capability") {
          property.value = vendor_model_list["eirp_capability"];
        } else if (property.propertyName == "antenna_model") {
          property.value = vendor_model_list["antenna_model"];
        }
      });
      global_list_value.forEach((property: any) => {
        if (property.propertyName == "fcc_id") {
          property.value = vendor_model_list["fcc_id"];
        }
      });
      optional_list_value.forEach((property: any) => {
        if (property.propertyName == "cbsd_category") {
          property.value = vendor_model_list["category"];
          this.setState({
            get_cbsd_category: vendor_model_list["category"],
          });
        } else if (property.propertyName == "air_interface") {
          property.value = vendor_model_list["technology_name"];
        }
      });
      cbsd_list = cbsd_list_value;
      optional_list = optional_list_value;
      installation_list = installation_list_value;
      global_list = global_list_value;
      this.setState({
        cbsd_category: vendor_model_list["category"],
        optional_list_value: optional_list,
        cbsd_list_value: cbsd_list,
        global_list_value: global_list,
        installation_list_value: installation_list,
      });
    }
    //console.log(optional_list, cbsd_list, "oooooooooooooooo");
  };

  get_sector_properties = (sectorId: any, sectorValue: any) => {
    this.setState({
      isLoading: true,
    });

    this.setState({
      sector_id: sectorId,
      sector_value: sectorValue,
      isParameterSubmitted: false,
      isEditParam: false,
      files: [],
    });
    requestAPICall(
      "get_sector_details",
      {
        asset_type_id: this.state.assetTypeId,
        asset_id: this.state.assetId,
        sector_id: sectorId,
      },
      {}
    ).then((data) => {
      //console.log(data);
      if (data.responseCode === 200) {
        sector_property = data["properties"];
        installation_list = data["properties"]["installation"];
        optional_list = data["properties"]["optional"];
        cbsd_list = data["properties"]["cbsd"];
        global_list = data["properties"]["global"];
        other_list = data["properties"]["others"];
        let cbsd_category = data["properties"]["optional"].filter(
          (property: any) => property.propertyName === "cbsd_category"
        );

        var htimg =
          data["properties"]["installation"].filter((property: any) => property.propertyName === "height_image")[0]
            .value != "";
        var azimg =
          data["properties"]["installation"].filter((property: any) => property.propertyName === "azimuth_image")[0]
            .value != "";
        var dtimg =
          data["properties"]["installation"].filter((property: any) => property.propertyName === "down_tilt_image")[0]
            .value != "";

        this.setState(
          {
            showvalue: true,
            asset_installation_property: sector_property,
            cbsd_category: cbsd_category[0]["value"],
            dtimg: dtimg,
            heightimg: htimg,
            azimuthimg: azimg,
            sectorParamStatus: data["status"] !== null ? data["status"][1] : "",
          },
          () => {
            if (this.state.submitToCloud == false) this.calculateHAAT();
          }
        );
        this.listVendorAPI();
        this.parameterStatus();
        this.param_actions(data["properties"]["optional"]);
        this.set_dropdown_label("heightype1", "HEIGHT_TYPE_AGL");
        this.set_dropdown_label("measurementcap1", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT");
        this.set_dropdown_label("optionalcalsign1", "1");
        this.set_dropdown_label("optionalgrparam", "1");
      }
      this.setState({
        isLoading: false,
      });
      // this.setIndoorDeplyment(installation_list)
    });
  };
  calculateHAAT = () => {
    this.setState({
      isLoading: true,
    });

    var lat = 0;
    var lon = 0;
    var rad_center = "0";

    this.state.asset_installation_property.installation.forEach((property: any) => {
      if (property.propertyName == "latitude") {
        lat = property.value;
      }
      if (property.propertyName == "longitude") {
        lon = property.value;
      }
      if (property.propertyName == "height") {
        rad_center = property.value;
      }
    });
    let requestBody = {
      lat: lat,
      lon: lon,
      nradial: 8,
      src: "ned_1",
      rad_center: parseFloat(rad_center) * 3.281,
      unit: "m",
    };
    //console.log(requestBody);
    requestAPICall("paramCalc", {}, requestBody).then((data) => {
      //console.log(data);
      if (data.responseCode == 200) {
        installation_list.forEach((property: any) => {
          if (property.propertyName == "haat") {
            property.value = data.HAAT;
          }
        });
      }
      this.setState({
        isLoading: false,
      });
    });
  };

  clear_installation_value = () => {
    installation_list.forEach((element: any) => {
      if (element.propertyName === "height_type") {
        element.value = "";
      } else if (element.propertyName === "horizantal_accuracy") {
        element.value = "0";
      } else if (element.propertyName === "vertical_accuracy") {
        element.value = "0";
      } else if (element.propertyName === "down_tilt") {
        element.value = "0";
      } else if (element.propertyName === "measurement_capability") {
        element.value = "";
      } else if (element.propertyName === "antenna_pattern") {
        element.value = "";
      } else if (element.propertyName === "haat") {
        element.value = "";
      }
    });
    this.setState({
      installation_list: installation_list,
    });
  };

  clear_cbsd_value = () => {
    cbsd_list.forEach((element: any) => {
      if (element.propertyName === "cbsd_vendor_model") {
        element.value = "";
      } else if (element.propertyName === "cbsd_software_version") {
        element.value = "0";
      } else if (element.propertyName === "cbsd_hardware_version") {
        element.value = "0";
      } else if (element.propertyName === "cbsd_firmware_version") {
        element.value = "0";
      }
    });
    installation_list.forEach((element: any) => {
      if (element.propertyName === "antenna_beamwidth") {
        element.value = "";
      } else if (element.propertyName === "antenna_gain") {
        element.value = "0";
      } else if (element.propertyName === "eirp_capability") {
        element.value = "0";
      } else if (element.propertyName === "antenna_model") {
        element.value = "0";
      }
    });
    optional_list.map((element: any) => {
      if (element.propertyName === "cbsd_category") {
        element.value = "";
      } else if (element.propertyName === "air_interface") {
        element.value = "";
      }
    });
    this.setState({
      cbsd_list: cbsd_list,
      installation_list: installation_list,
      optional_list: optional_list,
    });
  };

  clear_optional_value = () => {
    optional_list.forEach((element: any) => {
      if (element.propertyName === "call_sign") {
        element.value = "0";
      } else if (element.propertyName === "grouping_param") {
        element.value = "0";
      } else if (element.propertyName === "supported_spec") {
        element.value = "";
      }
    });
    this.setState({
      optional_list: optional_list,
    });
  };
  onDrop = (files: any, type: any) => {
    if (type !== undefined && type === "p12") {
      this.setState({
        p12cert: files,
      });
    } else {
      this.setState({ files: files, clearImage: false });
    }
  };
  onClear = (files: any) => {
    this.setState({ files: files, clearImage: true });
  };
  select_onChange(e: any, type: any, property: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    this.set_dropdown_label(id, e.target.value);
    if (type === "cbsd") {
      cbsd_list.map((cbsd: any) => {
        if (cbsd.propertyName === property) {
          cbsd.value = e.target.value;
        }
      });
    } else if (type === "optional") {
      optional_list.map((optional: any) => {
        if (optional.propertyName === property) {
          optional.value = e.target.value;
        }
      });
    }
  }
  onChange(e: any, field: string) {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    if (field == "installation") {
      installation_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    } else if (field == "cbsd") {
      cbsd_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    } else if (field == "optional") {
      optional_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    } else if (field == "global") {
      global_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    } else if (field == "other") {
      other_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    }
  }
  assetPropChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "enb_id") {
      this.setState({ enb: e.target.value }, () => {});
    }
    this.props.assetInfo.properties.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
    });
  }
  is_override_cpi(e: any) {
    //console.log(e.target.checked);
    this.setState({
      override_cpi: e.target.checked,
    });
  }
  cpiSaveChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  cbsd_category(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    //console.log(e.target.value);
    optional_list.map((optional: any) => {
      if (optional.propertyName === "cbsd_category") {
        optional.value = e.target.value;
      }
    });
    //console.log(cbsd_list, "ccccccccccccccc");
  }
  installation_height(e: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    this.set_dropdown_label(id, e.target.value);
    //console.log(e.target.value);
    installation_list.map((installation: any) => {
      if (installation.propertyName === "height_type") {
        installation.value = e.target.value;
      }
    });
  }
  set_dropdown_label(id: any, value: any) {
    //console.log(id, value);
    if (value === "") {
      $("#" + id).css({
        top: "14px",
        fontSize: "14px",
      });
      $("#" + id).show();
    } else {
      $("#" + id).css({
        top: "5px",
        fontSize: "12px",
      });
      $("#" + id).show();
    }
  }
  installation_mc = (e: any, id: any) => {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    this.set_dropdown_label(id, e.target.value);
    //console.log(e.target.value);
    installation_list.map((installation: any) => {
      if (installation.propertyName === "measurement_capability") {
        installation.value = e.target.value;
      }
    });
  };
  air_interface(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    //console.log(e.target.value);
    optional_list.map((optional: any) => {
      if (optional.propertyName === "air_interface") {
        optional.value = e.target.value;
      }
    });
  }
  indoor_deployment_check(e: any) {
    //console.log(e.target.checked);
    this.setState({
      is_indoor_deployment: e.target.checked,
      isEditParam: true,
    });
    if (e.target.checked == true)
      installation_list.forEach((property: any) => {
        if (property.propertyName == "indoor_deployment") {
          property.value = 1;
        }
      });
    if (e.target.checked == false)
      installation_list.forEach((property: any) => {
        if (property.propertyName == "indoor_deployment") {
          property.value = 0;
        }
      });
  }

  setAssetVendor = (e: any, id: any) => {
    try {
      select_vendor = e.target.value;
      //console.log(select_vendor);

      cbsd_list.map((cbsd: any) => {
        if (cbsd.propertyName === "cbsd_vendor_model") {
          cbsd.value = e.target.value;
        }
      });
      this.set_dropdown_label(id, e.target.value);
      if (e.target.value !== undefined) {
        var model_value = this.state.assetVendorList.filter((property: any) => {
          if (property.name === select_vendor) {
            //console.log(property.name);
            return property;
          }
        });
        //console.log(model_value);
        vendor_model_list = model_value[0];
        this.setState({
          selectedVendorId: model_value[0].vendorModalId,
          selected_vendor: model_value[0].name,
        });
        this.set_ap_cbsd_value(cbsd_list, optional_list, installation_list, global_list);
      }
      //console.log(this.state.selected_vendor);
    } catch (error) {}
  };
  showModal = () => {
    let check = true;
    //console.log(this.state.asset_installation_property);
    this.state.asset_installation_property["global"].forEach((property: any, key: any) => {
      if (property.propertyName === "user_id" && property.value == "") {
        this.paramFocus[key].focus();
        check = false;
      }
    });
    if (check) {
      this.getUserProfile();
      this.setState({
        show: true,
        override_cpi: false,
      });
    }
    //console.log("Show modal");
  };
  getUserProfile() {
    //console.log("GET USER PROFILE============");
    let userList: any = {};
    requestAPICall("get_user_profile", {}, {}).then((data) => {
      if (data.responseCode === 200) {
        //console.log(data);
        userList = data["user_list"];

        this.setState({
          cpiId: userList["cpiId"],
          cpiName: userList["cpiName"],
          registeredCpi: userList["registeredCpi"],
          p12Path: userList["p12Path"],
        });
        if (userList["isSubmitted"] == true) {
          this.setState({
            certificateSubmitted: true,
          });
        }
      }
    });
    //console.log(this.state.cpiId);
    //console.log(this.state.cpiName);
    //console.log(this.state.certificateSubmitted);
  }
  saveParameters = () => {
    this.setState({
      isLoading: true,
    });

    let requestBody: any = {};
    if (this.state.override_cpi == true) {
      requestBody = {
        username: localStorage.getItem("username"),
        parameterDescription: "Send to SAS",
        // "parameterStatus": "Submitted",
        assetId: this.reduxStore["assetId"],
        sectorId: this.state.sector_id,
        sectorName: this.state.sector_value,
        properties: this.state.asset_installation_property,
        category: this.state.cbsd_category,
      };
      if (this.state.p12path != "" && this.state.cpiPassword != "") {
        requestBody["fileSubmission"] = true;
      }
      //console.log(requestBody);
      this.submitToSAS(requestBody);
    } else {
      requestBody = {
        username: localStorage.getItem("username"),
        parameterDescription: "Send to SAS",
        parameterStatus: "Submitted",
        cpiId: this.state.cpiId,
        cpiName: this.state.cpiName,
        registeredCpi: this.state.registeredCpi,
        assetId: this.reduxStore["assetId"],
        sectorId: this.state.sector_id,
        sectorName: this.state.sector_value,
        properties: this.state.asset_installation_property,
        category: this.state.cbsd_category,
        deviceStatus: this.state.paramStat,
        p12: "",
        p12Password: "",
        fileSubmission: true,
      };
      if ((this.state.p12path == "" || this.state.p12path == undefined) && this.state.cpiPassword == "") {
        requestBody["fileSubmission"] = false;
      }
      //console.log(requestBody);
      if (this.state.p12cert.length == 0) {
        this.submitToSAS(requestBody);
      }
    }
    if (this.state.p12cert != undefined && this.state.p12cert.length > 0) {
      //console.log(this.state.p12cert[0].name);
      var picReader = new FileReader();
      let filerequestBody = {};
      let fileName = this.state.p12cert[0].name;
      let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
      picReader.onload = (e) => {
        filerequestBody = {
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
          type: "p12",
        };
        requestAPICall("upload_file", {}, filerequestBody).then((data) => {
          //console.log(data);
          requestBody["p12"] = data.file_path;
          requestBody["p12Password"] = this.state.cpiPassword;
          this.submitToSAS(requestBody);
        });
      };
      picReader.readAsDataURL(this.state.p12cert[0]);
    }
    // TODO:
    this.props.refreshAsset(this.reduxStore["assetId"]);
    this.props.vanishPopup();
  };
  parameterStatus = () => {
    //console.log("GET STATUS============");
    let requestBody = {};
    requestAPICall(
      "param_status",
      {
        asset_id: this.props["assetInfo"]["assetId"],
        sector_id: this.state.sector_id,
      },
      requestBody
    ).then((data) => {
      if (data.responseCode == 200) {
        this.setState({
          paramStat: data.ParamstatusCode,
        });
        if ([3, 4, 5, 6, 11].includes(this.state.paramStat)) {
          this.setState({
            sasSubmission: true,
          });
        } else {
          this.setState({
            sasSubmission: false,
          });
        }
      }
      //console.log(this.state.paramStat);
    });
  };
  submitToSAS = (requestBody: any) => {
    if ([3, 4, 5, 6, 11].includes(this.state.paramStat)) {
      //console.log("UPDATE DEVICE");

      requestAPICall("update_device", {}, requestBody).then((data) => {
        this.setState({
          isLoading: false,
        });

        //console.log(data);
        if (data.responseCode == 200) {
          this.props.refreshStatus();
          this.setState({
            isLoading: false,
            isParameterSubmitted: true,
            warnMessage: data.message,
            alertbox: true,
          });
          this.parameterStatus();
        } else {
          this.setState({
            isLoading: false,
            warnMessage: data.message,
            alertbox: true,
            isParameterSubmitted: false,
          });
        }
      });
    } else if ([13, 14, 8].includes(this.state.paramStat)) {
      requestAPICall("replace_device", {}, requestBody).then((data) => {
        this.setState({
          isLoading: false,
        });

        //console.log(data);
        if (data.responseCode == 200) {
          this.props.refreshStatus();
          this.setState({
            isLoading: false,
            isParameterSubmitted: true,
            warnMessage: data.message,
            alertbox: true,
          });
          this.parameterStatus();
          this.props.backToAP();
        } else {
          this.setState({
            isLoading: false,
            warnMessage: data.message,
            alertbox: true,
            isParameterSubmitted: false,
          });
        }
      });
    } else {
      //console.log("CREATE DEVICE");
      // if (this.state.cbsd_category == "DEVICE_CATEGORY_A") {
      //     requestBody['parameterStatus'] = "Ready for CPI"
      // } else if (this.state.cbsd_category == "DEVICE_CATEGORY_B") {
      //     requestBody['parameterStatus'] = "Pending registration"

      // }
      requestAPICall("submit_to_sas", {}, requestBody).then((data) => {
        this.setState({
          isLoading: false,
        });
        //console.log(data);
        if (data.responseCode == 200) {
          this.props.refreshStatus();
          this.setState({
            isParameterSubmitted: true,
            warnMessage: data.message,
            alertbox: true,
          });
          this.parameterStatus();
          this.props.backToAP();
        } else {
          this.setState({
            warnMessage: data.message,
            alertbox: true,
            isParameterSubmitted: false,
          });
        }
      });
    }
  };
  hideModal = () => {
    this.setState({
      show: false,
      override_cpi: false,
      p12cert: [],
    });
  };
  updateAssetBasicParams = () => {
    let requestBody = {
      assetName: this.props.assetInfo["assetName"],
      properties: this.props.assetInfo["properties"],
      status: this.props.assetInfo.operationalStatus,
    };
    //console.log(requestBody);
    requestAPICall(
      "update_enb",
      {
        asset_type_id: this.reduxStore["assetTypeId"],
        asset_id: this.props.assetInfo["assetId"],
      },
      requestBody
    ).then((data) => {
      this.setState({
        isLoading: false,
      });
      if (data.responseCode == 200) {
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  };
  updateAssetParameters = () => {
    //console.log("update asset params");
    // if (this.state.assetTypeId == 1) {
    //     this.updateAssetBasicParams()
    // }
    this.setState({
      isLoading: true,
    });

    var requestBody = {
      vendorId: this.state.selectedVendorId,
      properties: {
        cbsd: cbsd_list,
        optional: optional_list,
        installtion: installation_list,
        global: global_list,
        others: other_list,
      },
    };
    requestAPICall(
      "update_asset_install_details",
      { asset_type_id: this.state.assetTypeId, asset_id: this.state.assetId },
      requestBody
    ).then((data) => {
      this.setState(
        {
          isLoading: false,
        },
        () => {
          if (data.responseCode == 200) {
            this.parameterStatus();
            this.send_button();
            this.props.refreshAsset(this.reduxStore["assetId"]);
            this.props.vanishPopup();
            this.props.backToAP();
            cbsd_list = data["installation_params"]["cbsd"];
            installation_list = data["installation_params"]["installation"];
            optional_list = data["installation_params"]["optional"];
            global_list = data["installation_params"]["global"];
            other_list = data["installation_params"]["others"];
            this.setState({
              warnMessage: data.message,
              alertbox: true,
              submitToCloud: true,
              isParameterSubmitted: false,
            });
          } else {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
              submitToCloud: false,
              isParameterSubmitted: false,
            });
          }
        }
      );
    });
  };
  updateSectorParameters = () => {
    //console.log("update sector params");
    // if (this.state.assetTypeId == 1) {
    //     this.updateAssetBasicParams()
    // }
    this.setState({
      isLoading: true,
    });

    var requestBody = {
      vendorId: this.state.selectedVendorId,
      properties: {
        cbsd: cbsd_list,
        optional: optional_list,
        installtion: installation_list,
        global: global_list,
        others: other_list,
      },
    };
    requestAPICall(
      "update_sector_details",
      {
        asset_type_id: this.state.assetTypeId,
        asset_id: this.state.assetId,
        sector_id: this.state.sector_id,
      },
      requestBody
    ).then((data) => {
      //console.log(data);
      this.get_sector_properties(this.state.sector_id, this.state.sector_value);
      this.parameterStatus();
      this.send_button();
      this.props.backToAP();
      this.props.refreshAsset(this.reduxStore["assetId"]);
      this.props.vanishPopup();

      this.setState(
        {
          isLoading: false,
        },
        () => {
          if (data.responseCode == 200) {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
              isParameterSubmitted: false,
              submitToCloud: true,
            });
          } else {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
              isParameterSubmitted: false,
              submitToCloud: false,
            });
          }
        }
      );
      // localStorage.setItem('refresh_asset', 'true');
    });
  };
  sendParameters = (e: any) => {
    this.setState({
      isLoading: true,
    });
    let check = true;

    this.state.asset_installation_property["global"].forEach((property: any, key: any) => {
      if (property.propertyName === "user_id" && property.value == "") {
        this.paramFocus[key].focus();
        check = false;
      }
    });

    if (check) {
      var paramDesc = "";
      var paramStatus = "";
      if (
        this.state.sectorParamStatus == "Replacement in Progress" ||
        this.state.sectorParamStatus == "Requested for Replacement"
      ) {
        paramDesc = "Requested for Replacement";
        paramStatus = "Requested for Replacement";
      } else {
        paramDesc = "Save As Draft";
        paramStatus = "Draft";
      }
      let requestBody = {
        parameterDescription: paramDesc,
        parameterStatus: paramStatus,
        assetId: this.reduxStore["assetId"],
        sectorId: this.state.sector_id,
        sectorName: this.state.sector_value,
        properties: this.state.asset_installation_property,
        deviceStatus: this.state.paramStat,
      };
      if (this.state.paramStat == 0) {
        requestAPICall("submit_to_cpi", {}, requestBody).then((data) => {
          //console.log(data);
          this.setState(
            {
              isLoading: false,
            },
            () => {
              if (data["responseCode"] == 200) {
                this.props.refreshStatus();
                this.parameterStatus();
                this.props.refreshAsset(this.reduxStore["assetId"]);
                this.props.vanishPopup();
                this.setState({
                  isParameterSubmitted: true,
                  warnMessage: data.message,
                  alertbox: true,
                });
              } else {
                this.setState({
                  isParameterSubmitted: false,
                  warnMessage: data.message,
                  alertbox: true,
                });
              }
            }
          );
        });
      } else {
        requestAPICall("update_to_cpi", {}, requestBody).then((data) => {
          //console.log(data);
          this.setState(
            {
              isLoading: false,
            },
            () => {
              if (data["responseCode"] == 200) {
                this.props.refreshStatus();
                this.parameterStatus();
                this.props.refreshAsset(this.reduxStore["assetId"]);
                this.props.vanishPopup();
                this.setState({
                  isParameterSubmitted: true,
                  warnMessage: data.message,
                  alertbox: true,
                });
              } else {
                this.setState({
                  isParameterSubmitted: false,
                  warnMessage: data.message,
                  alertbox: true,
                });
              }
            }
          );
        });
      }
    }
  };
  // sendParameters = (e: any) => {
  //     let check = true;

  //     this.state.asset_installation_property['global'].forEach((property: any, key: any) => {
  //         if (property.propertyName === "user_id" && property.value == "") {
  //             this.paramFocus[key].focus()
  //             check = false

  //         }
  //     })

  //     if (check) {
  //         this.setState({
  //             isLoading: true,
  //         })
  //         let requestBody = {
  //             "parameterDescription": "Save As Draft",
  //             "parameterStatus": "Draft",
  //             "assetId": this.state.assetId,
  //             "sectorId": this.state.sector_id,
  //             "sectorName": this.state.sector_value,
  //             "properties": this.state.asset_installation_property

  //         }
  //         requestAPICall("send_parameter", {}, requestBody).then(data => {
  //             //console.log(data)
  //             if (data['responseCode'] == 200) {

  //                 this.setState({
  //                     isLoading: false,
  //                     isParameterSubmitted: true,
  //                     warnMessage: data.message,
  //                     alertbox: true,
  //                 })
  //                 this.parameterStatus()
  //             } else {
  //                 this.setState({
  //                     isLoading: false,
  //                     isParameterSubmitted: false,
  //                     warnMessage: data.message,
  //                     alertbox: true,
  //                 })
  //             }
  //         })
  //     }
  // }
  setShowAlert = (st: any) => {
    this.setState({
      alertbox: st,
      warnMessage: "",
    });
  };
  send_button = () => {
    //console.log(this.state.paramStat);
    //console.log(this.state.isParameterSubmitted);
    this.set_dropdown_label("heightype1", "HEIGHT_TYPE_AGL");
    this.set_dropdown_label("measurementcap1", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT");
    this.set_dropdown_label("optionalcalsign1", "1");
    this.set_dropdown_label("optionalgrparam", "1");
    if (this.state.isParameterSubmitted == false) {
      if (localStorage.getItem("userRole") === "Installer") {
        if (this.state.paramStat == 0) {
          // COMMENTED ON 9-7-20 DUE TO CHANGING CATEGORY A WILL CALLING CREATESIGNED
          // if (this.state.get_cbsd_category == "DEVICE_CATEGORY_A" && this.state.defaultProviderId != 2) {
          //     return <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Submit to SAS</button>
          // } else {
          //     return <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.sendParameters(e) }}  >Submit to CPI</button>
          // }

          return (
            <button
              className="btn cardbtn submitbtn m-t-10 m-b-10"
              disabled={!this.state.submitToCloud}
              onClick={(e: any) => {
                e.preventDefault();
                this.sendParameters(e);
              }}
            >
              Submit to Cloud
            </button>
          );
        } else {
          // COMMENTED ON 9-7-20 DUE TO CHANGING CATEGORY A WILL CALLING CREATESIGNED
          // if (this.state.get_cbsd_category == "DEVICE_CATEGORY_A" && this.state.defaultProviderId != 2) {
          //     return <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Update to SAS</button>
          // } else {
          //     return <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.sendParameters(e) }}  >Update to CPI</button>
          // }

          return (
            <button
              className="btn cardbtn submitbtn m-t-10 m-b-10"
              disabled={!this.state.submitToCloud}
              onClick={(e: any) => {
                e.preventDefault();
                this.sendParameters(e);
              }}
            >
              Update to Cloud
            </button>
          );
        }
      } else if ([0, 1, 2, 10, 12].includes(this.state.paramStat)) {
        return (
          <button
            className="btn cardbtn submitbtn m-t-10 m-b-10"
            disabled={!this.state.submitToCloud}
            onClick={(e: any) => {
              e.preventDefault();
              this.showModal();
            }}
            data-toggle="modal"
            data-target="#submitModal"
          >
            Submit to SAS
          </button>
        );
      } else if ([3, 4, 5, 6, 7, 8, 9, 11, 13, 14].includes(this.state.paramStat)) {
        return (
          <button
            className="btn cardbtn submitbtn m-t-10 m-b-10"
            disabled={!this.state.submitToCloud}
            onClick={(e: any) => {
              e.preventDefault();
              this.showModal();
            }}
            data-toggle="modal"
            data-target="#submitModal"
          >
            Update to SAS
          </button>
        );
      }
    } else {
      if (localStorage.getItem("userRole") === "Installer") {
        if (this.state.get_cbsd_category == "DEVICE_CATEGORY_A") {
          return (
            <button className="btn cardbtn btn-submitted m-t-10 m-b-10" style={{ color: "#222223" }}>
              <i className="material-icons submitcheckmob">check</i>
              <span className="p-l-25">Submitted</span>
            </button>
          );
        } else {
          return (
            <button className="btn cardbtn btn-submitted m-t-10 m-b-10" style={{ color: "#222223" }}>
              <i className="material-icons submitcheckmob">check</i>
              <span className="p-l-25">Sent</span>
            </button>
          );
        }
      } else {
        return (
          <button className="btn cardbtn btn-submitted m-t-10 m-b-10" style={{ color: "#222223" }}>
            <i className="material-icons submitcheckmob">check</i>
            <span className="p-l-25">Submitted</span>
          </button>
        );
      }
    }
  };
  takePicture = async (value: any) => {
    //console.log("opening camera");
    try {
      var image: any = await Camera.getPhoto({
        quality: 50,
        allowEditing: false,
        source: CameraSource.Prompt, //asks for whether gallery upload or camera capture
        saveToGallery: false,
        resultType: CameraResultType.DataUrl,
      });
      //console.log(image);
      this.uploadFile(image, value);
    } catch (error) {
      this.setState({
        isLoading: false,
        alertbox: true,
        warnMessage: error,
      });
    }
  };

  uploadFile = (image: any, value: any) => {
    try {
      this.setState({
        isLoading: true,
        isUploadImage: true,
        isEditParam: true,
      });
      let filerequestBody = {};

      var initial = image["dataUrl"].split(";")[0];
      var fileExt = initial.split("/")[1];

      let tempDate = new Date();
      var date =
        tempDate.getFullYear() +
        "-" +
        (tempDate.getMonth() + 1) +
        "-" +
        tempDate.getDate() +
        "_" +
        tempDate.getHours() +
        ":" +
        tempDate.getMinutes() +
        ":" +
        tempDate.getSeconds();
      var fileName = value + "_" + date + "." + fileExt;

      var fileValue = image["dataUrl"];

      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: fileValue,
        type: "asset-image",
      };
      //console.log(filerequestBody);
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        //console.log(data);
        this.setState({
          isUploadImage: false,
        });
        if (data["responseCode"] == 200) {
          //console.log(data.file_id);
          if (value == "height") {
            this.setState({
              heightimg: true,
            });
            installation_list.map((installation: any) => {
              if (installation.propertyName === "height_image") {
                installation.value = data.file_id;
              }
            });
          } else if (value === "azimuth") {
            this.setState({
              azimuthimg: true,
            });
            installation_list.map((installation: any) => {
              if (installation.propertyName === "azimuth_image") {
                installation.value = data.file_id;
              }
            });
          } else if (value === "down_tilt") {
            this.setState({
              dtimg: true,
            });
            installation_list.map((installation: any) => {
              if (installation.propertyName === "down_tilt_image") {
                installation.value = data.file_id;
              }
            });
          }
          this.setState({ isLoading: false }, () => {
            this.setState({
              warnMessage: "Image uploaded successfully",
              alertbox: true,
            });
          });
        } else {
          this.setState({ isLoading: false });
          this.setState({ alertbox: true, warnMessage: data.message });
        }
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        alertbox: true,
        warnMessage: error,
      });
    }
  };
  collapseClick = (id: any) => {
    //console.log("==========", id);
    var type;
    var div_id;
    $(document).ready(function () {
      $("#" + id).on("hidden.bs.collapse", function () {
        //console.log("Closed");
        $("#div1").show();
        $("#div2").show();
        $("#div3").show();
      });
      $("#" + id).on("shown.bs.collapse", function () {
        //console.log("Opened");
        $("#div1").hide();
        $("#div2").hide();
        $("#div3").hide();
      });
    });
    if (id === "cbsdT") {
      div_id = "cbsdTab";
    } else if (id === "optionalT") {
      div_id = "optionalTab";
    } else {
      div_id = "installationTab";
    }
    type = $("#" + div_id)[0]["attributes"][4]["value"];
    if (type.toString() == "true") {
      this.setState({
        select_type: id,
      });
    } else {
      this.setState({
        select_type: "",
      });
    }
  };
  setShowLoading = (st: boolean) => {
    this.setState({ isLoading: st });
  };

  render() {
    let files = this.state.files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let files1 = this.state.p12cert.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    const { cpiId, cpiName } = this.state;
    //console.log(cpiId);
    const isEnabled = cpiId.length > 0 && cpiName.length > 0;
    let classDName = files.length ? "dropzone-uploaded" : "dropzone";
    let classDName1 = files1.length ? "dropzone-uploaded" : "dropzone";
    return (
      <IonPage>
        <IonContent>
          <div>
            <div className="mobonly">
              <div className="param-info-mob">
                <div
                  className="col-12 text-center searchborder"
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #f6f7fb",
                    paddingTop: "env(safe-area-inset-top)",
                  }}
                >
                  <img
                    className="backicon-mob"
                    style={{ width: "43px", paddingTop: "6px" }}
                    onClick={() => {
                      this.props.hideParamOverlay();
                    }}
                    src={BackIcon}
                  />
                  <span className="apheader">PARAMETERS</span>
                </div>
                <div className="col-12 p-0">
                  <ul className="nav nav-tabs sectab" id="secTabmob" role="tablist">
                    {this.state.sectorIds
                      ? this.state.sectorIds.map((sector: any, index: any) => {
                          if (this.state.sectorIds.length == 1) {
                            return (
                              <div style={{ width: "100%" }}>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    this.setState({
                                      submitToCloud: false,
                                      sasSubmission: true,
                                    });
                                    this.get_sector_properties(sector.sector_id, sector.sector_value);
                                  }}
                                >
                                  <a
                                    className={`nav-link p-l-0 ${
                                      this.state.sector_id === sector["sector_id"] ? "active" : ""
                                    } p-l-0`}
                                    id="sec1-tab-mob"
                                    data-toggle="tab"
                                    href="#sec1mob"
                                    role="tab"
                                    aria-controls="sec1mob"
                                    aria-selected="true"
                                  >
                                    SEC {index + 1}
                                  </a>
                                </li>
                                {this.state.sectorIds.length - 1 === index ? null : (
                                  <div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "-30px",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "5px",
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            );
                          } else if (this.state.sectorIds.length == 2) {
                            return (
                              <div style={{ width: "50%" }}>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    this.setState({ submitToCloud: false });
                                    this.get_sector_properties(sector.sector_id, sector.sector_value);
                                  }}
                                >
                                  <a
                                    className={`nav-link p-l-0 ${
                                      this.state.sector_id === sector["sector_id"] ? "active" : ""
                                    } p-l-0`}
                                    id="sec1-tab-mob"
                                    data-toggle="tab"
                                    href="#sec1mob"
                                    role="tab"
                                    aria-controls="sec1mob"
                                    aria-selected="true"
                                  >
                                    SEC {index + 1}
                                  </a>
                                </li>
                                {this.state.sectorIds.length - 1 === index ? null : (
                                  <div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "-30px",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "5px",
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            );
                          } else if (this.state.sectorIds.length == 3) {
                            return (
                              <div style={{ width: "33%" }}>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    this.setState({ submitToCloud: false });
                                    this.get_sector_properties(sector.sector_id, sector.sector_value);
                                  }}
                                >
                                  <a
                                    className={`nav-link p-l-0 ${
                                      this.state.sector_id === sector["sector_id"] ? "active" : ""
                                    } p-l-0`}
                                    id="sec1-tab-mob"
                                    data-toggle="tab"
                                    href="#sec1mob"
                                    role="tab"
                                    aria-controls="sec1mob"
                                    aria-selected="true"
                                  >
                                    SEC {index + 1}
                                  </a>
                                </li>
                                {this.state.sectorIds.length - 1 === index ? null : (
                                  <div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "-30px",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "5px",
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            );
                          } else if (this.state.sectorIds.length == 4) {
                            return (
                              <div style={{ width: "25%" }}>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    this.setState({ submitToCloud: false });
                                    this.get_sector_properties(sector.sector_id, sector.sector_value);
                                  }}
                                >
                                  <a
                                    className={`nav-link p-l-0 ${
                                      this.state.sector_id === sector["sector_id"] ? "active" : ""
                                    } p-l-0`}
                                    style={{ padding: "0.5rem 0rem" }}
                                    id="sec1-tab-mob"
                                    data-toggle="tab"
                                    href="#sec1mob"
                                    role="tab"
                                    aria-controls="sec1mob"
                                    aria-selected="true"
                                  >
                                    SEC {index + 1}
                                  </a>
                                </li>
                                {this.state.sectorIds.length - 1 === index ? null : (
                                  <div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "-30px",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "5px",
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            );
                          } else if (this.state.sectorIds.length == 5) {
                            return (
                              <div style={{ width: "20%" }}>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    this.setState({ submitToCloud: false });
                                    this.get_sector_properties(sector.sector_id, sector.sector_value);
                                  }}
                                >
                                  <a
                                    className={`nav-link p-l-0 ${
                                      this.state.sector_id === sector["sector_id"] ? "active" : ""
                                    } p-l-0`}
                                    style={{ padding: "0.5rem 0rem" }}
                                    id="sec1-tab-mob"
                                    data-toggle="tab"
                                    href="#sec1mob"
                                    role="tab"
                                    aria-controls="sec1mob"
                                    aria-selected="true"
                                  >
                                    SEC {index + 1}
                                  </a>
                                </li>
                                {this.state.sectorIds.length - 1 === index ? null : (
                                  <div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "-30px",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "5px",
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            );
                          } else if (this.state.sectorIds.length == 6) {
                            return (
                              <div style={{ width: "16.5%" }}>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    this.setState({ submitToCloud: false });
                                    this.get_sector_properties(sector.sector_id, sector.sector_value);
                                  }}
                                >
                                  <a
                                    className={`nav-link p-l-0 ${
                                      this.state.sector_id === sector["sector_id"] ? "active" : ""
                                    } p-l-0`}
                                    style={{ padding: "0.5rem 0rem" }}
                                    id="sec1-tab-mob"
                                    data-toggle="tab"
                                    href="#sec1mob"
                                    role="tab"
                                    aria-controls="sec1mob"
                                    aria-selected="true"
                                  >
                                    SEC {index + 1}
                                  </a>
                                </li>
                                {this.state.sectorIds.length - 1 === index ? null : (
                                  <div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "-30px",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        borderRight: "1px solid #C1C8CE80",
                                        height: "8px",
                                        marginTop: "5px",
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                            );
                          }
                        })
                      : null}
                  </ul>
                </div>
              </div>
              <div className="tab-content" id="secTabContentmob">
                <div className="tab-pane fade show active" id="sec1" role="tabpanel" aria-labelledby="sec1-tab">
                  <div
                    className=" "
                    id="accordionExample"
                    style={{
                      height: "calc(100vh - 83px)",
                      background: "#f6f7fb",
                    }}
                  >
                    <div
                      className="col-12"
                      style={{
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 208px)",
                        padding: "1rem",
                      }}
                    >
                      <div className="accordion">
                        <div className="">
                          {global_list.map((global: any, key: any) => {
                            if (global.isShowable == 1) {
                              if (global.dataType == "string" || global.dataType == "float") {
                                return (
                                  <div className="col-12 p-0">
                                    <div className="form-label-group m-0">
                                      <input
                                        type="text"
                                        className="accesspointname"
                                        ref={(ref) => {
                                          this.paramFocus[key] = ref;
                                          return true;
                                        }}
                                        name={global.propertyName}
                                        id={global.propertyName}
                                        value={global.value}
                                        placeholder={global.displayName}
                                        onChange={(e: any) => {
                                          this.onChange(e, "global");
                                        }}
                                      />
                                      <label className="pages" htmlFor={global.propertyName}>
                                        {global.displayName}*
                                      </label>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })}
                        </div>
                        {this.reduxStore["assetTypeId"] === 1 ? (
                          <div id="div1" className="">
                            <div className="row">
                              <div className="col-md-6 col-6 d-inline-block p-r-5">
                                <div className="form-label-group m-0">
                                  <span
                                    className="distance"
                                    style={{
                                      display: "block",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Site ID:
                                  </span>
                                  <span className="distance apinfo">{this.state.siteId}</span>
                                  {/* <input type="text" disabled className="serial" name="access_point_id" id="Site Id" value={this.state.siteId}
                                                                        placeholder="Site Id" />
                                                                    <label className="pages" htmlFor="Site Id">Site ID</label> */}
                                </div>
                              </div>

                              <div className="col-md-6 col-6 d-inline-block p-l-5">
                                <div className="form-label-group m-0">
                                  <span
                                    className="distance"
                                    style={{
                                      display: "block",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Sector ID:
                                  </span>
                                  <span className="distance apinfo">{this.state.sector_value}</span>
                                  {/* <input disabled type="text" className="serial" name="sector_id" id="Sector Id" value={this.state.sector_value} onChange={(e: any) => this.assetPropChange(e)}
                                                                        placeholder="Sector Id" />
                                                                    <label className="pages" htmlFor="Site Id">Sector ID</label> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.reduxStore["assetTypeId"] === 1 ? (
                          <div id="div2" className="">
                            <div className="row">
                              {/* {other_list.map((other: any, key: any) => {
                                                                if (other.isShowable == 1 && (other.propertyName == "pci" || other.propertyName == "cell_id")) {
                                                                    if (other.dataType == "string") {
                                                                        return <div className={`col-md-6 col-6  d-inline-block m-t-10 ${key % 2 !== 0 ? 'p-r-5' : 'p-l-5'} `} >
                                                                            <div className="form-label-group m-0"> */}
                              {/* <input type="text" name={other.propertyName} className="serial" id={other.propertyName} value={other.value} placeholder={other.displayName} onChange={(e: any) => { this.onChange(e, 'other') }} />
                                                                                <label className="pages" htmlFor={other.propertyName}>{other.displayName}</label>  */}
                              {/* <span className='distance' style={{ display: 'block', fontSize: '13px' }}>{other.displayName}:</span>
                                                                                <span className='distance apinfo' >{other.value == "" ? "- " : other.value}</span>
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                }
                                                            })
                                                            } */}
                            </div>
                          </div>
                        ) : null}

                        {this.reduxStore["assetTypeId"] === 1 ? (
                          <div id="div3" className="row">
                            <div className="col-md-6 col-6 d-inline-block m-t-10 p-r-5">
                              <div className="form-label-group m-0 d-none">
                                <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                                  eNB/gNb ID:
                                </span>
                                <span className="distance apinfo">{this.state.enb === "" ? "-" : this.state.enb}</span>
                                {/* <input type="text" className="serial" name="enb_id" id="enb_id" value={this.state.enb} onChange={(e: any) => this.assetPropChange(e)}
                                                                    placeholder="enb Id" />
                                                                <label className="pages" htmlFor="enb_id">eNB ID</label> */}
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <div
                          className=""
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            boxShadow: "0px 3px 8px #00000029",
                            marginTop: "20px",
                            background: "#fff",
                          }}
                        >
                          {this.state.select_type === "cbsdT" ? (
                            <div
                              className="param-clearfields"
                              onClick={this.clear_cbsd_value}
                              style={{
                                margin: "15px 50px 10px 10px",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            >
                              <span>Clear fields</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="cbsdmob collapsed"
                            style={{ padding: "15px", height: "55px" }}
                            id="cbsdTab"
                            data-toggle="collapse"
                            data-target="#cbsdT"
                            aria-expanded="false"
                            onClick={() => this.collapseClick("cbsdT")}
                            aria-controls="collapseOne"
                          >
                            <span className="apheader font18" style={{ textTransform: "none" }}>
                              CBSD
                            </span>
                            <span className="arrow dropd"></span>
                          </div>
                          <div
                            id="cbsdT"
                            className="collapse"
                            aria-labelledby="cbsdTab"
                            data-parent="#accordionExample"
                          >
                            <div className="col-12 justify-content-center p-b-10 p-r-10 p-l-10">
                              {cbsd_list.map((cbsd: any) => {
                                if (cbsd.propertyName == "cbsd_vendor_model") {
                                  return (
                                    <div className="floating-label">
                                      <select
                                        disabled={!this.state.edit_vendor}
                                        name={cbsd.propertyName}
                                        value={cbsd.value}
                                        className="floating-select selectarrow paramSelectmob"
                                        style={{
                                          borderRadius: "6px 6px 0px 0px",
                                        }}
                                        onChange={(e) => this.setAssetVendor(e, "cbsdmodel1")}
                                      >
                                        <option value="" hidden>
                                          {cbsd.displayName}
                                        </option>
                                        {this.state.assetVendorList.map((vendor: any) => (
                                          <option value={vendor.name}>{vendor.name}</option>
                                        ))}
                                      </select>
                                      <label id="cbsdmodel1">{cbsd.displayName}</label>
                                    </div>
                                  );
                                } else if (cbsd.propertyName == "cbsd_software_version") {
                                  return (
                                    <div className="col-12 p-0">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          disabled
                                          className="paraminput-mob b-t-0"
                                          placeholder="Software Version"
                                          name={cbsd.propertyName}
                                          id={cbsd.propertyName}
                                          value={cbsd.value}
                                          onChange={(e: any) => {
                                            this.onChange(e, "cbsd");
                                          }}
                                        />
                                        <label className="acpdetails" htmlFor={cbsd.propertyName}>
                                          {cbsd.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                } else if (cbsd.propertyName == "cbsd_hardware_version") {
                                  return (
                                    <div className="col-12 p-0">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          disabled
                                          className="paraminput-mob b-t-0"
                                          name={cbsd.propertyName}
                                          id={cbsd.propertyName}
                                          value={cbsd.value}
                                          onChange={(e: any) => {
                                            this.onChange(e, "cbsd");
                                          }}
                                          placeholder="Hardware Version"
                                        />
                                        <label className="acpdetails" htmlFor={cbsd.propertyName}>
                                          {cbsd.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                } else if (cbsd.propertyName == "cbsd_firmware_version") {
                                  return (
                                    <div className="col-12 p-0">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          disabled
                                          className="paraminput-mob b-t-0"
                                          style={{
                                            borderRadius: "0px 0 6px 6px",
                                          }}
                                          name={cbsd.propertyName}
                                          id={cbsd.propertyName}
                                          value={cbsd.value}
                                          onChange={(e: any) => {
                                            this.onChange(e, "cbsd");
                                          }}
                                          placeholder="Firmware Version"
                                        />
                                        <label className="acpdetails" htmlFor={cbsd.propertyName}>
                                          {cbsd.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>

                        <div
                          className="m-t-10"
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            boxShadow: "0px 3px 8px #00000029",
                            marginTop: "20px",
                            background: "#fff",
                          }}
                        >
                          {this.state.select_type === "install" ? (
                            <div
                              className="param-clearfields"
                              onClick={this.clear_installation_value}
                              style={{
                                margin: "15px 50px 10px 10px",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            >
                              <span>Clear fields</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="installationmob collapsed"
                            style={{ padding: "15px", height: "55px" }}
                            id="installationTab"
                            data-toggle="collapse"
                            data-target="#install"
                            onClick={() => this.collapseClick("install")}
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            <span className="apheader font18" style={{ textTransform: "none" }}>
                              Installation
                            </span>
                            <span className="arrow dropd"></span>
                          </div>
                          <div
                            id="install"
                            className="collapse"
                            aria-labelledby="installationTab"
                            data-parent="#accordionExample"
                          >
                            <div className="col-12 justify-content-center p-r-10 p-l-10 p-b-10">
                              {installation_list.map((installation: any) => {
                                if (installation.units !== "") {
                                  unit = "(" + installation.units + ")";
                                } else {
                                  unit = "";
                                }
                                if (installation.isShowable == 1) {
                                  if (installation.dataType != "image") {
                                    if (installation.propertyName == "latitude") {
                                      var element = (
                                        <div className="col-6 p-0 ">
                                          <div className="form-label-group m-0">
                                            <input
                                              disabled={true}
                                              type="text"
                                              className="paraminput-mob b-r-0"
                                              name={installation.propertyName}
                                              id={installation.propertyName}
                                              value={installation.value}
                                              style={{
                                                borderRadius: "6px 0px 0px 0px",
                                              }}
                                              placeholder="Latitude"
                                              onChange={(e: any) => {
                                                this.onChange(e, "installation");
                                              }}
                                            />
                                            <label className="acpdetails" htmlFor={installation.propertyName}>
                                              {installation.displayName} {unit}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                      element_list.push(element);
                                    } else if (installation.propertyName == "longitude") {
                                      var element = (
                                        <div className="col-6 p-0">
                                          <div className="form-label-group m-0">
                                            <input
                                              disabled={true}
                                              type="text"
                                              className="paraminput-mob"
                                              style={{
                                                borderRadius: "0px 6px 0px 0px",
                                              }}
                                              name={installation.propertyName}
                                              id={installation.propertyName}
                                              value={installation.value}
                                              placeholder="Longitude"
                                              onChange={(e: any) => {
                                                this.onChange(e, "installation");
                                              }}
                                            />
                                            <label className="acpdetails" htmlFor={installation.propertyName}>
                                              {installation.displayName} {unit}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                      element_list.push(element);
                                      return (
                                        <div className="row" style={{ margin: "0px" }}>
                                          {element_list}
                                        </div>
                                      );
                                    } else if (installation.propertyName == "height") {
                                      element_list = [];
                                      return (
                                        <div className="col-12 p-0">
                                          <div className="form-label-group m-0">
                                            {!this.state.heightimg ? (
                                              <img
                                                src={camicon}
                                                style={{
                                                  width: "21px",
                                                  position: "absolute",
                                                  right: "0px",
                                                  margin: "20px",
                                                  zIndex: 50,
                                                }}
                                                onClick={() => this.takePicture("height")}
                                              ></img>
                                            ) : (
                                              <img
                                                src={checkSuccess}
                                                style={{
                                                  width: "21px",
                                                  position: "absolute",
                                                  right: "0px",
                                                  margin: "13px",
                                                  zIndex: 50,
                                                }}
                                                onClick={() => this.takePicture("height")}
                                              ></img>
                                            )}
                                            <input
                                              type="text"
                                              className="paraminput-mob b-t-0"
                                              style={{ borderRadius: "0px" }}
                                              disabled
                                              placeholder="Height"
                                              name={installation.propertyName}
                                              id={installation.propertyName}
                                              value={installation.value}
                                              onChange={(e: any) => {
                                                this.onChange(e, "installation");
                                              }}
                                            />
                                            <label className="acpdetails" htmlFor={installation.propertyName}>
                                              {installation.displayName} {unit}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    } else if (installation.propertyName == "height_type") {
                                      return (
                                        <div className="floating-label">
                                          <select
                                            className="floating-select selectarrow paramSelectmob b-t-0"
                                            value={installation.value}
                                            name={installation.propertyName}
                                            onChange={(e) => {
                                              this.installation_height(e, "heightype1");
                                            }}
                                          >
                                            <option hidden>Height Type</option>
                                            <option value="HEIGHT_TYPE_AGL">HEIGHT_TYPE_AGL</option>
                                            <option value="HEIGHT_TYPE_AMSL">HEIGHT_TYPE_AMSL</option>
                                            {/* <option value="HEIGHT_TYPE_UNSPECIFIED">HEIGHT_TYPE_UNSPECIFIED</option> */}
                                          </select>
                                          <label id="heightype1">{installation.displayName}</label>
                                        </div>
                                      );
                                    } else if (installation.propertyName == "antenna_azimuth") {
                                      return (
                                        <div className="col-12 p-0">
                                          <div className="form-label-group m-0">
                                            {!this.state.azimuthimg ? (
                                              <img
                                                src={camicon}
                                                style={{
                                                  width: "21px",
                                                  position: "absolute",
                                                  right: "0px",
                                                  margin: "20px",
                                                  zIndex: 50,
                                                }}
                                                onClick={() => this.takePicture("azimuth")}
                                              ></img>
                                            ) : (
                                              <img
                                                src={checkSuccess}
                                                style={{
                                                  width: "21px",
                                                  position: "absolute",
                                                  right: "0px",
                                                  margin: "13px",
                                                  zIndex: 50,
                                                }}
                                                onClick={() => this.takePicture("azimuth")}
                                              ></img>
                                            )}
                                            <input
                                              type="text"
                                              className="paraminput-mob b-t-0"
                                              style={{ borderRadius: "0px" }}
                                              placeholder="Antenna Azimuth"
                                              name={installation.propertyName}
                                              id={installation.propertyName}
                                              value={installation.value}
                                              onChange={(e: any) => {
                                                this.onChange(e, "installation");
                                              }}
                                            />
                                            <label className="acpdetails" htmlFor={installation.propertyName}>
                                              {installation.displayName} {unit}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    } else if (installation.propertyName == "measurement_capability") {
                                      return (
                                        <div className="floating-label">
                                          <select
                                            className="floating-select selectarrow paramSelectmob b-t-0"
                                            style={{
                                              borderRadius: "0px 0px 6px 6px",
                                            }}
                                            name={installation.propertyName}
                                            value={installation.value}
                                            onChange={(e) => {
                                              this.installation_mc(e, "measurementcap1");
                                            }}
                                          >
                                            <option hidden>
                                              {installation.displayName} {unit}
                                            </option>
                                            {/* <option value="MEASUREMENT_CAPABILITY_UNSPECIFIED">MEASUREMENT_CAPABILITY_UNSPECIFIED</option> */}
                                            <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT">
                                              MSRMT_WITH_GRANT
                                            </option>
                                            <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITHOUT_GRANT">
                                              MSRMT_WITHOUT_GRANT
                                            </option>
                                          </select>
                                          <label id="measurementcap1">{installation.displayName}</label>
                                        </div>
                                      );
                                    } else if (installation.propertyName == "down_tilt") {
                                      return (
                                        <div className="col-12 p-0">
                                          <div className="form-label-group m-0">
                                            {!this.state.dtimg ? (
                                              <img
                                                src={camicon}
                                                style={{
                                                  width: "21px",
                                                  position: "absolute",
                                                  right: "0px",
                                                  margin: "20px",
                                                  zIndex: 50,
                                                }}
                                                onClick={() => this.takePicture("down_tilt")}
                                              ></img>
                                            ) : (
                                              <img
                                                src={checkSuccess}
                                                style={{
                                                  width: "21px",
                                                  position: "absolute",
                                                  right: "0px",
                                                  margin: "13px",
                                                  zIndex: 50,
                                                }}
                                                onClick={() => this.takePicture("down_tilt")}
                                              ></img>
                                            )}
                                            <input
                                              type="text"
                                              className="paraminput-mob b-t-0"
                                              placeholder="Down Tilt"
                                              name={installation.propertyName}
                                              id={installation.propertyName}
                                              value={installation.value}
                                              onChange={(e: any) => {
                                                this.onChange(e, "installation");
                                              }}
                                            />
                                            <label className="acpdetails" htmlFor={installation.propertyName}>
                                              {installation.displayName} {unit}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    } else if (installation.propertyName == "indoor_deployment") {
                                      return (
                                        <div className="col-12 p-b-10" style={{ left: "5px" }}>
                                          <div className="custom-control custom-checkbox ">
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              checked={installation.value == 1 ? true : false}
                                              id="indoordep"
                                              name="example1"
                                              onChange={(e: any) => {
                                                this.indoor_deployment_check(e);
                                              }}
                                            />
                                            <label
                                              className="custom-control-label check"
                                              style={{ left: "-16px" }}
                                              htmlFor="indoordep"
                                            >
                                              {installation.displayName}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      installation.propertyName === "antenna_gain" ||
                                      installation.propertyName === "eirp_capability" ||
                                      installation.propertyName === "antenna_beamwidth" ||
                                      installation.propertyName === "antenna_model"
                                    ) {
                                      return (
                                        <div className="col-12 p-0">
                                          <div className="form-label-group m-0">
                                            <input
                                              disabled
                                              type="text"
                                              className="paraminput-mob b-t-0"
                                              style={{ borderRadius: "0px" }}
                                              placeholder="Horizontal Accuracy"
                                              name={installation.propertyName}
                                              id={installation.propertyName}
                                              value={installation.value}
                                              onChange={(e: any) => {
                                                this.onChange(e, "installation");
                                              }}
                                            />
                                            <label className="acpdetails" htmlFor={installation.propertyName}>
                                              {installation.displayName} {unit}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className="col-12 p-0">
                                          <div className="form-label-group m-0">
                                            <input
                                              type="text"
                                              className="paraminput-mob b-t-0"
                                              style={{ borderRadius: "0px" }}
                                              placeholder="Horizontal Accuracy"
                                              name={installation.propertyName}
                                              id={installation.propertyName}
                                              value={installation.value}
                                              onChange={(e: any) => {
                                                this.onChange(e, "installation");
                                              }}
                                            />
                                            <label className="acpdetails" htmlFor={installation.propertyName}>
                                              {installation.displayName} {unit}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                  if (installation.dataType == "image") {
                                    if (installation.propertyName == "antenna_pattern") {
                                      // return <div className='col-12 justify-content-center p-t-10 p-b-10 b-t-0' style={{ border: '0.5px solid #d5d4d5' }}>
                                      //     <i className="material-icons imgclose-file dropzclose" style={{ right: '20px' }} onClick={(e: any) => { e.preventDefault(); files = []; this.onClear([]) }}>clear</i>
                                      //     <Dropzone onDrop={this.onDrop} disabled={false}>
                                      //         {({ getRootProps, getInputProps }) => (
                                      //             <section className='dropzone-set' >
                                      //                 <div {...getRootProps({ className: classDName })}>
                                      //                     <input {...getInputProps()} accept='.csv' />
                                      //                     {installation.value !== "0" && files.length == 0 ? <span style={{ fontSize: '14px' }}> File uploaded Already</span> :
                                      //                         (files.length) ? <span style={{ color: "#00D56E", display: 'contents' }}>
                                      //                             {files}
                                      //                         </span> : <span style={{ fontSize: '14px' }}> + Add {installation.displayName}</span>
                                      //                     }
                                      //                 </div>
                                      //             </section>
                                      //         )}
                                      //     </Dropzone>
                                      // </div>
                                      return (
                                        <div className="col-12 p-0">
                                          <div className="form-label-group m-0">
                                            <input
                                              type="text"
                                              className="paraminput-mob b-t-0"
                                              style={{ borderRadius: "0px" }}
                                              placeholder="Horizontal Accuracy"
                                              name={installation.propertyName}
                                              id={installation.propertyName}
                                              value={installation.value}
                                              onChange={(e: any) => {
                                                this.onChange(e, "installation");
                                              }}
                                            />
                                            <label className="acpdetails" htmlFor={installation.propertyName}>
                                              {installation.displayName} {unit}
                                            </label>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                }
                              })}
                            </div>
                          </div>
                        </div>

                        <div
                          className=""
                          style={{
                            border: "none",
                            borderRadius: "5px",
                            boxShadow: "0px 3px 8px #00000029",
                            marginTop: "20px",
                            background: "#fff",
                          }}
                        >
                          {this.state.select_type === "optionalT" ? (
                            <div
                              className="param-clearfields"
                              onClick={this.clear_optional_value}
                              style={{
                                margin: "15px 50px 10px 10px",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderRadius: "10px",
                                padding: "5px",
                              }}
                            >
                              <span>Clear fields</span>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            className="optionmob collapsed"
                            style={{ padding: "15px", height: "55px" }}
                            id="optionalTab"
                            data-toggle="collapse"
                            data-target="#optionalT"
                            aria-expanded="false"
                            onClick={() => this.collapseClick("optionalT")}
                            aria-controls="collapseOne"
                          >
                            <span className="apheader font18" style={{ textTransform: "none" }}>
                              Optional
                            </span>
                            <span className="arrow dropd"></span>
                            {/* <i className="fas fa-sort-down dropd"></i> */}
                          </div>
                          <div
                            id="optionalT"
                            className="collapse"
                            aria-labelledby="optionalTab"
                            data-parent="#accordionExample"
                          >
                            <div className="col-12 justify-content-center p-b-10 p-r-10 p-l-10">
                              {optional_list.map((optional: any) => {
                                if (optional.propertyName == "call_sign") {
                                  return (
                                    <div className="floating-label">
                                      <select
                                        className="floating-select selectarrow paramSelectmob"
                                        style={{
                                          borderRadius: "6px 6px 0px 0px",
                                        }}
                                        name={optional.propertyName}
                                        value={optional.value}
                                        onChange={(e) => {
                                          this.select_onChange(
                                            e,
                                            "optional",
                                            optional.propertyName,
                                            "optionalcalsign1"
                                          );
                                        }}
                                      >
                                        <option value="value" hidden>
                                          1
                                        </option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                      </select>
                                      <label id="optionalcalsign1">{optional.displayName}</label>
                                    </div>
                                  );
                                } else if (optional.propertyName == "cbsd_info") {
                                  return (
                                    <select
                                      className="form-control paraminput-mob b-t-0"
                                      name={optional.propertyName}
                                      value={optional.value}
                                      style={{ borderRadius: "0px" }}
                                      onChange={(e) => {
                                        this.select_onChange(e, "optional", optional.propertyName, "");
                                      }}
                                    >
                                      <option value="value" hidden>
                                        1
                                      </option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                    </select>
                                  );
                                } else if (optional.propertyName == "grouping_param") {
                                  return (
                                    <div className="floating-label">
                                      <select
                                        className="floating-select selectarrow paramSelectmob b-t-0"
                                        name={optional.propertyName}
                                        value={optional.value}
                                        onChange={(e) => {
                                          this.select_onChange(e, "optional", optional.propertyName, "optionalgrparam");
                                        }}
                                      >
                                        <option value="" hidden>
                                          {optional.displayName}
                                        </option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                      </select>
                                      <label id="optionalgrparam">{optional.displayName}</label>
                                    </div>
                                  );
                                } else if (optional.propertyName == "cbsd_category") {
                                  return (
                                    <div className="col-md-12 p-0">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          className="paraminput-mob b-t-0"
                                          disabled
                                          name={optional.propertyName}
                                          id={optional.propertyName}
                                          value={optional.value}
                                          placeholder={optional.displayName}
                                          onChange={(e: any) => {
                                            this.onChange(e, "cbsd");
                                          }}
                                        />
                                        <label className="acpdetails" htmlFor={optional.propertyName}>
                                          {optional.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                } else if (optional.propertyName == "air_interface") {
                                  return (
                                    <div className="col-md-12 p-0">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          className="paraminput-mob b-t-0"
                                          disabled
                                          name={optional.propertyName}
                                          id={optional.propertyName}
                                          value={optional.value}
                                          placeholder={optional.displayName}
                                          onChange={(e: any) => {
                                            this.onChange(e, "cbsd");
                                          }}
                                        />
                                        <label className="acpdetails" htmlFor={optional.propertyName}>
                                          {optional.displayName}
                                        </label>
                                      </div>
                                    </div>
                                  );
                                } else if (optional.propertyName == "supported_spec") {
                                  return (
                                    <div className="col-12 p-0">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          className="paraminput-mob b-t-0"
                                          style={{
                                            borderRadius: "0px 0 6px 6px",
                                          }}
                                          name={optional.propertyName}
                                          id={optional.propertyName}
                                          value={optional.value}
                                          onChange={(e: any) => {
                                            this.onChange(e, "optional");
                                          }}
                                          placeholder="Supported Spec"
                                        />
                                        <label className="acpdetails" htmlFor={optional.propertyName}>
                                          Supported Spec
                                        </label>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row col-md-12 bottom-actions" style={{ width: "100%" }}>
                        <div className="" style={{ width: "100%" }}>
                          {this.state.assetTypeId == 1 ? (
                            <button
                              className="btn cardbtn btn-submitted"
                              disabled={this.state.isUploadImage}
                              onClick={() => {
                                this.updateSectorParameters();
                              }}
                            >
                              Save
                            </button>
                          ) : (
                            <button
                              className="btn cardbtn btn-submitted"
                              disabled={this.state.isUploadImage}
                              onClick={() => {
                                this.updateAssetParameters();
                              }}
                            >
                              Save
                            </button>
                          )}
                        </div>

                        <div className="" style={{ width: "100%" }}>
                          {this.send_button()}
                          {/* {this.state.paramStat == 3 ?

                                                        this.state.isParameterSubmitted && this.state.submit_param ?
                                                            <button className='btn cardbtn btn-submitted m-t-10 m-b-10' style={{ color: '#222223' }}>
                                                                <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                                Submitted</button>
                                                            :
                                                            !(this.state.isParameterSubmitted) && this.state.submit_param ?
                                                                <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Update to SAS</button>
                                                                :
                                                                !(this.state.submit_param) && this.state.isParameterSubmitted ?
                                                                    <button className='btn cardbtn btn-submitted m-t-10 m-b-10' style={{ color: '#222223' }}>
                                                                        <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                                        Sent</button>
                                                                    :
                                                                    !(this.state.submit_param) && !(this.state.isParameterSubmitted) ?
                                                                        <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.sendParameters(e) }}  >Update to CPI</button> : null
                                                        :
                                                        this.state.isParameterSubmitted && this.state.submit_param ?
                                                            <button className='btn cardbtn btn-submitted m-t-10 m-b-10' style={{ color: '#222223' }}>
                                                                <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                        Submitted</button>
                                                            :
                                                            !(this.state.isParameterSubmitted) && this.state.submit_param ?
                                                                <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Submit to SAS</button>
                                                                :
                                                                !(this.state.submit_param) && this.state.isParameterSubmitted ?
                                                                    <button className='btn cardbtn btn-submitted m-t-10 m-b-10' style={{ color: '#222223' }}>
                                                                        <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                                Sent</button>
                                                                    :
                                                                    !(this.state.submit_param) && !(this.state.isParameterSubmitted) ?
                                                                        <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.sendParameters(e) }}  >Submit to CPI</button> : null} */}
                        </div>
                      </div>

                      {/* <IonRow className="submit-btn-wrapper bottom-actions">

                                                <div className='' style={{ width: "100%", paddingLeft: "2px" }}>
                                                    {
                                                        this.state.isParameterSubmitted ?
                                                            <button className='btn cardbtn btn-submitted' style={{ color: '#222223' }}>
                                                                <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                                Submitted</button>
                                                            :
                                                            <button className='btn cardbtn submitbtn' onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Submit Parameters</button>
                                                    }                                </div>
                                            </IonRow> */}
                      {/* <button className=' btn savebtn' style={{ bottom: '20px' }} onClick={() => { this.updateSectorParameters() }} >Submit Parameters</button> */}
                    </div>
                  </div>

                  {/* <div className='col-12 m-t-5' >
                                <button className=' btn savebtn' style={{ bottom: '10px', width: '268px' }} disabled>Update</button>
                            </div> */}
                </div>

                <div className="tab-pane fade" id="sec2mob" role="tabpanel" aria-labelledby="sec2-tab-mob"></div>
                <div className="tab-pane fade" id="sec3mob" role="tabpanel" aria-labelledby="sec3-tab-mob"></div>
              </div>
            </div>
            <Modal show={this.state.show} handleClose={this.hideModal}>
              {/* <div className="modal fade" id="submitModal" role="dialog" aria-hidden="true"> */}
              <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="row m-0" style={{}}>
                      <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "14px" }}>
                        SUBMITTING PARAMETERS
                      </h6>
                      <i
                        className="material-icons resetcloseicon imgclose-file"
                        data-dismiss="modal"
                        onClick={(e: any) => {
                          e.preventDefault();
                          this.hideModal();
                        }}
                        aria-label="Close"
                      >
                        clear
                      </i>
                    </div>
                    <div className="row m-0" style={{}}>
                      <span className="distance" style={{ marginTop: "10px", fontWeight: 400 }}>
                        Please enter CPI credentials
                      </span>
                    </div>
                    <div className=" m-t-5 justify-content-center">
                      <div className="form-group">
                        <div className="col-12 p-0">
                          <div className="form-label-group m-0">
                            <input
                              disabled={this.state.override_cpi}
                              type="text"
                              style={
                                (this.state.get_cbsd_category == "DEVICE_CATEGORY_B" ||
                                  this.state.get_cbsd_category == "") &&
                                this.state.certificateSubmitted == false
                                  ? { width: "100%", borderColor: "#CED7DF" }
                                  : {
                                      width: "100%",
                                      borderRadius: "6px",
                                      borderBottom: "1px solid #CED7DF",
                                    }
                              }
                              id="cpeidmob"
                              value={this.state.cpiId}
                              name="cpiId"
                              onChange={this.cpiSaveChange}
                              className="text-user img-center"
                              placeholder="CPI ID"
                            />
                            <label htmlFor="cpeidmob">CPI ID</label>
                          </div>
                        </div>
                        {this.state.defaultProviderId === 2 ? (
                          <div className="col-12 p-0">
                            <div className="form-label-group m-0">
                              <input
                                disabled={this.state.override_cpi}
                                type="text"
                                style={
                                  this.state.certificateSubmitted == false
                                    ? { width: "100%", borderColor: "#CED7DF" }
                                    : {
                                        width: "100%",
                                        borderRadius: "6px",
                                        borderBottom: "1px solid #CED7DF",
                                      }
                                }
                                id="cpeidmob"
                                value={this.state.cpiName}
                                name="cpiName"
                                onChange={this.cpiSaveChange}
                                className="text-user img-center"
                                placeholder="CPI Name"
                              />
                              <label htmlFor="cpenamemob">CPI Name</label>
                            </div>
                          </div>
                        ) : null}
                        {this.state.certificateSubmitted == false ? (
                          <div>
                            <div className="col-12 p-0">
                              <div className="form-label-group m-0">
                                <input
                                  disabled={this.state.override_cpi}
                                  type="password"
                                  style={{
                                    width: "100%",
                                    borderColor: "#CED7DF",
                                  }}
                                  id="cpenamemob"
                                  className="text-pwd img-center"
                                  name="cpiPassword"
                                  onChange={this.cpiSaveChange}
                                  placeholder="CPI Name"
                                />
                                <label htmlFor="cpenamemob">CPI Certificate Password</label>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div>
                                <div
                                  className="col-12 p-0 m-t-10 justify-content-center"
                                  style={{
                                    padding: "0px 35px",
                                    fontSize: "12px",
                                  }}
                                >
                                  <Dropzone
                                    onDrop={(e: any) => {
                                      this.onDrop(e, "p12");
                                    }}
                                    disabled={this.state.field_disable || this.state.override_cpi}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="dropzone-set">
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          {files1.length ? (
                                            <span style={{ color: "#00D56E" }}>
                                              {files1}

                                              {/* <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); files1 = [] }}>clear</i> */}
                                            </span>
                                          ) : (
                                            <span style={{ fontSize: "14px" }}>+ Upload CPI Certificate</span>
                                          )}
                                        </div>
                                        <div></div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row m-0 m-t-5">
                      <div className="form-group">
                        {this.state.defaultProviderId !== 2 ? (
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              disabled
                              checked={this.state.override_cpi}
                              onChange={(e: any) => {
                                this.is_override_cpi(e);
                              }}
                              className="custom-control-input"
                              id="customCheck"
                              name="example1"
                            />
                            <label
                              className="custom-control-label check"
                              style={{ float: "left", margin: "10px 0px" }}
                              htmlFor="customCheck"
                            >
                              Override mandatory CPI credentials
                            </label>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row m-0 m-t-5 justify-content-center" style={{}}>
                      <button
                        className="btn cardbtn"
                        style={{ width: "100%" }}
                        onClick={() => {
                          this.saveParameters();
                          this.hideModal();
                        }}
                      >
                        Submit Parameters
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </Modal>
            <IonAlert
              isOpen={this.state.alertbox}
              onDidDismiss={() => this.setShowAlert(false)}
              message={this.state.warnMessage}
              buttons={["OK"]}
            />
            <IonLoading
              isOpen={this.state.isLoading}
              onDidDismiss={() => this.setShowLoading(false)}
              message={"Loading..."}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  }
}
export default withIonLifeCycle(params);
