import React, { Component } from 'react'
import "./ap-parameters.css";
import { IonPage, IonContent, withIonLifeCycle, IonAlert, IonLoading } from '@ionic/react';
import BackIcon from '../assets/img/go-back-icon-img.png'
import Fresnel from "../assets/img/fresnel.png"

class fresnelzone extends React.Component {
    state: any = {};
    props: any = {};
    constructor(props: any) {

        super(props);
        this.state = {

            freq: "",
            dist: "",
            radius: "",
            recheight: "",
            recdist: "",
            distUnit: "1",
            dist1Unit: "1",
            dist2Unit: "1",
            freqUnit: "1",
            blockage: "",
            d1: "",
            d2: "",
            perc: "",
            zn: "",
            freq1: "",
            obsRadiusat60: "",
            obsRadius: ""


        };
        this.distDropdownChange = this.distDropdownChange.bind(this)
        this.freqDropdownChange = this.freqDropdownChange.bind(this)
        this.calculate = this.calculate.bind(this)
        this.calculateFresnelObs = this.calculateFresnelObs.bind(this)
        this.reset = this.reset.bind(this)
        this.obsReset = this.obsReset.bind(this)
    }
    //Fresnel zone genral
    distDropdownChange = (e: any) => {
        this.setState({
            distUnit: e.target.value
        })

    }
    freqDropdownChange = (e: any) => {
        this.setState({
            freqUnit: e.target.value
        })

    }
    inputChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })

    }
    calculate() {
        let miles: any = ""
        let freq: any = ""
        miles = this.state.dist
        freq = this.state.freq
        if (this.state.distUnit == "1") {
            miles = parseInt(this.state.dist) * 1.609
        }
        if (this.state.freqUnit == "1") {
            freq = parseInt(this.state.freq) / 1000
        }

        let radius = 17.31 * Math.sqrt(miles / (4 * (freq)))
        this.setState({
            radius: radius.toFixed(2)
        })

    }

    reset() {
        this.setState({
            freq: "",
            dist: "",
            radius: "",
        })
    }

    //Fresnel zone obstruction
    dist1DropdownChange = (e: any) => {
        this.setState({
            dist1Unit: e.target.value
        })

    }
    dist2DropdownChange = (e: any) => {
        this.setState({
            dist2Unit: e.target.value
        })

    }
    calculateFresnelObs() {
        let d1: any = ""
        let d2: any = ""
        let freq: any = ""
        d1 = this.state.d1
        d2 = this.state.d2
        freq = this.state.freq1
        if (this.state.dist1Unit == "1") {
            d1 = parseInt(this.state.d1) * 1.609
        }
        if (this.state.dist2Unit == "1") {
            d2 = parseInt(this.state.d2) * 1.609
        }
        if (this.state.freqUnit == "1") {
            freq = parseInt(this.state.freq1) / 1000
        }

        let obsRadius = this.state.zn * 17.32 * Math.sqrt((d1 * d2) / (freq * (d1 + d2)))
        this.setState({
            obsRadius: obsRadius.toFixed(2)
        }, () => this.calculateblockage())

    }
    calculateblockage() {
        let obsRadiusat60 = this.state.obsRadius * (this.state.perc / 100)
        this.setState({
            obsRadiusat60: obsRadiusat60.toFixed(2)
        })
        let blockage = this.state.obsRadius - obsRadiusat60

        this.setState({
            blockage: blockage.toFixed(2)
        })
    }
    obsReset() {
        this.setState({
            blockage: "",
            d1: "",
            d2: "",
            perc: "",
            zn: "",
            freq1: "",
            obsRadiusat60: "",
            obsRadius: ""
        })
    }
    render() {
        return (
            <div className="mobonly">
                <IonPage>
                    <IonContent>
                        <div className='col-12 text-center searchborder' style={{ padding: '10px' }}>
                            <img className='backicon-mob' style={{ width: '43px', paddingTop: '6px' }} onClick={this.props.hideOverlay} src={BackIcon} />
                            <span className="apheader">Fresnel zone</span>
                        </div>
                        <div className='container-fluid apdash calcfont' style={{ overflow: 'hidden auto', maxHeight: 'calc(100vh - 47px)' }}>
                            <h4 className="m-t-5">Fresnel zone General</h4>
                            <div className="card speedtestcard" style={{ height: "max-content", marginBottom: "10px" }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div className="col-12 p-0 m-t-10">
                                        <img src={Fresnel} />
                                    </div>
                                    <h6 className="font14 m-t-20">Distance Between Antennas (d)</h6>
                                    <div className="row">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" id="dist" value={this.state.dist} onChange={(e: any) => { this.inputChange(e) }} name="dist" className="calculatorinput" />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => this.distDropdownChange(e)} className="form-control calculatorselect calcinlineselect">
                                                <option value="1">Miles</option>
                                                <option value="2">Km</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Frequency (f)</h6>
                                    <div className="row">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" id="freq" value={this.state.freq} onChange={(e: any) => { this.inputChange(e) }} name="freq" className="calculatorinput" />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => this.freqDropdownChange(e)} className="form-control calculatorselect calcinlineselect">
                                                <option value="1">MHz</option>
                                                <option value="2">GHz</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row m-t-20 text-center">
                                        <div className="col-6 p-r-5">
                                            <button className="btn modalbtn" onClick={this.calculate}>Calculate</button>
                                        </div>
                                        <div className="col-6 p-l-5">
                                            <button className="lightbtnmob modalbtn" onClick={this.reset}>Reset</button>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Radius at Center</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="radius" value={this.state.radius} name="radius" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="radius" name="radius" value="m" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 className="m-t-20">Fresnel Zone Obstruction</h4>
                            <div className="card speedtestcard" style={{ height: "max-content", marginBottom: "10px" }}>
                                <div className="card-body" style={{ padding: '10px' }}>
                                    <div className="row">
                                        <div className="col-6 p-r-10">
                                            <h6 className="font14 m-t-20">Transmitter Distance (d1)</h6>
                                            <div className="row">
                                                <div className="col-6 p-r-0 calculator  ">
                                                    <input type="number" id="d1" name="d1" value={this.state.d1} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                                </div>
                                                <div className="col-6 p-l-0">
                                                    <select onChange={(e: any) => this.dist1DropdownChange(e)} className="form-control calculatorselect calcinlineselect">
                                                        <option value="1">Miles</option>
                                                        <option value="2">Km</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 p-l-10">
                                            <h6 className="font14 m-t-20">Receiver Distance (d2)</h6>
                                            <div className="row">
                                                <div className="col-6 p-r-0 calculator  ">
                                                    <input type="number" id="d2" value={this.state.d2} onChange={(e: any) => { this.inputChange(e) }} name="d2" className="calculatorinput" />
                                                </div>
                                                <div className="col-6 p-l-0">
                                                    <select onChange={(e: any) => this.dist2DropdownChange(e)} className="form-control calculatorselect calcinlineselect">
                                                        <option value="1">Miles</option>
                                                        <option value="2">Km</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Frequency (f)</h6>
                                    <div className="row">
                                        <div className="col-8 p-r-0 calculator  ">
                                            <input type="number" value={this.state.freq1} id="freq1" name="freq1" onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                        </div>
                                        <div className="col-4 p-l-0">
                                            <select onChange={(e: any) => this.freqDropdownChange(e)} className="form-control calculatorselect calcinlineselect">
                                                <option value="1">MHz</option>
                                                <option value="2">GHz</option>
                                            </select>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Fresnel Zone number</h6>
                                    <div className="row">
                                        <div className="col-12 calculator">
                                            <input type="number" id="zn" name="zn" value={this.state.zn} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" style={{ borderRadius: '6px' }} />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Percentage</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="perc" name="perc" value={this.state.perc} onChange={(e: any) => { this.inputChange(e) }} className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="radius" name="radius" value="%" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <div className="row m-t-20 text-center">
                                        <div className="col-6 p-r-5">
                                            <button className="btn modalbtn" onClick={this.calculateFresnelObs}>Calculate</button>
                                        </div>
                                        <div className="col-6 p-l-5">
                                            <button className="lightbtnmob modalbtn" onClick={this.obsReset}>Reset</button>
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Radius</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="radius" value={this.state.obsRadius} name="radius" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="radius" name="radius" value="m" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Radius at 60 % Fresnel</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="radius" value={this.state.obsRadiusat60} name="radius" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="radius" name="radius" value="m" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                    <h6 className="font14 m-t-10">Max Allowed Blockage</h6>
                                    <div className="row">
                                        <div className="col-10 p-r-0 calculator  ">
                                            <input type="number" id="radius" value={this.state.blockage} name="radius" className="calculatorinput" />
                                        </div>
                                        <div className="col-2 p-l-0">
                                            <input type="text" disabled id="radius" name="radius" value="m" className="calculatorinput calcinlineselect text-center p-0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IonContent>
                </IonPage>
            </div >
        );
    }
}

export default withIonLifeCycle(fresnelzone);