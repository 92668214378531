import React, { Component } from "react";
import Back from "../assets/img/left-arrow.svg";
import heat from "../assets/img/heaticon.png";
import Room from "../assets/img/columbus.png";
import Modal from "./modal";
import Heat from "../assets/img/heaticon.png";
import BackIcon from "../assets/img/go-back-icon-img.png";
import geo from "../assets/img/target-icon.png";
import align from "../assets/img/align-icon.png";
import { requestAPICall } from "../service/apiHandler";
import { IonAlert, IonLoading } from "@ionic/react";
import edit from "../assets/img/edit.png";
import { IonGrid, IonRow, IonCol, IonFab, IonFabButton } from "@ionic/react";
import addCPEIcon from "../assets/img/add-cpe-icon-img.png";
import Dropzone from "react-dropzone";
import APMap from "../assets/img/add-cpe-icon-img-blue.png";
import calc from "../assets/img/calculate.svg";
import calcmob from "../assets/img/align-icon.png";
import SearchIcon from "../assets/img/search_icon.png";
import heat2 from "../assets/img/google-icon-img1-red.png";
import { withRouter } from "react-router-dom";
import APMoreInfo from "./ap-more-info";
import { Card } from "@material-ui/core";

const INITIAL_STATE = {
  card1: false,
  card2: false,
  show: true,
  alertbox: false,
  edit_serial: true,
};

var asset_property: any = {};

class IndoorAP extends Component {
  state: any = {};
  props: any = {};
  ap_info_ref1: React.RefObject<any>;
  constructor(props: any) {
    super(props);

    this.state = {
      name: "",
      access_point_id: "",
      user_id: "",
      description: "",
      image: "",
      no_of_sectors: 0,
      latitude: "",
      longitude: "",
      asset_type_id: 0,
      sector_id: [],
      rad_center: "",
      serialid: "",
    };
    this.state = { ...INITIAL_STATE };
    this.ap_info_ref1 = React.createRef();
    this.backtoMap = this.backtoMap.bind(this);
  }
  componentDidMount() {
    console.log(this.props);
    asset_property = this.props["asset_details"]["properties"];
    this.setState({ assetId: this.props["asset_details"]["assetId"] });
    this.setState({ assetStatus: this.props["asset_details"]["operationalStatus"] });
    this.setState({ asset_type_id: this.props["asset_details"]["assetTypeId"] });
    this.setState({ sector_id: this.props["asset_details"]["sector_id"] });
    console.log(this.props["asset_details"]);
    this.load_property(this.props["asset_details"]["properties"]);
  }
  isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };
  set_property = () => {
    this.setState({
      image: "",
    });
    if (!this.isEmpty(this.props["asset_details"]["sasStatus"])) {
      console.log("====== SAS Registration found====");
      this.setState({
        edit_serial: false,
      });
    }
    asset_property = this.props["asset_details"]["properties"];
    this.setState({ assetId: this.props["asset_details"]["assetId"] });
    this.setState({ assetStatus: this.props["asset_details"]["operationalStatus"] });
    this.setState({ asset_type_id: this.props["asset_details"]["assetTypeId"] });
    this.setState({ sector_id: this.props["asset_details"]["sector_id"] });
    console.log(this.props["asset_details"]["assetId"]);
    this.load_property(this.props["asset_details"]["properties"]);
    this.ap_info_ref1.current.set_property();
  };
  load_property(asset_property: any) {
    asset_property.forEach((property: any) => {
      if (property.propertyName == "ap_name" || property.propertyName == "cpe_name") {
        this.setState({
          name: property.value,
        });
      } else if (property.propertyName == "access_point_id" || property.propertyName == "cpe_id") {
        this.setState({
          access_point_id: property.value,
        });
      } else if (property.propertyName == "user_id") {
        this.setState({
          user_id: property.value,
        });
      } else if (property.propertyName == "description") {
        this.setState({
          description: property.value,
        });
      } else if (property.propertyName == "image") {
        this.setState({
          image: property.value,
        });
      } else if (property.propertyName == "no_of_sectors") {
        this.setState({
          no_of_sectors: property.value,
        });
      } else if (property.propertyName == "latitude") {
        this.setState({
          latitude: property.value,
        });
      } else if (property.propertyName == "longitude") {
        this.setState({
          longitude: property.value,
        });
      } else if (property.propertyName == "radiation_center") {
        this.setState({
          rad_center: property.value,
        });
      } else if (property.propertyName == "serial_id") {
        this.setState({
          serialid: property.value,
        });
      }
    });
  }
  showModal1 = () => {
    this.setState({
      card1: true,
    });
    console.log(this.state.show);
  };
  hideModal1 = () => {
    this.setState({
      card1: false,
    });
  };
  showModal2 = () => {
    this.setState({
      card2: true,
    });
    console.log(this.state.show);
  };
  hideModal2 = () => {
    this.setState({
      card2: false,
    });
  };
  backtoMap() {
    console.log("==========");
    this.props.refreshAsset(this.props["asset_details"]["assetId"]);
  }
  calculate = () => {
    this.props.calculate();
  };
  remove_photo() {
    var properties = asset_property.map((property: any) => {
      if (property.propertyName === "image") {
        property.value = "";
      }
      return property;
    });
    let requestBody = {
      assetName: this.state.name,
      serialId: this.state.serial_id,
      assetTypeId: this.state.assetTypeId,
      // "latitude": this.props.latlong[0],
      // "longitude": this.props.latlong[1],
      properties: properties,
      sector_id: this.state.sector_id,
      status: "Offline",
    };
    requestAPICall(
      "edit_asset_details",
      { asset_type_id: this.state.asset_type_id, asset_id: this.state.assetId },
      requestBody
    ).then((data) => {
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
        this.get_asset_details();
        this.hideModal2();
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  }
  get_asset_details = () => {
    let requestBody = {};
    requestAPICall("get_asset_details", { asset_id: this.state.assetId }, requestBody).then((data) => {
      console.log(data);
      asset_property = data["properties"];
      let image_value = data["properties"].filter((property: any) => property.propertyName !== "serial_id");
      this.setState({
        image: image_value.value,
      });
      // console.log(data.properties[6]["value"])
      if (data.responseCode == 200) {
      }
    });
  };
  delete_asset() {
    let requestbody = {
      parameterStatus: "Deregistered",
      reason: "delete from SAS",
    };
    requestAPICall("delete_device", { asset_id: this.state.assetId }, requestbody).then((data) => {
      console.log(data.responseCode, "asset deleted=========");
    });
    requestAPICall("delete_asset", { asset_id: this.state.assetId }, requestbody).then((data) => {
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
          card: 6,
        });
        this.hideModal2();
        this.hideModal1();
        this.props.asset_delete_confirm();
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  }
  editAsset = () => {
    var obj = {
      assetType: this.props.asset_details.assetTypeId,
      assetId: this.state.assetId,
      properties: asset_property,
      assetAction: "edit",
      editSerial: this.state.edit_serial,
      noOfSectors: asset_property["no_of_sectors"],
      serial_id: this.props["asset_details"]["serialId"],
    };
    this.props.editAsset(obj);
  };

  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
    if (this.state.alertbox === false) {
      this.props.back();
    }
  };
  render() {
    return (
      <div>
        <div>
          <div className="webonly">
            <div className="row">
              <div className="col-md-5">
                <div className="card apcard">
                  <div className="card-body" style={{ padding: "0px" }}>
                    <div className="row">
                      <div className="col-md-3">
                        <img className="backicon" src={BackIcon} onClick={this.props.back} />
                        <button type="button" className="backbutton"></button>
                        {/* <img className='backicon' onClick={this.props.back} src={Back} />
                                                <button type='button' className='backbutton'></button> */}
                      </div>
                      <div className="col-md-9 m-r-m" style={{ padding: "13px 0" }}>
                        <span className="apheader m-r-m">CBSD Details</span>
                      </div>
                    </div>
                    <div className="card-body " style={{ marginTop: "-15px" }}>
                      {/* <div className='row m-t-5' style={{ margin: "0px", position: 'relative' }}>
                                                <span className=''><i className="fas fa-times imgclose" onClick={(e: any) => { e.preventDefault(); this.showModal2() }} data-toggle="modal" data-target="#deleteimageModal"></i></span>
                                                <img className='apimage' src={this.state.image} height="200" width="300" />
                                            </div> */}
                      <div className="text-center " style={{ position: "relative" }}>
                        <span className="">
                          {this.state.image !== "" && this.state.image !== undefined ? (
                            <i
                              className="material-icons imgclose"
                              data-toggle="modal"
                              data-target="#deleteimageModal"
                              onClick={(e: any) => {
                                e.preventDefault();
                                this.showModal2();
                              }}
                            >
                              clear
                            </i>
                          ) : (
                            ""
                          )}
                        </span>
                        {this.state.image !== "" && this.state.image !== undefined ? (
                          <img className="apimage" src={this.state.image} height="200" width="300" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row m-0">
                        <span className="apheader" style={{ textTransform: "none", fontSize: "20px" }}>
                          {this.state.name}
                        </span>
                        {/* <div className="ap-dot"><span></span></div>
                                                <span className='distance-text' style={{ fontWeight: 400, padding: "6px" }}>{this.state.rad_center} ft</span> */}
                      </div>
                      <div className="row m-0">
                        <div
                          className="col-md-12"
                          style={{ padding: "0 0 0px 0", borderBottom: "0.5px solid #D8D8D8" }}
                        >
                          <p className="apdetails-web">{this.state.description}</p>
                        </div>
                      </div>

                      <div className="row m-0">
                        <div className="col-md-6 p-0">
                          <span className="distance" style={{ fontSize: "13px" }}>
                            ID:
                          </span>
                          <span className="distance apinfo" style={{ paddingLeft: "5px" }}>
                            {this.state.access_point_id}
                          </span>
                        </div>
                        <div className="col-md-6 ">
                          <span className="distance" style={{ fontSize: "13px" }}>
                            Status:
                          </span>
                          <span className="distance apinfo" style={{ color: "#E22B2B", paddingLeft: "5px" }}>
                            {this.state.assetStatus}
                          </span>
                        </div>
                      </div>
                      <div style={{ marginTop: "5px" }}></div>

                      <div className="row m-0">
                        <div className="col-md-6 p-0">
                          <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                            Latitude:
                          </span>
                          <span className="distance apinfo">{this.state.latitude}</span>
                        </div>
                        <div className="col-md-6">
                          <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                            Longitude:
                          </span>
                          <span className="distance apinfo">{this.state.longitude}</span>{" "}
                        </div>
                      </div>
                      <div className="row m-0">
                        <div className="col-md-6 p-0">
                          <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                            Height:
                          </span>
                          <span className="distance apinfo">{this.state.rad_center} ft</span>
                        </div>
                        <div className="col-md-6 ">
                          <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                            Serial #:
                          </span>
                          {this.state.serialid !== undefined ? (
                            <span className="distance apinfo">{this.state.serialid}</span>
                          ) : (
                            <span className="distance apinfo">-</span>
                          )}
                        </div>
                      </div>

                      <div className="" style={{ position: "fixed", bottom: "23px" }}>
                        <div className="row m-0">
                          <button className="editbtn distance" style={{ color: "#0073FF" }} onClick={this.editAsset}>
                            <i className="fas fa-pen " style={{ marginRight: "10px" }}></i>
                            Edit
                          </button>
                        </div>
                        <div className="row m-0">
                          <button
                            className="deletebtn distance m-t-5"
                            style={{ color: "#E22B2B" }}
                            data-toggle="modal"
                            data-target="#deleteModal"
                            onClick={(e: any) => {
                              e.preventDefault();
                              this.showModal1();
                            }}
                          >
                            <i className="fas fa-trash " style={{ marginRight: "10px" }}></i>
                            Delete CBSD
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="col-md-12 justify-content-flex-end">
                  <div className="heaticon text-center">
                    <img className="heat" src={Heat} />
                  </div>
                </div>

                <div className="col-md-12 float-right">
                  <div className="geoicon text-center">
                    <img style={{ padding: "5px" }} src={geo} />
                  </div>
                </div>
                <div className="col-md-12 float-right" onClick={this.calculate}>
                  <div className="geoicon text-center">
                    <img style={{ padding: "6.5px" }} src={align} />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <APMoreInfo
                  ref={this.ap_info_ref1}
                  assetInfo={this.props["asset_details"]}
                  backtoAPInfo={this.backtoMap}
                />
              </div>
            </div>
          </div>

          <div className="mobonly">
            <div style={{ position: "fixed", bottom: "200px", right: "0px" }}>
              <IonFab horizontal="end" style={{ position: "relative" }}>
                <IonFabButton color="light" size="small" style={{ marginBottom: "10px" }}>
                  <img className="heat" style={{ marginTop: "3px", padding: "0px", marginLeft: "0px" }} src={Heat} />
                </IonFabButton>
              </IonFab>
              <IonFab horizontal="end" style={{ position: "relative" }}>
                <IonFabButton color="light" size="small" style={{ marginBottom: "10px" }}>
                  <img style={{ padding: "5px" }} src={geo} />
                </IonFabButton>
              </IonFab>
              <IonFab horizontal="end" style={{ position: "relative" }}>
                <IonFabButton color="light" size="small" style={{ marginBottom: "10px" }}>
                  <img className="calcicon" src={calcmob} />
                </IonFabButton>
              </IonFab>
              <IonFab horizontal="end" style={{ position: "relative" }}>
                <IonFabButton color="light" size="small" style={{ marginBottom: "10px" }}>
                  <img className="search-mob" src={SearchIcon} />
                  {/* <i className="fa fa-search searchicon" style={{ margin: "0px" }} aria-hidden="true"></i> */}
                </IonFabButton>
              </IonFab>
            </div>

            <IonRow className="bottom-menu" style={{ bottom: "160px" }}>
              <IonCol size="4" style={{ padding: "5px" }}>
                {/* <i className="fa fa-circle cpeicon"></i> */}
                <img className="cpeicon" style={{ width: "37px", padding: "10px 14px 10px 6px" }} src={addCPEIcon} />
                <button type="button" className="cardbutton">
                  Add CPE
                </button>
              </IonCol>
              <IonCol size="4" style={{ padding: "5px" }}>
                <img className="apicon" style={{ width: "37px", padding: "10px 14px 10px 6px" }} src={APMap} />
                <button type="button" className="cardbutton">
                  Add CBSD
                </button>
              </IonCol>
              <IonCol size="4" style={{ padding: "5px" }}>
                <img className="heat2" src={heat2} />
                <button type="button" className="cardbutton" id="propagationSettingsmob">
                  Heat
                </button>
              </IonCol>
            </IonRow>
            <div className="">
              <div className="card apinfocard" style={{ position: "fixed", bottom: "0px" }}>
                <div className="card-body apinfobody">
                  <div className="row  m-t-10">
                    <div className="col-4 p-r-0">
                      <img className="apimage" src={Room} />
                    </div>
                    <div className="col-8 p-r-0">
                      <span className="apheader" style={{ textTransform: "none" }}>
                        Piedmont Lake
                      </span>
                      <span className="distance">. 270ft</span>
                      <div>
                        <span className="distance" style={{ fontSize: "12px" }}>
                          Status:
                        </span>
                        <span className="distance apinfo" style={{ color: "#E22B2B", paddingLeft: "5px" }}>
                          Offline
                        </span>
                      </div>
                      <div className="col-12 p-0">
                        <span>
                          <span className="distance" style={{ fontSize: "12px" }}>
                            Lat:
                          </span>
                          <span className="distance apinfo">43.197167</span>
                        </span>
                        <span className="p-l-10">
                          <span className="distance" style={{ fontSize: "12px" }}>
                            Long:
                          </span>
                          <span className="distance apinfo">43.197167</span>
                        </span>
                      </div>

                      <div className="col-12 p-0">
                        <div
                          className="aptoggle"
                          onClick={() => {
                            this.props.history.push("/parameters-mob");
                          }}
                        >
                          <i className="fas fa-cog"></i>
                        </div>
                        <div
                          className="aptoggle"
                          onClick={() => {
                            this.props.history.push("/network-status-mob");
                          }}
                        >
                          <i className="fas fa-wifi"></i>
                        </div>
                        <div
                          className="apedit"
                          onClick={() => {
                            this.props.history.push("/access-point-details-mob");
                          }}
                        >
                          <img style={{ paddingBottom: "5px", width: "13px" }} src={edit} />
                          {/* <i className="fas fa-pen"></i> */}
                        </div>
                        <div
                          className="apdel"
                          data-toggle="modal"
                          data-target="#deleteModal"
                          onClick={(e: any) => {
                            e.preventDefault();
                            this.showModal1();
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='col-md-3' >
                        <APMoreInfo />
                    </div> */}
          </div>
        </div>

        <Modal show={this.state.show && this.state.card1} handleClose={this.hideModal1}>
          {/* <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-hidden="true"> */}
          <div
            className="modal-dialog center-modal modal-dialog-centered modal-sm"
            role="document"
            style={{ maxWidth: "335px" }}
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 15px" }}>
                  <span className="modal-title" style={{ marginTop: "0px", fontSize: "14px", fontWeight: 500 }}>
                    CONFIRMATION
                  </span>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal1();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 text-center justify-content-center" style={{ padding: "0 10px" }}>
                  <span className="modaltext">Are you sure you want to delete this CBSD?</span>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 col-6 p-r-5">
                    <button
                      className="btn btn-secondary-c3"
                      style={{ width: "95%" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal1();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 col-6 p-l-5">
                    <button
                      className="btn modalbtn"
                      style={{ width: "98%", marginLeft: "2px" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.delete_asset();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <Modal show={this.state.show && this.state.card2} handleClose={this.hideModal2}>
          {/* <div className="modal fade" id="deleteimageModal" role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 15px" }}>
                  <span className="modal-title" style={{ marginTop: "0px", fontSize: "14px", fontWeight: 500 }}>
                    CONFIRMATION
                  </span>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal2();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <span className="modaltext">Are you sure you want to remove this photo?</span>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn btn-secondary-c3"
                      style={{ width: "95%" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal2();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn modalbtn"
                      style={{ width: "98%", marginLeft: "2px" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.remove_photo();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
                {/* <div className='row m-t-20 text-center' style={{ padding: '0 10px' }}>
                                <div className='col-md-6 p-0'>
                                    <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} >No</button>
                                </div>
                                <div className='col-md-6 p-0'>
                                    <button className='btn modalbtn' onClick={(e: any) => { e.preventDefault(); this.hideModal2() }} >Yes</button>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <IonAlert
          isOpen={this.state.alertbox}
          onDidDismiss={() => this.setShowAlert(false)}
          message={this.state.warnMessage}
          buttons={["OK"]}
        />
      </div>
    );
  }
}
export default IndoorAP;
