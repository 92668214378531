import { IonAlert, IonLoading } from "@ionic/react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import checkSuccess from "../assets/img/check-success.png";
import sort from "../assets/img/checked.png";
import user from "../assets/img/userpic.png";
import { requestAPICall } from "../service/apiHandler";
import Modal from "./modal";

const INITIAL_STATE = {
  all_user_list: [],
  readOnly: true,
  readOnly1: true,
  show: true,
  card: "",
  alertbox: false,
  aerialVendorList: [],
  smbVendorList: [],
  roeVendorList: [],
  cpeVendorList: [],
  sasList: [],
  domainProxyList: [],
  selectedAerialVendorId: 0,
  selectedSMBVendorId: 0,
  selectedROEVendorId: 0,
  selectedCPEVendorId: 0,
  selectedProviderId: 0,
  providerName: "",
  vendorName: "",
  vendorModel: "",
  fccId: "",
  noOfTransmitters: "",
  vendorType: "",
  image: "",
  files: [],
  images: [],
  importfiles: [],
  userList: {},
  updateUser: {},
  userEmail: "",
  cpiId: "",
  view: "",
  AntennaPattern: "",
  userStatus: "",
  succeesUpdate: 0,
  showcpimodel: false,
  technology: "",
  softwareVersion: "",
  hardwareVersion: "",
  firmwareVersion: "",
  category: "",
  beamwidth: "",
  sasChecked: "",
  dpChecked: "",
  user_role: "",
  user_role_list: [],
  action: "ADD",
  antenna_model: "",
  fullname: "",
  deleteOption: false,
  selectedEditProviderId: "",
  selectedEditProviderTypeId: "",
  providerLogo: "",
  providerEndpoint: "",
  authUsername: "",
  authPassword: "",
  assetVendorType: "",
};
var status_list: any = [];
export default class CSettings extends Component {
  state: any = {};
  props: any = {};
  apFocus: any = [];
  cpFocus: any = [];
  userFocus: any = [];
  constructor(props: any) {
    super(props);
    this.apFocus = [];
    this.cpFocus = [];
    this.userFocus = [];
    this.state = { ...INITIAL_STATE };
    this.listVendorAPI = this.listVendorAPI.bind(this);
    this.getCookies = this.getCookies.bind(this);
    this.listProviders = this.listProviders.bind(this);
    this.createProvider = this.createProvider.bind(this);
    this.createVendor = this.createVendor.bind(this);
    this.addimage = this.addimage.bind(this);
    this.importUser = this.importUser.bind(this);
    this.listUser = this.listUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.password_click = this.password_click.bind(this);
  }
  componentDidMount() {
    //console.log("=======")
    this.getCookies();
    this.listVendorAPI();
    this.listProviders();
    this.getDefaultSettings();
    this.listUser();
  }
  getCookies() {
    //console.log("=============");
    //console.log(localStorage.getItem('idToken'))
    if (localStorage.getItem("idToken") === null && localStorage.getItem("accessToken") === null) {
      this.props.history.push("/map");
    }
  }

  setShowLoading = (st: number) => {
    this.setState({ is_loader: st });
  };
  create_user_validation = () => {
    let check = false;
    if (this.state.username === "" || this.state.username === undefined) {
      this.userFocus["username"].focus();
      check = true;
    } else if (this.state.email === "" || this.state.email === undefined) {
      this.userFocus["email"].focus();
      check = true;
    }
    // else if (this.state.password === "" || this.state.password === undefined) {
    //     this.userFocus["password"].focus()
    //     check = true
    // }
    return check;
  };
  createUser() {
    let check = this.create_user_validation();
    if (!check) {
      this.setState({
        is_loader: 1,
      });
      let requestBody = {
        username: this.state.username,
        // password: this.state.password,
        email: this.state.email,
        cpiName: this.state.cpiName,
        cpiId: this.state.cpiid,
        user_role: this.state.user_role,
        firstname: this.state.fullname,
      };
      requestAPICall("create_user", {}, requestBody).then((data) => {
        //console.log(data)
        this.setState({
          is_loader: 2,
        });
        this.hideModal();
        if (data.responseCode == 200) {
          this.setState({
            warningmessage: data.message,
            alertbox: true,
          });
          this.listUser();
        } else {
          if (data.message === "username is missing") {
            this.setState({
              warningmessage: "Please Enter required fields",
              alertbox: true,
            });
          } else {
            this.setState({
              warningmessage: data.message,
              alertbox: true,
            });
          }
        }
      });
    }
  }
  listUser() {
    //console.log("GET USER PROFILE")

    requestAPICall("list_user", {}, {}).then((data) => {
      //console.log("user data : ", data)
      var all_user_list: any = [];
      var userResp = data["userList"];
      //console.log(userResp)
      userResp.forEach((element: any) => {
        // if (element['userRole'] == "RegionalAdmin" || element['userRole'] == "Engineer" || element['userRole'] == "CPI") {
        all_user_list.push(element);

        // }
      });
      if (localStorage.getItem("userRole") === "RegionalAdmin") {
        this.setState({
          user_role_list: ["RegionalAdmin", "Engineer", "CPI", "Installer"],
        });
      } else if (
        localStorage.getItem("userRole") === "MasterAdmin" ||
        localStorage.getItem("userRole") === "AdminManager"
      ) {
        this.setState({
          user_role_list: ["RegionalAdmin", "Engineer", "CPI", "Installer"],
        });
      } else if (localStorage.getItem("userRole") === "Engineer") {
        this.setState({
          user_role_list: ["CPI", "Installer"],
        });
      }
      this.setState({
        all_user_list: all_user_list,
        user_role: this.state.user_role_list[0],
      });
      //console.log(this.state.all_user_list)
    });
  }
  onClear = (files: any) => {
    this.setState({ images: files, clearImage: true });
  };

  addimage() {
    this.setState({
      image: 3,
    });
    //console.log(this.state.image)
  }
  listProviders() {
    //console.log("LIST PROVIDER")
    requestAPICall("list_provider", {}, {}).then((data) => {
      //console.log(data)
      var sasList: any = [];
      var domainProxyList: any = [];
      if (data["provider_list"] != undefined) {
        var resp = data["provider_list"];

        resp.forEach((element: any) => {
          if (element["providerTypeId"] === 1) {
            sasList.push(element);
          } else {
            domainProxyList.push(element);
          }
        });
      }
      this.setState({ domainProxyList: domainProxyList });
      this.setState({ sasList: sasList });
    });
  }
  onDrop = (image: any) => {
    this.setState({ images: image });
    //console.log(this.state.images)
  };
  onImportFile = (importfile: any) => {
    this.setState({ importfiles: importfile });
    //console.log(this.state.importfiles)
  };
  onFiles = (files: any) => {
    this.setState({ files: files });
    //console.log(this.state.files)
  };
  setAerialVendor = (e: any) => {
    //console.log(e.target.value);
    this.setState({ selectedAerialVendorId: e.target.value }, () => {
      this.updateSettings();
    });
  };
  setSMBVendor = (e: any) => {
    //console.log(e.target.value);
    this.setState({ selectedSMBVendorId: e.target.value }, () => {
      this.updateSettings();
    });
  };
  setROEVendor = (e: any) => {
    //console.log(e.target.value);
    this.setState({ selectedROEVendorId: e.target.value }, () => {
      this.updateSettings();
    });
  };
  setCPEVendor = (e: any) => {
    //console.log(e.target.value);
    this.setState({ selectedCPEVendorId: e.target.value }, () => {
      this.updateSettings();
    });
  };
  setProvider = (e: any, id: any) => {
    //console.log(id);

    if (id === 0) {
      //console.log(e.target.value);
      this.setState({ selectedProviderId: e.target.value }, () => {
        // this.updateSettings();
      });
    } else {
      this.setState({ selectedProviderId: id }, () => {
        // this.updateSettings();
      });
    }
  };

  listVendorAPI() {
    //console.log("LIST VENDOR")
    requestAPICall("list_vendor", {}, {}).then((data) => {
      var aerialVendorList: any = [];
      var smbVendorList: any = [];
      var roeVendorList: any = [];
      var cpeVendorList: any = [];
      if (data["aerial_vendor_list"] != undefined) {
        var resp = data["aerial_vendor_list"];
        //console.log(resp)
        resp.forEach((element: any) => {
          //console.log(element);
          if (element["assetTypeId"] == 1) {
            aerialVendorList.push(element);
          } else {
            cpeVendorList.push(element);
          }
        });
      }

      if (data["smb_vendor_list"] != undefined) {
        var resp = data["smb_vendor_list"];
        //console.log(resp)
        resp.forEach((element: any) => {
          //console.log(element);
          if (element["assetTypeId"] == 6) {
            smbVendorList.push(element);
          } else {
            cpeVendorList.push(element);
          }
        });
      }

      if (data["roe_vendor_list"] != undefined) {
        var resp = data["roe_vendor_list"];
        //console.log(resp)
        resp.forEach((element: any) => {
          //console.log(element);
          if (element["assetTypeId"] == 1) {
            roeVendorList.push(element);
          } else {
            cpeVendorList.push(element);
          }
        });
      }

      this.setState({
        aerialVendorList: aerialVendorList,
        smbVendorList: smbVendorList,
        roeVendorList: roeVendorList,
      });
      this.setState({ cpeVendorList: cpeVendorList });
    });
  }
  getDefaultSettings() {
    requestAPICall("get_default_setings", {}, {}).then((data) => {
      //console.log(data)
      if (data.responseCode === 200) {
        this.setState({
          selectedAerialVendorId: data.defaultAerialVendorId,
          selectedSMBVendorId: data.defaultSMBVendorId,
          selectedROEVendorId: data.defaultROEVendorId,
          selectedCPEVendorId: data.defaultCpeVendorId,
          selectedProviderId: data.defaultProviderId,
          selectedUserId: data.defaultGlobalUserId,
        });

        if (data.defaultProviderTypeId == 1) {
          this.setState({
            sasChecked: true,
            dpChecked: false,
          });
        }
        if (data.defaultProviderTypeId == 2) {
          this.setState({
            dpChecked: true,
            sasChecked: false,
          });
        }
      }
    });
  }
  updateSettings = () => {
    let requestBody = {
      user_default_provider: this.state.selectedProviderId,
      user_default_aerial_vendor: this.state.selectedAerialVendorId,
      user_default_smb_vendor: this.state.selectedSMBVendorId,
      user_default_roe_vendor: this.state.selectedROEVendorId,
      user_default_cpe_vendor: this.state.selectedCPEVendorId,
      user_default_global_userId: this.state.selectedUserId,
    };
    requestAPICall("update_settings", {}, requestBody).then((data) => {
      //console.log(data)
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == 200) {
        this.setState({
          warningmessage: "Successfully updated the settings",
          alertbox: true,
        });
        localStorage.removeItem("AP_Outdoor_Data");
        localStorage.removeItem("AP_Indoor_Data");
        localStorage.removeItem("vendor_type");
      } else {
        this.setState({
          warningmessage: "Failed to update the settings",
          alertbox: true,
        });
      }
    });
  };
  onChange = (e: any) => {
    //console.log(this.state.newPassword, "ssssssssss")
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  hideModal1 = () => {
    this.setState({
      showcpimodel: false,
    });
  };
  createProvider(providerTypeId: any, providerName: any, providerEndpoint: any) {
    this.setState({
      is_loader: 1,
    });
    //console.log("CREATE PROVIDER")
    var picReader = new FileReader();
    let filerequestBody = {};
    let fileName = this.state.images[0].name;
    let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
    picReader.onload = (e) => {
      //console.log(picReader.result);
      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: picReader.result,
      };
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        //console.log(data)
        if (data["responseCode"] == 200) {
          //console.log(data.file_id)
          let requestBody = {
            providerName: providerName,
            providerTypeId: providerTypeId,
            providerEndpoint: providerEndpoint,
            logo: data.file_id,
          };
          //console.log(requestBody)
          requestAPICall("create_provider", {}, requestBody).then((data) => {
            //console.log(data)
            if (data["responseCode"] == 200) {
              this.listProviders();
            }
            this.setState({
              is_loader: 2,
              alertbox: true,
              warningmessage: data.message,
            });
          });
        } else {
          this.setState({
            alertbox: true,
            warningmessage: data.message,
          });
        }
      });
    };
    picReader.readAsDataURL(this.state.images[0]);
  }
  apvalidation = () => {
    let check = false;
    if (this.state.vendorName === "" || this.state.vendorName === undefined) {
      this.apFocus["vendorname"].focus();
      check = true;
    } else if (this.state.vendorModel === "" || this.state.vendorModel === undefined) {
      this.apFocus["vendorModel"].focus();
      check = true;
    }
    // else if (this.state.fccId === "" || this.state.fccId === undefined) {
    //     this.apFocus["fccId"].focus()
    //     check = true
    // }
    else if (this.state.beamwidth === "" || this.state.beamwidth === undefined) {
      this.apFocus["Beamwidth"].focus();
      check = true;
    } else if (this.state.antenna_model === "" || this.state.antenna_model === undefined) {
      this.apFocus["antenna_model1"].focus();
      check = true;
    } else if (this.state.antennaGain === "" || this.state.antennaGain === undefined) {
      this.apFocus["antennaGain"].focus();
      check = true;
    } else if (this.state.eirpCapability === "" || this.state.eirpCapability === undefined) {
      this.apFocus["eirpCapability"].focus();
      check = true;
    } else if (this.state.category === "" || this.state.category === undefined) {
      this.apFocus["category"].focus();
      check = true;
    } else if (this.state.technology === "" || this.state.technology === undefined) {
      this.apFocus["technology"].focus();
      check = true;
    }
    return check;
  };
  cpvalidation = () => {
    let check = false;
    if (this.state.vendorName === "" || this.state.vendorName === undefined) {
      this.cpFocus["cpevendorName"].focus();
      check = true;
    } else if (this.state.vendorModel === "" || this.state.vendorModel === undefined) {
      this.cpFocus["cpevendorModel"].focus();
      check = true;
    }
    // else if (this.state.fccId === "" || this.state.fccId === undefined) {
    //     this.cpFocus["cpefccId"].focus()
    //     check = true
    // }
    else if (this.state.beamwidth === "" || this.state.beamwidth === undefined) {
      this.cpFocus["Beamwidth1"].focus();
      check = true;
    } else if (this.state.antenna_model === "" || this.state.antenna_model === undefined) {
      this.cpFocus["antenna_model"].focus();
      check = true;
    } else if (this.state.antennaGain === "" || this.state.antennaGain === undefined) {
      this.cpFocus["antennaGain1"].focus();
      check = true;
    } else if (this.state.eirpCapability === "" || this.state.eirpCapability === undefined) {
      this.cpFocus["eirpCapability1"].focus();
      check = true;
    } else if (this.state.category === "" || this.state.category === undefined) {
      this.cpFocus["category1"].focus();
      check = true;
    } else if (this.state.technology === "" || this.state.technology === undefined) {
      this.cpFocus["technology1"].focus();
      check = true;
    }
    return check;
  };
  createVendor() {
    var assetTypeId;
    if (
      this.state.card == "addaerial" ||
      this.state.card == "addroe" ||
      this.state.card == "editroe" ||
      this.state.card == "editaerial"
    ) {
      assetTypeId = 1;
    } else if (this.state.card == "addsmb" || this.state.card == "editsmb") {
      assetTypeId = 6;
    } else {
      assetTypeId = 3;
    }
    let check;
    if (assetTypeId === 1 || assetTypeId === 6) {
      check = this.apvalidation();
    } else {
      check = this.cpvalidation();
    }

    //console.log("CREATE VENDOR")
    let requestBody = {
      name: this.state.vendorName,
      model: this.state.vendorModel,
      antennaPattern: this.state.AntennaPattern,
      softwareVersion: this.state.softwareVersion,
      hardwareVersion: this.state.hardwareVersion,
      firmwareVersion: this.state.firmwareVersion,
      technology: this.state.technology,
      category: this.state.category,
      noOfTransmitters: this.state.noOfTransmitters,
      vendorType: this.state.vendorType,
      fccId: this.state.fccId,
      assetTypeId: assetTypeId,
      active: 1,
      antenna_beamwidth: this.state.beamwidth,
      eirp_capability: this.state.eirpCapability,
      antenna_gain: this.state.antennaGain,
      antenna_model: this.state.antenna_model,
      assetVendorType: this.state.assetVendorType,
    };
    if (!check) {
      this.setState({
        is_loader: 1,
      });
      requestAPICall("create_vendor", {}, requestBody).then((data) => {
        //console.log(data)
        this.hideModal();
        if (data["responseCode"] == 200) {
          this.listVendorAPI();
        } else {
          this.setState({
            alertbox: true,
            warningmessage: data["message"],
          });
        }
        this.setState({
          is_loader: 2,
        });
      });
    }
  }

  updateVendor() {
    var assetTypeId;
    if (
      this.state.card == "addaerial" ||
      this.state.card == "addroe" ||
      this.state.card == "editroe" ||
      this.state.card == "editaerial"
    ) {
      assetTypeId = 1;
    } else if (this.state.card == "addsmb" || this.state.card == "editsmb") {
      assetTypeId = 6;
    } else {
      assetTypeId = 3;
    }
    let check;
    if (assetTypeId === 1 || assetTypeId === 6) {
      check = this.apvalidation();
    } else {
      check = this.cpvalidation();
    }
    let requestBody = {
      // 'name': this.state.vendorName,
      model: this.state.vendorModel,
      antennaPattern: this.state.AntennaPattern,
      softwareVersion: this.state.softwareVersion,
      hardwareVersion: this.state.hardwareVersion,
      firmwareVersion: this.state.firmwareVersion,
      technology: this.state.technology,
      category: this.state.category,
      noOfTransmitters: this.state.noOfTransmitters,
      vendorType: this.state.vendorType,
      fccId: this.state.fccId,
      assetTypeId: assetTypeId,
      active: 1,
      antenna_beamwidth: this.state.beamwidth,
      eirp_capability: this.state.eirpCapability,
      antenna_gain: this.state.antennaGain,
      antenna_model: this.state.antenna_model,
    };
    let vendor_id;
    if (this.state.assetVendorType === "Aerial") {
      vendor_id = this.state.selectedAerialVendorId;
    } else if (this.state.assetVendorType === "SMB") {
      vendor_id = this.state.selectedSMBVendorId;
    } else if (this.state.assetVendorType === "ROE") {
      vendor_id = this.state.selectedROEVendorId;
    } else if (assetTypeId === 3) {
      vendor_id = this.state.selectedCPEVendorId;
    }

    if (!check) {
      this.setState({
        is_loader: 1,
      });
      requestAPICall("upate_vendor", { vendor_id: vendor_id }, requestBody).then((data) => {
        //console.log(data)
        this.hideModal();
        if (data["responseCode"] == 200) {
          this.listVendorAPI();
          this.setState({
            alertbox: true,
            warningmessage: data["message"],
          });

          this.setState({
            is_loader: 2,
          });
        }
      });
    }
  }
  importUser() {
    status_list = [];
    //console.log("Import user info")
    var fileReader = new FileReader();
    let filerequestBody = {};
    let fileName = this.state.importfiles[0].name;
    let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
    fileReader.onload = (e) => {
      //console.log(fileReader.result);
      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: fileReader.result,
      };
      //console.log(filerequestBody)
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        //console.log(data)
        if (data["responseCode"] == 200) {
          //console.log(data.file_id)
          let requestBody = {
            filePath: data.file_path,
          };
          requestAPICall("import_user", {}, requestBody).then((data) => {
            status_list = data.asset_status;
            //console.log(data)
            if (data["responseCode"] == 200) {
              this.listUser();
              this.setState({
                card: "status",
              });
            } else {
              status_list = [];
            }
          });
        }
      });
    };
    fileReader.readAsDataURL(this.state.importfiles[0]);
  }
  showResetPasswordModal = (e: any, user: any) => {
    //console.log(user)
    this.setState({
      userList: user,
    });
    //console.log(this.state.userList)
    this.setState({
      card: e.target.id,
    });
  };
  password_click(e: any) {
    //console.log(e.target.checked)
    //console.log(e.target.name)
    if (e.target.name == "auto") {
      this.setState({
        auto_check: true,
        manual_check: false,
      });
    } else if (e.target.name == "manual") {
      this.setState({
        auto_check: false,
        manual_check: true,
      });
    }
  }
  setUserStatus = (e: any, user: any) => {
    //console.log(e.target.value)
    this.setState({
      userStatus: e.target.value,
      updateUser: user,
    });
  };
  callchangePasswordAPI = () => {
    let requestBody = {
      username: this.state.userList["userName"],
      currentPassword: this.state.userList["userPassword"],
      newPassword: this.state.newPassword,
      email: this.state.userList["userEmail"],
      resetEmpPassword: false,
    };
    //console.log(requestBody)
    if (this.state.auto_check === true) {
      requestAPICall("change_random_password", {}, requestBody).then((data) => {
        //console.log(data)
        if (data.responseCode == 200) {
          //console.log(data)
          this.setState({
            alertbox: true,
            warningmessage: data["message"],
          });
          this.setState({
            card: "",
          });
        }
      });
    } else if (this.state.manual_check === true) {
      let requestBody = {
        username: this.state.userList["userName"],
        newPassword: this.state.newPassword,
        email: this.state.userList["userEmail"],
        resetEmpPassword: true,
      };

      requestAPICall("change_password", {}, requestBody).then((data) => {
        if (data.responseCode == 200) {
          //console.log(data)
          this.setState({
            alertbox: true,
            warningmessage: data["message"],
          });
          this.setState({
            card: "",
          });
        } else {
          this.setState({
            warningmessage: data.message,
            alertbox: true,
          });
        }
      });
    }
  };
  handleChange = (evt: any) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  userNameClick = (e: any, indx: any) => {
    //console.log(e.target.name, e.target.value)
    this.setState({
      card: "cpi",
    });
  };

  editModelEmailClick = (e: any, indx: any) => {
    //console.log(e.target.name, e.target.value)
    this.state.all_user_list.forEach((user: any, index: any) => {
      if (index === indx) {
        user.firstName = e.target.value;
        this.setState({
          updateUser: user,
        });
      }
    });
    this.setState({
      fullname: e.target.value,
    });
  };
  editModelCPIClick = (e: any, indx: any) => {
    //console.log(e.target.name, e.target.value)
    this.state.all_user_list.forEach((user: any, index: any) => {
      if (index === indx) {
        user.cpiId = e.target.value;
        this.setState({
          updateUser: user,
        });
      }
    });
  };
  editModelStatusClick = (e: any, indx: any) => {
    //console.log(e.target.name, e.target.value)
    this.state.all_user_list.forEach((user: any, index: any) => {
      if (index === indx) {
        user.isActive = e.target.value;
        this.setState({
          updateUser: user,
        });
      }
    });
  };
  updateUserInfo = (user: any) => {
    //console.log(user)
    //console.log(this.state.view)
    let body = {
      user_email: user.userEmail,
      is_active: user.status,
      cpi_id: user.cpiId,
      cpi_name: user.cpiName,
      user_status: this.state.userStatus,
      first_name: this.state.fullname,
    };
    requestAPICall("update_user", { user_id: user.userId }, body).then((data) => {
      //console.log(data)
      if (data.responseCode == 200) {
        this.setState({
          is_loader: 0,
          succeesUpdate: 0,
          view: "",
        });
        setTimeout(() => {
          this.listUser();
        }, 500);
      }
    });
  };

  setShowAlert = () => {
    this.setState({
      alertbox: !this.state.alertbox,
    });
  };
  editSAS = (providerId: any, providerTypeId: any) => {
    this.setState(
      {
        selectedEditProviderId: providerId,
        selectedEditProviderTypeId: providerTypeId,
      },
      () => this.get_provider_details()
    );
  };
  editSasModal() {
    this.setState({
      card: "editsass",
    });
  }
  get_provider_details() {
    this.setState({
      is_loader: 1,
    });
    let requestBody = {};
    requestAPICall(
      "get_provider",
      {
        provider_id: this.state.selectedEditProviderId,
        provider_type_id: this.state.selectedEditProviderTypeId,
      },
      requestBody
    ).then((data) => {
      //console.log(data)
      this.setState({
        is_loader: 0,
      });
      if (data.responseCode == 200) {
        //console.log(data)
        this.setState(
          {
            providerName: data["provider_name"],
            providerLogo: data["provider_logo"],
            providerEndpoint: data["provider_endpoint"],
            authUsername: data["auth_username"],
            authPassword: data["auth_password"],
          },
          () => this.editSasModal()
        );
      }
    });
  }
  editProvider() {
    this.setState({
      is_loader: 1,
    });
    //console.log(this.state.images)
    if (this.state.images.length > 0) {
      var picReader = new FileReader();
      let filerequestBody = {};
      let fileName = this.state.images[0].name;
      let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
      //console.log("=============");
      picReader.onload = (e) => {
        filerequestBody = {
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
        };
        //console.log(filerequestBody);
        requestAPICall("upload_file", {}, filerequestBody).then((data) => {
          //console.log(data)
          if (data["responseCode"] == 200) {
            let ReqBody = {
              providerName: this.state.providerName,
              providerEndpoint: this.state.providerEndpoint,
              authUsername: this.state.authUsername,
              authPassword: this.state.authPassword,
              providerLogo: data.file_id,
            };
            this.updateProvider(ReqBody);
          }
        });
      };
      picReader.readAsDataURL(this.state.images[0]);
    } else {
      let ReqBody = {
        providerName: this.state.providerName,
        providerEndpoint: this.state.providerEndpoint,
        authUsername: this.state.authUsername,
        authPassword: this.state.authPassword,
      };
      this.updateProvider(ReqBody);
    }
  }

  updateProvider = (reqBody: any) => {
    //console.log(reqBody)
    requestAPICall(
      "update_provider",
      {
        provider_id: this.state.selectedEditProviderId,
        provider_type_id: this.state.selectedEditProviderTypeId,
      },
      reqBody
    ).then((data) => {
      //console.log(data)
      this.setState({
        is_loader: 0,
      });
      this.hideModal();
      if (data.responseCode == 200) {
        //console.log(data)
        this.setState({
          warningmessage: data.message,
          alertbox: true,
        });
      } else {
        this.setState({
          warningmessage: data.message,
          alertbox: true,
        });
      }
      this.listProviders();
    });
  };
  showModal = (e: any) => {
    this.setState({
      card: e.target.id,
    });
    if (
      e.target.id === "addaerial" ||
      e.target.id === "addsmb" ||
      e.target.id === "addroe" ||
      e.target.id === "addcpe"
    ) {
      this.setState({
        action: "ADD",
      });
    } else if (e.target.id === "editaerial") {
      this.setState({
        action: "EDIT",
      });
      this.get_vendor_details(this.state.selectedAerialVendorId);
    } else if (e.target.id === "editsmb") {
      this.setState({
        action: "EDIT",
      });
      this.get_vendor_details(this.state.selectedSMBVendorId);
    } else if (e.target.id === "editroe") {
      this.setState({
        action: "EDIT",
      });
      this.get_vendor_details(this.state.selectedROEVendorId);
    } else if (e.target.id === "editcpe") {
      this.setState({
        action: "EDIT",
      });
      this.get_vendor_details(this.state.selectedCPEVendorId);
    }

    let displayVendorType = "";
    let assetVendorType = "";
    if (e.target.id === "addaerial") {
      displayVendorType = "Directional";
      assetVendorType = "Aerial";
    } else if (e.target.id === "addsmb") {
      displayVendorType = "Omni";
      assetVendorType = "SMB";
    } else if (e.target.id === "addroe") {
      displayVendorType = "Directional";
      assetVendorType = "ROE";
    }

    this.setState({
      providerName: "",
      providerEndpoint: "",
      vendorName: "",
      vendorModel: "",
      fccId: "",
      noOfTransmitters: "",
      vendorType: displayVendorType,
      image: "",
      AntennaPattern: "",
      technology: "",
      softwareVersion: "",
      hardwareVersion: "",
      assetVendorType: assetVendorType,
      firmwareVersion: "",
      category: "",
      beamwidth: "",
      eirpCapability: "",
      antennaGain: "",
      antenna_model: "",
      fullname: "",
      username: "",
      password: "",
      cpiName: "",
      cpiid: "",
      user_role: "RegionalAdmin",
      email: "",
      selectedEditProviderId: "",
      providerLogo: [],
    });
    //console.log(this.state.providerName)
    //console.log(this.state.show)
  };

  get_vendor_details = (selectId: any) => {
    let requestBody = {};
    requestAPICall("get_vendor", { vendor_id: selectId }, requestBody).then((data) => {
      //console.log(data)
      if (data.responseCode == 200) {
        //console.log(data['vendor'])
        this.setState({
          vendorName: data["vendor"]["venor_name"],
          vendorModel: data["vendor"]["model_name"],
          AntennaPattern: data["vendor"]["antenna_pattern"],
          fccId: data["vendor"]["fcc_id"],
          beamwidth: data["vendor"]["antenna_beamwidth"],
          softwareVersion: data["vendor"]["software_version"],
          hardwareVersion: data["vendor"]["hardware_version"],
          firmwareVersion: data["vendor"]["firmware_version"],
          antennaGain: data["vendor"]["antenna_gain"],
          eirpCapability: data["vendor"]["eirp_capability"],
          category: data["vendor"]["category"],
          noOfTransmitters: data["vendor"]["no_of_tranmitters"],
          vendorType: data["vendor"]["vendor_type"],
          technology: data["vendor"]["technology_name"],
          antenna_model: data["vendor"]["antenna_model"],
          assetVendorType: data["vendor"]["asset_vendor_type"],
        });
      }
    });
  };

  deleteModalUser = (user: any) => {
    this.setState({
      user: user,
    });
    //console.log(user)
    if (user.userRole != "Installer") {
      this.setState({
        showcpimodel: true,
      });
    } else {
      this.confirm_delete_user();
    }
  };
  confirm_delete_user() {
    this.setState({
      is_loader: 1,
    });
    let body = {
      isactive: 0,
    };
    requestAPICall("delete_user", { user_id: this.state.user.userId }, body).then((data) => {
      //console.log(data)
      if (data.responseCode == 200) {
        this.listUser();
        this.setState({
          is_loader: 0,
          showcpimodel: false,
        });
      }
    });
  }
  hideModal = () => {
    //console.log("=====")
    this.setState({
      card: "",
      images: [],
      files: [],
      importfiles: [],
      auto_check: false,
      manual_check: false,
      newPassword: "",
      authUsername: "",
      authPassword: "",
    });
    // ReactDOM.findDOMNode(this.refs.addDomainProxy)['value'] = "";
    this.listUser();
    this.setState({ providerName: "", providerEndpoint: "" });
    //console.log(this.state.providerName)
  };
  onCloseModal = () => {
    this.setState({ value: "" });
  };

  enableSAS = (e: any) => {
    //console.log(e.target.checked)
    this.setState({
      sasChecked: e.target.checked,
      dpChecked: false,
    });
  };
  setUserId = (e: any) => {
    //console.log(e.target.value);
    this.setState({ selectedUserId: e.target.value }, () => {
      // this.updateSettings();
    });
  };
  checkRole = (user: any, e: any) => {
    //console.log(user)
    //console.log(e.target.value)
    if (localStorage.getItem("userRole") === "RegionalAdmin" && user.userRole === "RegionalAdmin") {
      this.setState({
        deleteOption: true,
      });
    } else if (localStorage.getItem("userRole") === "MasterAdmin" && user.userRole === "MasterAdmin") {
      this.setState({
        deleteOption: true,
      });
    }
    //  else if (localStorage.getItem('userRole') === "Engineer") {
    //     this.setState({
    //         deleteOption : false
    //     })
    // }
    else {
      this.setState({
        deleteOption: false,
      });
    }
  };

  enableDP = (e: any) => {
    //console.log(e.target.checked)
    this.setState({
      sasChecked: false,
      dpChecked: e.target.checked,
    });
  };
  user_role_change = (e: any) => {
    this.setState({
      user_role: e.target.value,
    });
    //console.log(e.target.value)
  };
  render() {
    let files = this.state.files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let images = this.state.images.map((image: any) => (
      <span className="ellipsistooltip90" key={image.name}>
        {image.name}
      </span>
    ));

    let importfiles = this.state.importfiles.map((files: any) => (
      <span className="ellipsistooltip90" key={files.name}>
        {files.name}
      </span>
    ));
    let classDName = files.length ? "dropzone-uploaded" : "dropzone";
    let classDName1 = images.length ? "dropzone-uploaded" : "dropzone";
    let classDName2 = importfiles.length ? "dropzone-setheight-uploaded" : "dropzone-setheight";

    return (
      <div style={{ backgroundColor: "#F9FAFC" }}>
        <div className="row" style={{ padding: "0px 35px" }}>
          <div className="col-md-12 p-0">
            <h4>Settings</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div
              className="card"
              style={{
                marginTop: "10px",
                width: "calc(100vw - 40px)",
                height: "inherit",
                position: "relative",
                boxShadow: "0px 3px 4px #0000001A",
              }}
            >
              <div className="card-body">
                <span className="searchcontent">SYSTEM SETTINGS</span>
                {/* <div className="col-md-4 p-0">
                                            <div className="form-label-group m-0">
                                                <input type="text" className="paramtext-web"></input>
                                                <label className="acpdetails" h>{installation.displayName}</label>
                                            </div>
                                        </div> */}
                <div className="col-md-4 p-0">
                  <div className="form-label-group m-0">
                    <input
                      type="text"
                      className="accesspointname"
                      id="usersettings"
                      value={this.state.selectedUserId}
                      placeholder="User Id"
                      onChange={(e) => this.setUserId(e)}
                    />
                    <label className="pages" htmlFor="usersettings">
                      User Id*
                    </label>
                  </div>
                </div>
                <div className="m-t-10">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="custom-control custom-checkbox mobsettings d-inline-block"
                            style={{ paddingLeft: "1rem" }}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              style={{ margin: "5px" }}
                              id="customChecksass"
                              name="example1"
                              checked={this.state.sasChecked}
                              onChange={(e: any) => this.enableSAS(e)}
                            />
                            <label
                              className="custom-control-label check fontw500"
                              style={{
                                left: "-16px",
                                fontSize: "12px",
                                color: "#aaaaaa",
                              }}
                              htmlFor="customChecksass"
                            >
                              SELECT SAS
                            </label>
                          </div>
                        </div>

                        {this.state.userRole !== "Engineer" && this.state.userRole !== "CPI" ? (
                          <div
                            className="col-md-2 text-right"
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "22px",
                            }}
                          >
                            <i
                              className="fa fa-plus p-r-5"
                              style={{
                                color: "#0073FF",
                                marginTop: "3px",
                                fontSize: "10px",
                                cursor: "pointer",
                              }}
                            />
                            <span
                              className="font12"
                              style={{
                                color: "#0073FF",
                                marginTop: "3px",
                                cursor: "pointer",
                              }}
                              id="addsass"
                              onClick={this.showModal}
                            >
                              ADD
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="col-md-12" style={{ overflowY: "auto", height: "122px" }}>
                          {this.state.sasList.map((sas: any) => (
                            <div className="col-md-3 d-inline-block p-l-0 p-r-10">
                              <button
                                type="button"
                                style={{ width: "100%" }}
                                disabled={
                                  this.state.dpChecked ||
                                  (sas.providerName != "google" && sas.providerName != "federated")
                                }
                                onClick={(e) => {
                                  this.setProvider(e, sas.providerId);
                                }}
                                className={`settingbtn m-t-10 ${
                                  sas.providerId === this.state.selectedProviderId ? "settingbtn-active" : ""
                                }`}
                                data-toggle="button"
                              >
                                <img
                                  className={` ${sas.providerName === "commscope" ? "commheight" : "sasheight"}`}
                                  src={sas.providerImage}
                                />
                                <div className="text-block">
                                  <p>
                                    <i
                                      className="fa fa-pen setedit"
                                      id="editsass"
                                      onClick={() => this.editSAS(sas.providerId, sas.providerTypeId)}
                                    ></i>
                                  </p>
                                </div>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="col-md-12">
                        <div className="row">
                          {/* <div className='col-md-6 d-none'>
                                                        <div className='row'>

                                                            <div className='col-md-12 p-r-0'>
                                                                <div>
                                                                    <div className="custom-control custom-checkbox mobsettings d-inline-block" style={{ paddingLeft: '1rem' }}>
                                                                        <input type="checkbox" className="custom-control-input" style={{ margin: '5px' }} id="customCheckmob" checked={this.state.dpChecked} onChange={(e) => this.enableDP(e)} name="example2" />
                                                                        <label className="custom-control-label domainsetting fontw500" htmlFor="customCheckmob">SELECT DOMAIN PROXY</label>
                                                                    </div>
                                                                </div>

                                                                <div style={{ position: "absolute", top: "0px", right: "0px" }}>
                                                                    <i className="fa fa-plus p-r-5" style={{ color: '#0073FF', marginTop: '3px', fontSize: '10px', cursor: "pointer" }} />
                                                                    <span className="font12" style={{ color: '#0073FF', marginTop: '3px', cursor: 'pointer' }} id='dmp' onClick={this.showModal} >ADD</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className='col-md-12'>
                                                                <div className="col-md-11 p-l-0 p-r-10">
                                                                    <select className="form-control settingdropdown buildinfo p-l-5" disabled={this.state.sasChecked} value={this.state.selectedProviderId} onChange={(e) => { this.setProvider(e, 0) }} style={{ width: '100%', marginTop: '13px' }}>
                                                                        <option value="" hidden>Please select one</option>
                                                                        {this.state.domainProxyList.map((proxy: any) => <option value={proxy['providerId']} >{proxy['providerName']}</option>)}
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-1">
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div> */}
                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12 p-r-0">
                                <div>
                                  <span className="fontw500 domainsetting">SELECT AERIAL VENDOR</span>
                                </div>
                              </div>
                              {/* <div className='col-md-12' style={{ marginTop: '33px' }}>  </div> */}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0px",
                                  right: "0px",
                                }}
                              >
                                <i
                                  className="fa fa-plus p-r-5"
                                  style={{
                                    color: "#0073FF",
                                    marginTop: "3px",
                                    fontSize: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                                <span
                                  className="font12"
                                  style={{
                                    color: "#0073FF",
                                    marginTop: "3px",
                                    cursor: "pointer",
                                  }}
                                  id="addaerial"
                                  onClick={this.showModal}
                                >
                                  ADD
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-md-11 p-l-0 p-r-10 d-inline-block">
                                  <select
                                    className="form-control settingdropdown buildinfo p-l-5 m-t-10"
                                    value={this.state.selectedAerialVendorId}
                                    onChange={(e) => {
                                      this.setAerialVendor(e);
                                    }}
                                    style={{ width: "100%", marginTop: "13px" }}
                                  >
                                    <option value="" hidden>
                                      Please select one
                                    </option>
                                    {this.state.aerialVendorList.map((vendor: any) => (
                                      <option value={vendor["vendorModalId"]}>{vendor["name"]}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-1 p-l-5 p-r-0 d-inline-block">
                                  {this.state.selectedAerialVendorId != "" ? (
                                    <i
                                      className="fa fa-pen setedit"
                                      title="Edit"
                                      id="editaerial"
                                      onClick={(e) => this.showModal(e)}
                                    ></i>
                                  ) : (
                                    <i className="fa fa-pen" id="editaerial" style={{ color: "#ededf0" }}></i>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12 p-r-0">
                                <div>
                                  <span className="fontw500 domainsetting">SELECT SMB VENDOR</span>
                                </div>
                              </div>
                              {/* <div className='col-md-12' style={{ marginTop: '33px' }}>  </div> */}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0px",
                                  right: "0px",
                                }}
                              >
                                <i
                                  className="fa fa-plus p-r-5"
                                  style={{
                                    color: "#0073FF",
                                    marginTop: "3px",
                                    fontSize: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                                <span
                                  className="font12"
                                  style={{
                                    color: "#0073FF",
                                    marginTop: "3px",
                                    cursor: "pointer",
                                  }}
                                  id="addsmb"
                                  onClick={this.showModal}
                                >
                                  ADD
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-md-11 p-l-0 p-r-10 d-inline-block">
                                  <select
                                    className="form-control settingdropdown buildinfo p-l-5 m-t-10"
                                    value={this.state.selectedSMBVendorId}
                                    onChange={(e) => {
                                      this.setSMBVendor(e);
                                    }}
                                    style={{ width: "100%", marginTop: "13px" }}
                                  >
                                    <option value="" hidden>
                                      Please select one
                                    </option>
                                    {this.state.smbVendorList.map((vendor: any) => (
                                      <option value={vendor["vendorModalId"]}>{vendor["name"]}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-1 p-l-5 p-r-0 d-inline-block">
                                  {this.state.selectedSMBVendorId != "" ? (
                                    <i
                                      className="fa fa-pen setedit"
                                      title="Edit"
                                      id="editsmb"
                                      onClick={(e) => this.showModal(e)}
                                    ></i>
                                  ) : (
                                    <i className="fa fa-pen" id="editsmb" style={{ color: "#ededf0" }}></i>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="row">
                              <div className="col-md-12 p-r-0">
                                <div>
                                  <span className="fontw500 domainsetting">SELECT ROE VENDOR</span>
                                </div>
                              </div>
                              {/* <div className='col-md-12' style={{ marginTop: '33px' }}>  </div> */}
                              <div
                                style={{
                                  position: "absolute",
                                  top: "0px",
                                  right: "0px",
                                }}
                              >
                                <i
                                  className="fa fa-plus p-r-5"
                                  style={{
                                    color: "#0073FF",
                                    marginTop: "3px",
                                    fontSize: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                                <span
                                  className="font12"
                                  style={{
                                    color: "#0073FF",
                                    marginTop: "3px",
                                    cursor: "pointer",
                                  }}
                                  id="addroe"
                                  onClick={this.showModal}
                                >
                                  ADD
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="col-md-11 p-l-0 p-r-10 d-inline-block">
                                  <select
                                    className="form-control settingdropdown buildinfo p-l-5 m-t-10"
                                    value={this.state.selectedROEVendorId}
                                    onChange={(e) => {
                                      this.setROEVendor(e);
                                    }}
                                    style={{ width: "100%", marginTop: "13px" }}
                                  >
                                    <option value="" hidden>
                                      Please select one
                                    </option>
                                    {this.state.roeVendorList.map((vendor: any) => (
                                      <option value={vendor["vendorModalId"]}>{vendor["name"]}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-1 p-l-5 p-r-0 d-inline-block">
                                  {this.state.selectedROEVendorId != "" ? (
                                    <i
                                      className="fa fa-pen setedit"
                                      title="Edit"
                                      id="editroe"
                                      onClick={(e) => this.showModal(e)}
                                    ></i>
                                  ) : (
                                    <i className="fa fa-pen" id="editroe" style={{ color: "#ededf0" }}></i>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className='col-md-4 d-none'>
                                                        <div className='row'>

                                                            <div className='col-md-12 p-r-0'>
                                                                <div>
                                                                    <span className="fontw500 domainsetting" >SELECT CPE VENDOR</span>
                                                                </div>
                                                            </div>
                                                            {/* <div className='col-md-12' style={{ marginTop: '33px' }}>  </div> 
                                                            <div style={{ position: "absolute", top: "0px", right: "0px" }}>
                                                                <i className="fa fa-plus p-r-5" style={{ color: '#0073FF', marginTop: '3px', fontSize: '10px', cursor: "pointer" }} />
                                                                <span className="font12" style={{ color: '#0073FF', marginTop: '3px', cursor: 'pointer' }} id='addcpe' onClick={this.showModal}>ADD</span>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className='col-md-12'>
                                                                <div className="col-md-11 p-l-0 p-r-10 d-inline-block">
                                                                    <select className="form-control settingdropdown buildinfo p-l-5 m-t-10" value={this.state.selectedCPEVendorId} onChange={(e) => { this.setCPEVendor(e) }} style={{ width: '100%', marginTop: '13px' }}>
                                                                        <option value="" hidden>Please select one</option>
                                                                        {this.state.cpeVendorList.map((vendor: any) => <option value={vendor['vendorModalId']} >{vendor['name']}</option>)}
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-1 p-l-5 p-r-0 d-inline-block">
                                                                    {
                                                                        this.state.selectedCPEVendorId != "" && this.state.selectedCPEVendorId != null ? <i className="fa fa-pen setedit" title="Edit" id='editcpe' onClick={(e) => this.showModal(e)}></i>
                                                                            : <i className="fa fa-pen " style={{ color: "#ededf0" }} id='editcpe' ></i>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* <div style={{ top: "0px", right: "0px" }}>
                                                            <i className="fa fa-plus p-r-5" style={{ color: '#0073FF', marginTop: '3px', fontSize: '10px', cursor: "pointer" }} />
                                                            <span className="font12" style={{ color: '#0073FF', marginTop: '3px', cursor: 'pointer' }} id='editcpe' onClick={this.showModal}>EDIT</span>
                                                        </div>

                                                    </div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-12 m-t-20 text-right">
                            <button className="btn cardbtn" style={{ width: "185px" }} onClick={this.updateSettings}>
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card importcard m-b-10" style={{ position: "relative", borderRadius: "6px" }}>
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <span className="searchcontent">USER DETAILS</span>
                </div>
                <div className="col-md-4 p-r-30" style={{ display: "flex", justifyContent: "flex-end" }}>
                  <i
                    className="fa fa-plus"
                    style={{
                      color: "#0073FF",
                      marginTop: "6px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  />
                  <span
                    className="p-l-5 font14"
                    style={{
                      color: "#0073FF",
                      marginTop: "3px",
                      cursor: "pointer",
                    }}
                    id="cpiinstaller"
                    onClick={this.showModal}
                  >
                    ADD
                  </span>
                  <i
                    className="material-icons p-l-5"
                    style={{
                      color: "#0073FF",
                      transform: "rotate(180deg)",
                      cursor: "pointer",
                      display: "none",
                    }}
                  >
                    get_app
                  </i>
                  <span
                    className="font14"
                    style={{
                      color: "#0073FF",
                      paddingTop: "2px",
                      cursor: "pointer",
                      display: "none",
                    }}
                    id="heat"
                    onClick={this.showModal}
                  >
                    Import File
                  </span>
                </div>
              </div>
              <div className="row m-t-20">
                <div className="col-md-12">
                  <table className="table sitetable ">
                    <thead className="sitehead userdetailhead">
                      <tr className="">
                        <th className="w5 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}></th>
                        <th className="w15 table-bg-hdr-color">
                          Full Name
                          <img className="sortdown" src={sort} style={{ width: "10px" }} />
                          <img
                            src={sort}
                            style={{
                              width: "10px",
                              marginLeft: "10px",
                              marginBottom: "-10px",
                            }}
                          />
                        </th>

                        <th className="w10 table-bg-hdr-color">
                          User Name
                          <img className="sortdown" src={sort} style={{ width: "10px" }} />
                          <img
                            src={sort}
                            style={{
                              width: "10px",
                              marginLeft: "10px",
                              marginBottom: "-10px",
                            }}
                          />
                        </th>

                        <th className="w20 table-bg-hdr-color">
                          Email
                          <img className="sortdown" src={sort} style={{ width: "10px" }} />
                          <img
                            src={sort}
                            style={{
                              width: "10px",
                              marginLeft: "10px",
                              marginBottom: "-10px",
                            }}
                          />
                        </th>

                        <th className="w15 table-bg-hdr-color">
                          User Role
                          <img className="sortdown" src={sort} style={{ width: "10px" }} />
                          <img
                            src={sort}
                            style={{
                              width: "10px",
                              marginLeft: "10px",
                              marginBottom: "-10px",
                            }}
                          />
                        </th>

                        <th className="w15 table-bg-hdr-color">
                          CPI ID
                          <img className="sortdown" src={sort} style={{ width: "10px" }} />
                          <img
                            src={sort}
                            style={{
                              width: "10px",
                              marginLeft: "10px",
                              marginBottom: "-10px",
                            }}
                          />
                        </th>

                        <th className="w10 table-bg-hdr-color">
                          Status
                          <img className="sortdown" src={sort} style={{ width: "10px" }} />
                          <img
                            src={sort}
                            style={{
                              width: "10px",
                              marginLeft: "10px",
                              marginBottom: "-10px",
                            }}
                          />
                        </th>

                        <th className="w10 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                      </tr>
                    </thead>
                    <tbody className="userdetailbody">
                      {this.state.all_user_list.map((users: any, index: any) => (
                        <tr>
                          <td className="w5">
                            {" "}
                            <img src={user} alt="Avatar" style={{ margin: "0px" }} className="avatar float-left" />
                          </td>
                          <td className="w15">
                            <input
                              disabled
                              style={{
                                border: "0px",
                                borderRadius: "6px",
                                height: "42px",
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                padding: "0px",
                              }}
                              title={users.firstName}
                              className="accesspointname"
                              value={users.firstName}
                              name={users.firstName}
                              // onChange={(e: any) => this.editModelEmailClick(e, index)}
                              // onClick={(e: any) => this.userNameClick(e, index)}
                            />
                          </td>
                          <td className="w10">
                            <input
                              disabled
                              style={{
                                border: "0px",
                                borderRadius: "6px",
                                height: "42px",
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                padding: "0px",
                              }}
                              title={users.userName}
                              className="accesspointname"
                              value={users.userName}
                            />
                          </td>
                          <td className="w20">
                            <input
                              disabled
                              style={{
                                border: "0px",
                                borderRadius: "6px",
                                height: "42px",
                                width: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                padding: "0px",
                              }}
                              title={users.userEmail}
                              className="accesspointname"
                              value={users.userEmail}
                            />
                          </td>
                          <td className="w15">
                            <input
                              disabled
                              style={{
                                border: "0px",
                                borderRadius: "6px",
                                height: "42px",
                                width: "100%",
                                padding: "0px",
                              }}
                              className="accesspointname"
                              value={users.userRole}
                            />
                          </td>
                          <td className="w15">
                            <input
                              type="text"
                              readOnly={!(this.state.view == users.userName)}
                              style={
                                !(this.state.view == users.userName)
                                  ? {
                                      borderRadius: "6px",
                                      height: "42px",
                                      width: "100%",
                                      border: "1px solid #fff",
                                      padding: "0px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }
                                  : {
                                      borderRadius: "6px",
                                      height: "42px",
                                      width: "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }
                              }
                              title={users.cpiId}
                              className="accesspointname"
                              name={users.cpiId}
                              value={users.cpiId}
                              onChange={(e: any) => this.editModelCPIClick(e, index)}
                            />
                          </td>
                          <td className="w10 ">
                            {!(this.state.view == users.userName) ? (
                              users.userStatus === 1 ? (
                                <div>
                                  <span
                                    className="dot"
                                    style={{
                                      color: "#00D56E",
                                      backgroundColor: "#00D56E",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      paddingLeft: "7px",
                                      color: "#00D56E",
                                    }}
                                  >
                                    Active
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  <span
                                    className="dot"
                                    style={{
                                      color: "rgb(226, 43, 43)",
                                      backgroundColor: "rgb(226, 43, 43)",
                                    }}
                                  ></span>
                                  <span
                                    style={{
                                      color: "rgb(226, 43, 43)",
                                      paddingLeft: "7px",
                                    }}
                                  >
                                    Inactive
                                  </span>
                                </div>
                              )
                            ) : (
                              <select
                                name="status"
                                onChange={(e: any) => this.setUserStatus(e, users)}
                                className="form-control settingdropdown buildinfo accesspointname p-l-5 "
                              >
                                <option value="1">Active</option>
                                <option value="2">Inactive</option>
                              </select>
                            )}

                            {/* <select name="status" disabled={!(this.state.view == users.userName)} onChange={(e: any) => this.setUserStatus(e, users)} className="form-control settingdropdown buildinfo accesspointname p-l-5 " >
                                                            {
                                                                users.userStatus == 1 ?
                                                                    <option value='1' hidden>Active</option> : null
                                                            }
                                                            {
                                                                users.userStatus == 2 ?
                                                                    <option value="2" hidden>Inactive</option> : null
                                                            }
                                                            <option value="1" >Active</option>
                                                            <option value="2" >Inactive</option>
                                                        </select> */}
                          </td>

                          <td className="w10">
                            {this.state.view === users.userName ? (
                              <div>
                                {this.state.succeesUpdate == 1 ? (
                                  ""
                                ) : (
                                  <span
                                    className="csetting-text float-right"
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      this.setState({
                                        readOnly: !this.state.readOnly,
                                        view: "",
                                      });
                                    }}
                                  >
                                    Cancel
                                  </span>
                                )}
                                {this.state.succeesUpdate == 1 ? (
                                  ""
                                ) : (
                                  <div
                                    className="settings csettingscheck text-center"
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      this.updateUserInfo(users);
                                    }}
                                  >
                                    <img
                                      src={checkSuccess}
                                      alt="Update"
                                      id="accept-change"
                                      style={{
                                        marginTop: "2px",
                                        padding: "4px",
                                      }}
                                      className=""
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                <a
                                  href="#"
                                  role="button"
                                  id="dropdownMenuLink"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  onClick={(e) => this.checkRole(users, e)}
                                >
                                  <i
                                    className="fas fa-ellipsis-v float-right"
                                    style={{
                                      color: "#B4B4B4",
                                      marginTop: "10px",
                                    }}
                                    onClick={(e) => this.checkRole(users, e)}
                                  ></i>
                                </a>
                                <div
                                  className="dropdown-menu csettings"
                                  style={{ backgroundColor: "black" }}
                                  aria-labelledby="dropdownMenuLink"
                                >
                                  <a
                                    className="dropdown-item cursor"
                                    href="#"
                                    style={{ color: "white" }}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      this.setState({
                                        readOnly: false,
                                        view: users.userName,
                                      });
                                    }}
                                  >
                                    Edit
                                  </a>
                                  {this.state.deleteOption === false ? (
                                    <a
                                      className="dropdown-item cursor"
                                      style={{ color: "white" }}
                                      id="delete"
                                      onClick={() => this.deleteModalUser(users)}
                                    >
                                      Delete
                                    </a>
                                  ) : (
                                    ""
                                  )}

                                  <a
                                    className="dropdown-item cursor"
                                    id="updatep"
                                    onClick={(e: any) => this.showResetPasswordModal(e, users)}
                                    style={{ color: "white" }}
                                  >
                                    Reset Password
                                  </a>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={
            (this.state.show && this.state.card === "addaerial") ||
            this.state.card === "addsmb" ||
            this.state.card === "addroe" ||
            this.state.card === "editaerial" ||
            this.state.card === "editsmb" ||
            this.state.card === "editroe"
          }
          tabIndex="-1"
          handleClose={this.hideModal}
        >
          {/* <div className="modal fade"  role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="">
                  <span className="modal-title fontw500" style={{ marginTop: "0px", fontSize: "15px" }}>
                    {this.state.action} VENDOR
                  </span>
                  <i
                    className="material-icons modalcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="m-t-20" style={{ maxHeight: "200px", overflow: "hidden auto" }}>
                  {this.state.action === "EDIT" ? (
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          ref={(ref) => {
                            this.apFocus["vendorname"] = ref;
                            return true;
                          }}
                          className="text-user"
                          type="text"
                          disabled
                          style={{ width: "100%", height: "45px" }}
                          id="vendorname"
                          placeholder="Vendor Name"
                          name="vendorName"
                          value={this.state.vendorName}
                          onChange={this.handleChange}
                        />
                        <label className="pages" htmlFor="vendorname">
                          Vendor Name *
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          ref={(ref) => {
                            this.apFocus["vendorname"] = ref;
                            return true;
                          }}
                          className="text-user"
                          style={{ width: "100%", height: "45px" }}
                          id="vendorname"
                          placeholder="Vendor Name"
                          name="vendorName"
                          value={this.state.vendorName}
                          onChange={this.handleChange}
                        />
                        <label className="pages" htmlFor="vendorname">
                          Vendor Name *
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.apFocus["vendorModel"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="vendorModel"
                        placeholder="Model"
                        name="vendorModel"
                        value={this.state.vendorModel}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="vendorModel">
                        Model *
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="AntennaPattern"
                        placeholder="Antenna Pattern"
                        name="AntennaPattern"
                        value={this.state.AntennaPattern}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="AntennaPattern">
                        Antenna Pattern
                      </label>
                    </div>
                  </div>
                  {this.state.action === "EDIT" ? (
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          ref={(ref) => {
                            this.apFocus["fccId"] = ref;
                            return true;
                          }}
                          className="text-user"
                          type="text"
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                            height: "45px",
                          }}
                          id="fccId"
                          placeholder="FCC ID"
                          name="fccId"
                          value={this.state.fccId}
                          onChange={this.handleChange}
                        />
                        <label className="pages" htmlFor="fccId">
                          FCC ID
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          ref={(ref) => {
                            this.apFocus["fccId"] = ref;
                            return true;
                          }}
                          className="text-user"
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                            height: "45px",
                          }}
                          id="fccId"
                          placeholder="FCC ID"
                          name="fccId"
                          value={this.state.fccId}
                          onChange={this.handleChange}
                        />
                        <label className="pages" htmlFor="fccId">
                          FCC ID
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.apFocus["Beamwidth"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="Beamwidth"
                        placeholder="FCC ID"
                        name="beamwidth"
                        value={this.state.beamwidth}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="Beamwidth">
                        Antenna Beamwidth (degrees) *
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.apFocus["antenna_model1"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="antenna_model1"
                        placeholder="Antenna Model"
                        name="antenna_model"
                        value={this.state.antenna_model}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="antenna_model1">
                        Antenna Model *
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="Software"
                        placeholder="Software Version"
                        name="softwareVersion"
                        value={this.state.softwareVersion}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="Software">
                        Software Version{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="Hardware"
                        placeholder="Hardware Version"
                        name="hardwareVersion"
                        value={this.state.hardwareVersion}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="Hardware">
                        Hardware Version{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="Firmware"
                        placeholder="Firmware Version"
                        name="firmwareVersion"
                        value={this.state.firmwareVersion}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="Firmware">
                        Firmware Version{" "}
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.apFocus["antennaGain"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="antennaGain"
                        placeholder="Antenna Gain"
                        name="antennaGain"
                        value={this.state.antennaGain}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="antennaGain">
                        Antenna Gain (dBi) *
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.apFocus["eirpCapability"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="eirpCapability"
                        placeholder="Eirp Capability"
                        name="eirpCapability"
                        value={this.state.eirpCapability}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="eirpCapability">
                        Eirp Capability (dBm)*
                      </label>
                    </div>
                  </div>

                  <select
                    ref={(ref) => {
                      this.apFocus["category"] = ref;
                      return true;
                    }}
                    className="form-control settingdropdown"
                    style={{ border: "1px solid #CED7DF", borderRadius: "0px" }}
                    id="category"
                    name="category"
                    value={this.state.category}
                    onChange={this.handleChange}
                  >
                    <option value="" hidden>
                      Category *
                    </option>
                    <option value="DEVICE_CATEGORY_A">A</option>
                    <option value="DEVICE_CATEGORY_B">B</option>
                  </select>

                  <select
                    ref={(ref) => {
                      this.apFocus["technology"] = ref;
                      return true;
                    }}
                    className="form-control settingdropdown b-t-0"
                    style={{ border: "1px solid #CED7DF", borderRadius: "0px" }}
                    id="technology"
                    name="technology"
                    value={this.state.technology}
                    onChange={this.handleChange}
                  >
                    <option value="" hidden>
                      Radio Technologies *
                    </option>
                    <option value="RADIO_TECHNOLOGY_UNSPECIFIED">RADIO_TECHNOLOGY_UNSPECIFIED</option>
                    <option value="E_UTRA">E_UTRA</option>
                    <option value="CAMBIUM_NETWORKS">CAMBIUM_NETWORKS</option>
                    <option value="FOUR_G_BBW_SAA_1">FOUR_G_BBW_SAA_1</option>
                    <option value="NR">NR</option>
                    <option value="DOODLE_CBRS">DOODLE_CBRS</option>
                    <option value="REDLINE">REDLINE</option>
                    <option value="TARANA_WIRELESS">TARANA_WIRELESS</option>
                  </select>
                  <select
                    className="form-control settingdropdown b-t-0"
                    style={{ border: "1px solid #CED7DF", borderRadius: "0px" }}
                    name="noOfTransmitters"
                    value={this.state.noOfTransmitters}
                    onChange={this.handleChange}
                  >
                    <option value="" hidden>
                      No. of Transmitters{" "}
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  {/* {
                                        this.state.action === "EDIT" ? */}
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.apFocus["vendortype"] = ref;
                          return true;
                        }}
                        className="text-user b-t-0"
                        type="text"
                        disabled
                        style={{
                          width: "100%",
                          height: "45px",
                          borderRadius: "0px 0px 6px 6px",
                        }}
                        id="vendortype"
                        placeholder="Directionality"
                        name="Directionality"
                        value={this.state.vendorType}
                      />
                      <label className="pages" htmlFor="vendorname">
                        Directionality*
                      </label>
                    </div>
                  </div>
                  {/* : 
                                            <div className='col-md-12 p-0'>
                                                <div className="form-label-group m-0">
                                                    <input ref={(ref) => { this.apFocus["vendortype"] = ref; return true; }} className='text-user b-t-0' type="text" disabled style={{ width: '100%', height: '45px', borderRadius: '0px 0px 6px 6px' }} id="vendortype" placeholder='Directionality' name='Directionality' value={this.state.vendorType} />
                                                    <label className="pages" htmlFor="vendorname">Directionality*</label>
                                                </div>
                                            </div> */}
                  {/* <select className="form-control settingdropdown b-t-0" style={{ border: '1px solid #CED7DF', borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }} name="vendorType" value={this.state.vendorType} onChange={this.handleChange}>
                                            <option value="" hidden  >Directionality</option>
                                            <option value="Omni" >Omni</option>
                                            <option value="Directional" >Directional</option>
                                        </select>
                                     } */}
                </div>
                {this.state.action === "ADD" ? (
                  <div className="col-md-12 p-0 m-t-20">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.createVendor();
                      }}
                    >
                      Add Vendor
                    </button>
                  </div>
                ) : (
                  <div className="col-md-12 p-0 m-t-20">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.updateVendor();
                      }}
                    >
                      Update Vendor
                    </button>
                  </div>
                )}
                {/* <div className='col-md-12 p-0 m-t-20'>
                                    <button className='btn cardbtn' style={{ width: '100%' }} onClick={() => { this.createVendor(1); this.hideModal() }}>Add Vendor</button>
                                </div> */}
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <Modal
          show={(this.state.show && this.state.card === "addcpe") || this.state.card === "editcpe"}
          handleClose={this.hideModal}
        >
          {/* <div className="modal fade"  role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="">
                  <span className="modal-title fontw500" style={{ marginTop: "0px", fontSize: "15px" }}>
                    {this.state.action} VENDOR
                  </span>
                  <i
                    className="material-icons modalcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="m-t-20" style={{ maxHeight: "200px", overflow: "hidden auto" }}>
                  {this.state.action === "ADD" ? (
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          ref={(ref) => {
                            this.cpFocus["cpevendorName"] = ref;
                            return true;
                          }}
                          className="text-user"
                          type="text"
                          style={{ width: "100%", height: "45px" }}
                          id="cpevendorName"
                          placeholder="Vendor Name"
                          name="vendorName"
                          value={this.state.vendorName}
                          onChange={this.handleChange}
                        />
                        <label className="pages" htmlFor="cpevendorName">
                          Vendor Name *
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          ref={(ref) => {
                            this.cpFocus["cpevendorName"] = ref;
                            return true;
                          }}
                          className="text-user"
                          type="text"
                          disabled
                          style={{ width: "100%", height: "45px" }}
                          id="cpevendorName"
                          placeholder="Vendor Name"
                          name="vendorName"
                          value={this.state.vendorName}
                          onChange={this.handleChange}
                        />
                        <label className="pages" htmlFor="cpevendorName">
                          Vendor Name *
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.cpFocus["cpevendorModel"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="cpevendorModel"
                        placeholder="Model"
                        name="vendorModel"
                        value={this.state.vendorModel}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="cpevendorModel">
                        Model *
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="cpeAntennaPattern"
                        placeholder="Antenna Pattern"
                        name="AntennaPattern"
                        value={this.state.AntennaPattern}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="cpeAntennaPattern">
                        Antenna Pattern
                      </label>
                    </div>
                  </div>
                  {this.state.action === "EDIT" ? (
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          ref={(ref) => {
                            this.cpFocus["cpefccId"] = ref;
                            return true;
                          }}
                          className="text-user"
                          type="text"
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                            height: "45px",
                          }}
                          id="cpefccId"
                          placeholder="FCC ID"
                          name="fccId"
                          value={this.state.fccId}
                          onChange={this.handleChange}
                        />
                        <label className="pages" htmlFor="cpefccId">
                          FCC ID
                        </label>
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          ref={(ref) => {
                            this.cpFocus["cpefccId"] = ref;
                            return true;
                          }}
                          className="text-user"
                          style={{
                            borderRadius: "0px",
                            width: "100%",
                            height: "45px",
                          }}
                          id="cpefccId"
                          placeholder="FCC ID"
                          name="fccId"
                          value={this.state.fccId}
                          onChange={this.handleChange}
                        />
                        <label className="pages" htmlFor="cpefccId">
                          FCC ID
                        </label>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.cpFocus["Beamwidth1"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="Beamwidth1"
                        placeholder="FCC ID"
                        name="beamwidth"
                        value={this.state.beamwidth}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="Beamwidth1">
                        Antenna Beamwidth (degrees) *
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.cpFocus["antenna_model"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="antenna_model"
                        placeholder="Antenna Model"
                        name="antenna_model"
                        value={this.state.antenna_model}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="antenna_model">
                        Antenna Model *
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="Software1"
                        placeholder="Software Version"
                        name="softwareVersion"
                        value={this.state.softwareVersion}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="Software1">
                        Software Version{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="Hardware1"
                        placeholder="Hardware Version"
                        name="hardwareVersion"
                        value={this.state.hardwareVersion}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="Hardware1">
                        Hardware Version{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="Firmware1"
                        placeholder="Firmware Version"
                        name="firmwareVersion"
                        value={this.state.firmwareVersion}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="Firmware1">
                        Firmware Version{" "}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.cpFocus["antennaGain1"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="antennaGain1"
                        placeholder="Antenna Gain"
                        name="antennaGain"
                        value={this.state.antennaGain}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="antennaGain1">
                        Antenna Gain (dBi) *
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        ref={(ref) => {
                          this.cpFocus["eirpCapability1"] = ref;
                          return true;
                        }}
                        className="text-user"
                        style={{
                          borderRadius: "0px",
                          width: "100%",
                          height: "45px",
                        }}
                        id="eirpCapability1"
                        placeholder="Eirp Capability"
                        name="eirpCapability"
                        value={this.state.eirpCapability}
                        onChange={this.handleChange}
                      />
                      <label className="pages" htmlFor="eirpCapability1">
                        Eirp Capability (dBm)*
                      </label>
                    </div>
                  </div>

                  <select
                    ref={(ref) => {
                      this.cpFocus["category1"] = ref;
                      return true;
                    }}
                    className="form-control settingdropdown"
                    style={{ border: "1px solid #CED7DF", borderRadius: "0px" }}
                    id="category1"
                    name="category"
                    value={this.state.category}
                    onChange={this.handleChange}
                  >
                    <option value="" hidden>
                      Category *
                    </option>
                    <option value="DEVICE_CATEGORY_A">A</option>
                    <option value="DEVICE_CATEGORY_B">B</option>
                  </select>

                  <select
                    ref={(ref) => {
                      this.cpFocus["technology1"] = ref;
                      return true;
                    }}
                    className="form-control settingdropdown b-t-0"
                    style={{ border: "1px solid #CED7DF", borderRadius: "0px" }}
                    id="technology1"
                    name="technology"
                    value={this.state.technology}
                    onChange={this.handleChange}
                  >
                    <option value="" hidden>
                      Radio Technologies *
                    </option>
                    <option value="RADIO_TECHNOLOGY_UNSPECIFIED">RADIO_TECHNOLOGY_UNSPECIFIED</option>
                    <option value="E_UTRA">E_UTRA</option>
                    <option value="CAMBIUM_NETWORKS">CAMBIUM_NETWORKS</option>
                    <option value="FOUR_G_BBW_SAA_1">FOUR_G_BBW_SAA_1</option>
                    <option value="NR">NR</option>
                    <option value="DOODLE_CBRS">DOODLE_CBRS</option>
                    <option value="REDLINE">REDLINE</option>
                    <option value="TARANA_WIRELESS">TARANA_WIRELESS</option>
                  </select>
                  <select
                    className="form-control settingdropdown b-t-0"
                    style={{
                      border: "1px solid #CED7DF",
                      borderTopRightRadius: "0px",
                      borderTopLeftRadius: "0px",
                    }}
                    name="noOfTransmitters"
                    value={this.state.noOfTransmitters}
                    onChange={this.handleChange}
                  >
                    <option value="" hidden>
                      No. of Transmitters
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
                {this.state.action === "ADD" ? (
                  <div className="col-md-12 p-0 m-t-20">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.createVendor();
                      }}
                    >
                      Add Vendor
                    </button>
                  </div>
                ) : (
                  <div className="col-md-12 p-0 m-t-20">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.updateVendor();
                      }}
                    >
                      Update Vendor
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <Modal show={this.state.show && this.state.card === "dmp"} handleClose={this.hideModal}>
          {/* <div className="modal fade"  role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="">
                  <span className="modal-title fontw500" style={{ marginTop: "0px", fontSize: "15px" }}>
                    DOMAIN PROXY
                  </span>
                  <i
                    className="material-icons modalcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>

                <div className="form-label-group m-t-20">
                  <input
                    type="text"
                    className="accesspointname"
                    name="providerName"
                    id="Test"
                    placeholder="Test"
                    value={this.state.providerName}
                    onChange={this.handleChange}
                  />
                  <label className="pages" htmlFor="Test">
                    Test
                  </label>
                </div>
                <i
                  className="material-icons imgclose-file"
                  style={{
                    margin: "10px",
                    right: "15px",
                    position: "absolute",
                  }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    images = [];
                    this.onClear([]);
                  }}
                >
                  clear
                </i>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone-set m-t-10">
                      <div {...getRootProps({ className: classDName1 })}>
                        <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />

                        {images.length ? (
                          <span style={{ color: "#00D56E", display: "flex" }}>
                            {images}

                            {/* <i className="material-icons imgclose-file" style={{ position: 'absolute', right: '25px' }} onClick={(e: any) => { e.preventDefault(); files = [] }}>clear</i> */}
                          </span>
                        ) : (
                          <span style={{ fontSize: "14px" }}>+ Add Image</span>
                        )}
                      </div>
                      <div></div>
                    </section>
                  )}
                </Dropzone>
                {this.state.providerName !== "" && this.state.images.length !== 0 ? (
                  <div className="col-md-12  p-0 m-t-20 ">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={(e: any) => {
                        this.createProvider(2, this.state.providerName, "");
                        this.hideModal();
                      }}
                    >
                      Select
                    </button>
                  </div>
                ) : (
                  <div className="col-md-12  p-0 m-t-20 ">
                    <button
                      disabled
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={(e: any) => {
                        this.createProvider(2, this.state.providerName, "");
                        this.hideModal();
                      }}
                    >
                      Select
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>

        {/* SAS MODEL */}
        <Modal
          show={this.state.show && (this.state.card === "addsass" || this.state.card === "editsass")}
          handleClose={this.hideModal}
        >
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="">
                  {this.state.card === "addsass" ? (
                    <span className="modal-title fontw500" style={{ marginTop: "0px", fontSize: "15px" }}>
                      ADD SAS
                    </span>
                  ) : (
                    <span className="modal-title fontw500" style={{ marginTop: "0px", fontSize: "15px" }}>
                      EDIT SAS
                    </span>
                  )}
                  <i
                    className="material-icons modalcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>

                <div className="form-label-group m-t-20">
                  <input
                    type="text"
                    className="accesspointname"
                    id="providerName"
                    placeholder="Name"
                    name="providerName"
                    value={this.state.providerName}
                    onChange={this.handleChange}
                  />
                  <label className="pages" htmlFor="providerName">
                    Name
                  </label>
                </div>
                <div className="form-label-group m-t-20">
                  <input
                    type="text"
                    className="accesspointname"
                    id="providerEndpoint"
                    placeholder="Endpoint"
                    name="providerEndpoint"
                    value={this.state.providerEndpoint}
                    onChange={this.handleChange}
                  />
                  <label className="pages" htmlFor="providerEndpoint">
                    Endpoint
                  </label>
                </div>
                <i
                  className="material-icons imgclose-file dropzclose"
                  style={{ marginRight: "10px", right: "15px" }}
                  onClick={(e: any) => {
                    e.preventDefault();
                    images = [];
                    this.onClear([]);
                  }}
                >
                  clear
                </i>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="dropzone-set m-t-10">
                      <div {...getRootProps({ className: classDName1 })}>
                        <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                        {images.length === 0 && this.state.providerLogo != "" ? (
                          <span style={{ fontSize: "14px" }}> Image uploaded Already</span>
                        ) : images.length ? (
                          <span style={{ color: "#00D56E", display: "flex" }}>{images}</span>
                        ) : (
                          <span style={{ fontSize: "14px" }}>+ Add Image</span>
                        )}
                      </div>
                      <div></div>
                    </section>
                  )}
                </Dropzone>
                {this.state.selectedEditProviderId == 2 ? (
                  <div className="form-label-group m-t-20">
                    <input
                      type="text"
                      className="accesspointname"
                      id="authUsername"
                      placeholder="Auth Username"
                      name="authUsername"
                      value={this.state.authUsername}
                      onChange={this.handleChange}
                    />
                    <label className="pages" htmlFor="authUsername">
                      Auth Username
                    </label>
                  </div>
                ) : null}
                {this.state.selectedEditProviderId == 2 ? (
                  <div className="form-label-group m-t-20">
                    <input
                      type="password"
                      className="accesspointname"
                      id="authPassword"
                      placeholder="Auth Password"
                      name="authPassword"
                      value={this.state.authPassword}
                      onChange={this.handleChange}
                    />
                    <label className="pages" htmlFor="authPassword">
                      Auth Password
                    </label>
                  </div>
                ) : null}

                {this.state.card === "addsass" ? (
                  <div className="col-md-12 p-0 m-t-20">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.createProvider(1, this.state.providerName, this.state.providerEndpoint);
                        this.hideModal();
                      }}
                    >
                      Finish
                    </button>
                  </div>
                ) : (
                  <div className="col-md-12 p-0 m-t-20">
                    <button
                      className="btn cardbtn"
                      style={{ width: "100%" }}
                      onClick={() => {
                        this.editProvider();
                      }}
                    >
                      Update
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <Modal show={this.state.show && this.state.card === "heat"} handleClose={this.hideModal}>
          {/* <div className="modal fade" id="heatModal" role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    IMPORT FILES
                  </h6>
                  <i
                    className="material-icons modalcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={this.hideModal}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-10 justify-content-center">
                  <div className="col-md-12">
                    <Dropzone onDrop={this.onImportFile}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone-set">
                          <div style={{ height: "280px" }} {...getRootProps({ className: classDName2 })}>
                            <input {...getInputProps()} accept=".xlsx" />
                            {importfiles.length ? (
                              <span style={{ color: "#00D56E", display: "flex" }}>
                                {importfiles}

                                {/* <i className="material-icons imgclose-file" style={{ position: 'absolute', right: '25px' }} onClick={(e: any) => { e.preventDefault(); files = [] }}>clear</i> */}
                              </span>
                            ) : (
                              <div>
                                <div style={{ textAlign: "center" }}>
                                  <i
                                    className="fas fa-plus-circle text-center"
                                    style={{
                                      padding: "10px",
                                      fontSize: "40px",
                                    }}
                                  ></i>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <span className="text-center" style={{ fontSize: "14px" }}>
                                    Drag and Drop files
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                    <button className="btn cardbtn" style={{ width: "268px" }} onClick={this.importUser}>
                      IMPORT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.card === "status"}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="row" style={{ padding: "0 10px" }}>
                <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                  IMPORT USER STATUS
                </h6>
                <i
                  className="material-icons modalcloseicon imgclose-file"
                  data-dismiss="modal"
                  onClick={this.hideModal}
                  aria-label="Close"
                >
                  clear
                </i>
              </div>
              <div className="modal-body">
                <table className="table sitecollapse">
                  <thead>
                    <tr>
                      <th className="w15">Asset Name</th>
                      <th className="w20">Status</th>
                    </tr>
                    {status_list.map((status: any) => {
                      //console.log(status)
                      return (
                        <tr>
                          <td>{status.name}</td>
                          <td>{status.status}</td>
                        </tr>
                      );
                    })}
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.card === "cpi"} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="row" style={{ padding: "0 10px" }}>
                <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                  Associated CPIs
                </h6>
                <i
                  className="material-icons modalcloseicon imgclose-file"
                  data-dismiss="modal"
                  onClick={this.hideModal}
                  aria-label="Close"
                >
                  clear
                </i>
              </div>
              <div className="modal-body">
                <table className="table sitecollapse">
                  <thead>
                    <tr>
                      <th className="w15">Full Name</th>
                      <th className="w20">Email</th>
                    </tr>
                    {status_list.map((status: any) => {
                      //console.log(status)
                      return (
                        <tr>
                          <td>{status.name}</td>
                          <td>{status.status}</td>
                        </tr>
                      );
                    })}
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.card === "updatep"} handleClose={this.hideModal}>
          {/* <div className="modal fade"  role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    RESET EMPLOYEE PASSWORD
                  </h6>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-5" style={{ padding: "0 10px" }}>
                  <span className="distance" style={{ fontSize: "13px" }}>
                    Select an option and password reset instructions{"\n"}will be emailed to the employee
                  </span>
                </div>
                <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="gender" name="gender2">
                      <FormControlLabel
                        name="auto"
                        checked={this.state.auto_check}
                        onClick={this.password_click}
                        value="auto"
                        control={<Radio color="primary" />}
                        label="Auto generate random password"
                      />
                      <FormControlLabel
                        name="manual"
                        checked={this.state.manual_check}
                        onClick={this.password_click}
                        value="manual"
                        control={<Radio color="primary" />}
                        label="Manual password"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="col-md-10 p-r-0">
                    <div className="form-label-group m-0">
                      <input
                        type="password"
                        className="accesspointname img-center m-t-5"
                        id="newPassword"
                        name="newPassword"
                        value={this.state.newPassword}
                        placeholder="Password"
                        style={{ borderRadius: "6px" }}
                        onChange={this.onChange}
                        disabled={!this.state.manual_check}
                      />
                      <label className="pages" htmlFor="newPassword">
                        Password
                      </label>
                    </div>
                  </div>
                  {/* <div className="form-group" >
                                        <label className='distance'>
                                            <input type="radio" name="auto" checked={this.state.auto_check} onClick={this.password_click} />Auto generate random password
                                        </label>
                                        <label className='distance'>
                                            <input type="radio" name="manual" checked={this.state.manual_check} onClick={this.password_click} />Manual password
                                        </label>
                                        <input type="password" className="text-pwd img-center m-t-5" id="newPassword" name="newPassword" placeholder="Password" style={{ borderRadius: '6px' }} onChange={this.handleChange} disabled={this.state.auto_check} />
                                    </div> */}
                </div>
                <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                  {this.state.manual_check ? (
                    <button
                      className="btn cardbtn"
                      style={{ width: "268px" }}
                      disabled={!this.state.manual_check}
                      onClick={this.callchangePasswordAPI}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className="btn cardbtn"
                      style={{ width: "268px" }}
                      disabled={!this.state.auto_check}
                      onClick={this.callchangePasswordAPI}
                    >
                      Send Reset Instructions
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.card === "cpiinstaller"} handleClose={this.hideModal}>
          {/* <div className="modal fade"  role="dialog" aria-hidden="true"> */}
          <div
            className="modal-dialog center-modal modal-dialog-centered modal-sm"
            role="document"
            style={{ maxWidth: "23vw" }}
          >
            <div
              className="modal-content"
              style={{ maxHeight: "51vh", overflow: "auto", backgroundClip: "border-box" }}
            >
              <div className="modal-body">
                <div className="">
                  <span className="modal-title fontw500" style={{ marginTop: "0px", fontSize: "15px" }}>
                    ADD USER
                  </span>
                  <i
                    className="material-icons modalcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="col-md-12 m-t-20 p-0">
                  <div className="form-label-group m-0">
                    <input
                      className="text-user"
                      maxLength={45}
                      style={{ width: "100%", height: "45px" }}
                      id="fullname"
                      placeholder="Full Name"
                      name="fullname"
                      value={this.state.fullname}
                      onChange={this.onChange}
                    />
                    <label className="pages" htmlFor="fullname">
                      First Name | Last Name
                    </label>
                  </div>
                </div>
                <div className="col-md-12 p-0">
                  <div className="form-label-group m-0">
                    <input
                      className="text-user"
                      ref={(ref) => {
                        this.userFocus["username"] = ref;
                        return true;
                      }}
                      maxLength={45}
                      style={{
                        borderRadius: "0px",
                        width: "100%",
                        height: "45px",
                      }}
                      id="username"
                      placeholder="User Name"
                      name="username"
                      value={this.state.username}
                      onChange={this.onChange}
                    />
                    <label className="pages" htmlFor="username">
                      User Name*
                    </label>
                  </div>
                </div>
                <div className="col-md-12 p-0">
                  <div className="form-label-group m-0">
                    <input
                      className="text-user"
                      ref={(ref) => {
                        this.userFocus["email"] = ref;
                        return true;
                      }}
                      maxLength={50}
                      style={{
                        borderRadius: "0px",
                        width: "100%",
                        height: "45px",
                      }}
                      id="address"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                    <label className="pages" htmlFor="address">
                      Email*
                    </label>
                  </div>
                </div>

                {/* <div className='col-md-12 p-0'>
                                    <div className="form-label-group m-0">
                                        <input type="password" className='text-user' ref={(ref) => { this.userFocus["password"] = ref; return true; }} maxLength={20} style={{ borderRadius: '0px', width: '100%', height: '45px' }} id="pwd" placeholder='Password' name='password' value={this.state.password} onChange={this.onChange} />
                                        <label className="pages" htmlFor="pwd">Password *</label>
                                    </div>
                                </div> */}
                <div className="col-md-12 p-0">
                  <div className="form-label-group m-0">
                    <select
                      className="text-user"
                      style={{
                        borderRadius: "0px",
                        width: "100%",
                        height: "45px",
                      }}
                      value={this.state.user_role}
                      onChange={(e) => this.user_role_change(e)}
                    >
                      {this.state.user_role_list.map((role: any) => (
                        <option value={role}>{role}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12 p-0">
                  <div className="form-label-group m-0">
                    <input
                      className="text-user"
                      style={{
                        borderRadius: "0px",
                        width: "100%",
                        height: "45px",
                      }}
                      maxLength={45}
                      id="cpiid"
                      placeholder="CPI Id"
                      name="cpiid"
                      value={this.state.cpiid}
                      onChange={this.onChange}
                    />
                    <label className="pages" htmlFor="cpiid">
                      CPI Id
                    </label>
                  </div>
                </div>

                <div className="col-md-12 p-0">
                  <div className="form-label-group m-0">
                    <input
                      className="text-user"
                      style={{
                        borderRadius: "0 0 6px 6px",
                        borderBottomWidth: "1px",
                        width: "100%",
                        height: "45px",
                      }}
                      maxLength={45}
                      id="cpiname"
                      placeholder="CPI Name"
                      name="cpiName"
                      value={this.state.cpiName}
                      onChange={this.onChange}
                    />
                    <label className="pages" htmlFor="cpiname">
                      CPI Name
                    </label>
                  </div>
                </div>
                {/* <select className="form-control settingdropdown" style={{ border: '0.5px solid #CED7DF', borderTopRightRadius: '0px', borderTopLeftRadius: '0px' }} name="Status" >
                                    <option value="" hidden >Status</option>
                                    <option value="1">1</option>
                                    <option value="2" >2</option>
                                    <option value="3" >3</option>
                                    <option value="4">4</option>

                                </select> */}

                <div className="col-md-12 p-0 m-t-20">
                  <button
                    className="btn cardbtn"
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.createUser();
                    }}
                  >
                    Add User
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>

        <Modal show={this.state.showcpimodel} handleClose={this.hideModal1}>
          {/* <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-hidden="true"> */}
          <div
            className="modal-dialog center-modal modal-dialog-centered modal-sm"
            role="document"
            style={{ maxWidth: "335px" }}
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 15px" }}>
                  <span
                    className="modal-title"
                    style={{
                      marginTop: "0px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    CONFIRMATION
                  </span>
                  <i
                    className="material-icons modalcloseicon imgclose-file"
                    style={{ position: "absolute" }}
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                  <span className="modaltext">Are you sure you want to delete this User?</span>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn btn-secondary-c3"
                      style={{ width: "95%" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal1();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn modalbtn"
                      style={{ width: "98%", marginLeft: "2px" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.confirm_delete_user();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>

        <IonAlert
          isOpen={this.state.alertbox}
          onDidDismiss={this.setShowAlert}
          message={this.state.warningmessage}
          buttons={["OK"]}
        />

        <IonLoading
          isOpen={this.state.is_loader === 1}
          onDidDismiss={() => this.setShowLoading(2)}
          message={"Loading..."}
          duration={5000}
        />
      </div>
    );
  }
}
