import { IonContent, IonLoading, IonHeader, IonAlert, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Interactive from 'react-interactive'
import logo from '../assets/img/Spectra_logo.png'
import { requestAPICall } from "../service/apiHandler";
import './Login.css'

const INITIAL_STATE = {
    username: '',
    password: '',
    email: '',
    cpiid: '',
    cpiname: '',
    submit: false,
    selectedName: "",
    is_loader: 0,
    alertbox: false,
    warnMessage: ""
};

export default class Register extends Component {
    state: any = {};
    props: any = {};
    constructor(props: any) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.callSignupAPI = this.callSignupAPI.bind(this)
    }


    handleChange = (e: any) => {

        this.setState({
            [e.target.name]: e.target.value
        })

        console.log(this.state)
    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }

    setShowLoading = (st: number) => {
        this.setState({ is_loader: st })
    }
    formValidation() {
        if (this.state.username == "") {
            this.setState({
                warnMessage: "Please enter username"
            })
            this.setState({
                alertbox: true
            })

        } 
        // if (this.state.username !== "" && !(/^[a-zA-Z0-9.-]{1,10}+$/.test(this.state.username) ) {
        //     this.setState({
        //         warnMessage: "Please enter username"
        //     })
        //     this.setState({
        //         alertbox: true
        //     })

        // }
        
        else if (this.state.email == "") {
            this.setState({
                warnMessage: "Please enter Email Id"
            })
            this.setState({
                alertbox: true
            })

        } else if (this.state.password == "") {
            this.setState({
                warnMessage: "Please enter password"
            })
            this.setState({
                alertbox: true
            })

        } else {
            this.setState({
                submit: true
            })
        }
    }

    submitForm = (e: any) => {
        this.formValidation()
        e.preventDefault()
        if (this.state.username != "" && this.state.password != "" && this.state.email != "") {
            let email_validate = false
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (this.state.email.match(mailformat)) {
                email_validate = true
            } else {
                email_validate = false
            }
            if (email_validate) {
                this.callSignupAPI()
            } else {
                this.setState({
                    warnMessage: "Please Enter valid Email Address"
                })
                this.setState({
                    alertbox: true
                })
            }
        }
        console.log('submit')

    }

    callSignupAPI() {
        this.setState({
            is_loader: 1
        })
        let requestBody = {
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            cpiName: this.state.cpiname,
            cpiId: this.state.cpiid
        }
        requestAPICall("signup", {}, requestBody)
            .then(data => {
                console.log(data)
                this.setState({
                    is_loader: 2
                })
                if (data.responseCode == 200) {
                    this.setState({
                        username: '',
                        password: '',
                        email: '',
                        cpiid: '',
                        cpiname: '',
                    })
                    this.props.history.push('/login');
                } else {
                    this.setState({
                        warnMessage: data.message
                    })
                    this.setState({
                        alertbox: true
                    })

                }
            })

    }



    render() {
        // if (this.state.submit === true)
        //     return <Redirect from='/register' to='/login' />
        const { username, password, email } = this.state;
        const isEnabled = username.length > 0 && password.length > 0 && email.length > 0;
        return (
            <IonPage>
                <IonContent>
                    <div className="webonly">
                        <div className='app-body img-responsive' style={{ marginTop: '0px' }}>
                            <div className='row m-r-0 m-l-0'>
                                <div className='col-md-12'>
                                    <div className='row'>

                                        <div className='col-md-7 col-sm-12 login-bg'>
                                            <div className='row bg-text bg-slogan'>
                                                <div className="col-md-12">
                                                    <span>Your Signal.</span><br />
                                                    <span style={{ lineHeight: '1.8' }}>Your Company.</span><br />
                                                    <span style={{ lineHeight: '1.8' }}>Your Promise to Customers.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5 col-sm-12 login-logo' style={{ top: '25px' }}>
                                            <div className='row m-r-0 m-l-0'>
                                                <div className='col-md-12'>
                                                    <img className='img-center img' src={logo} />
                                                </div>
                                            </div>
                                            <form onSubmit={this.submitForm}>
                                                <div className='row m-r-0 m-l-0' style={{ paddingTop: '5%' }} >
                                                    <div className='col-md-12'>
                                                        <span className='login-text img-center'>Create account</span>

                                                        <div className="row m-t-30 justify-content-center" >
                                                            <div className="form-label-group m-0">
                                                                <input type="text" name="username" id="registeruser" maxLength={45} className="text-user img-center" style={{borderBottom:'1px solid #CED7DF'}} placeholder="Username" value={this.state.username} onChange={this.handleChange} />
                                                                <label htmlFor="registeruser">Username*</label>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center" >
                                                            <div className="form-label-group m-0">
                                                                <input type="email" name="email" id="registeremail" maxLength={50} className="text-user img-center b-t-0" style={{ borderRadius: '0px' }} value={this.state.email} placeholder="Email" onChange={this.handleChange} />
                                                                <label htmlFor="registeremail">Email*</label>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center" >
                                                            <div className="form-label-group m-0">
                                                                <input type="password" name="password" id="registerpwd" maxLength={20} className="text-user img-center b-t-0" style={{ borderRadius: '0px' }} value={this.state.password} placeholder="Password" onChange={this.handleChange} />
                                                                <label htmlFor="registerpwd">Password*</label>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center" >
                                                            <div className="form-label-group m-0">
                                                                <input type="text" name="cpiid" id="cpiid" maxLength={45} className="text-user img-center b-t-0" style={{ borderRadius: '0px' }} value={this.state.cpiid} placeholder="CPI ID" onChange={this.handleChange} />
                                                                <label htmlFor="cpiid">CPI ID</label>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center" >
                                                            <div className="form-label-group m-0">
                                                                <input type="text" name="cpiname" id="cpiname" maxLength={45} className="text-pwd img-center b-t-0" placeholder="CPI Name" value={this.state.cpiname} onChange={this.handleChange} />
                                                                <label htmlFor="cpiname">CPI Name</label>
                                                            </div>
                                                        </div>
                                                        <div className='img-center'>
                                                            <button className='btn btn-log m-t-20' disabled={!isEnabled} type='submit' onClick={this.submitForm}>Sign Up</button>
                                                        </div>
                                                        <div className='login-info m-t-10'>
                                                            <span>Have an account?</span>
                                                            <Interactive as='span' onClick={() => { this.props.history.push('/login') }} ><span style={{ color: '#0059B2', fontWeight: 'bold', cursor: 'pointer', marginLeft: '5px' }}>Login</span></Interactive>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobonly">
                        <div className='app-body img-responsive' style={{ marginTop: '0px' }}>
                            <div className='row m-r-0 m-l-0'>
                                <div className='col-md-12'>
                                    <div className='row'>

                                        <div className='col-md-7 col-sm-12 login-bg'>
                                            <div className='row bg-text bg-slogan'>
                                                <div className="col-md-12">
                                                    <span>Your Signal.</span><br />
                                                    <span style={{ lineHeight: '1.8' }}>Your Company.</span><br />
                                                    <span style={{ lineHeight: '1.8' }}>Your Promise to Customers.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-5 col-sm-12 login-logo signlogo'>
                                            <div className='row m-r-0 m-l-0 logo'>
                                                <div className='col-md-12'>
                                                    <img className='img-center img' src={logo} />
                                                </div>
                                            </div>
                                            <form onSubmit={this.submitForm}>
                                                <div className='row m-r-0 m-l-0' style={{ paddingTop: '12%' }} >
                                                    <div className='col-md-12 homecontent p-r-16 p-l-16'>
                                                        <div className='col-md-12 '>
                                                            <span className='login-text img-center'>Create Account</span>
                                                        </div>
                                                        <div className='col-md-12 p-0'>
                                                            <div className="m-t-24 justify-content-center" >
                                                                <div className="form-label-group m-0">
                                                                    <input type="text" name="username" id="registerusermob" style={{ height: '59px' }} maxLength={45} className="text-user img-center" placeholder="Username" value={this.state.username} onChange={this.handleChange} />
                                                                    <label className="forgot" htmlFor="registerusermob">Username*</label>
                                                                </div>
                                                            </div>
                                                            <div className="justify-content-center" >
                                                                <div className="form-label-group m-0">
                                                                    <input type="email" name="email" id="registeremailmob" className="text-user img-center" maxLength={50} style={{ borderRadius: '0px', height: '59px' }} value={this.state.email} placeholder="Email" onChange={this.handleChange} />
                                                                    <label className="forgot" htmlFor="registeremailmob">Email*</label>
                                                                </div>
                                                            </div>
                                                            <div className="justify-content-center" >
                                                                <div className="form-label-group m-0">
                                                                    <input type="password" name="password" id="registerpwdmob" className="text-user img-center" maxLength={20} style={{ borderRadius: '0px', height: '59px' }} value={this.state.password} placeholder="Password" onChange={this.handleChange} />
                                                                    <label className="forgot" htmlFor="registerpwdmob">Password*</label>
                                                                </div>
                                                            </div>
                                                            <div className="justify-content-center" >
                                                                <div className="form-label-group m-0">
                                                                    <input type="text" name="cpiid" id="cpiidmob" className="text-user img-center" maxLength={45} style={{ borderRadius: '0px', height: '59px' }} value={this.state.cpiid} placeholder="CPI ID" onChange={this.handleChange} />
                                                                    <label className="forgot" htmlFor="cpiidmob">CPI ID</label>
                                                                </div>
                                                            </div>
                                                            <div className="justify-content-center" >
                                                                <div className="form-label-group m-0">
                                                                    <input type="text" name="cpiname" id="cpinamemob" style={{ height: '59px' }} maxLength={45} className="text-pwd img-center" placeholder="CPI Name" value={this.state.cpiname} onChange={this.handleChange} />
                                                                    <label className="forgot" htmlFor="cpinamemob">CPI Name</label>
                                                                </div>
                                                            </div>
                                                            <div className='img-center m-t-24'>
                                                                <button className='btn btn-log' disabled={!isEnabled} type='submit' onClick={this.submitForm}>Sign Up</button>
                                                            </div>
                                                            <div className='login-info m-t-24'>
                                                                <span>Already have an account?</span>
                                                                <Interactive as='span' onClick={() => { this.props.history.push('/login') }} ><span className="fontw500" style={{ color: '#000', cursor: 'pointer', marginLeft: '5px' }}>Login</span></Interactive>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <IonAlert
                        isOpen={this.state.alertbox}
                        onDidDismiss={() => this.setShowAlert(false)}

                        message={this.state.warnMessage}
                        buttons={['OK',]}
                    />

                    <IonLoading
                        isOpen={this.state.is_loader === 1}
                        onDidDismiss={() => this.setShowLoading(2)}
                        message={'Loading...'}
                        duration={5000}
                    />

                </IonContent>
            </IonPage>
        );
    }
}  