import { BarcodeScanner } from "@ionic-native/barcode-scanner";
import { IonAlert, IonContent, IonLoading, IonPage, withIonLifeCycle } from "@ionic/react";
import React from "react";
import qricon from "../assets/img/bar-code-img-icon.png";
import BackIcon from "../assets/img/go-back-icon-img.png";
import store from "../store";
import "./ap-parameters.css";

class apreplace extends React.Component {
  state: any = {
    warnMessage: "",
    alertbox: false,
    isLoading: false,
    newSerialNumber: "",
  };
  props: any = {};
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log(this.props.assetInfo);
  }

  replaceClicked = () => {
    var newInp = this.state.newSerialNumber.toString().trim();
    if (newInp != null && newInp != "") {
      this.reduxStore = store.getState()["general"];
      this.reduxStore["newSerialId"] = newInp;
      this.props.replaceSerialNumber();
    } else {
      this.setState({
        alertbox: true,
        warnMessage: "New Serial Number cannot be empty.",
      });
    }
  };

  openScanner = async () => {
    const data = await BarcodeScanner.scan();
    if (!data.cancelled) {
      this.setState({ newSerialNumber: data.text });
    }
  };

  render() {
    return (
      <IonPage>
        <IonContent>
          <div>
            <div className="mobonly">
              <div className="param-info-mob">
                <div
                  className="col-12 text-center searchborder"
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #f6f7fb",
                    paddingTop: "calc(env(safe-area-inset-top) + 10px)",
                  }}
                >
                  <img
                    className="backicon-mob"
                    style={{ width: "43px", paddingTop: "6px" }}
                    onClick={() => {
                      this.props.hideReplaceOverlay();
                    }}
                    src={BackIcon}
                  />
                  <span className="apheader">Replace CBSD</span>
                </div>
                <div className="col-12 p-0">
                  <div style={{ margin: "20px" }}>
                    {this.props.assetInfo.properties.map((prop: any) => {
                      if (prop.propertyName == "serial_id") {
                        const element = (
                          <div>
                            <div className="replacecbsd-header">
                              <span>Old Serial Number</span>
                            </div>
                            <div className=" m-0">
                              <input
                                disabled={true}
                                type="text"
                                className="accesspointname font14"
                                style={{ width: "100%" }}
                                value={prop.value}
                              />
                            </div>
                          </div>
                        );
                        return element;
                      }
                    })}

                    <div className="replacecbsd-header" style={{ marginTop: "20px" }}>
                      <span>New Serial Number</span>
                    </div>
                    <div className="row">
                      <div className="col-10 m-0 p-r-0">
                        <input
                          value={this.state.newSerialNumber}
                          onChange={(e: any) => {
                            this.setState({ newSerialNumber: e.target.value });
                          }}
                          type="text"
                          className="accesspointname font14"
                          style={{ width: "100%", borderRadius: "6px 0px 0px 6px", borderRight: "none" }}
                          placeholder={"Scan/type new Serial Number"}
                        />
                      </div>
                      <div className="col-2 p-l-0">
                        <div
                          className="v-h-center"
                          style={{
                            height: "100%",
                            borderRadius: "0px 6px 6px 0px",
                            border: "0.5px solid #CED7DF",
                            borderLeft: "none",
                          }}
                          onClick={() => {
                            this.openScanner();
                          }}
                        >
                          <img
                            src={qricon}
                            style={{ width: "25px" }}
                            onClick={() => {
                              this.openScanner();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* BOTTONS AT THE BOTTOM */}
                  <div className="row col-md-12 bottom-actions" style={{ width: "100%", margin: "20px 0px" }}>
                    <div className="" style={{ width: "100%" }}>
                      <button
                        className="btn cardbtn btn-submitted"
                        // disabled={this.state.isUploadImage}
                        onClick={this.replaceClicked}
                      >
                        Replace
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <IonAlert
            isOpen={this.state.alertbox}
            onDidDismiss={() => this.setState({ alertbox: false })}
            message={this.state.warnMessage}
            buttons={["OK"]}
          />

          <IonLoading
            isOpen={this.state.isLoading}
            onDidDismiss={() => this.setState({ isLoading: false })}
            message={"Loading..."}
            // duration={5000}
          />
        </IonContent>
      </IonPage>
    );
  }
}
export default withIonLifeCycle(apreplace);
