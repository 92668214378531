import { IonContent, IonPage } from "@ionic/react";
import React, { Component } from "react";
import logo from "../assets/img/Spectra_logo.png";
import moblogo from "../assets/img/Spectra_logo_mob.png";
import logoname from "../assets/img/Spectra_logo_white.png";
import { isBrowser, isMobile } from "react-device-detect";
import cogs from "../assets/img/settings-white.png";
import { requestAPICall } from "../service/apiHandler";
import ConfigJSON from "../config/config.json";
import { Plugins } from "@capacitor/core";

const { Device } = Plugins;

const INITIAL_STATE = {
  login: false,
  register: false,
  version: "",
};

export default class Home extends Component {
  state: any = {};
  props: any = {};
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.getAppVersion = this.getAppVersion.bind(this);
    this.getCookies = this.getCookies.bind(this);
    this.getVersionDetails = this.getVersionDetails.bind(this);
    this.navigateToQR = this.navigateToQR.bind(this);
  }
  componentDidMount() {
    window.addEventListener("load", this.getCookies);
    window.addEventListener("focus", this.getAppVersion);
    this.getAppVersion();
    this.getCookies();
    Device.getInfo().then((info) => {
      if (info["platform"] !== "web") {
        this.getVersionDetails();
      }
    });
  }

  getVersionDetails() {
    requestAPICall("version", {}, { env: ConfigJSON["env"] }).then((response: any) => {
      if (response.status == 200) {
        const currentVersion = ConfigJSON["version"];
        const latestVersion = response.data["version"].toString();
        if (currentVersion !== latestVersion) {
          this.props.history.replace("/update");
        }
      }
    });
  }

  async getAppVersion() {
    this.setState({ version: "version : " + ConfigJSON["version"] });
  }

  getCookies() {
    if (
      [undefined, null, "undefined", "null"].includes(localStorage.getItem("idToken")) ||
      [undefined, null, "undefined", "null"].includes(localStorage.getItem("accessToken"))
    ) {
      this.props.history.push("/home");
    } else {
      if (isBrowser) {
        this.props.history.push("/map/outdoor");
      } else {
        localStorage.removeItem("backButton");
        localStorage.removeItem("appState");
        this.props.history.push("/map/outdoor/main");
      }
    }
  }

  navigateToQR() {
    this.props.history.push("/configure");
  }
  render() {
    return (
      <IonPage>
        <IonContent>
          <div className="webonly">
            <div className="app-body img-responsive" style={{ marginTop: "0px" }}>
              {/* <div id="home-reload" style={{ display: 'none' }} className='row m-r-0 m-l-0'> */}
              <div id="home-reload" className="row m-r-0 m-l-0">
                <div className="col-md-7 col-sm-12 login-bghome">
                  <div className="row bg-text bg-slogan">
                    <div className="col-md-12">
                      <span>Your Signal.</span>
                      <br />
                      <span style={{ lineHeight: "1.8" }}>Your Company.</span>
                      <br />
                      <span style={{ lineHeight: "1.8" }}>Your Promise to Customers.</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 login-logo">
                  <div className="row m-r-0 m-l-0">
                    <div className="col-md-12 homelogo logoweb">
                      <img className="img-center img" src={logo} />
                    </div>
                    <div className="col-md-12 homelogo logomob">
                      <img className="img-center imgmob" src={moblogo} />
                      <img className="img-center m-t-5 namemob" src={logoname} />
                    </div>
                  </div>
                  <div className="row m-r-0 m-l-0">
                    <div className="col-md-12 homecontent" style={{ paddingTop: "30%" }}>
                      <span className="login-texthome img-center">Get Started Now</span>
                      <button
                        className="btn img-center m-t-30"
                        onClick={() => {
                          this.props.history.push("/login");
                        }}
                      >
                        Log In
                      </button>
                      <div className="login-info m-t-30" style={{ fontSize: "11px" }}>
                        <span>By continuing, you agree to our</span>
                        <br />
                        <a
                          target="_blank"
                          href="https://c3spectra.com/training/terms-conditions"
                          className="cond"
                          style={{ paddingLeft: "7px" }}
                        >
                          Terms & Conditions
                        </a>
                        <span>&nbsp;and&nbsp;</span>
                        <a target="_blank" href="https://c3spectra.com/training/privacy-policy" className="cond">
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobonly">
            <div className="app-body img-responsive" style={{ marginTop: "0px" }}>
              <div className="row m-r-0 m-l-0">
                <div className="col-md-7 col-sm-12 login-bghome">
                  <div className="row bg-text bg-slogan">
                    <div className="col-md-12">
                      <span>Your Signal.</span>
                      <br />
                      <span style={{ lineHeight: "1.8" }}>Your Company.</span>
                      <br />
                      <span style={{ lineHeight: "1.8" }}>Your Promise to Customers.</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12 login-logo">
                  <div className="col-12" onClick={this.navigateToQR}>
                    <img className="homesetting" src={cogs} style={{ top: "env(safe-area-inset-top, 0px)" }} />
                  </div>
                  <div className="row m-r-0 m-l-0">
                    <div className="col-md-12 homelogo logoweb">
                      <img className="img-center img" src={logo} />
                    </div>
                    <div
                      className="col-md-12 homelogo logomob"
                      style={{
                        top: "calc(env(safe-area-inset-top, 0px) + 60px)",
                      }}
                    >
                      <img className="img-center imgmob" src={moblogo} />
                      <img className="img-center m-t-5 namemob" src={logoname} />
                    </div>
                  </div>
                  <div className="row m-r-0 m-l-0">
                    <div className="col-md-12 homecontent p-r-16 p-l-16" style={{ paddingTop: "30%" }}>
                      <span className="login-texthome img-center">Get Started Now</span>
                      <button
                        className="btn img-center m-t-24"
                        onClick={() => {
                          this.props.history.push("/login");
                        }}
                      >
                        Log In
                      </button>
                      <div className="login-info m-t-30" style={{ fontSize: "11px" }}>
                        <span>By continuing, you agree to our</span>
                        <br />
                        <a
                          href="https://c3spectra.com/training/terms-conditions"
                          className="cond"
                          style={{ paddingLeft: "7px" }}
                        >
                          Terms & Conditions
                        </a>
                        <span>&nbsp;and&nbsp;</span>
                        <a href="https://c3spectra.com/training/privacy-policy" className="cond">
                          Privacy Policy
                        </a>
                      </div>

                      <div className="login-info m-t-10" style={{ fontSize: "11px" }}>
                        <span>{this.state.version}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

$(document).ready(function () {
  setTimeout(() => {
    $("#home-reload").css("display", "");
  }, 500);
});
