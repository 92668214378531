import { IonAlert, IonLoading, IonRow } from "@ionic/react";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import camicon from "../assets/img/camera-icon-img.png";
import checkSuccess from "../assets/img/check-success.png";
import { requestAPICall } from "../service/apiHandler";
import "./ap-parameters.css";
import Modal from "./modal";

var sector_property: any = [];
var cbsd_list: any = [];
var installation_list: any = [];
var optional_list: any = [];
var global_list: any = [];
var other_list: any = [];
var select_vendor = "";
var unit: any = "";
var vendor_model_list: any = [];
var enb_prop: any = {};
var property_vendor_model_list: any = [];
export default class APParameters extends Component {
  state: any = {};
  props: any = {};
  paramFocus: any = [];
  constructor(props: any) {
    super(props);
    this.state = {
      showvalue: false,
      sectorIds: [],
      noOfSectors: 0,
      show: false,
      files: [],
      height_files: [],
      azimuth_files: [],
      down_tilt_files: [],
      sector_id: 0,
      assetVendorList: [],
      cpeVendorList: [],
      p12cert: [],
      selectedAPVendorId: 0,
      selectedCPEVendorId: 0,
      selectedSectorId: "",
      selectedSectorName: "",
      asset_installation_property: [],
      selectVendor: "",
      is_loader: false,
      alertbox: false,
      isParameterSubmitted: false,
      cpiId: "",
      cpiName: "",
      cbsd_category: "",
      cpiPassword: "",
      submit_param: "",
      p12Path: "",
      is_indoor_check: false,
      optional_list_value: [],
      installation_list_value: [],
      cbsd_list_value: [],
      global_list_value: [],
      other_list_value: [],
      selectedVendorId: "",
      defaultApVendorId: "",
      defaultCpeVendorId: "",
      defaultProviderId: "",
      submitToCloud: false,
      override_cpi: false,
      paramStat: 0,
      dtimg: false,
      heightimg: false,
      azimuthimg: false,
      certificateSubmitted: false,
      siteId: "",
      enb: "",
      isUploadImage: false,
      edit_vendor: true,
      isEditParam: false,
      sasSubmission: false,
      sectorParamStatus: "",
    };
    this.get_sector_properties = this.get_sector_properties.bind(this);
    this.select_onChange = this.select_onChange.bind(this);
    this.cbsd_category = this.cbsd_category.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
    this.param_actions = this.param_actions.bind(this);
  }
  componentDidMount() {
    // //console.log(this.props)
    // //console.log(this.props.assetInfo.assetTypeId)
    // cbsd_list = []
    // installation_list = []
    // optional_list = []
    // global_list = []
    // this.getDefaultSettings()
    // this.setState({
    //     is_loader: true
    // })
    // if (this.props.assetInfo.assetTypeId == 1) {
    //     this.setState({
    //         noOfSectors: parseInt(this.props.assetInfo.properties.no_of_sectors),
    //         sectorIds: this.props.assetInfo.sector_id
    //     }, () => {
    //         var sector_id = this.props.assetInfo.sector_id[0]
    //         this.get_sector_properties(sector_id, "", "json")
    //         this.listVendorAPI()
    //     })
    // }
    // else {
    //     this.setState({
    //         asset_installation_property: this.props["assetInfo"]["installation_params"],
    //         global_list_value: this.props["assetInfo"]["installation_params"]["global"],
    //         installation_list_value: this.props["assetInfo"]["installation_params"]["installation"]
    //     })
    //     cbsd_list = this.props["assetInfo"]["installation_params"]["cbsd"]
    //     installation_list = this.props["assetInfo"]["installation_params"]["installation"]
    //     optional_list = this.props["assetInfo"]["installation_params"]["optional"]
    //     global_list = this.props["assetInfo"]["installation_params"]["global"]
    //     let cbsd_category = this.props["assetInfo"]["installation_params"]["optional"].filter((property: any) => property.propertyName === "cbsd_category")
    //     this.setState({
    //         cbsd_category: cbsd_category[0]['value']
    //     })
    //     this.listVendorAPI()
    //     this.param_actions(this.props["assetInfo"]["installation_params"]["optional"])
    //     // this.getDefaultSettings()
    // }
    // this.getUserProfile()
    // this.parameterStatus()
    // this.setIndoorDeplyment(installation_list)
    // this.calculateHAAT(installation_list)
  }

  set_property = () => {
    //console.log(this.props)
    //console.log("data of assetinfo ", this.props.assetInfo)
    this.setState({
      is_loader: true,
    });
    cbsd_list = [];
    installation_list = [];
    optional_list = [];
    global_list = [];
    other_list = [];
    this.getDefaultSettings();
    this.getEnb(this.props.assetInfo.properties);
    if (!this.isEmpty(this.props.assetInfo["sasStatus"])) {
      //console.log("====== SAS Registration found====")
      this.setState({
        edit_vendor: false,
      });
    }
    //console.log("================================================" + this.props.assetInfo.properties.no_of_sectors);
    if (this.props.assetInfo.assetTypeId == 1) {
      this.setState(
        {
          noOfSectors: parseInt(this.props.assetInfo.properties.no_of_sectors),
          sectorIds: this.props.assetInfo.sector_id,
          siteId: this.props.assetInfo.assetIdentifierId,
        },
        () => {
          var sector_id = this.props.assetInfo.sector_id[0];
          this.get_sector_properties(sector_id, "", "json");
          this.listVendorAPI();
        }
      );
    } else {
      this.setState({
        asset_installation_property: this.props["assetInfo"]["installation_params"],
        global_list_value: this.props["assetInfo"]["installation_params"]["global"],
        installation_list_value: this.props["assetInfo"]["installation_params"]["installation"],
        other_list_value: this.props["assetInfo"]["installation_params"]["other"],
        siteId: this.props.assetInfo.assetIdentifierId,
      });
      cbsd_list = this.props["assetInfo"]["installation_params"]["cbsd"];
      installation_list = this.props["assetInfo"]["installation_params"]["installation"];
      optional_list = this.props["assetInfo"]["installation_params"]["optional"];
      global_list = this.props["assetInfo"]["installation_params"]["global"];
      other_list = this.props["assetInfo"]["installation_params"]["others"];

      let cbsd_category = this.props["assetInfo"]["installation_params"]["optional"].filter(
        (property: any) => property.propertyName === "cbsd_category"
      );
      this.setState({
        cbsd_category: cbsd_category[0]["value"],
      });
      this.listVendorAPI();
      this.param_actions(this.props["assetInfo"]["installation_params"]["optional"]);
      // this.getDefaultSettings()
    }

    this.getUserProfile();
    this.parameterStatus();
    this.calculateHAAT(installation_list);
    this.setState({
      is_loader: false,
    });
  };

  isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  set_dropdown_label(id: any, value: any) {
    //console.log(id, value)
    if (value === "") {
      $("#" + id).css({
        top: "14px",
        fontSize: "14px",
      });
      $("#" + id).show();
    } else {
      $("#" + id).css({
        top: "5px",
        fontSize: "12px",
      });
      $("#" + id).show();
    }
  }
  getEnb = (prop: any) => {
    prop.forEach((property: any) => {
      if (property.propertyName == "enb_id") {
        this.setState({
          enb: property.value,
        });
      }
    });
  };
  calculateHAAT = (installation_list: any) => {
    // this.setState({ is_loader: true });
    var lat = 0;
    var lon = 0;
    var rad_center = "0";

    installation_list.forEach((property: any) => {
      if (property.propertyName == "latitude") {
        lat = property.value;
      }
      if (property.propertyName == "longitude") {
        lon = property.value;
      }
      if (property.propertyName == "height") {
        rad_center = property.value;
      }
    });
    let requestBody = {
      lat: lat,
      lon: lon,
      nradial: 8,
      src: "ned_1",
      rad_center: parseFloat(rad_center) * 3.281,
      unit: "m",
    };
    //console.log(requestBody)

    requestAPICall("paramCalc", {}, requestBody).then((data) => {
      //console.log(data)
      if (data.responseCode == 200) {
        installation_list.forEach((property: any) => {
          if (property.propertyName == "haat") {
            property.value = data.HAAT;
          }
        });
      }
      this.setState({});
    });
  };

  parameterStatus = () => {
    //console.log("GET STATUS============")
    let requestBody = {};
    requestAPICall(
      "param_status",
      {
        asset_id: this.props["assetInfo"]["assetId"],
        sector_id: this.state.selectedSectorId,
      },
      requestBody
    ).then((data) => {
      if (data.responseCode == 200) {
        this.setState({
          paramStat: data.ParamstatusCode,
        });
        if ([3, 4, 5, 6, 11].includes(this.state.paramStat)) {
          this.setState({
            sasSubmission: true,
          });
        } else {
          this.setState({
            sasSubmission: false,
          });
        }
      }

      //console.log(this.state.paramStat)
    });
  };

  setIndoorDeplyment = (installation_list: any) => {
    if (this.props.assetInfo.assetTypeId == 1 || this.props.assetInfo.assetTypeId == 3) {
      this.setState({
        is_indoor_check: false,
      });
      installation_list.forEach((property: any) => {
        if (property.propertyName == "indoor_deployment") {
          property.value = 0;
        }
      });
    }
    if (this.props.assetInfo.assetTypeId == 2 || this.props.assetInfo.assetTypeId == 4) {
      this.setState({
        is_indoor_check: true,
      });
      installation_list.forEach((property: any) => {
        if (property.propertyName == "indoor_deployment") {
          property.value = 1;
        }
      });
    }
  };

  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };
  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };
  getUserProfile() {
    //console.log("GET USER PROFILE============")
    let userList: any = {};
    requestAPICall("get_user_profile", {}, {}).then((data) => {
      if (data.responseCode === 200) {
        //console.log(data)
        userList = data["user_list"];

        var image = userList["userImage"];
        this.setState({
          cpiId: userList["cpiId"],
          cpiName: userList["cpiName"],
          registeredCpi: userList["registeredCpi"],
          p12Path: userList["p12Path"],
        });
        if (userList["isSubmitted"] == true) {
          this.setState({
            certificateSubmitted: true,
          });
        }
      }
    });
    //console.log(this.state.cpiId)
    //console.log(this.state.registeredCpi)
  }
  param_actions = (optional_list: any) => {
    //console.log(optional_list)
    optional_list.map((optional: any) => {
      if (optional.propertyName === "cbsd_category") {
        this.setState({
          get_cbsd_category: optional.value,
        });
        //console.log(optional.value)
        //console.log(localStorage.getItem("userRole"))
        if (optional.value === "DEVICE_CATEGORY_A" && localStorage.getItem("userRole") === "Installer") {
          this.setState({
            submit_param: true,
          });
        } else if (optional.value !== "DEVICE_CATEGORY_A" && localStorage.getItem("userRole") === "Installer") {
          this.setState({
            submit_param: false,
          });
        } else if (localStorage.getItem("userRole") !== "Installer") {
          this.setState({
            submit_param: true,
          });
        }
      }
    });
  };
  getDefaultSettings() {
    requestAPICall("get_default_setings", {}, {}).then((data) => {
      if (data.responseCode === 200) {
        //console.log(data)

        this.setState(
          {
            defaultCpeVendorId: data.defaultCpeVendorId,
            defaultApVendorId: data.defaultApVendorId,
            defaultProviderId: data.defaultProviderId,
          },
          () => {
            this.listVendorAPI();
          }
        );
      }
    });
  }
  listVendorAPI() {
    var listVendorUrl = "list_particular_vendor";
    var listVendorInput: any = {
      asset_type_id: this.props["assetInfo"]["assetTypeId"],
    };
    if (this.props["assetInfo"]["assetTypeId"] == 1 || this.props["assetInfo"]["assetTypeId"] == 6) {
      listVendorUrl = "list_directional_vendor";
      listVendorInput = {
        asset_vendor_type: this.props.assetInfo.properties.filter(
          (check: any) => check["propertyName"] == "ap_mounting_type"
        )[0]["value"],
        vendor_type: this.props.assetInfo.properties.filter((check: any) => check["propertyName"] == "vendor_type")[0][
          "value"
        ],
      };
    }
    //console.log("data for asset props ", this.props.assetInfo.properties)
    //console.log("data for api ", listVendorUrl)
    //console.log("data for api request ", listVendorInput)
    requestAPICall(listVendorUrl, listVendorInput, {}).then((data) => {
      //console.log("data of response ", data)
      if (data["vendor_list"] !== undefined && data["vendor_list"].length > 0) {
        try {
          //console.log("data for cbsd ", data['vendor_list'])
          let vendor_model = cbsd_list.filter((property: any) => property.propertyName === "cbsd_vendor_model");
          if (vendor_model[0].value === "" || vendor_model[0].value === undefined) {
            if (this.state.defaultApVendorId !== null) {
              var model_value = data["vendor_list"].filter((property: any) => {
                if (property.vendorModalId === this.state.defaultApVendorId) {
                  return property;
                }
              });
              vendor_model_list = model_value[0];
            }
            if (this.state.defaultCpeVendorId !== null) {
              var model_value = data["vendor_list"].filter((property: any) => {
                if (property.vendorModalId === this.state.defaultCpeVendorId) {
                  return property;
                }
              });
              vendor_model_list = model_value[0];
            }
          }
        } catch (error) {}
        if (
          this.props["assetInfo"]["assetVendorId"] == null &&
          (this.state.defaultApVendorId === null || this.state.defaultCpeVendorId === null)
        ) {
          this.setState({
            selectedVendorId: data["vendor_list"][0]["vendorModalId"],
          });
        }
        //console.log("vendor list specific ", data['vendor_list'])
        this.setState(
          {
            assetVendorList: data["vendor_list"],
          },
          () => {
            if (data["vendor_list"].length > 0) {
              this.set_dropdown_label("cbsdmodel", data["vendor_list"][0]);
            } else {
              this.set_dropdown_label("cbsdmodel", "");
            }
          }
        );
      }
      this.set_dropdown_label("heightype", "HEIGHT_TYPE_AGL");
      this.set_dropdown_label("measurementcap", "MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT");
      this.set_dropdown_label("optionalgroupingparam", "1");
      this.set_dropdown_label("optionalcalsign", "1");
    });
  }

  set_ap_cbsd_value = (
    cbsd_list_value: any,
    optional_list_value: any,
    installation_list_value: any,
    global_list_value: any
  ) => {
    //console.log("setttttttttttttttttttt")
    //console.log(vendor_model_list, 'vendorrrrrrrrr')
    if (vendor_model_list != undefined) {
      cbsd_list_value.forEach((property: any) => {
        if (property.propertyName == "cbsd_software_version") {
          property.value = vendor_model_list["software_version"];
        } else if (property.propertyName == "cbsd_hardware_version") {
          property.value = vendor_model_list["hardware_version"];
        } else if (property.propertyName == "cbsd_firmware_version") {
          property.value = vendor_model_list["firmware_version"];
        } else if (property.propertyName == "cbsd_vendor_model") {
          property.value = vendor_model_list["name"];
        }
      });
      installation_list_value.forEach((property: any) => {
        if (property.propertyName == "antenna_beamwidth") {
          property.value = vendor_model_list["antenna_beamwidth"];
        } else if (property.propertyName == "antenna_model") {
          property.value = vendor_model_list["antenna_model"];
        } else if (property.propertyName == "antenna_gain") {
          property.value = vendor_model_list["antenna_gain"];
        } else if (property.propertyName == "eirp_capability") {
          property.value = vendor_model_list["eirp_capability"];
        }
      });
      optional_list_value.forEach((property: any) => {
        if (property.propertyName == "cbsd_category") {
          property.value = vendor_model_list["category"];
          this.setState({
            get_cbsd_category: vendor_model_list["category"],
          });
        } else if (property.propertyName == "air_interface") {
          property.value = vendor_model_list["technology_name"];
        } else if (property.propertyName == "fcc_id") {
          property.value = vendor_model_list["fcc_id"];
        }
      });
      global_list_value.forEach((property: any) => {
        if (property.propertyName == "fcc_id") {
          property.value = vendor_model_list["fcc_id"];
        }
      });
      cbsd_list = cbsd_list_value;
      optional_list = optional_list_value;
      installation_list = installation_list_value;
      global_list = global_list_value;
      this.setState({
        cbsd_category: vendor_model_list["category"],
        optional_list_value: optional_list,
        cbsd_list_value: cbsd_list,
        global_list_value: global_list,
        installation_list_value: installation_list,
      });
    }
    //console.log(optional_list, cbsd_list, 'oooooooooooooooo')
  };

  cbsd_category(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    //console.log(e.target.value)
    optional_list.map((optional: any) => {
      if (optional.propertyName === "cbsd_category") {
        optional.value = e.target.value;
        this.setState({
          get_cbsd_category: e.target.value,
        });
      }
    });
  }
  air_interface(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    //console.log(e.target.value)
    optional_list.map((optional: any) => {
      if (optional.propertyName === "air_interface") {
        optional.value = e.target.value;
      }
    });
  }
  installation_height(e: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    this.set_dropdown_label(id, e.target.value);
    //console.log(e.target.value)
    installation_list.map((installation: any) => {
      if (installation.propertyName === "height_type") {
        installation.value = e.target.value;
      }
    });
  }
  measurement_capability(e: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    this.set_dropdown_label(id, e.target.value);
    //console.log(e.target.value, e.target.name)
    installation_list.map((installation: any) => {
      if (installation.propertyName === "measurement_capability") {
        installation.value = e.target.value;
      }
    });
  }
  select_onChange(e: any, type: any, property: any, id: any) {
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    this.set_dropdown_label(id, e.target.value);
    if (type === "cbsd") {
      cbsd_list.map((cbsd: any) => {
        if (cbsd.propertyName === property) {
          cbsd.value = e.target.value;
        }
      });
    } else if (type === "optional") {
      optional_list.map((optional: any) => {
        if (optional.propertyName === property) {
          optional.value = e.target.value;
        }
      });
    }
  }
  showModal = () => {
    let check = true;

    this.state.asset_installation_property["global"].forEach((property: any, key: any) => {
      if (property.propertyName === "user_id" && property.value == "") {
        this.paramFocus[key].focus();
        check = false;
      }
    });
    if (check) {
      this.getUserProfile();
      this.setState({
        show: true,
      });
    }
    //console.log(this.state.show)
  };
  downltiltImgClick = (type: any) => {
    //console.log("========")
    this.setState({
      dtimg: false,
    });
    $("#down_tilt_drop_zone").click();
  };
  heightImgClick = (type: any) => {
    //console.log("========")
    this.setState({
      heightimg: false,
    });
    $("#height_drop_zone").click();
  };
  azimuthImgClick = (type: any) => {
    //console.log("========")
    this.setState({
      azimuthimg: false,
    });
    $("#azimuth_drop_zone").click();
  };
  uploadFile = (files: any, value: any) => {
    //console.log("=============", files)
    try {
      let filerequestBody = {};
      this.setState({ is_loader: true });
      var picReader = new FileReader();

      let fileName = files[0].name;
      let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
      if (files[0].uploadType === undefined) {
        picReader.onload = (e) => {
          filerequestBody = {
            fileName: fileName,
            fileExtension: fileExt,
            fileValue: e.target?.result,
            type: "asset-image",
          };
          //console.log(filerequestBody)
          this.uploadImage(filerequestBody, value);
        };
        picReader.readAsDataURL(files[0]);
      }
    } catch (error) {
      this.setState({
        is_loader: false,
        alertbox: true,
        warnMessage: error,
      });
    }
  };
  uploadImage = (filerequestBody: any, value: any) => {
    try {
      this.setState({
        is_loader: true,
        isUploadImage: true,
        isEditParam: true,
      });
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        //console.log(data)
        if (data["responseCode"] == 200) {
          //console.log(data.file_id);
          this.setState({
            isUploadImage: false,
          });
          if (value == "height") {
            this.setState({
              heightimg: true,
            });
            installation_list.map((installation: any) => {
              if (installation.propertyName === "height_image") {
                installation.value = data.file_id;
              }
            });
          } else if (value === "antenna_azimuth") {
            this.setState({
              azimuthimg: true,
            });
            installation_list.map((installation: any) => {
              if (installation.propertyName === "azimuth_image") {
                installation.value = data.file_id;
              }
            });
          } else if (value === "down_tilt") {
            this.setState({
              dtimg: true,
            });
            installation_list.map((installation: any) => {
              if (installation.propertyName === "down_tilt_image") {
                installation.value = data.file_id;
              }
            });
          }
          this.setState({ is_loader: false }, () => {
            this.setState({
              warnMessage: "Image uploaded successfully",
              alertbox: true,
            });
          });
        } else {
          this.setState({ is_loader: false });
          this.setState({ alertbox: true, warnMessage: data.message });
        }
      });
    } catch (error) {
      this.setState({
        is_loader: false,
        alertbox: true,
        warnMessage: error,
      });
    }
  };
  onClear = (files: any, type: any) => {
    if (type == "antenna_pattern") {
      this.setState({ files: files, clearImage: true });
    } else if (type === "") {
      this.setState({ p12cert: files, clearImage: true });
    }
  };

  updateAssetBasicParams = () => {
    let requestBody = {
      assetName: this.props.assetInfo.assetName,
      properties: this.props.assetInfo.properties,
      status: this.props.assetInfo.operationalStatus,
    };
    //console.log(requestBody)
    requestAPICall(
      "update_enb",
      {
        asset_type_id: this.props.assetInfo.assetTypeId,
        asset_id: this.props.assetInfo.assetId,
      },
      requestBody
    ).then((data) => {
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == 200) {
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  };
  savePrameters = () => {
    this.setState({
      is_loader: true,
    });
    let requestBody: any = {};
    if (this.state.override_cpi == true) {
      requestBody = {
        username: localStorage.getItem("username"),
        parameterDescription: "Send to SAS",
        parameterStatus: "Submitted",
        assetId: this.props.assetInfo.assetId,
        sectorId: this.state.selectedSectorId,
        sectorName: this.state.selectedSectorName,
        properties: this.state.asset_installation_property,
        category: this.state.cbsd_category,
        deviceStatus: this.state.paramStat,
      };
      if (this.state.p12path != "" && this.state.cpiPassword != "") {
        requestBody["fileSubmission"] = true;
      }

      this.submitToSAS(requestBody);
    } else {
      requestBody = {
        username: localStorage.getItem("username"),
        parameterDescription: "Send to SAS",
        parameterStatus: "Submitted",
        cpiId: this.state.cpiId,
        cpiName: this.state.cpiName,
        registeredCpi: this.state.registeredCpi,
        assetId: this.props.assetInfo.assetId,
        sectorId: this.state.selectedSectorId,
        sectorName: this.state.selectedSectorName,
        properties: this.state.asset_installation_property,
        category: this.state.cbsd_category,
        deviceStatus: this.state.paramStat,
        p12: "",
        p12Password: "",
        fileSubmission: true,
      };
      if ((this.state.p12path == "" || this.state.p12path == undefined) && this.state.cpiPassword == "") {
        requestBody["fileSubmission"] = false;
      }

      if (this.state.p12cert.length == 0) {
        this.submitToSAS(requestBody);
      }
    }

    if (this.state.p12cert.length > 0) {
      //console.log(this.state.p12cert[0].name)
      var picReader = new FileReader();
      let filerequestBody = {};
      let fileName = this.state.p12cert[0].name;
      let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
      picReader.onload = (e) => {
        filerequestBody = {
          fileName: fileName,
          fileExtension: fileExt,
          fileValue: picReader.result,
          type: "p12",
        };
        requestAPICall("upload_file", {}, filerequestBody).then((data) => {
          //console.log(data)
          requestBody["p12"] = data.file_path;
          requestBody["p12Password"] = this.state.cpiPassword;

          this.submitToSAS(requestBody);
        });
      };
      picReader.readAsDataURL(this.state.p12cert[0]);
    }
    // TODO: refreshAsset
    this.props.refreshAsset(this.props["assetInfo"]["assetId"]);
  };

  submitToSAS = (requestBody: any) => {
    //console.log(requestBody)
    //console.log(this.state.assetInfo)

    if ([3, 4, 5, 6, 11].includes(this.state.paramStat)) {
      //console.log("UPDATE DEVICE")
      requestAPICall("update_device", {}, requestBody).then((data) => {
        //console.log(data)
        if (data.responseCode == 200) {
          this.props.refreshStatus(this.props["assetInfo"]["assetId"]);
          this.setState({
            is_loader: false,
            isParameterSubmitted: true,
            warnMessage: data.message,
            alertbox: true,
          });
          this.parameterStatus();
        } else {
          this.setState({
            is_loader: false,
            warnMessage: data.message,
            alertbox: true,
            isParameterSubmitted: false,
          });
        }
      });
    } else if ([13, 14, 8].includes(this.state.paramStat)) {
      requestAPICall("replace_device", {}, requestBody).then((data) => {
        //console.log(data)
        if (data.responseCode == 200) {
          this.props.refreshStatus(this.props["assetInfo"]["assetId"]);
          this.setState({
            is_loader: false,
            isParameterSubmitted: true,
            warnMessage: data.message,
            alertbox: true,
          });
          this.parameterStatus();
          this.props.backfromAPParam();
        } else {
          this.setState({
            is_loader: false,
            warnMessage: data.message,
            alertbox: true,
            isParameterSubmitted: false,
          });
        }
      });
    } else {
      // //console.log("CREATE DEVICE")
      // if (this.state.cbsd_category == "DEVICE_CATEGORY_A") {
      //     requestBody['parameterStatus'] = "Ready for CPI"
      // } else if (this.state.cbsd_category == "DEVICE_CATEGORY_B") {
      //     requestBody['parameterStatus'] = "Pending registration"

      // }
      requestAPICall("submit_to_sas", {}, requestBody).then((data) => {
        //console.log(data)
        if (data.responseCode == 200) {
          this.props.refreshStatus(this.props["assetInfo"]["assetId"]);
          this.setState({
            is_loader: false,
            isParameterSubmitted: true,
            warnMessage: data.message,
            alertbox: true,
          });
          this.parameterStatus();
          this.props.backfromAPParam();
        } else {
          this.setState({
            is_loader: false,
            warnMessage: data.message,
            alertbox: true,
            isParameterSubmitted: false,
          });
        }
      });
    }
  };

  hideModal = () => {
    this.setState({
      show: false,
      override_cpi: false,
      p12cert: [],
    });
  };

  get_sector_properties = (sectorId: any, sector_name: any, type: any) => {
    this.setState({
      is_loader: true,
    });
    // //console.log(this.state.paramStat)
    // //console.log(sectorId, sector_name, type, 'ssssssssssss')

    if (type == "ID") {
      var sector_id = sectorId;
      var sector_name = sector_name;
    } else {
      var sector_id = sectorId["sector_id"];
      var sector_name = sectorId["sector_value"];
    }
    this.setState({
      selectedSectorId: sector_id,
      selectedSectorName: sector_name,
      isParameterSubmitted: false,
      isEditParam: false,
      files: [],
    });
    //console.log(sector_id, 'ssssssss')
    requestAPICall(
      "get_sector_details",
      {
        asset_type_id: this.props.assetInfo["assetTypeId"],
        asset_id: this.props["assetInfo"]["assetId"],
        sector_id: sector_id,
      },
      {}
    ).then((data) => {
      if (data.responseCode == 200) {
        //console.log("installation on right", data)
        sector_property = data["properties"];
        installation_list = data["properties"]["installation"];
        optional_list = data["properties"]["optional"];
        let cbsd_category = data["properties"]["optional"].filter(
          (property: any) => property.propertyName === "cbsd_category"
        );
        cbsd_list = data["properties"]["cbsd"];
        global_list = data["properties"]["global"];
        other_list = data["properties"]["others"];

        var htimg =
          data["properties"]["installation"].filter((property: any) => property.propertyName === "height_image")[0]
            .value != "";
        var azimg =
          data["properties"]["installation"].filter((property: any) => property.propertyName === "azimuth_image")[0]
            .value != "";
        var dtimg =
          data["properties"]["installation"].filter((property: any) => property.propertyName === "down_tilt_image")[0]
            .value != "";

        this.setState({
          showvalue: true,
          asset_installation_property: sector_property,
          noOfSectors: parseInt(this.props.assetInfo.properties.no_of_sectors),
          sectorIds: this.props.assetInfo.sector_id,
          sector_id: sector_id,
          sector_name: sector_name,
          cbsd_category: cbsd_category[0].value,
          optional_list_value: optional_list,
          cbsd_list_value: cbsd_list,
          installation_list_value: installation_list,
          global_list_value: global_list,
          other_list_value: other_list,
          dtimg: dtimg,
          heightimg: htimg,
          azimuthimg: azimg,
          sectorParamStatus: data["status"] !== null ? data["status"][1] : "",
        });
        //console.log(this.state)
        this.param_actions(data["properties"]["optional"]);
        this.listVendorAPI();
        this.parameterStatus();
        // this.set_ap_cbsd_value(cbsd_list , optional_list , installation_list , global_list)
      }
      // this.setIndoorDeplyment(installation_list)
      this.calculateHAAT(installation_list);
      this.setState({
        is_loader: false,
      });
    });
  };

  onDrop = (files: any, type: any) => {
    //console.log(type, 'ttttttttttttttttttttttttt')
    if (type === "down_tilt") {
      this.setState(
        {
          down_tilt_files: files,
        },
        () => this.uploadFile(this.state.down_tilt_files, type)
      );
    } else if (type === "antenna_azimuth") {
      this.setState(
        {
          azimuth_files: files,
        },
        () => this.uploadFile(this.state.azimuth_files, type)
      );
    } else if (type === "height") {
      this.setState(
        {
          height_files: files,
        },
        () => this.uploadFile(this.state.height_files, type)
      );
    } else if (type === "p12") {
      this.setState({
        p12cert: files,
      });
    } else {
      this.setState({
        files: files,
      });
    }
  };

  updateSectorParameters() {
    this.setState({
      is_loader: true,
    });
    // if (this.props['assetInfo']['assetTypeId'] == 1) {
    //     this.updateAssetBasicParams()
    // }
    var requestBody = {
      vendorId: this.state.selectedVendorId,
      properties: {
        cbsd: cbsd_list,
        optional: optional_list,
        installtion: installation_list,
        global: global_list,
        others: other_list,
      },
    };

    requestAPICall(
      "update_sector_details",
      {
        asset_type_id: this.props.assetInfo.assetTypeId,
        asset_id: this.props["assetInfo"]["assetId"],
        sector_id: this.state.sector_id,
      },
      requestBody
    ).then((data) => {
      //console.log(data);
      this.setState({ is_loader: false });
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
          isParameterSubmitted: false,
          submitToCloud: true,
        });
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
          isParameterSubmitted: false,
          submitToCloud: false,
        });
      }
      this.get_sector_properties(this.state.sector_id, this.state.sector_name, "ID");
      this.parameterStatus();
      this.props.backfromAPParam();
      this.send_button();
      this.props.refreshAsset(this.props["assetInfo"]["assetId"]);
      // localStorage.setItem('refresh_asset', 'true');
    });
  }

  updateAssetParameters() {
    this.setState({
      is_loader: true,
    });
    //console.log(this.state, 'sssssssssssssssssssss')
    //console.log(this.state.get_cbsd_category)

    // if (this.props['assetInfo']['assetTypeId'] == 1) {
    //     this.updateAssetBasicParams()
    // }

    var requestBody = {
      vendorId: this.state.selectedVendorId,
      properties: {
        cbsd: cbsd_list,
        optional: optional_list,
        installtion: installation_list,
        global: global_list,
        others: other_list,
      },
    };
    requestAPICall(
      "update_asset_install_details",
      {
        asset_type_id: this.props["assetInfo"]["assetTypeId"],
        asset_id: this.props["assetInfo"]["assetId"],
        sector_id: this.state.sector_id,
      },
      requestBody
    ).then((data) => {
      //console.log(data)
      this.setState(
        {
          is_loader: false,
        },
        () => {
          if (data.responseCode == 200) {
            this.parameterStatus();
            this.send_button();
            this.props.backfromAPParam();
            this.props.refreshAsset(this.props["assetInfo"]["assetId"]);
            cbsd_list = data["installation_params"]["cbsd"];
            installation_list = data["installation_params"]["installation"];
            optional_list = data["installation_params"]["optional"];
            global_list = data["installation_params"]["global"];
            other_list = data["installation_params"]["others"];
            this.setState({
              asset_installation_property: data.installation_params,
              warnMessage: data.message,
              alertbox: true,
              isParameterSubmitted: false,
              submitToCloud: true,
            });
          } else {
            this.setState({
              warnMessage: data.message,
              alertbox: true,
              isParameterSubmitted: false,
              submitToCloud: false,
            });
          }
        }
      );
    });
  }

  onChange(e: any, field: string) {
    //console.log("==================")
    this.setState({
      [e.target.name]: e.target.value,
      isEditParam: true,
    });
    if (field == "installation") {
      installation_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    } else if (field == "cbsd") {
      cbsd_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    } else if (field == "optional") {
      optional_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    } else if (field == "global") {
      global_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    } else if (field == "other") {
      other_list.forEach((property: any) => {
        if (property.propertyName == e.target.name) {
          property.value = e.target.value;
        }
      });
    }
  }
  assetPropChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "enb_id") {
      this.setState({ enb: e.target.value }, () => {});
    }
    this.props.assetInfo.properties.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
      }
    });
  }

  setAssetVendor = (e: any, id: any) => {
    try {
      select_vendor = e.target.value;
      cbsd_list.map((cbsd: any) => {
        if (cbsd.propertyName === "cbsd_vendor_model") {
          cbsd.value = e.target.value;
        }
      });
      this.set_dropdown_label(id, e.target.value);
      if (e.target.value !== undefined) {
        var model_value = this.state.assetVendorList.filter((property: any) => {
          if (property.name === select_vendor) {
            //console.log(property.name)
            return property;
          }
        });
        //console.log(model_value)
        vendor_model_list = model_value[0];
        this.setState({
          selectedVendorId: model_value[0].vendorModalId,
        });
        this.set_ap_cbsd_value(cbsd_list, optional_list, installation_list, global_list);
      }
    } catch (error) {}
  };
  send_button = () => {
    //console.log(this.state.paramStat)
    //console.log(this.state.isParameterSubmitted)
    //console.log(this.state.get_cbsd_category)
    if (this.state.isParameterSubmitted == false) {
      if (localStorage.getItem("userRole") === "Installer") {
        if (this.state.paramStat == 0) {
          // COMMENTED ON 9-7-20 DUE TO CHANGING CATEGORY A WILL CALLING CREATESIGNED
          // if (this.state.get_cbsd_category == "DEVICE_CATEGORY_A" && this.state.defaultProviderId != 2) {
          //     return <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Submit to SAS</button>
          // } else {
          //     return <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.sendParameters(e) }}  >Submit to CPI</button>
          // }

          return (
            <button
              className="btn cardbtn submitbtn m-t-10 m-b-10"
              disabled={!this.state.submitToCloud}
              onClick={(e: any) => {
                e.preventDefault();
                this.sendParameters(e);
              }}
            >
              Submit to Cloud
            </button>
          );
        } else {
          // COMMENTED ON 9-7-20 DUE TO CHANGING CATEGORY A WILL CALLING CREATESIGNED
          // if (this.state.get_cbsd_category == "DEVICE_CATEGORY_A" && this.state.defaultProviderId != 2) {
          //     return <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Update to SAS</button>
          // } else {
          //     return <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.sendParameters(e) }}  >Update to CPI</button>
          // }

          return (
            <button
              className="btn cardbtn submitbtn m-t-10 m-b-10"
              disabled={!this.state.submitToCloud}
              onClick={(e: any) => {
                e.preventDefault();
                this.sendParameters(e);
              }}
            >
              Update to Cloud
            </button>
          );
        }
      } else if ([0, 1, 2, 10, 12].includes(this.state.paramStat)) {
        return (
          <button
            className="btn cardbtn submitbtn m-t-10 m-b-10"
            disabled={!this.state.submitToCloud}
            onClick={(e: any) => {
              e.preventDefault();
              this.showModal();
            }}
            data-toggle="modal"
            data-target="#submitModal"
          >
            Submit to SAS
          </button>
        );
      } else if ([3, 4, 5, 6, 7, 8, 9, 11, 13, 14].includes(this.state.paramStat)) {
        return (
          <button
            className="btn cardbtn submitbtn m-t-10 m-b-10"
            disabled={!this.state.submitToCloud}
            onClick={(e: any) => {
              e.preventDefault();
              this.showModal();
            }}
            data-toggle="modal"
            data-target="#submitModal"
          >
            Update to SAS
          </button>
        );
      }
    } else {
      if (localStorage.getItem("userRole") === "Installer") {
        if (this.state.get_cbsd_category == "DEVICE_CATEGORY_A") {
          return (
            <button className="btn cardbtn btn-submitted m-t-10 m-b-10" style={{ color: "#222223" }}>
              <i className="material-icons submitcheck">check</i>
              <span className="p-l-25">Submitted</span>
            </button>
          );
        } else {
          return (
            <button className="btn cardbtn btn-submitted m-t-10 m-b-10" style={{ color: "#222223" }}>
              <i className="material-icons submitcheck">check</i>
              <span className="p-l-25">Sent</span>
            </button>
          );
        }
      } else {
        return (
          <button className="btn cardbtn btn-submitted m-t-10 m-b-10" style={{ color: "#222223" }}>
            <i className="material-icons submitcheck">check</i>
            <span className="p-l-25">Submitted</span>
          </button>
        );
      }
    }
  };

  sendParameters = (e: any) => {
    this.setState({
      is_loader: true,
    });
    let check = true;
    this.state.asset_installation_property["global"].forEach((property: any, key: any) => {
      if (property.propertyName === "user_id" && property.value == "") {
        this.paramFocus[key].focus();
        check = false;
      }
    });

    if (check) {
      var paramDesc = "";
      var paramStatus = "";
      if (
        this.state.sectorParamStatus == "Replacement in Progress" ||
        this.state.sectorParamStatus == "Requested for Replacement"
      ) {
        paramDesc = "Requested for Replacement";
        paramStatus = "Requested for Replacement";
      } else {
        paramDesc = "Save As Draft";
        paramStatus = "Draft";
      }
      let requestBody = {
        parameterDescription: paramDesc,
        parameterStatus: paramStatus,
        assetId: this.props.assetInfo.assetId,
        sectorId: this.state.selectedSectorId,
        sectorName: this.state.selectedSectorName,
        properties: this.state.asset_installation_property,
        deviceStatus: this.state.paramStat,
      };
      if (this.state.paramStat == 0) {
        requestAPICall("submit_to_cpi", {}, requestBody).then((data) => {
          //console.log(data)
          this.setState(
            {
              is_loader: false,
            },
            () => {
              if (data["responseCode"] == 200) {
                this.props.refreshStatus(this.props["assetInfo"]["assetId"]);
                this.parameterStatus();
                this.props.refreshAsset(this.props["assetInfo"]["assetId"]);
                this.setState({
                  isParameterSubmitted: true,
                  warnMessage: data.message,
                  alertbox: true,
                });
              } else {
                this.setState({
                  isParameterSubmitted: false,
                  warnMessage: data.message,
                  alertbox: true,
                });
              }
            }
          );
        });
      } else {
        requestAPICall("update_to_cpi", {}, requestBody).then((data) => {
          //console.log(data)
          this.setState(
            {
              is_loader: false,
            },
            () => {
              if (data["responseCode"] == 200) {
                this.props.refreshStatus(this.props["assetInfo"]["assetId"]);
                this.parameterStatus();
                this.props.refreshAsset(this.props["assetInfo"]["assetId"]);
                this.setState({
                  isParameterSubmitted: true,
                  warnMessage: data.message,
                  alertbox: true,
                });
              } else {
                this.setState({
                  is_loader: false,
                  isParameterSubmitted: false,
                  warnMessage: data.message,
                  alertbox: true,
                });
              }
            }
          );
        });
      }
    }
  };

  cpiSaveCahnge = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  indoor_deployment_check(e: any) {
    //console.log(e.target.checked)
    this.setState({
      is_indoor_check: e.target.checked,
      isEditParam: true,
    });
    if (e.target.checked == true)
      installation_list.forEach((property: any) => {
        if (property.propertyName == "indoor_deployment") {
          property.value = 1;
        }
      });
    if (e.target.checked == false) {
      installation_list.forEach((property: any) => {
        if (property.propertyName == "indoor_deployment") {
          property.value = 0;
        }
      });
    }
  }

  is_override_cpi(e: any) {
    //console.log(e.target.checked)
    this.setState({
      override_cpi: e.target.checked,

      // cpiId : "",
      // cpiName : ""
    });
  }
  collapseClick = (id: any) => {
    //console.log("==========", id)
    var type;
    var div_id;
    $(document).ready(function () {
      $("#" + id).on("hidden.bs.collapse", function () {
        //console.log("Closed")
        type = "close";
        $("#div1").show();
        $("#div2").show();
        $("#div3").show();
      });
      $("#" + id).on("shown.bs.collapse", function () {
        //console.log("Opened")
        type = "open";
        $("#div1").hide();
        $("#div2").hide();
        $("#div3").hide();
      });
    });

    if (id === "cbsdT") {
      div_id = "cbsdTab";
    } else if (id === "optionalT") {
      div_id = "optionalTab";
    } else {
      div_id = "installationTab";
    }
    type = $("#" + div_id)[0]["attributes"][4]["value"];
    if (type.toString() == "true") {
      this.setState({
        select_type: id,
      });
    } else {
      this.setState({
        select_type: "",
      });
    }
  };
  clear_installation_value = () => {
    installation_list.forEach((element: any) => {
      if (element.propertyName === "height_type") {
        element.value = "";
      } else if (element.propertyName === "horizantal_accuracy") {
        element.value = "0";
      } else if (element.propertyName === "vertical_accuracy") {
        element.value = "0";
      } else if (element.propertyName === "down_tilt") {
        element.value = "0";
      } else if (element.propertyName === "measurement_capability") {
        element.value = "";
      } else if (element.propertyName === "antenna_pattern") {
        element.value = "";
      } else if (element.propertyName === "haat") {
        element.value = "";
      }
    });
    this.setState({
      installation_list: installation_list,
    });
  };

  clear_cbsd_value = () => {
    cbsd_list.forEach((element: any) => {
      if (element.propertyName === "cbsd_vendor_model") {
        element.value = "";
      } else if (element.propertyName === "cbsd_software_version") {
        element.value = "0";
      } else if (element.propertyName === "cbsd_hardware_version") {
        element.value = "0";
      } else if (element.propertyName === "cbsd_firmware_version") {
        element.value = "0";
      }
    });
    installation_list.forEach((element: any) => {
      if (element.propertyName === "antenna_beamwidth") {
        element.value = "";
      } else if (element.propertyName === "antenna_gain") {
        element.value = "0";
      } else if (element.propertyName === "eirp_capability") {
        element.value = "0";
      } else if (element.propertyName === "antenna_model") {
        element.value = "0";
      }
    });
    optional_list.map((element: any) => {
      if (element.propertyName === "cbsd_category") {
        element.value = "";
      } else if (element.propertyName === "air_interface") {
        element.value = "";
      }
    });
    this.setState({
      cbsd_list: cbsd_list,
      installation_list: installation_list,
      optional_list: optional_list,
    });
  };

  clear_optional_value = () => {
    optional_list.map((element: any) => {
      if (element.propertyName === "call_sign") {
        element.value = "0";
      } else if (element.propertyName === "grouping_param") {
        element.value = "0";
      } else if (element.propertyName === "supported_spec") {
        element.value = "";
      }
    });
    this.setState({
      optional_list: optional_list,
    });
  };

  render() {
    let files = this.state.files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let height_files = this.state.height_files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let azimuth_files = this.state.azimuth_files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let down_tilt_files = this.state.down_tilt_files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let p12_files = this.state.p12cert.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    const { cpiId, cpiName } = this.state;
    //console.log(cpiId)

    const isEnabled = cpiId.length > 0 && cpiName.length > 0;

    let classDName = files.length ? "dropzone-uploaded" : "dropzone";
    let classDName1 = azimuth_files.length ? "dropzone-uploaded" : "dropzone";
    let classDName2 = height_files.length ? "dropzone-uploaded" : "dropzone";
    let classDName3 = down_tilt_files.length ? "dropzone-uploaded" : "dropzone";
    let classDName4 = p12_files.length ? "dropzone-uploaded" : "dropzone";
    return (
      <div>
        <div className="webonly">
          <div className="row m-t-10">
            <div className="col-md-12">
              <ul className="nav nav-tabs sectab font12" id="secTab" role="tablist">
                {this.props.assetInfo["assetTypeId"] === 1
                  ? this.state.sectorIds.map((sector: any, index: any) => {
                      var wid = (100 / this.state.sectorIds.length).toString() + "%";
                      return (
                        <div style={{ width: wid }}>
                          <li
                            className="nav-item"
                            onClick={() => {
                              this.setState({
                                submitToCloud: false,
                                sasSubmission: true,
                              });
                              this.get_sector_properties(sector, "", "json");
                            }}
                          >
                            <a
                              className={`nav-link p-l-0 ${
                                this.state.sector_id === sector["sector_id"] ? "active" : ""
                              } p-l-0`}
                              style={{ padding: "0.5rem 0rem" }}
                              id="sec1-tab"
                              data-toggle="tab"
                              href="#sec1"
                              role="tab"
                              aria-controls="sec1"
                              aria-selected="true"
                            >
                              SEC{index + 1}
                            </a>
                          </li>
                          {this.state.sectorIds.length - 1 === index ? null : (
                            <div>
                              <div
                                style={{
                                  borderRight: "1px solid #C1C8CE80",
                                  height: "8px",
                                  marginTop: "-30px",
                                }}
                              ></div>
                              <div
                                style={{
                                  borderRight: "1px solid #C1C8CE80",
                                  height: "8px",
                                  marginTop: "5px",
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
          <div className="tab-content" id="secTabContent">
            <div className="tab-pane fade show active" id="sec1" role="tabpanel" aria-labelledby="sec1-tab">
              <div className="m-t-10" id="accordionExample">
                <div
                  className="col-md-12"
                  style={{
                    overflowY: "auto",
                    maxHeight: "calc(100vh - 276px)",
                    padding: "1rem",
                    paddingTop: "0px",
                  }}
                >
                  <div className="accordion">
                    <div className="">
                      {global_list.map((global: any, key: any) => {
                        if (global.isShowable == 1) {
                          if (global.dataType == "string" || global.dataType == "float") {
                            return (
                              <div className="col-md-12 p-0">
                                <div className="form-label-group m-0">
                                  <input
                                    type="text"
                                    className="accesspointname"
                                    ref={(ref) => {
                                      this.paramFocus[key] = ref;
                                      return true;
                                    }}
                                    name={global.propertyName}
                                    id={global.propertyName}
                                    value={global.value}
                                    placeholder={global.displayName}
                                    onChange={(e: any) => {
                                      this.onChange(e, "global");
                                    }}
                                  />
                                  <label className="pages" htmlFor={global.propertyName}>
                                    {global.displayName}*
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        }
                      })}
                    </div>
                    {this.props.assetInfo.assetTypeId === 1 ? (
                      <div id="div1" className="row">
                        <div className="col-md-6">
                          <div className="form-label-group m-0">
                            <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                              Site ID:
                            </span>
                            <span className="distance apinfo">{this.state.siteId}</span>
                            {/* <input type="text" disabled className="accesspointname" name="access_point_id" id="Site Id" value={this.state.siteId}
                                                            placeholder="Site Id" />
                                                        <label className="pages" htmlFor="Site Id">Site ID</label> */}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-label-group m-0">
                            <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                              Sector ID:
                            </span>
                            <span className="distance apinfo">{this.state.selectedSectorName}</span>
                            {/* <input disabled type="text" className="accesspointname" name="sector_id" id="Sector Id" value={this.state.selectedSectorName} onChange={(e: any) => this.assetPropChange(e)}
                                                            placeholder="Sector Id" />
                                                        <label className="pages" htmlFor="Site Id">Sector ID</label> */}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.props.assetInfo.assetTypeId === 1 ? (
                      <div id="div2" className="row">
                        <div className="col-md-6 col-6 d-inline-block m-t-7 p-r-5">
                          <div className="form-label-group m-0 d-none">
                            <span className="distance" style={{ display: "block", fontSize: "13px" }}>
                              eNB/gNb ID:
                            </span>
                            <span className="distance apinfo">{this.state.enb === "" ? "-" : this.state.enb}</span>
                          </div>
                        </div>
                        {/* {other_list.map((other: any, key: any) => {
                                                    if (other.isShowable == 1 && (other.propertyName == "pci")) {
                                                        if (other.dataType == "string") {
                                                            return <div className='col-md-6 d-none'>
                                                                <span className='distance' style={{ display: 'block', fontSize: '13px' }}>{other.displayName}:</span>
                                                                <span className='distance apinfo' >{other.value == "" ? "- " : other.value}</span>
                                                            </div>

                                                        }

                                                    }
                                                })
                                                } */}
                      </div>
                    ) : null}

                    {this.props.assetInfo.assetTypeId === 1 ? (
                      <div id="div3" className="row">
                        {/* {other_list.map((other: any, key: any) => {
                                                    if (other.isShowable == 1 && (other.propertyName == "cell_id")) {
                                                        if (other.dataType == "string") {
                                                            return <div className='col-md-6'>
                                                                <span className='distance' style={{ display: 'block', fontSize: '13px' }}>{other.displayName}:</span>
                                                                <span className='distance apinfo' >{other.value == "" ? "- " : other.value}</span>
                                                            </div>
                                                        }

                                                    }
                                                })
                                                } */}
                      </div>
                    ) : null}

                    <div
                      className=""
                      style={{
                        border: "1px solid #8F9FAC",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {this.state.select_type === "cbsdT" ? (
                        <div
                          className="param-clearfields"
                          style={{
                            margin: "10px 50px 5px 5px",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderRadius: "10px",
                            padding: "3px",
                          }}
                        >
                          <span onClick={this.clear_cbsd_value}>Clear fields</span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="cbsd collapsed"
                        style={{ padding: "10px" }}
                        id="cbsdTab"
                        data-toggle="collapse"
                        data-target="#cbsdT"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        onClick={() => this.collapseClick("cbsdT")}
                      >
                        <span className="apheader" style={{ textTransform: "none" }}>
                          CBSD
                        </span>
                        {/* <span className="clearfields1" onClick={this.clear_cbsd_value}>Clear fields</span> */}
                        <span className="arrow dropd"></span>
                        {/* <i className="fas fa-sort-down dropd"></i> */}
                      </div>
                      <div id="cbsdT" className="collapse" aria-labelledby="cbsdTab" data-parent="#accordionExample">
                        {cbsd_list.map((cbsd: any) => {
                          if (cbsd.propertyName == "cbsd_vendor_model") {
                            return (
                              <div className="floating-label">
                                <select
                                  disabled={!this.state.edit_vendor}
                                  name={cbsd.propertyName}
                                  value={cbsd.value}
                                  className="floating-select selectarrow paramSelect"
                                  onChange={(e) => this.setAssetVendor(e, "cbsdmodel")}
                                >
                                  <option hidden value=""></option>
                                  {this.state.assetVendorList.map((vendor: any) => (
                                    <option value={vendor["name"]}>{vendor["name"]}</option>
                                  ))}
                                </select>
                                <label id="cbsdmodel">{cbsd.displayName}</label>
                              </div>
                            );
                          } else if (cbsd.dataType == "string") {
                            return (
                              <div className="col-md-12 p-0">
                                <div className="form-label-group m-0">
                                  <input
                                    type="text"
                                    className={`paramtext-web ${
                                      cbsd.propertyName === "cbsd_firmware_version" ? "borderRadbottom" : ""
                                    }`}
                                    disabled
                                    name={cbsd.propertyName}
                                    id={cbsd.propertyName}
                                    value={cbsd.value}
                                    placeholder={cbsd.displayName}
                                    onChange={(e: any) => {
                                      this.onChange(e, "cbsd");
                                    }}
                                  />
                                  <label className="acpdetails" htmlFor={cbsd.propertyName}>
                                    {cbsd.displayName}
                                  </label>
                                </div>
                              </div>
                            );
                          } else if (cbsd.dataType == "list") {
                            if (cbsd.propertyName == "cbsd_category") {
                              return (
                                <div className="form-label-group m-0">
                                  <select
                                    name={cbsd.propertyName}
                                    placeholder={cbsd.displayName}
                                    value={cbsd.value}
                                    style={{ borderRadius: "0px 0px 6px 6px" }}
                                    className="form-control paramtext-web"
                                    onChange={(e) => {
                                      this.cbsd_category(e);
                                    }}
                                  >
                                    <option hidden>{cbsd.displayName}</option>
                                    <option value="DEVICE_CATEGORY_A">DEVICE_CATEGORY_A</option>
                                    <option value="DEVICE_CATEGORY_B">DEVICE_CATEGORY_B</option>
                                    {/* <option value="CPE-CBSD">CPE-CBSD</option> */}
                                  </select>
                                </div>
                              );
                            } else {
                              return (
                                <div className="col-md-12 p-0">
                                  <div className="form-label-group m-0">
                                    <input
                                      type="text"
                                      className="paramtext-web"
                                      name={cbsd.propertyName}
                                      id={cbsd.propertyName}
                                      value={cbsd.value}
                                      disabled
                                      placeholder={cbsd.displayName}
                                      onChange={(e: any) => {
                                        this.onChange(e, "cbsd");
                                      }}
                                    />
                                    <label className="acpdetails" htmlFor={cbsd.propertyName}>
                                      {cbsd.displayName}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          }
                        })}
                      </div>
                    </div>

                    <div
                      className="m-t-10"
                      style={{
                        border: "1px solid #8F9FAC",
                        borderRadius: "5px",
                      }}
                    >
                      {this.state.select_type === "install" ? (
                        <div
                          className="param-clearfields"
                          style={{
                            margin: "10px 50px 5px 5px",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderRadius: "10px",
                            padding: "3px",
                          }}
                        >
                          <span onClick={this.clear_installation_value}>Clear fields</span>
                        </div>
                      ) : (
                        ""
                      )}

                      <div
                        className="installation collapsed"
                        style={{ padding: "10px" }}
                        id="installationTab"
                        data-toggle="collapse"
                        data-target="#install"
                        aria-expanded="false"
                        onClick={() => this.collapseClick("install")}
                        aria-controls="collapseOne"
                      >
                        <span className="apheader" style={{ textTransform: "none" }}>
                          Installation
                        </span>
                        <span className="arrow dropd"></span>
                        {/* <i className="fas fa-sort-down dropd"></i> */}
                      </div>

                      <div
                        id="install"
                        className="collapse"
                        aria-labelledby="installationTab"
                        data-parent="#accordionExample"
                      >
                        {installation_list.map((installation: any) => {
                          if (installation.units !== "") {
                            unit = "(" + installation.units + ")";
                          } else {
                            unit = "";
                          }
                          if (installation.dataType === "file" || installation.dataType === "image") {
                            if (installation.propertyName === "antenna_pattern") {
                              // return <div className='row paramtext justify-content-center' style={{ fontSize: '12px', height: '60px' }}>
                              //     <i className="material-icons imgclose-file dropzclose" style={{ right: '30px' }} onClick={(e: any) => { e.preventDefault(); files = []; this.onClear([], "antenna_pattern") }}>clear</i>
                              //     <Dropzone onDrop={(e: any) => { this.onDrop(e, installation.propertyName) }} disabled={false}>
                              //         {({ getRootProps, getInputProps }) => (
                              //             <section className='dropzone-set' >
                              //                 <div {...getRootProps({ className: classDName1 })}>
                              //                     <input {...getInputProps()} accept='.csv' />
                              //                     {installation.value !== "0" && files.length == 0 ? <span style={{ fontSize: '14px' }}> File uploaded Already</span> :
                              //                         (files.length) ? <span style={{ color: "#00D56E", display: 'contents' }}>
                              //                             {files}
                              //                         </span> : <span style={{ fontSize: '14px' }}> + Add {installation.displayName}</span>
                              //                     }
                              //                 </div>
                              //             </section>
                              //         )}
                              //     </Dropzone>
                              // </div>

                              return (
                                <div className="col-md-12 p-0">
                                  <div className="form-label-group m-0">
                                    <input
                                      type="text"
                                      className="paramtext-web"
                                      name={installation.propertyName}
                                      id={installation.propertyName}
                                      value={installation.value}
                                      style={{ borderRadius: "0px" }}
                                      placeholder={installation.displayName}
                                      onChange={(e: any) => {
                                        this.onChange(e, "installation");
                                      }}
                                    />
                                    <label className="acpdetails" htmlFor={installation.propertyName}>
                                      {installation.displayName} {unit}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          } else if (
                            installation.dataType == "string" ||
                            installation.dataType == "float" ||
                            installation.dataType == "int"
                          ) {
                            if (installation.propertyName == "latitude" || installation.propertyName == "longitude") {
                              return (
                                <div className="col-md-6 p-0 d-inline-block">
                                  <div className="form-label-group m-0 ">
                                    <input
                                      type="text"
                                      disabled={true}
                                      className={`paramtext-web ${
                                        installation.propertyName === "latitude" ? "paramrightborder" : ""
                                      }`}
                                      name={installation.propertyName}
                                      id={installation.propertyName}
                                      value={installation.value}
                                      style={{ borderRadius: "0px" }}
                                      placeholder={installation.displayName}
                                      onChange={(e: any) => {
                                        this.onChange(e, "installation");
                                      }}
                                    />
                                    <label className="acpdetails" htmlFor={installation.propertyName}>
                                      {installation.displayName} {unit}
                                    </label>
                                  </div>
                                </div>
                              );
                            } else if (
                              installation.propertyName === "antenna_beamwidth" ||
                              installation.propertyName === "eirp_capability" ||
                              installation.propertyName === "antenna_gain" ||
                              installation.propertyName === "antenna_model"
                            ) {
                              return (
                                <div className="col-md-12 p-0">
                                  <div className="form-label-group m-0">
                                    <input
                                      type="text"
                                      className="paramtext-web"
                                      disabled
                                      name={installation.propertyName}
                                      id={installation.propertyName}
                                      value={installation.value}
                                      style={{ borderRadius: "0px" }}
                                      placeholder={installation.displayName}
                                      onChange={(e: any) => {
                                        this.onChange(e, "installation");
                                      }}
                                    />
                                    <label className="acpdetails" htmlFor={installation.propertyName}>
                                      {installation.displayName} {unit}
                                    </label>
                                  </div>
                                </div>
                              );
                            } else if (installation.propertyName === "down_tilt") {
                              return (
                                <div className="col-md-12 p-0">
                                  <div className="form-label-group m-0">
                                    {!this.state.dtimg ? (
                                      <img
                                        src={camicon}
                                        style={{
                                          width: "21px",
                                          position: "absolute",
                                          right: "0px",
                                          margin: "13px",
                                          zIndex: 50,
                                        }}
                                        onClick={() => this.downltiltImgClick("down_tilt")}
                                      ></img>
                                    ) : (
                                      <img
                                        src={checkSuccess}
                                        style={{
                                          width: "21px",
                                          position: "absolute",
                                          right: "0px",
                                          margin: "13px",
                                          zIndex: 50,
                                        }}
                                        onClick={() => this.downltiltImgClick("down_tilt")}
                                      ></img>
                                    )}
                                    <input
                                      type="text"
                                      className="paramtext-web"
                                      name={installation.propertyName}
                                      id={installation.propertyName}
                                      value={installation.value}
                                      style={{ borderRadius: "0px" }}
                                      placeholder={installation.displayName}
                                      onChange={(e: any) => {
                                        this.onChange(e, "installation");
                                      }}
                                    />
                                    <label className="acpdetails" htmlFor={installation.propertyName}>
                                      {installation.displayName} {unit}
                                    </label>
                                    <Dropzone
                                      onDrop={(e: any) => {
                                        this.onDrop(e, installation.propertyName);
                                      }}
                                      disabled={this.state.field_disable}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <section className="dropzone-set">
                                          <div
                                            style={{ display: "none" }}
                                            id="down_tilt_drop_zone"
                                            {...getRootProps({
                                              className: classDName2,
                                            })}
                                          >
                                            <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                                          </div>
                                          <div></div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                </div>
                              );
                            } else if (installation.propertyName === "height") {
                              return (
                                <div className="col-md-12 p-0">
                                  <div className="form-label-group m-0">
                                    {!this.state.heightimg ? (
                                      <img
                                        src={camicon}
                                        style={{
                                          width: "21px",
                                          position: "absolute",
                                          right: "0px",
                                          margin: "13px",
                                          zIndex: 50,
                                        }}
                                        onClick={() => this.heightImgClick("height")}
                                      ></img>
                                    ) : (
                                      <img
                                        src={checkSuccess}
                                        style={{
                                          width: "21px",
                                          position: "absolute",
                                          right: "0px",
                                          margin: "13px",
                                          zIndex: 50,
                                        }}
                                        onClick={() => this.heightImgClick("height")}
                                      ></img>
                                    )}
                                    <input
                                      type="text"
                                      className="paramtext-web"
                                      disabled
                                      name={installation.propertyName}
                                      id={installation.propertyName}
                                      value={installation.value}
                                      style={{ borderRadius: "0px" }}
                                      placeholder={installation.displayName}
                                      onChange={(e: any) => {
                                        this.onChange(e, "installation");
                                      }}
                                    />
                                    <label className="acpdetails" htmlFor={installation.propertyName}>
                                      {installation.displayName} {unit}
                                    </label>
                                    <Dropzone
                                      onDrop={(e: any) => {
                                        this.onDrop(e, installation.propertyName);
                                      }}
                                      disabled={this.state.field_disable}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <section className="dropzone-set">
                                          <div
                                            style={{ display: "none" }}
                                            id="height_drop_zone"
                                            {...getRootProps({
                                              className: classDName2,
                                            })}
                                          >
                                            <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                                          </div>
                                          <div></div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                </div>
                              );
                            } else if (installation.propertyName === "antenna_azimuth") {
                              return (
                                <div className="col-md-12 p-0">
                                  <div className="form-label-group m-0">
                                    {!this.state.azimuthimg ? (
                                      <img
                                        src={camicon}
                                        style={{
                                          width: "21px",
                                          position: "absolute",
                                          right: "0px",
                                          margin: "13px",
                                          zIndex: 50,
                                        }}
                                        onClick={() => this.azimuthImgClick("antenna_azimuth")}
                                      ></img>
                                    ) : (
                                      <img
                                        src={checkSuccess}
                                        style={{
                                          width: "21px",
                                          position: "absolute",
                                          right: "0px",
                                          margin: "13px",
                                          zIndex: 50,
                                        }}
                                        onClick={() => this.azimuthImgClick("antenna_azimuth")}
                                      ></img>
                                    )}
                                    <input
                                      type="text"
                                      className="paramtext-web"
                                      name={installation.propertyName}
                                      id={installation.propertyName}
                                      value={installation.value}
                                      style={{ borderRadius: "0px" }}
                                      placeholder={installation.displayName}
                                      onChange={(e: any) => {
                                        this.onChange(e, "installation");
                                      }}
                                    />
                                    <label className="acpdetails" htmlFor={installation.propertyName}>
                                      {installation.displayName} {unit}
                                    </label>
                                    <Dropzone
                                      onDrop={(e: any) => {
                                        this.onDrop(e, installation.propertyName);
                                      }}
                                      disabled={this.state.field_disable}
                                    >
                                      {({ getRootProps, getInputProps }) => (
                                        <section className="dropzone-set">
                                          <div
                                            style={{ display: "none" }}
                                            id="azimuth_drop_zone"
                                            {...getRootProps({
                                              className: classDName2,
                                            })}
                                          >
                                            <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                                          </div>
                                          <div></div>
                                        </section>
                                      )}
                                    </Dropzone>
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div className="col-md-12 p-0">
                                  <div className="form-label-group m-0">
                                    <input
                                      type="text"
                                      className="paramtext-web"
                                      name={installation.propertyName}
                                      id={installation.propertyName}
                                      value={installation.value}
                                      style={{ borderRadius: "0px" }}
                                      placeholder={installation.displayName}
                                      onChange={(e: any) => {
                                        this.onChange(e, "installation");
                                      }}
                                    />
                                    <label className="acpdetails" htmlFor={installation.propertyName}>
                                      {installation.displayName} {unit}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          } else if (installation.dataType == "list") {
                            if (installation.propertyName == "height_type") {
                              var height_type_list = installation.valid;
                              return (
                                <div className="floating-label">
                                  <select
                                    name={installation.propertyName}
                                    value={installation.value}
                                    className="floating-select selectarrow paramSelect"
                                    onChange={(e) => {
                                      this.installation_height(e, "heightype");
                                    }}
                                  >
                                    <option value="" hidden>
                                      {installation.displayName}
                                    </option>
                                    <option value="HEIGHT_TYPE_AGL">HEIGHT_TYPE_AGL</option>
                                    <option value="HEIGHT_TYPE_AMSL">HEIGHT_TYPE_AMSL</option>
                                  </select>
                                  <label id="heightype">{installation.displayName}</label>
                                </div>
                              );
                            } else if (installation.propertyName == "measurement_capability") {
                              return (
                                <div className="floating-label">
                                  <select
                                    name={installation.propertyName}
                                    value={installation.value}
                                    className="floating-select selectarrow paramSelect"
                                    onChange={(e) => {
                                      this.measurement_capability(e, "measurementcap");
                                    }}
                                  >
                                    <option value="" hidden>
                                      {installation.displayName}
                                    </option>
                                    {/* <option value="MEASUREMENT_CAPABILITY_UNSPECIFIED">MEASUREMENT_CAPABILITY_UNSPECIFIED</option> */}
                                    <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITH_GRANT">
                                      MSRMT_WITH_GRANT
                                    </option>
                                    <option value="MEASUREMENT_CAPABILITY_RECEIVED_POWER_WITHOUT_GRANT">
                                      MSRMT_WITHOUT_GRANT
                                    </option>
                                  </select>
                                  <label id="measurementcap">{installation.displayName}</label>
                                </div>
                              );
                            }
                          } else if (installation.dataType == "bool") {
                            return (
                              <div className="form-group" style={{ margin: "1px" }}>
                                <div
                                  className="custom-control custom-checkbox paramtext-web"
                                  style={{ padding: "14px 35px" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheck"
                                    checked={installation.value == 1 ? true : false}
                                    name={installation.propertyName}
                                    onChange={(e: any) => {
                                      this.indoor_deployment_check(e);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label check"
                                    style={{ float: "left" }}
                                    htmlFor="customCheck"
                                  >
                                    {installation.displayName}{" "}
                                  </label>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>

                    <div
                      className=""
                      style={{
                        border: "1px solid #8F9FAC",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      {this.state.select_type === "optionalT" ? (
                        <div
                          className="param-clearfields"
                          style={{
                            margin: "10px 50px 5px 5px",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            borderRadius: "10px",
                            padding: "3px",
                          }}
                          onClick={this.clear_optional_value}
                        >
                          <span>Clear fields</span>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className="option collapsed"
                        style={{ padding: "10px" }}
                        id="optionalTab"
                        data-toggle="collapse"
                        data-target="#optionalT"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                        onClick={() => this.collapseClick("optionalT")}
                      >
                        <span className="apheader" style={{ textTransform: "none" }}>
                          Optional
                        </span>
                        <span className="arrow dropd"></span>
                        {/* <i className="fas fa-sort-down dropd"></i> */}
                      </div>
                      <div
                        id="optionalT"
                        className="collapse"
                        aria-labelledby="optionalTab"
                        data-parent="#accordionExample"
                      >
                        {optional_list.map((optional: any) => {
                          if (optional.isShowable) {
                            if (optional.dataType == "string") {
                              return (
                                <div className="form-label-group m-0">
                                  <input
                                    type="text"
                                    className="paramtext-web borderRadbottom"
                                    name={optional.propertyName}
                                    id={optional.propertyName}
                                    value={optional.value}
                                    placeholder={optional.displayName}
                                    onChange={(e: any) => {
                                      this.onChange(e, "optional");
                                    }}
                                  />
                                  <label className="acpdetails" htmlFor={optional.propertyName}>
                                    {optional.displayName}
                                  </label>
                                </div>
                              );
                            } else if (optional.dataType == "list") {
                              if (optional.propertyName == "cbsd_category") {
                                return (
                                  <div className="col-md-12 p-0">
                                    <div className="form-label-group m-0">
                                      <input
                                        type="text"
                                        className="paramtext-web"
                                        disabled
                                        name={optional.propertyName}
                                        id={optional.propertyName}
                                        value={optional.value}
                                        placeholder={optional.displayName}
                                        onChange={(e: any) => {
                                          this.onChange(e, "cbsd");
                                        }}
                                      />
                                      <label className="acpdetails" htmlFor={optional.propertyName}>
                                        {optional.displayName}
                                      </label>
                                    </div>
                                  </div>
                                );
                              } else if (optional.propertyName === "air_interface") {
                                return (
                                  <div className="col-md-12 p-0">
                                    <div className="form-label-group m-0">
                                      <input
                                        type="text"
                                        className="paramtext-web"
                                        disabled
                                        name={optional.propertyName}
                                        id={optional.propertyName}
                                        value={optional.value}
                                        placeholder={optional.displayName}
                                        onChange={(e: any) => {
                                          this.onChange(e, "cbsd");
                                        }}
                                      />
                                      <label className="acpdetails" htmlFor={optional.propertyName}>
                                        {optional.displayName}
                                      </label>
                                    </div>
                                  </div>
                                );
                              } else {
                                if (optional.propertyName === "call_sign") {
                                  return (
                                    <div className="floating-label">
                                      <select
                                        name={optional.propertyName}
                                        className="floating-select selectarrow paramSelect"
                                        value={optional.value}
                                        onChange={(e) => {
                                          this.select_onChange(e, "optional", optional.propertyName, "optionalcalsign");
                                        }}
                                      >
                                        <option value="" hidden>
                                          1
                                        </option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                      </select>
                                      <label id="optionalcalsign">{optional.displayName}</label>
                                    </div>
                                  );
                                } else if (optional.propertyName === "grouping_param") {
                                  return (
                                    <div className="floating-label">
                                      <select
                                        name={optional.propertyName}
                                        className="floating-select selectarrow paramSelect"
                                        value={optional.value}
                                        onChange={(e) => {
                                          this.select_onChange(
                                            e,
                                            "optional",
                                            optional.propertyName,
                                            "optionalgroupingparam"
                                          );
                                        }}
                                      >
                                        <option value="" hidden>
                                          1
                                        </option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                      </select>
                                      <label id="optionalgroupingparam">{optional.displayName}</label>
                                    </div>
                                  );
                                }
                              }
                            }
                          }
                        })}
                      </div>
                    </div>
                  </div>

                  <IonRow className="bottom-actions">
                    <div className="" style={{ width: "100%" }}>
                      {this.props.assetInfo["assetTypeId"] == 1 ? (
                        <button
                          className="btn cardbtn btn-submitted"
                          disabled={this.state.isUploadImage}
                          onClick={() => {
                            this.updateSectorParameters();
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn cardbtn btn-submitted"
                          disabled={this.state.isUploadImage}
                          onClick={() => {
                            this.updateAssetParameters();
                          }}
                        >
                          Save
                        </button>
                      )}
                    </div>

                    <div className="" style={{ width: "100%" }}>
                      {this.send_button()}

                      {/* {this.state.paramStat == 3  ?

                                                this.state.isParameterSubmitted && this.state.submit_param ?
                                                    <button className='btn cardbtn btn-submitted m-t-10 m-b-10' style={{ color: '#222223' }}>
                                                        <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                        Submitted</button>
                                                    :
                                                    !(this.state.isParameterSubmitted) && this.state.submit_param ?
                                                        <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Update to SAS</button>
                                                        :
                                                        !(this.state.submit_param) && this.state.isParameterSubmitted ?
                                                            <button className='btn cardbtn btn-submitted m-t-10 m-b-10' style={{ color: '#222223' }}>
                                                                <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                                Sent</button>
                                                            :
                                                            !(this.state.submit_param) && !(this.state.isParameterSubmitted) ?
                                                                <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.sendParameters(e) }}  >Update to CPI</button> : null

                                                :
                                                this.state.isParameterSubmitted && this.state.submit_param ?
                                                    <button className='btn cardbtn btn-submitted m-t-10 m-b-10' style={{ color: '#222223' }}>
                                                        <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                        Submitted</button>
                                                    :
                                                    !(this.state.isParameterSubmitted) && this.state.submit_param ?
                                                        <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.showModal() }} data-toggle="modal" data-target="#submitModal" >Submit to SAS</button>
                                                        :
                                                        !(this.state.submit_param) && this.state.isParameterSubmitted ?
                                                            <button className='btn cardbtn btn-submitted m-t-10 m-b-10' style={{ color: '#222223' }}>
                                                                <i className="fa fa-check" style={{ fontSize: '13px', margin: '1px', color: '#00D56E', paddingRight: '4px' }}></i>
                                                                Sent</button>
                                                            :
                                                            !(this.state.submit_param) && !(this.state.isParameterSubmitted) ?
                                                                <button className='btn cardbtn submitbtn m-t-10 m-b-10' disabled={!this.state.submitToCloud} onClick={(e: any) => { e.preventDefault(); this.sendParameters(e) }}  >Submit to CPI</button> : null



                                            } */}
                    </div>
                    <IonAlert
                      isOpen={this.state.alertbox}
                      onDidDismiss={() => this.setShowAlert(false)}
                      message={this.state.warnMessage}
                      buttons={["OK"]}
                    />

                    <IonLoading
                      isOpen={this.state.is_loader}
                      onDidDismiss={() => this.setShowLoading(false)}
                      message={"Loading..."}
                      // duration={80000}
                    />
                  </IonRow>
                </div>
              </div>

              {/* <div className='col-md-12 m-t-5' >
                                <button className=' btn savebtn' style={{ bottom: '10px', width: '268px' }} disabled>Update</button>
                            </div> */}
            </div>
            <div className="tab-pane fade" id="sec2" role="tabpanel" aria-labelledby="sec2-tab"></div>
            <div className="tab-pane fade" id="sec3" role="tabpanel" aria-labelledby="sec3-tab"></div>
          </div>
        </div>

        <Modal show={this.state.show} handleClose={this.hideModal}>
          {/* <div className="modal fade" id="submitModal" role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row m-0" style={{}}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "14px" }}>
                    SUBMITTING PARAMETERS
                  </h6>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-0" style={{}}>
                  <span className="distance" style={{ marginTop: "10px", fontWeight: 400 }}>
                    Please enter CPI credentials
                  </span>
                </div>
                <div className=" m-t-5 justify-content-center">
                  <div className="form-group">
                    <div className="col-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          disabled={this.state.override_cpi}
                          type="text"
                          style={
                            this.state.certificateSubmitted == false
                              ? { width: "100%", borderColor: "#CED7DF" }
                              : {
                                  width: "100%",
                                  borderRadius: "6px",
                                  borderBottom: "1px solid #CED7DF",
                                }
                          }
                          id="cpeidmob"
                          value={this.state.cpiId}
                          name="cpiId"
                          onChange={this.cpiSaveCahnge}
                          className="text-user img-center"
                          placeholder="CPI ID"
                        />
                        <label htmlFor="cpeidmob">CPI ID</label>
                      </div>
                    </div>
                    {this.state.defaultProviderId === 2 ? (
                      <div className="col-12 p-0">
                        <div className="form-label-group m-0">
                          <input
                            disabled={this.state.override_cpi}
                            type="text"
                            style={
                              this.state.certificateSubmitted == false
                                ? { width: "100%", borderColor: "#CED7DF" }
                                : {
                                    width: "100%",
                                    borderRadius: "6px",
                                    borderBottom: "1px solid #CED7DF",
                                  }
                            }
                            id="cpeidmob"
                            value={this.state.cpiName}
                            name="cpiName"
                            onChange={this.cpiSaveCahnge}
                            className="text-user img-center"
                            placeholder="CPI Name"
                          />
                          <label htmlFor="cpenamemob">CPI Name</label>
                        </div>
                      </div>
                    ) : null}
                    {this.state.certificateSubmitted == false ? (
                      <div>
                        <div className="col-12 p-0">
                          <div className="form-label-group m-0">
                            <input
                              disabled={this.state.override_cpi}
                              type="password"
                              style={{ width: "100%", borderColor: "#CED7DF" }}
                              id="cpenamemob"
                              name="cpiPassword"
                              onChange={this.cpiSaveCahnge}
                              className="text-pwd img-center"
                              placeholder="CPI Name"
                            />
                            <label htmlFor="cpenamemob">CPI Certificate Password</label>
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <div>
                            <div
                              className="col-12 p-0 m-t-10 justify-content-center"
                              style={{ padding: "0px 35px", fontSize: "12px" }}
                            >
                              <i
                                className="material-icons imgclose-file dropzclose"
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  p12_files = [];
                                  this.onClear([], "cpi_certificate");
                                }}
                              >
                                clear
                              </i>
                              <Dropzone
                                onDrop={(e: any) => {
                                  this.onDrop(e, "p12");
                                }}
                                disabled={this.state.field_disable || this.state.override_cpi}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section className="dropzone-set">
                                    <div
                                      {...getRootProps({
                                        className: classDName4,
                                      })}
                                    >
                                      <input {...getInputProps()} accept=".p12" />
                                      {this.state.p12cert.length ? (
                                        <span style={{ color: "#00D56E" }}>
                                          {p12_files}

                                          {/* <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); p12_files = [] }}>clear</i> */}
                                        </span>
                                      ) : (
                                        <span style={{ fontSize: "14px" }}>+ Upload CPI Certificate</span>
                                      )}
                                    </div>
                                    <div></div>
                                  </section>
                                )}
                              </Dropzone>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row m-0 m-t-5">
                  <div className="form-group">
                    {this.state.defaultProviderId !== 2 ? (
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          disabled
                          checked={this.state.override_cpi}
                          onChange={(e: any) => {
                            this.is_override_cpi(e);
                          }}
                          className="custom-control-input"
                          id="customCheckcpi"
                          name="customCheckcpiexample"
                        />
                        <label
                          className="custom-control-label check"
                          style={{ float: "left", margin: "10px 0px" }}
                          htmlFor="customCheckcpi"
                        >
                          Override mandatory CPI credentials
                        </label>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row m-0 m-t-5 justify-content-center" style={{}}>
                  <button
                    className="btn cardbtn"
                    style={{ width: "100%" }}
                    onClick={() => {
                      this.savePrameters();
                      this.hideModal();
                    }}
                  >
                    Submit Parameters
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
