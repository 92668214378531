import React, { Component } from "react";
import {
  GoogleMap,
  StandaloneSearchBox,
  Marker,
  HeatmapLayer,
  InfoWindow,
  Circle,
  Polyline,
} from "@react-google-maps/api";
import { isBrowser, isMobile } from "react-device-detect";
import BuildingInfo1 from "./BuildingInfo1";
import BuildingInfo2 from "./BuildingInfo2";
import BuildingInfo3 from "./BuildingInfo3";
import Building1 from "./building1";
import DASnode from "./DASnode";
import Floor from "./Floors";
import IndoorAP from "./indoor-access-point";
import AddAP from "./AddAP";
import Search from "./search";
import AddCPE from "./AddCPE";
import Measure from "./measure";
import Calculate from "./calculate";
import APdetails from "./access-point-details";
import IndoorSearch from "./indoorsearch";
import { IonContent, IonHeader, IonPage, IonGrid, IonRow, IonCol, IonModal, IonButton } from "@ionic/react";
import { Redirect, withRouter } from "react-router-dom";
import { act } from "react-dom/test-utils";
import { requestAPICall } from "../service/apiHandler";
import gps_icon from "../assets/img/add-cpe-icon-img-blue.png";

import CPEDGray from "../assets/img/status-img/cpe-dark-gray.png";
import AP from "../assets/img/Apicon.png";

import CPEMap from "../assets/img/CPE_ICON.png";
import APMap from "../assets/img/add-cpe-icon-img-blue.png";
import DASMap from "../assets/img/dark-pink_leaf_1.png";
import BuildingList from "./buildinglist";
import { connect } from "react-redux";
import { CURRENT_MAP_CLICK_INSTANCE, COORDINATES, ASSET, CURRENT_TAB } from "../constants/actionTypes";
import store from "../store";
import { Plugins, AppState } from "@capacitor/core";

const { App } = Plugins;
const { Toast } = Plugins;
const { BackgroundTask } = Plugins;
const { Geolocation } = Plugins;
const { GetPermissionsPlugin } = Plugins;

// let center = {
//     lat: 38.9072, lng: -77.0369
// }
var geoJsonStyle = {
  color: "#00D56E",
  weight: 5,
  opacity: 0.65,
};
var lineSymbol = {
  path: "M 0,-1 0,1",
  scale: 4,
  strokeColor: "#FF9445",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#FF9445",
  fillOpacity: 0.35,
  radius: 30000,
  zIndex: 1,
};
const circleOptions = {
  strokeColor: "#0059B2AD",
  strokeOpacity: 0.35,
  strokeWeight: 1,
  fillColor: "#0059B2AD",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  zIndex: 1,
};

// const styles = require('../assets/GoogleMapStyle.json')
var lat: any;
var lng: any;
var markerIcon = {
  url: require("../assets/img/location.svg"),
  scaledSize: new window.google.maps.Size(25, 25),
};
const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
  upadateGMapClickEvent: (value: any) =>
    dispatch({
      type: CURRENT_MAP_CLICK_INSTANCE,
      key: "gMapClickInstance",
      value,
    }),
  upadateMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "coordinates", value }),
  updateBuildingId: (value: any) => dispatch({ type: ASSET, key: "buildingId", value }),
  updateAssetTypeId: (value: any) => dispatch({ type: ASSET, key: "assetTypeId", value }),
  updateAssetAction: (value: any) => dispatch({ type: ASSET, key: "assetAction", value }),
  updateAssetId: (value: any) => dispatch({ type: ASSET, key: "assetId", value }),
  upadateAssetId: (value: any) => dispatch({ type: ASSET, key: "assetId", value }),
  upadateBuildingAction: (value: any) => dispatch({ type: ASSET, key: "buildingAction", value }),
  updateTabType: (value: any) => dispatch({ type: ASSET, key: "tabType", value }),
  floorMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "floor_coordinates", value }),
  updateSearchText: (value: any) => dispatch({ type: COORDINATES, key: "search", value }),
  updateFloorId: (value: any) => dispatch({ type: ASSET, key: "floorId", value }),
});

class Indoor extends Component {
  state: any = {};
  props: any = {};
  child: React.RefObject<any>;
  childCPE: React.RefObject<any>;
  childAP: React.RefObject<any>;
  childDASS: React.RefObject<any>;
  childAssetDetails: React.RefObject<any>;
  apdetails: React.RefObject<any>;
  _map: any;
  counter: any = 0;
  backButton_timer: any = null;
  backButton_event: any;
  appState_event: any;
  timer: any;
  permissionCheckInterval: any;
  marker_event1: any;
  marker_event2: any;
  inBetween: any;
  reduxStore: any = store.getState()["general"];
  watchId: any = "";
  // pageRef = React.useRef();

  constructor(props: any) {
    super(props);
    this.watchId = "";
    this.state = {
      center: null,
      card: 1,
      subcard: null,
      showModal: true,
      marks: [],
      latlonglist: [],
      buildingId: 0,
      currentMapClickEvent: {},
      lat: 0,
      long: 0,
      lng: 0,
      floor_no: 0,
      temp: 0,
      building_name: "",
      buildid: 0,
      assetList: [],
      selectedAssetId: 0,
      selected_building_data: {},
      building_action: "Add",
      properties: [],
      assetAction: "add",
      floor_name: "",
      selected_floor_id: 0,
      serial_id: "",
      asset_details: [],
      assetPointDropList: [],
      assetCardShow: false,
      onHover: false,
      assethoverLatLongList: [],
      assetPointclickList: [],
      building_status_action: "",
      back_pop_request_body: {},
      isAssetClickEnabled: false,
      isAssetDropEnabled: false,
      b1_show_overlay: false,
      b2_show_overlay: false,
      show_ap_overlay: false,
      show_cpe_overlay: false,
      show_das_overlay: false,
      para: [],
      ap_cpe_locamrk: false,
      search_asset: {},
      sasStatus: "",
      editSerial: true,
    };
    this.setShowModal = this.setShowModal.bind(this);
    this.hideB1Overlay = this.hideB1Overlay.bind(this);
    this.hideB2Overlay = this.hideB2Overlay.bind(this);
    this.hideAPOverlay = this.hideAPOverlay.bind(this);
    this.hideCPEOverlay = this.hideCPEOverlay.bind(this);
    this.hideDASOverlay = this.hideDASOverlay.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
    this.building_delete_confirm = this.building_delete_confirm.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
    this.child = React.createRef();
    this.childCPE = React.createRef();
    this.childAP = React.createRef();
    this.childDASS = React.createRef();
    this.apdetails = React.createRef();
    this.childAssetDetails = React.createRef();
  }
  componentDidMount() {
    if (isBrowser) {
      if (navigator.userAgent.search("Chrome") >= 0) {
        this.firstLocation();
      } else {
        if (
          [undefined, null, "undefined", "null"].includes(localStorage.getItem("indoor_map_center_lat")) ||
          [undefined, null, "undefined", "null"].includes(localStorage.getItem("indoor_map_center_lng"))
        ) {
          this.setState({
            gps_accuracy: 0,
          });
          this.setState(
            {
              center: {
                lat: 38.907192,
                lng: -77.036873,
              },
            },
            () => {
              this.props.upadateMapCoordinates({
                latitude: this.state.center["lat"],
                longitude: this.state.center["lng"],
              });
            }
          );
          localStorage.setItem("indoor_map_center_lat", "38.907192");
          localStorage.setItem("indoor_map_center_lng", "-77.036873");
        } else {
          lat = localStorage.getItem("indoor_map_center_lat");
          lng = localStorage.getItem("indoor_map_center_lng");
          this.setState(
            {
              center: {
                lat: parseFloat(lat),
                lng: parseFloat(lng),
              },
            },
            () => {
              this.props.upadateMapCoordinates({
                latitude: this.state.center["lat"],
                longitude: this.state.center["lng"],
              });
            }
          );
        }
      }
    }
    if (isMobile) {
      this.getMobilePermissions();
      $("#searchbox").hide();
      setTimeout(() => {
        // console.log("first resize................................")
        $("#searchbox").css("width", "99.9%");
        $("#searchbox").show();
      }, 50);
      setTimeout(() => {
        // console.log("second resize............................")
        $("#searchbox").css("width", "100%");
        $("#searchbox").show();
      }, 1000);
      localStorage.setItem("urlPath", this.props.location.pathname);
      this.set_backButton_listener();
    }
    if (isMobile) {
      this.timer = setInterval(() => {
        this.getMobileLocation();
      }, 5000);
    }
    // console.log(this.state.center)
    // this.props.upadateMapCoordinates({ "latitude": center['lat'], "longitude": center['lng'] })
    this.props.updateTabType("indoor");
  }
  clearWatch() {
    if (this.watchId != null) {
      Geolocation.clearWatch({ id: this.watchId });
    }
  }
  async getLocationUsingWatchPosition(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.watchId = Geolocation.watchPosition({}, (position: any, err: any) => {
        if (err) {
          // console.log(err)
        } else {
          // console.log(position)
          this.clearWatch();
          resolve(position);
        }
      });
    });
  }

  async firstLocation() {
    // console.log("getting device location")
    if (
      [undefined, null, "undefined", "null"].includes(localStorage.getItem("indoor_map_center_lat")) ||
      [undefined, null, "undefined", "null"].includes(localStorage.getItem("indoor_map_center_lng"))
    ) {
      const coordinates = await this.getLocationUsingWatchPosition();
      // const coordinates = await Geolocation.getCurrentPosition();
      this.setState({
        gps_lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
        gps_lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
        gps_accuracy: coordinates.coords.accuracy,
      });
      this.setState(
        {
          center: {
            lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
            lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
          },
        },
        () => {
          this.props.upadateMapCoordinates({
            latitude: this.state.center["lat"],
            longitude: this.state.center["lng"],
          });
        }
      );
      localStorage.setItem("indoor_map_center_lat", coordinates.coords.latitude.toFixed(6));
      localStorage.setItem("indoor_map_center_lng", coordinates.coords.longitude.toFixed(6));
    } else {
      lat = localStorage.getItem("indoor_map_center_lat");
      lng = localStorage.getItem("indoor_map_center_lng");
      this.setState(
        {
          center: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
        },
        () => {
          this.props.upadateMapCoordinates({
            latitude: this.state.center["lat"],
            longitude: this.state.center["lng"],
          });
        }
      );
    }
  }
  async getMobilePermissions() {
    // console.log("=================")
    try {
      clearInterval(this.permissionCheckInterval);
    } catch (error) {}
    let mobPermissions = await GetPermissionsPlugin.getPermissions({
      message: "permissions",
    });
    if (mobPermissions["gpsLocationGranted"] === true) {
      this.firstLocation();
    } else if (mobPermissions["gpsLocationGranted"] === false) {
      this.permissionCheckInterval = setInterval(async () => {
        this.firstLocation();
        let reCheck = await GetPermissionsPlugin.getPermissions({
          message: "permissions",
        });
        if (reCheck["gpsLocationGranted"] === true) {
          clearInterval(this.permissionCheckInterval);
        }
      }, 2000);
      // console.log(this.state.center, "current center")
    }
  }
  async getMobileLocation() {
    // console.log("getting device location")
    const coordinates = await this.getLocationUsingWatchPosition();
    // const coordinates = await Geolocation.getCurrentPosition();
    this.setState({
      gps_lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
      gps_lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
      gps_accuracy: coordinates.coords.accuracy,
    });
  }

  set_backButton_listener = () => {
    var backButtonListenerExists = localStorage.getItem("backButton");
    // console.log('checking for back button listener..')
    if (backButtonListenerExists == null) {
      localStorage.setItem("backButton", "true");
      // console.log('creating back button listener')
      this.backButton_event = App.addListener("backButton", (state: any) => {
        // state.isActive contains the active state
        // console.log('back button pressed..............');
        // console.log(this.state.card)
        if (this.state.card == 1) {
          // console.log('moving to outdoor............')
          this.props.history.push("/map");
        }
        if (this.state.card == 2) {
          // console.log('hiding DAS overlay..........................')
          this.hideDASOverlay();
        }
        if (this.state.card == 3) {
          // console.log('hiding B1 overlay..........................')
          this.hideB1Overlay();
        }
        if (this.state.card == 4) {
          // console.log('hiding B2 overlay..........................')
          this.hideB2Overlay();
        }
        if (this.state.card == 6) {
          // console.log('back from floors.......................')
          this.next1();
        }
        if (this.state.card == 7) {
          if (this.state.subcard == null) {
            // console.log('switching to dash........................')
            this.dash();
          } else {
            if (this.state.subcard == "details") {
              // console.log('from details to parent card...............')
              this.childAssetDetails.current.hideOverlay();
            }
            if (this.state.subcard == "parameter") {
              // console.log('from parameter to parent card...............')
              this.childAssetDetails.current.hideParamOverlay();
            }
            if (this.state.subcard == "network") {
              // console.log('from network to parent card...............')
              this.childAssetDetails.current.hideNetworkOverlay();
            }
          }
        }
        if (this.state.card == 8) {
          // console.log('hiding AP overlay..........................')
          this.hideAPOverlay();
        }
        if (this.state.card == 10) {
          // console.log('hiding CPE overlay..........................')
          this.hideCPEOverlay();
        }
        if (this.state.card == 11 || this.state.card == 12) {
          // console.log('switching to floor page........................')
          this.next6();
        }
      });
    } else {
      // console.log('backButton listener already exists')
    }
    if (this.backButton_timer == null) {
      // console.log('creating timer.............')
      this.backButton_timer = setInterval(() => {
        this.set_backButton_listener();
      }, 2000);
    } else {
      // console.log('timer already exists.............')
    }
    var appStateListenerExists = localStorage.getItem("appState");
    // console.log('checking for app state listener..')
    if (appStateListenerExists == null) {
      // console.log('creating app state event listener....................')
      localStorage.setItem("appState", "true");
      this.appState_event = App.addListener("appStateChange", (state: AppState) => {
        if (state.isActive) {
          this.set_backButton_listener();
        }
        if (!state.isActive) {
          let taskId = BackgroundTask.beforeExit(async () => {
            // In this function We might finish an upload, let a network request
            // finish, persist some data, or perform some other task
            // console.log('inactive state.............')
            // Example of long task

            if (this.backButton_timer != null) {
              // console.log('clearing timer.......')
              clearInterval(this.backButton_timer);
              this.backButton_timer = null;
            }
            if (this.backButton_event != null) {
              // console.log('clearing back button event..........')
              this.backButton_event.remove();
            }
            localStorage.removeItem("backButton");
            // Must call in order to end our task otherwise
            // we risk our app being terminated, and possibly
            // being labeled as impacting battery life
            BackgroundTask.finish({
              taskId,
            });
          });
        }
      });
    } else {
      // console.log('app state listener already exists............')
    }
  };
  componentDidUpdate() {
    this.reduxStore = store.getState()["general"];
  }

  componentWillUnmount() {
    if (isMobile) {
      // console.log('Component unmounting==============================')
      // console.log('removing back button listener...........')
      if (this.backButton_timer != null) {
        // console.log('clearing timer.......')
        clearInterval(this.backButton_timer);
        this.backButton_timer = null;
      }
      if (this.backButton_event != null) {
        // console.log('clearing back button event..........')
        this.backButton_event.remove();
      }
      if (this.appState_event != null) {
        // console.log('clearing app state event..........')
        this.appState_event.remove();
      }
      localStorage.removeItem("backButton");
      localStorage.removeItem("appState");
    }
  }
  updateSearchText = (value: any) => {
    this.props.updateSearchText(value);
  };
  setAddress = (address: any, locmark: any) => {
    this.geocodeAddress(address, locmark);
  };
  geocodeAddress = (address: any, locmark: any) => {
    this.setState({
      locmark: false,
    });
    // console.log(this.state.locmark, "locmark")
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { address: address },
      function handleResults(this: any, results: any, status: any) {
        if (status === google.maps.GeocoderStatus.OK) {
          // // console.log((results[0].geometry.viewport.Ya['i'] + results[0].geometry.viewport.Ya['j']) / 2, "position")

          this.setState(
            {
              center: {
                lat: parseFloat(results[0].geometry.location.lat()),
                lng: parseFloat(results[0].geometry.location.lng()),
              },
              // center: {
              //     lat: parseFloat(String((results[0].geometry.viewport.Ya['i'] + results[0].geometry.viewport.Ya['j']) / 2)), lng: parseFloat(String((results[0].geometry.viewport.Ua['i'] + results[0].geometry.viewport.Ua['j']) / 2))
              // }
            },
            () => {
              // console.log(this.state.center)
              localStorage.setItem("indoor_map_center_lat", this.state.center["lat"].toFixed(6).toString());
              localStorage.setItem("indoor_map_center_lng", this.state.center["lng"].toFixed(6).toString());
              this.props.upadateMapCoordinates({
                latitude: this.state.center["lat"].toFixed(6),
                longitude: this.state.center["lng"].toFixed(6),
              });
            }
          );

          this.setState({
            locmark: true,
          });
        }

        this.setState({
          foundAddress: null,
          isGeocodingError: true,
        });
      }.bind(this)
    );
    this.props.updateSearchText(null);
  };
  dash = () => {
    this.setState({
      card: 1,
      marks: [],
      param1: "",
      param2: "",
      temp: 0,
      latlonglist: [],
      azimuth1: 0,
      azimuth2: 0,
      tilt1: 0,
      tilt2: 0,
      distance: 0,
      assetPointDropList: [],
      isAssetDropEnabled: false,
    });

    this.listAssets("create-list", this.reduxStore["floorId"]);
  };
  dash1 = () => {
    this.setState({
      card: 7,
      marks: [],
      assetPointDropList: [],
      isAssetDropEnabled: false,
    });
    this.listAssets("create-list", this.reduxStore["floorId"]);
  };
  getSectorImage = (assetType: string, sectors: number, status: string) => {
    var url = AP;
    var height = 10;
    var width = 10;
    if (assetType == "CPE-indoor") {
      switch (status) {
        case "disabled":
          url = CPEDGray;
          height = 18;
          width = 18;
          break;
        case "create-list":
          url = CPEMap;
          height = 18;
          width = 18;
          break;
        case "calculate":
          url = CPEMap;
          height = 18;
          width = 18;
          break;
      }
    } else if (assetType == "AP-indoor") {
      switch (status) {
        case "disabled":
          url = CPEDGray;
          height = 18;
          width = 18;
          break;
        case "create-list":
          url = APMap;
          height = 18;
          width = 18;
          break;
        case "calculate":
          url = CPEMap;
          height = 18;
          width = 18;
          break;
      }
    } else if (assetType == "DAS") {
      switch (status) {
        case "disabled":
          url = CPEDGray;
          height = 18;
          width = 18;
          break;
        case "create-list":
          url = DASMap;
          height = 18;
          width = 18;
          break;
        case "calculate":
          url = CPEMap;
          height = 18;
          width = 18;
          break;
      }
    }
    return {
      url: url,
      scaledSize: new window.google.maps.Size(width, height),
      labelOrigin: new window.google.maps.Point(width / 2, height / 0.7),
    };
  };
  get_zoom = () => {
    // console.log(this._map)
    if (isBrowser) {
      if (this._map !== null && this._map !== undefined) {
        var zoom = this._map.zoom;
        // console.log(zoom)

        if (zoom < 16) {
          this.setState({
            assetCardShow: false,
          });
        } else {
          this.setState({
            assetCardShow: true,
          });
        }
      }
    }
    if (isMobile) {
      if (this._map !== null && this._map !== undefined) {
        var zoom = this._map.zoom;
        // console.log(zoom)
        if (zoom < 16) {
          this.setState({
            assetCardShow: false,
          });
        } else {
          this.listAssets("create-list", this.reduxStore["floorId"]);
          this.setState({
            assetCardShow: true,
          });
        }
      }
    }
  };
  assetCardDeatils(e: any, asset: any, text: any) {
    const { latLng } = e;
    if (text == "Info") {
      this.setState({
        isAssetClickEnabled: true,
        assetIdentifierId: asset.assetIdentifierId,
        assetStatus: asset.assetOperationalStatus,
        assetHeight: asset.assetHeight,
        sasStatus: asset.sasStatus,
      });
      this.setState({
        assetPointclickList: [
          {
            lat: parseFloat(latLng.lat().toFixed(6)),
            lng: parseFloat(latLng.lng().toFixed(6)),
          },
        ],
      });
    }
  }
  onHover = (e: any, asset: any) => {
    const { latLng } = e;
    if (isBrowser) {
      this.setState({
        onHover: true,
        assetIdentifierId: asset.assetIdentifierId,
        assetStatus: asset.assetOperationalStatus,
        assetHeight: asset.assetHeight,
        sasStatus: asset.sasStatus,
        assethoverLatLongList: [
          {
            lat: parseFloat(latLng.lat().toFixed(6)),
            lng: parseFloat(latLng.lng().toFixed(6)),
          },
        ],
      });
    }
  };
  onMouseOut() {
    if (isBrowser) {
      this.setState({
        onHover: false,
      });
    }
  }
  assetDetail = (e: any, asset: any, text: any) => {
    if (isMobile) {
      this.assetCardDeatils(e, asset, text);
    }
    this.setState({
      locmark: false,
    });
    this.setState({
      selectedAssetId: asset.assetId,
    });
    this.setState(
      {
        center: {
          lat: parseFloat(asset.coOrdinates["lat"]),
          lng: parseFloat(asset.coOrdinates["lng"]),
        },
      },
      () => {
        this.props.upadateMapCoordinates({
          latitude: this.state.center["lat"],
          longitude: this.state.center["lng"],
        });
        // console.log(this.state.center, "center")
      }
    );
    localStorage.setItem("indoor_map_center_lat", asset.coOrdinates["lat"]);
    localStorage.setItem("indoor_map_center_lng", asset.coOrdinates["lng"]);

    this.props.updateAssetId(asset.assetId);
    if (isMobile) {
      this.setState({ card: 7 }, () => {
        // // console.log(this.childAssetDetails);
        this.childAssetDetails.current.get_asset_details();
      });
    } else {
      // this.get_asset_details(asset.assetId, text)
      this.get_asset_details(asset.assetId);
    }
  };
  editAsset = (details: any) => {
    // console.log(details);
    setTimeout(() => {
      $(".gm-bundled-control").css("left", "95.6%");
      $(".gm-bundled-control").show();
    }, 5);
    this.setState(
      {
        properties: details["properties"],
        assetAction: details["assetAction"],
        selectedAssetId: details["assetId"],
        editSerial: details["editSerial"],
        serial_id: details["serial_id"],
      },
      () => {
        // this.setState({ card: 2 })
      }
    );
    this.props.updateAssetAction(details["assetAction"]);
    if (details["assetType"] == 2) {
      this.setState({
        card: 8,
        typeId: 2,
      });
    } else if (details["assetType"] == 4) {
      this.setState({
        card: 10,
        typeId: 4,
      });
    } else {
      this.setState({
        card: 2,
        typeId: 5,
      });
    }
  };
  get_asset_details = (selectedAssetId: any) => {
    let requestBody = {};
    requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody).then((data) => {
      // console.log(data)
      if (data.responseCode == 200) {
        this.props.updateAssetTypeId(data.assetTypeId);
        this.setState(
          {
            asset_details: data,
            card: 7,
          },
          () => {
            setTimeout(() => {
              $(".gm-bundled-control").css("left", "73%");
              $(".gm-bundled-control").show();
            }, 50);
            this.apdetails.current.set_property();
          }
        );
      }
      // console.log(this.state.asset_details)
    });
  };
  asset_delete_confirm = () => {
    // console.log("trigger indoor asset list")
    setTimeout(() => {
      $(".gm-bundled-control").css("left", "95.6%");
      $(".gm-bundled-control").show();
    }, 50);
    this.listAssets("create-list", this.reduxStore["floorId"]);
    this.setState({
      card: 6,
    });
  };
  listAssets(status: any, floorId: any) {
    if (floorId == "") {
      floorId = this.reduxStore["floorId"];
    }
    // console.log(this.reduxStore['floorId'])
    var assetsList: any = [];
    requestAPICall("get_building_assets", { building_id: this.reduxStore["buildingId"], floor_id: floorId }, {}).then(
      (data) => {
        // console.log(data)
        if (data.responseCode == 200) {
          data.assets.map((asset: any) => {
            if (
              asset["assetType"] == "AP-indoor" ||
              asset["assetType"] == "CPE-indoor" ||
              asset["assetType"] == "DAS"
            ) {
              var sasStatus_description = "";
              if (asset["sasStatus"].hasOwnProperty(asset["assetId"])) {
                sasStatus_description = asset["sasStatus"][asset["assetId"]].split(",")[1];
              }
              assetsList.push({
                assetType: asset["assetType"],
                assetId: asset["assetId"],
                sasStatus: sasStatus_description,
                assetName: asset["name"],
                assetOperationalStatus: asset["operationalStatus"],
                assetHeight: asset["radiation_center"],
                assetIdentifierId: asset["assetIdentifierId"],
                coOrdinates: {
                  lat: parseFloat(asset.latitude),
                  lng: parseFloat(asset.longitude),
                },
                name: asset["name"],
                labelText: {
                  text: asset["assetIdentifierId"],
                  color: "#000000",
                  fontSize: "17px",
                },
                markerIcon: this.getSectorImage(asset["assetType"], parseInt(asset["no_of_sectors"]), status),
              });
            }
          });
          this.setState({ assetList: assetsList });
        }
      }
    );
  }

  callListAPI = (typeId: number, nextCard: any) => {
    let requestBody = {};
    requestAPICall("list_asset_properties", { asset_type_id: typeId }, requestBody).then((data) => {
      if (data.responseCode == 200) {
        this.setState({ properties: data.properties }, () => {
          this.setState({ card: nextCard });
        });
      }
    });
  };

  sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  next1 = () => {
    this.floorplan_layer(null);
    this.setState({
      card: 1,
      assetList: [],
      selected_floor_id: 0,
      locmark: false,
    });
    // this.props.upadateMapCoordinates({ "latitude": center['lat'], "longitude": center['lng'] })
  };
  next2 = () => {
    this.props.updateAssetTypeId(5);
    this.callListAPI(5, 2);
    this.setState({
      // card: 2,
      show_das_overlay: true,
      typeId: 5,
      assetAction: "add",
    });
  };
  next3 = () => {
    // console.log("===================")
    this.setState({
      card: 3,
      b1_show_overlay: true,
    });
  };

  setBuildingId = (id: number) => {
    this.setState({ buildingId: id });
  };

  next4 = () => {
    // console.log("===================")
    this.setState({
      card: 4,
      b1_show_overlay: false,
      b2_show_overlay: true,
    });
  };
  next5 = () => {
    this.setState({
      card: 5,
    });
  };
  next6 = () => {
    this.setState({
      card: 6,
      marks: [],
      locmark: false,
      isAssetClickEnabled: false,
      isAssetDropEnabled: false,
      temp: 0,
    });
    this.marker_event1 = null;
    this.marker_event2 = null;
    this.inBetween = null;
  };
  next7 = () => {
    this.setState({
      card: 7,
    });
  };
  next13 = (e: any) => {
    this.setState({
      card: 13,
    });
  };
  setShowModal(hiddenProp: Boolean, e: any) {
    // console.log(hiddenProp);
    this.setState({ showModal: hiddenProp });
  }
  addAP = () => {
    // console.log(this.state.assetAction)
    this.props.updateAssetAction("add");
    this.props.updateAssetTypeId(2);
    this.callListAPI(2, 8);
    this.setState({
      // card: 8,
      show_ap_overlay: true,
      typeId: 2,
      assetAction: "add",
      ap_cpe_locamrk: false,
    });
  };
  search = () => {
    this.setState({
      card: 9,
    });
  };
  addCPE = () => {
    this.props.updateAssetAction("add");
    this.props.updateAssetTypeId(4);
    this.callListAPI(4, 10);
    this.setState({
      // card: 10,
      show_cpe_overlay: true,
      typeId: 4,
      assetAction: "add",
    });
  };
  addDAS = () => {
    this.props.updateAssetAction("add");
    this.props.updateAssetTypeId(5);
    this.callListAPI(5, 10);
    this.setState({
      // card: 10,
      show_das_overlay: true,
      typeId: 5,
      assetAction: "add",
      ap_cpe_locamrk: false,
    });
  };
  measure = () => {
    // this._map.data.setMapContainerStyle({
    //     height: "calc(100vh - 58px)"
    // })
    this.setState({
      isAssetClickEnabled: false,
    });
    this.setState({
      card: 11,
      ap_cpe_locamrk: false,
    });
  };

  set_selected_floor_id = (id: number) => {
    this.setState(
      {
        selected_floor_id: id,
        assetList: [],
      },
      () => {
        this.listAssets("create-list", id);
      }
    );
  };
  calculate = () => {
    setTimeout(() => {
      $(".gm-bundled-control").css("left", "95.6%");
      $(".gm-bundled-control").show();
    }, 5);
    this.setState({
      card: 12,
      ap_cpe_locamrk: false,
    });
  };
  onMapClick = async (e: any) => {
    const coordinates = await this.getLocationUsingWatchPosition();
    // const coordinates = await Geolocation.getCurrentPosition();
    this.setState({
      lat: coordinates.coords.latitude.toFixed(6),
      lng: coordinates.coords.longitude.toFixed(6),
    });
    localStorage.setItem("indoor_map_center_lat", coordinates.coords.latitude.toFixed(6));
    localStorage.setItem("indoor_map_center_lng", coordinates.coords.longitude.toFixed(6));
    this.props.upadateMapCoordinates({
      latitude: coordinates.coords.latitude.toFixed(6),
      longitude: coordinates.coords.longitude.toFixed(6),
    });
    if (this.state.marks.length <= 0) {
      this.setState(
        {
          marks: [
            ...this.state.marks,
            {
              lat: coordinates.coords.latitude,
              lng: coordinates.coords.longitude,
            },
          ],
        },
        () => {
          // console.log(this.state.marks)
        }
      );
    }
  };

  setSectorIcon = (noOfSec: any) => {
    if (noOfSec > 0) {
      this.setState({
        addAssetList: [
          {
            assetType: "AP-outdoor",
            coOrdinates: {
              lat: this.state.currentMapClickEvent.latLng.lat(),
              lng: this.state.currentMapClickEvent.latLng.lng(),
            },
            name: "New Asset",
            markerIcon: this.getSectorImage("AP-outdoor", noOfSec, "create-list"),
          },
        ],
      });
    } else {
      this.setState({ addAssetList: [] });
    }
  };
  changeMapCenter() {
    // console.log("============")
    try {
      this.setState(
        {
          center: {
            lat: parseFloat(this.reduxStore.coordinates.latitude.toFixed(6)),
            lng: parseFloat(this.reduxStore.coordinates.longitude.toFixed(6)),
          },
        },
        () => {
          this._map.setCenter(this.state.center);
        }
      );
    } catch (error) {}
  }
  latlong_apcpe = (lat: any, lng: any, ap_cpe_locamrk: any, search_asset: any) => {
    this.setState(
      {
        ap_cpe_locamrk: ap_cpe_locamrk,
        search_asset: search_asset,
        center: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
      },
      () => {
        localStorage.setItem("indoor_map_center_lat", this.state.center["lat"].toString());
        localStorage.setItem("indoor_map_center_lng", this.state.center["lng"].toString());
        this.props.upadateMapCoordinates({
          latitude: this.state.center["lat"],
          longitude: this.state.center["lng"],
        });
      }
    );
  };

  set_calculate = (lat: any, long: any, radc: any) => {
    // console.log(lat, long, radc)
    this.setState({
      lat: lat,
      lng: long,
      rad_center: radc,
      assetHeight: radc,
    });
  };
  onMarkerDragEnd = (e: any) => {
    // console.log(e.latLng)
    this.setState({
      lat: e.latLng.lat().toFixed(6),
      lng: e.latLng.lng().toFixed(6),
    });
    localStorage.setItem("indoor_map_center_lat", e.latLng.lat().toFixed(6));
    localStorage.setItem("indoor_map_center_lng", e.latLng.lng().toFixed(6));
    // console.log(this.state.lat, this.state.lng)
  };
  handleClick = (event: any) => {
    // console.log("======event")
    const { latLng } = event;
    this.setState({
      para: [latLng.lat().toFixed(6), latLng.lng().toFixed(6)],
      currentMapClickEvent: event,
      lat: latLng.lat().toFixed(6),
      long: latLng.lng().toFixed(6),
      lng: latLng.lng().toFixed(6),
      locmark: false,
    });
    localStorage.setItem("indoor_map_center_lat", latLng.lat().toFixed(6));
    localStorage.setItem("indoor_map_center_lng", latLng.lng().toFixed(6));
    this.props.upadateMapCoordinates({
      latitude: latLng.lat().toFixed(6),
      longitude: latLng.lng().toFixed(6),
    });
    if (isMobile) {
      this.setState({
        assetPointDropList: [
          {
            lat: parseFloat(latLng.lat().toFixed(6)),
            lng: parseFloat(latLng.lng().toFixed(6)),
          },
        ],
      });
      // console.log(this.state.assetPointDropList);
    }
    if (this.state.card === 3) {
      this.child.current.componentRef.current.disableButton();
    }
    if (this.state.card === 10) {
      if (isBrowser) this.childCPE.current.componentRef.current.disableButton();
    }
    if (this.state.card === 8) {
      if (isBrowser)
        this.setState({
          assetPointDropList: [
            {
              lat: parseFloat(latLng.lat().toFixed(6)),
              lng: parseFloat(latLng.lng().toFixed(6)),
            },
          ],
        });
      this.childAP.current.componentRef.current.disableButton();
    }
    if (this.state.card === 2) {
      if (isBrowser) {
        // console.log(this.childDASS);
        this.childDASS.current.disableButton();
      }
    }
    if (this.state.card === 6) {
      this.setState({
        assetPointDropList: [
          {
            lat: parseFloat(latLng.lat().toFixed(6)),
            lng: parseFloat(latLng.lng().toFixed(6)),
          },
        ],
      });
    }
    if (this.state.card === 11) {
      this.measure_calc(event);
    }
    // console.log(this.state.card)
    // console.log(this.state.assetPointDropList, this.state.isAssetDropEnabled)
  };

  measure_calc = (event: any) => {
    // console.log("============", this.state.temp)
    const { latLng } = event;
    if (this.state.temp === 0) {
      // console.log('when temp 0')
      this.marker_event1 = latLng;
      this.setState(
        {
          param1: [parseFloat(latLng.lat().toFixed(6)), parseFloat(latLng.lng().toFixed(6))],
          temp: 1,
        },
        () => {
          // console.log(this.state.param1, this.state.temp)
        }
      );
    } else if (this.state.temp === 1) {
      this.marker_event2 = latLng;
      this.setState(
        {
          param2: [parseFloat(latLng.lat().toFixed(6)), parseFloat(latLng.lng().toFixed(6))],
          temp: 2,
        },
        () => {
          let requestBody = {
            param1: this.state.param1,
            param2: this.state.param2,
          };
          // console.log(requestBody)
          requestAPICall("distance", {}, requestBody).then((data) => {
            // console.log(data, "data")
            if (data.responseCode == 200) {
              this.setState({
                distance: data.distance,
              });
            } else {
              this.setState({
                warnMessage: data.message,
                alertbox: true,
              });
            }
          });
        }
      );
      this.inBetween = google.maps.geometry.spherical.interpolate(this.marker_event1, this.marker_event2, 0.5);
      // console.log(this.inBetween)
    }
  };

  set_floor_no = (no: any) => {
    this.setState({
      floor_no: no,
    });
  };
  b3_back_action = (action: any, back_pop_request_body: any) => {
    // console.log(action, back_pop_request_body)
    if (action == "next" || action == "back3" || action == "back3FromEdit") {
      this.setState(
        {
          building_action: action,
          back_pop_request_body: back_pop_request_body,
        },
        () => {
          this.next4();
        }
      );
    } else {
      this.setState(
        {
          building_action: action,
          back_pop_request_body: back_pop_request_body,
        },
        () => {
          this.props.updateFloorId(null);
          this.next6();
        }
      );
    }
  };

  building_status_action = (action: any) => {
    // console.log(action)
    this.setState({
      building_action: action,
    });
    // console.log(this.state.building_action)
  };
  setBuildingCoordinates = (lat: any, lng: any) => {
    this.setState(
      {
        center: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
      },
      () => {
        // console.log(this.state.center)
        this.props.upadateMapCoordinates({
          latitude: this.state.center["lat"],
          longitude: this.state.center["lng"],
        });
      }
    );
    localStorage.setItem("indoor_map_center_lat", lat);
    localStorage.setItem("indoor_map_center_lng", lng);
  };
  reduce_floor = () => {
    if (isMobile) {
      this.setState({
        b2_show_overlay: false,
      });
    }
    // console.log(this.state.building_action, this.state.floor_no)
    if (this.state.building_action == "AddFloor") {
      this.next6();
    }
    if (this.state.building_action == "complete") {
      if (isMobile) {
        this.props.updateFloorId(null);
        this.next6();
      } else {
        this.next1();
      }
    }
    if (this.state.building_action == "next") {
      this.next4();
    }

    // Commented due to removing no_of_floors
    // else {
    //     // console.log(this.state.floor_no)
    //     this.setState({
    //         floor_no: this.state.floor_no - 1
    //     }, () => {
    //         // console.log(this.state.floor_no)
    //         if (this.state.floor_no === 0 || this.state.floor_no < 0 || this.state.building_action == "complete") {
    //             if (isMobile) {
    //                 this.props.updateFloorId(null);
    //                 this.next6()
    //             } else {
    //                 this.next1()
    //             }
    //         }
    //         else {
    //             this.next4()

    //         }
    //     })

    // }
  };
  building_name = (name: any) => {
    this.setState({
      building_name: name,
    });
  };
  floor_name = (name: any) => {
    this.setState({
      floor_name: name,
    });
    // console.log(this.state.floor_name)
  };
  get_bld_id = (data: any) => {
    // console.log(data)
    this.setState({
      buildid: data,
    });
    this.props.updateBuildingId(data);
    // console.log(this.state.buildid)
  };

  request_body = (data: any) => {
    this.setState({
      floor_req: data,
    });
  };

  selected_building = (data: any) => {
    this.setState({
      selected_building_data: data,
    });
  };

  building_action(action: any) {
    // console.log("========", action)
    this.props.upadateBuildingAction(action);
    this.setState({
      building_action: action,
    });

    if (this.state.card === 10) {
      this.childCPE.current.disableButton();
    } else if (this.state.card === 8) {
      this.childAP.current.disableButton();
    } else if (this.state.card == 2) {
      this.childDASS.current.disableButton();
    }
  }
  dummy = () => {};

  enableAssetDrop = (st: any) => {
    // console.log(st)
    this.setState({ isAssetDropEnabled: st }, () => {
      if (this.state.isAssetDropEnabled) {
        // this.listAssets('disabled-angle', "");
        // this.listAssetsWithInterval('disabled-angle');
      }
    });
  };
  hideB1Overlay = () => {
    this.setState({
      b1_show_overlay: false,
      card: 1,
      assetList: [],
      selected_floor_id: 0,
    });
  };

  hideEditB1Overlay = () => {
    this.setState({
      b1_show_overlay: false,
      card: 6,
    });
  };

  hideB2Overlay = () => {
    this.setState({
      b2_show_overlay: false,
      card: 1,
      assetList: [],
      selected_floor_id: 0,
    });
  };
  b2_back_action = (action: any) => {
    this.setState({
      b2_show_overlay: false,
      building_action: action,
    });
    this.props.upadateBuildingAction(action);
    // console.log(action)
    if (action === "back") {
      this.setState({
        b1_show_overlay: true,
        card: 3,
      });
    } else {
      this.setState({
        card: 1,
        assetList: [],
        selected_floor_id: 0,
      });
    }
    // console.log(this.state.b1_show_overlay)
  };
  hideAPOverlay = () => {
    this.setState({
      show_ap_overlay: false,
      card: 6,
      assetPointDropList: [],
      assetCardShow: false,
      isAssetDropEnabled: false,
    });
  };
  hideCPEOverlay = () => {
    this.setState({
      show_cpe_overlay: false,
      card: 6,
    });
  };
  hideDASOverlay = () => {
    this.setState({
      show_das_overlay: false,
      card: 6,
    });
  };
  back14 = () => {
    this.floorplan_layer(null);
    this.setState({
      card: 1,
      assetList: [],
      selected_floor_id: 0,
      locmark: true,
    });
  };

  building_delete_confirm() {
    // console.log("trigger building list")
    this.setState({
      card: 1,
    });
  }
  onMarkerDrag = () => {
    google.maps.event.addListener(this._map, "dragend", this.showNewRect);
  };

  refreshAsset = (selectedAssetId: any) => {
    // console.log("refresh asset details")
    // console.log(selectedAssetId)
    let requestBody = {};
    // console.log(this.state.assetList)

    requestAPICall("get_asset_details", { asset_id: selectedAssetId }, requestBody).then((asset) => {
      // console.log(asset)
      asset.properties.map((property: any) => {
        if (property.propertyName == "no_of_sectors") {
          asset["no_of_sectors"] = property.value;
        }
        if (property.propertyName == "radiation_center") {
          asset["radiation_center"] = property.value;
        }
        if (property.propertyName == "latitude") {
          asset.latitude = property.value;
        }
        if (property.propertyName == "longitude") {
          asset.longitude = property.value;
        }
      });

      this.state.assetList.forEach((element: any) => {
        if (element.assetId == selectedAssetId) {
          var sasStatus_description = "";
          if (asset["sasStatus"].hasOwnProperty(element["assetId"])) {
            sasStatus_description = asset["sasStatus"][asset["assetId"]].split(",")[1];
          }
          element.sasStatus = sasStatus_description;
          element.assetHeight = asset["radiation_center"];
          this.setState({
            sasStatus: sasStatus_description,
            assetHeight: asset["radiation_center"],
          });
        }
      });
    });
  };
  showNewRect = () => {
    localStorage.setItem("indoor_map_center_lat", this._map.getCenter().lat().toFixed(6));
    localStorage.setItem("indoor_map_center_lng", this._map.getCenter().lng().toFixed(6));
  };
  searchBuildingLocation = () => {
    this.setState({
      card: 14,
    });
  };
  curren_geo_position = async () => {
    const coordinates = await this.getLocationUsingWatchPosition();
    // const coordinates = await Geolocation.getCurrentPosition();
    this.setState(
      {
        browserLatitude: parseFloat(coordinates.coords.latitude.toFixed(6)),
        browserLongitude: parseFloat(coordinates.coords.longitude.toFixed(6)),
      },
      () => {
        this.setState(
          {
            center: {
              lat: parseFloat(coordinates.coords.latitude.toFixed(6)),
              lng: parseFloat(coordinates.coords.longitude.toFixed(6)),
            },
          },
          () => {
            try {
              this._map.setCenter(this.state.center);
              this._map.setZoom(15);
            } catch (error) {}
          }
        );
        localStorage.setItem("indoor_map_center_lat", coordinates.coords.latitude.toFixed(6));
        localStorage.setItem("indoor_map_center_lng", coordinates.coords.longitude.toFixed(6));
        this.props.upadateMapCoordinates({
          latitude: parseFloat(coordinates.coords.latitude.toFixed(6)),
          longitude: parseFloat(coordinates.coords.longitude.toFixed(6)),
        });
      }
    );
  };

  renderSwitch() {
    var param = this.state.card;
    switch (param) {
      case 1:
        return (
          <Building1
            setCurrentPosition={() => this.curren_geo_position()}
            searchfn={this.searchBuildingLocation}
            building_name={(name: any) => {
              this.building_name(name);
            }}
            next3={this.next3}
            next13={this.next13}
            next6={this.next6}
            send_id={(val: any) => {
              this.get_bld_id(val);
            }}
            build_action={(action: any) => {
              this.building_action(action);
            }}
            calculate={this.calculate}
            focusBuilding={(lat: any, lng: any) => this.focusBuilding(lat, lng)}
          />
        );
      case 2:
        if (isBrowser)
          return (
            <DASnode
              serial_id={this.state.serial_id}
              selectedAssetId={this.state.selectedAssetId}
              assetAction={this.state.assetAction}
              select_floor_id={this.state.selected_floor_id}
              buildid={this.state.buildid}
              back={this.next6}
              ref={this.childDASS}
              latlong={this.state.para}
              typeId={this.state.typeId}
              listAssets={(status: any, buildingId: any) => {
                this.listAssets(status, buildingId);
              }}
              properties={this.state.properties}
            />
          );
        if (isMobile)
          return (
            <div
              className="fixed-top"
              id="overlay"
              style={{
                height: "100%",
                display: this.state.show_das_overlay === true ? "block" : "none",
              }}
            >
              <DASnode
                hideOverlay={this.hideDASOverlay}
                serial_id={this.state.serial_id}
                selectedAssetId={this.state.selectedAssetId}
                assetAction={this.state.assetAction}
                select_floor_id={this.state.selected_floor_id}
                buildid={this.state.buildid}
                back={this.next6}
                ref={this.childDASS}
                latlong={this.state.para}
                typeId={this.state.typeId}
                listAssets={(status: any, buildingId: any) => {
                  this.listAssets(status, buildingId);
                }}
                properties={this.state.properties}
              />
            </div>
          );

      case 3:
        if (isBrowser)
          return (
            <BuildingInfo1
              latlong={this.state.para}
              next6={this.next6}
              action={this.state.building_action}
              ref={this.child}
              select_id={this.state.buildid}
              latitude={this.state.lat}
              longitude={this.state.long}
              selected_building_data={this.state.selected_building_data}
              next4={this.next4}
              back={this.next1}
              calculate={this.calculate}
              floor_no={(no: number) => {
                this.set_floor_no(no);
              }}
              building_name={(name: any) => {
                this.building_name(name);
              }}
              focusBuilding={(lat: any, lng: any) => this.focusBuilding(lat, lng)}
              setBuildingId={(id: number) => {
                this.setBuildingId(id);
              }}
            />
          );
        if (isMobile)
          return (
            <div
              className="fixed-top"
              id="overlay"
              style={{
                height: "100%",
                display: this.state.b1_show_overlay === true ? "block" : "none",
              }}
            >
              {this.state.b1_show_overlay ? (
                <BuildingInfo1
                  hideB1Overlay={this.hideB1Overlay}
                  hideEditB1Overlay={this.hideEditB1Overlay}
                  next6={this.next6}
                  action={this.state.building_action}
                  ref={this.child}
                  select_id={this.state.buildid}
                  latitude={this.state.lat}
                  longitude={this.state.long}
                  selected_building_data={this.state.selected_building_data}
                  next4={this.next4}
                  back={this.next1}
                  calculate={this.calculate}
                  floor_no={(no: number) => {
                    this.set_floor_no(no);
                  }}
                  building_name={(name: any) => {
                    this.building_name(name);
                  }}
                  setBuildingId={(id: number) => {
                    this.setBuildingId(id);
                  }}
                  focusBuilding={(lat: any, lng: any) => this.focusBuilding(lat, lng)}
                />
              ) : null}
            </div>
          );

      case 4:
        if (isBrowser)
          return (
            <BuildingInfo2
              action={this.state.building_action}
              calculate={this.calculate}
              build_action={(action: any) => {
                this.building_action(action);
              }}
              floor_name={(name: any) => {
                this.floor_name(name);
              }}
              select_id={this.state.buildid}
              building_name={this.state.building_name}
              buildingId={this.state.buildingId}
              floor_no={this.state.floor_no}
              next6={this.next6}
              next5={this.next5}
              back={this.next3}
              backFloor={this.next6}
              request_body={(body: any) => {
                this.request_body(body);
              }}
              back_pop_req_body={this.state.back_pop_request_body}
            />
          );
        if (isMobile)
          return (
            <div
              className="fixed-top"
              id="overlay"
              style={{
                height: "100%",
                display: this.state.b2_show_overlay === true ? "block" : "none",
              }}
            >
              {this.state.b2_show_overlay ? (
                <BuildingInfo2
                  hideB2Overlay={this.hideB2Overlay}
                  b2_back_action={(action: any) => {
                    this.b2_back_action(action);
                  }}
                  action={this.state.building_action}
                  next1={this.reduce_floor}
                  building_status_action={(action: any) => {
                    this.building_status_action(action);
                  }}
                  calculate={this.calculate}
                  floor_name={(name: any) => {
                    this.floor_name(name);
                  }}
                  select_id={this.state.buildid}
                  building_name={this.state.building_name}
                  buildingId={this.state.buildingId}
                  floor_no={this.state.floor_no}
                  next6={this.next6}
                  next5={this.next5}
                  back={this.next3}
                  request_body={(body: any) => {
                    this.request_body(body);
                  }}
                  back_pop_req_body={this.state.back_pop_request_body}
                />
              ) : null}
            </div>
          );
      // return (
      //     <Redirect from='/map' to='/buildingInfo2' />
      // )
      case 5:
        return (
          <BuildingInfo3
            action={this.state.building_action}
            calculate={this.calculate}
            floor_no={this.state.floor_no}
            select_id={this.state.buildid}
            b3_back_action={(action: any, back_pop_request_body: any) => {
              this.b3_back_action(action, back_pop_request_body);
            }}
            next6={this.next6}
            next1={this.reduce_floor}
            prevData={this.state.floor_req}
            floor_name={this.state.floor_name}
            back_pop_req_body={this.state.back_pop_request_body}
          />
        );
      case 6:
        return (
          <Floor
            building_name={this.state.building_name}
            changeMapCenter={() => {
              this.changeMapCenter();
            }}
            set_subcard={(value: any) => {
              this.setState({ subcard: value });
            }}
            next4={this.next4}
            next2={this.next2}
            back={this.next1}
            next3={this.next3}
            search={this.search}
            addAP={this.addAP}
            addDAS={this.addDAS}
            addCPE={this.addCPE}
            measure={this.measure}
            floor_no={(no: number) => {
              this.set_floor_no(no);
            }}
            calculate={this.calculate}
            select_id={this.state.buildid}
            building_delete_confirm={this.building_delete_confirm}
            selected_building={(data: any) => {
              this.selected_building(data);
            }}
            build_action={(action: any) => {
              this.building_action(action);
            }}
            setCurrentPosition={() => this.curren_geo_position()}
            enableAssetDrop={(st: any) => {
              this.enableAssetDrop(st);
            }}
            setAddress={(adrs: any, locmark: any) => this.setAddress(adrs, locmark)}
            updateSearchText={(value: any) => this.updateSearchText(value)}
            listAssets={(status: any, floorId: any) => {
              this.listAssets(status, floorId);
            }}
            select_floor_id={this.state.selected_floor_id}
            floor_id={(id: number) => {
              this.set_selected_floor_id(id);
            }}
            floorplan_layer={(e: any) => {
              this.floorplan_layer(e);
            }}
            latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => {
              this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset);
            }}
          />
        );
      case 7:
        if (isBrowser)
          return (
            <IndoorAP
              ref={this.apdetails}
              calculate={this.calculate}
              back={this.next6}
              asset_delete_confirm={this.asset_delete_confirm}
              asset_details={this.state.asset_details}
              editAsset={(details: any) => {
                this.editAsset(details);
              }}
              refreshAsset={(id: any) => this.refreshAsset(id)}
            />
          );
        if (isMobile)
          return (
            <APdetails
              vanishInfoPopup={() => {}}
              zoomPlus={() => {}}
              zoomMinus={() => {}}
              changeMapToSatellite={() => {}}
              resetToOutdoor={() => {}}
              azimuthClickedGoBack={(lat: any, lng: any) => {}}
              next6={this.next6}
              placeMarker={() => {
                this.setState({ isAssetDropEnabled: true });
              }}
              removeMarker={() => {
                this.setState({ isAssetDropEnabled: false });
              }}
              back={() => this.dash1()}
              set_subcard={(value: any) => {
                this.setState({ subcard: value });
              }}
              setCurrentPosition={() => this.curren_geo_position()}
              addAP={this.addAP}
              addDAS={this.addDAS}
              addCPE={this.addCPE}
              measure={this.measure}
              enableAssetDrop={(st: any) => {
                this.enableAssetDrop(st);
              }}
              calculate={this.calculate}
              set_values={(lat: any, long: any, radc: any) => {
                this.set_calculate(lat, long, radc);
              }}
              ref={this.childAssetDetails}
              changeMapCenter={() => {
                this.changeMapCenter();
              }}
              updateSearchText={(value: any) => this.updateSearchText(value)}
              setAddress={(address: any, locmark: any) => {
                this.setAddress(address, locmark);
              }}
              refreshAsset={(id: any) => this.refreshAsset(id)}
              latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => {
                this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset);
              }}
            />
          );
      case 8:
        if (isBrowser)
          return (
            <AddAP
              ref={this.childAP}
              typeId={this.state.typeId}
              latlong={this.state.para}
              assetAction={this.state.assetAction}
              assetId={2}
              properties={this.state.properties}
              parentState={this.state}
              editSerial={this.state.editSerial}
              back={this.next6}
              name={this.state.username}
              buildid={this.state.buildid}
              listAssets={(status: any, floorId: any) => {
                this.listAssets(status, floorId);
              }}
              setSector={(e: any) => {
                this.setSectorIcon(e);
              }}
              select_floor_id={this.state.selected_floor_id}
              get_asset_details={(selectAssetId: any) => this.get_asset_details(selectAssetId)}
            />
          );
        if (isMobile)
          return (
            <div
              className="fixed-top"
              id="overlay"
              style={{
                height: "100%",
                display: this.state.show_ap_overlay === true ? "block" : "none",
              }}
            >
              <AddAP
                hideOverlay={this.hideAPOverlay}
                ref={this.childAP}
                typeId={this.state.typeId}
                latlong={this.state.para}
                assetAction={this.state.assetAction}
                assetId={2}
                properties={this.state.properties}
                parentState={this.state}
                back={this.next6}
                name={this.state.username}
                buildid={this.state.buildid}
                listAssets={(status: any, floorId: any) => {
                  this.listAssets(status, floorId);
                }}
                setSector={(e: any) => {
                  this.setSectorIcon(e);
                }}
                select_floor_id={this.state.selected_floor_id}
                changeMapCenter={() => {
                  this.changeMapCenter();
                }}
              />
            </div>
          );
      // return (
      //     <Redirect from='/map' to='/addAP/assetType/3' />
      // );
      case 9:
        if (isMobile) return <Redirect from="/map" to="/search" />;
      case 10:
        if (isBrowser)
          return (
            <AddCPE
              selectedAssetId={this.state.selectedAssetId}
              listAssets={(status: any, buildingId: any) => {
                this.listAssets(status, buildingId);
              }}
              select_floor_id={this.state.selected_floor_id}
              assetAction={this.state.assetAction}
              typeId={this.state.typeId}
              buildid={this.state.buildid}
              ref={this.childCPE}
              latlong={this.state.para}
              back={this.next6}
              properties={this.state.properties}
              editSerial={this.state.editSerial}
              next_step={this.dummy}
              set_cp2_property={this.dummy}
              set_cp3_property={this.dummy}
              set_asset_property={this.dummy}
            />
          );
        if (isMobile)
          return (
            <div
              className="fixed-top"
              id="overlay"
              style={{
                height: "100%",
                display: this.state.show_cpe_overlay === true ? "block" : "none",
              }}
            >
              <AddCPE
                hideOverlay={this.hideCPEOverlay}
                selectedAssetId={this.state.selectedAssetId}
                listAssets={(status: any, buildingId: any) => {
                  this.listAssets(status, buildingId);
                }}
                select_floor_id={this.state.selected_floor_id}
                assetAction={this.state.assetAction}
                typeId={this.state.typeId}
                buildid={this.state.buildid}
                ref={this.childCPE}
                latlong={this.state.para}
                back={this.next6}
                properties={this.state.properties}
                next_step={this.dummy}
                set_cp2_property={this.dummy}
                set_cp3_property={this.dummy}
                set_asset_property={this.dummy}
              />
            </div>
          );

      case 11:
        return (
          <Measure
            back={this.next6}
            setCurrentPosition={() => this.curren_geo_position()}
            distance={this.state.distance}
            temp={this.state.temp}
          />
        );
      case 12:
        return (
          // <Calculate back={this.next6}  />
          <Calculate
            back={this.next6}
            setCurrentPosition={() => this.curren_geo_position()}
            place={this.onMapClick}
            markers={this.state.marks}
            latitude={this.state.lat}
            longitude={this.state.lng}
            changeProps={(target: any, value: any) => {
              this.setState({ [target]: value });
            }}
          />
        );
      // case 13:
      //     return(
      //         <BuildingList next3={this.next3} send_id={(val: any) => { this.get_bld_id(val) }} build_action={(action: any) => { this.building_action(action) }} calculate={this.calculate}/>
      //     )
      case 14:
        return (
          <IndoorSearch
            back={this.back14}
            setAddress={(address: any, locmark: any) => {
              this.setAddress(address, locmark);
            }}
            latlong_apcpe={(lat: number, lng: number, ap_cpe_locamrk: any, search_asset: any) => {
              this.latlong_apcpe(lat, lng, ap_cpe_locamrk, search_asset);
            }}
            send_id={(val: any) => {
              this.get_bld_id(val);
            }}
            focusBuilding={(lat: any, lng: any) => this.focusBuilding(lat, lng)}
            building_name={(name: any) => {
              this.building_name(name);
            }}
            next6={this.next6}
          />
        );
    }
  }
  floorplan_layer = (val: any) => {
    this._map.data.forEach((feature: any) => {
      this._map.data.remove(feature);
    });
    this.setState(
      {
        new: val,
      },
      () => {
        this.handleFloorPlanMapLoad(this._map, "floor");
      }
    );
  };
  handleFloorPlanMapLoad = (map: any, action: any) => {
    this._map = map;
    map.data.addGeoJson(this.state.new);
    map.data.setStyle({
      clickable: false,
      strokeWeight: 1,
    });
  };
  handleMapLoad = (map: any) => {
    this._map = map;
    map.data.addGeoJson(this.state.new);
    map.data.setStyle({
      clickable: false,
    });

    // if ([undefined, null, 'undefined', 'null'].includes(this.state.new) || [undefined, null, 'undefined', 'null'].includes(this.state.new)) {
    //     // console.log(this.state.new)

    // }
    // else {
    //     // console.log(this.state.new['features'][0]['geometry']['coordinates'][0][0][0][1])
    //     var floor_lat = this.state.new['features'][0]['geometry']['coordinates'][0][0][0][0]
    //     var floor_lon = this.state.new['features'][0]['geometry']['coordinates'][0][0][0][1]
    //     // this.props.floorMapCoordinates({ "latitude": parseFloat(floor_lat), "longitude": parseFloat(floor_lon) })
    // }
  };
  focusBuilding = (lat: any, lng: any) => {
    try {
      // console.log("setting centers");
      this._map.setCenter({ lat: lat, lng: lng });
      this._map.setZoom(18);
      localStorage.setItem("indoor_map_center_lat", lat);
      localStorage.setItem("indoor_map_center_lng", lng);
    } catch {}
  };
  polyline_options = {
    icons: [
      {
        icon: lineSymbol,
        offset: "0",
        repeat: "20px",
      },
    ],
    strokeColor: "#FF9445",
    strokeOpacity: 0,
    strokeWeight: 2,
    fillColor: "#FF9445",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: true,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };
  render() {
    return (
      <div>
        <div className="container-fluid dash p-0">
          <div className="row">
            <div className="col-md-12">
              {this.state.center != null && this.state.center != undefined ? (
                <GoogleMap
                  id="searchbox"
                  mapContainerStyle={
                    isMobile && this.state.card !== 11
                      ? { height: "calc(71vh - 58px)" }
                      : { height: "calc(100vh - 58px)" }
                  }
                  zoom={13}
                  center={this.state.center}
                  onClick={this.handleClick}
                  options={
                    isBrowser
                      ? {
                          zoomControl: true,
                          disableDefaultUI: true,
                          mapTypeControl: true,
                          mapTypeControlOptions: {
                            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: google.maps.ControlPosition.TOP_RIGHT,
                          },
                          styles: [
                            {
                              featureType: "poi",
                              stylers: [{ visibility: "off" }],
                            },
                          ],
                        }
                      : {
                          disableDefaultUI: true,
                          styles: [
                            {
                              featureType: "poi",
                              stylers: [{ visibility: "off" }],
                            },
                          ],
                        }
                  }
                  onZoomChanged={this.get_zoom}
                  onDragEnd={this.onMarkerDrag}
                  onLoad={this.handleMapLoad}
                >
                  <div>{this.renderSwitch()}</div>

                  {this.state.assetCardShow &&
                    this.state.assetList.map((asset: any, index: any) => (
                      <Marker
                        label={asset.labelText}
                        onMouseOut={this.onMouseOut}
                        onMouseOver={(e: any) => this.onHover(e, asset)}
                        position={asset.coOrdinates}
                        key={index}
                        icon={asset.markerIcon}
                        draggable={false}
                        onClick={(e: any) => {
                          this.state.card == 11 ? this.measure_calc(e) : this.assetDetail(e, asset, "Info");
                        }}
                      />
                    ))}
                  {!this.state.assetCardShow &&
                    this.state.assetList.map((asset: any, index: any) => (
                      <Marker
                        onMouseOut={this.onMouseOut}
                        onMouseOver={(e: any) => this.onHover(e, asset)}
                        position={asset.coOrdinates}
                        key={index}
                        icon={asset.markerIcon}
                        draggable={false}
                        onClick={(e: any) => {
                          this.state.card == 11 ? this.measure_calc(e) : this.assetDetail(e, asset, "Info");
                        }}
                      />
                    ))}
                  {this.state.marks.map((mark: any, index: any) => (
                    <Marker
                      position={mark}
                      key={index}
                      icon={markerIcon}
                      onDragEnd={this.onMarkerDragEnd}
                      draggable={true}
                    />
                  ))}
                  {/* <div className='text-center' onClick={this.next7}>
                                    <i className="fa fa-circle cpeicon" style={{ color: '#E22B2B', padding: '10px 6px' }}></i>
                                </div> */}

                  {/* < Marker position={{ lat: this.state.gps_lat, lng: this.state.gps_lng }} icon={gps_icon} />
                                <Circle center={{ lat: this.state.gps_lat, lng: this.state.gps_lng }} options={circleOptions} visible={this.state.accuracy_visible} radius={this.state.gps_accuracy} /> */}
                  {this.state.isAssetDropEnabled &&
                    this.state.assetPointDropList.map((mark: any, index: any) => (
                      <InfoWindow position={mark}>
                        <div
                          style={{
                            margin: "0px 20px 10px 0px",
                            fontWeight: "bold",
                          }}
                        >
                          <span>ASSET DROP</span>
                        </div>
                      </InfoWindow>
                    ))}
                  {this.state.onHover &&
                    this.state.assethoverLatLongList.map((mark: any, index: any) => (
                      <InfoWindow position={mark}>
                        <div>
                          <div>
                            <span className="font14 fontw500 p-r-30" style={{ color: "#FF9445" }}>
                              {this.state.assetIdentifierId}{" "}
                            </span>
                            <i className="material-icons infowindowclose">clear</i>
                          </div>
                          <div className="font14 fontw400 m-t-5">
                            <span>
                              Status: <span style={{ color: "#FF9445" }}>{this.state.assetStatus} </span>{" "}
                            </span>
                          </div>
                          <div className="font14 fontw400 m-t-5">
                            <span>
                              Height: <span style={{ color: "#FF9445" }}>{this.state.assetHeight} ft</span>{" "}
                            </span>
                          </div>
                          {this.state.sasStatus != "" ? (
                            <div className="font14 fontw400 m-t-5">
                              <span>
                                SAS Status: <span style={{ color: "#FF9445" }}>{this.state.sasStatus} </span>{" "}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </InfoWindow>
                    ))}
                  {this.state.isAssetClickEnabled &&
                    this.state.assetPointclickList.map((mark: any, index: any) => (
                      <InfoWindow position={mark}>
                        <div>
                          <div>
                            <span className="font14 fontw500 p-r-30" style={{ color: "#FF9445", fontWeight: "bold" }}>
                              {this.state.assetIdentifierId}{" "}
                            </span>
                            <i className="material-icons infowindowclose">clear</i>
                          </div>
                          <div className="font14 fontw400 m-t-5">
                            <span>
                              Status: <span style={{ color: "#FF9445" }}>{this.state.assetStatus} </span>{" "}
                            </span>
                          </div>
                          <div className="font14 fontw400 m-t-5">
                            <span>
                              Height: <span style={{ color: "#FF9445" }}>{this.state.assetHeight} ft</span>{" "}
                            </span>
                          </div>
                          {this.state.sasStatus != "" ? (
                            <div className="font14 fontw400 m-t-5">
                              <span>
                                SAS Status: <span style={{ color: "#FF9445" }}>{this.state.sasStatus} </span>{" "}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </InfoWindow>
                    ))}
                  {this.state.locmark === true ? (
                    <Marker position={this.state.center} onClick={this.handleClick} />
                  ) : (
                    ""
                  )}
                  {this.state.ap_cpe_locamrk === true ? (
                    <Marker
                      position={this.state.center}
                      onClick={(e: any) => {
                        this.state.card === 11
                          ? this.measure_calc(e)
                          : this.assetDetail(e, this.state.search_asset, "Info");
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {this.state.temp == 2 ? (
                    <Polyline
                      options={this.polyline_options}
                      path={[
                        {
                          lat: this.state.param1[0],
                          lng: this.state.param1[1],
                        },
                        {
                          lat: this.state.param2[0],
                          lng: this.state.param2[1],
                        },
                      ]}
                    />
                  ) : null}
                  {this.inBetween ? (
                    <InfoWindow position={this.inBetween}>
                      <div style={{}}>
                        <div>
                          <span>
                            Distance: <span style={{ color: "#FF9445" }}>{this.state.distance} m</span>{" "}
                          </span>
                        </div>
                      </div>
                    </InfoWindow>
                  ) : null}
                </GoogleMap>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          {/* <IonModal
                        isOpen={this.state.showModal}
                        swipeToClose={true}
                        // presentingElement={this.pageRef.current}
                        onDidDismiss={this.setShowModal.bind(this,false)} >
                        <p>This is modal content</p>
                        <IonButton onClick={this.setShowModal.bind(this,false)}>Close Modal</IonButton>
                    </IonModal> */}

          {/* <IonBottomDrawerModule state="drawerState" minimumHeight="minimumHeight" dockedHeight="dockedHeight"
  shouldBounce="shouldBounce" distanceTop="distanceTop">
  <div class="drawer-content">
    Bottom Drawer Content
  </div>
</IonBottomDrawerModule> */}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Indoor));
$(document).ready(function () {
  $("#searchbox").hide();

  setTimeout(() => {
    // console.log("first resize................................")
    $("#searchbox").css("width", "99.9%");
    $("#searchbox").show();
  }, 50);
  setTimeout(() => {
    // console.log("second resize............................")
    $("#searchbox").css("width", "100%");
    $("#searchbox").show();
  }, 1000);
});
