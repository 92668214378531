import React, { Component } from 'react'
import Dropz from './DropZ';
import "./ap-parameters.css";
import Back from '../assets/img/left-arrow.svg'
import "./network-status.css"
import { IonPage, IonContent } from '@ionic/react';
import Speed from "../assets/img/speedtest.png"
import { requestAPICall } from "../service/apiHandler";
import store from '../store';

export default class NetworkStatus extends React.Component {
    state: any = {};
    props: any = {};
    reduxStore: any = store.getState()['general'];
    constructor(props: any) {
        super(props);
        this.state = {
            sectorIds: [],
            sector_id: 0,
            selectedSectorId: "",
            is_loader: false,
            alertbox: false,
            screenshotImage: "",
            tech: "",
            device_id: "",
            takenAt: "",
            pci: "",
            latitude: 0,
            longitude: 0,
            isScreenshoted: false
        }
    }
    componentDidMount() {
        this.reduxStore = store.getState()['general'];
        console.log(this.reduxStore)
        if (this.reduxStore['assetTypeId'] == 1) {
            this.setState({
                sectorIds: this.props.assetInfo.sector_id,
                sector_id: this.props.assetInfo.sector_id[0]['sector_id']
            }, () => {
                this.getNetworkStatusDetails(this.state.sector_id)
            })
        } else {
            this.getNetworkStatusDetails(0)
        }
    }

    getNetworkStatusDetails(sectorId: any) {
        this.setState({ sector_id: sectorId })
        requestAPICall("get_network_status_screenshot", { type_id: this.reduxStore['assetTypeId'], asset_id: this.reduxStore['assetId'], sector_id: sectorId }, {}).then(data => {
            if (data.responseCode == 200) {
                console.log(data);

                this.setState({
                    screenshotImage: data['data']['screenshot'],
                    tech: data['data']['deviceTechnology'],
                    device_id: data['data']['deviceId'],
                    latitude: data['data']['latitude'],
                    longitude: data['data']['longitude'],
                    takenAt: data['data']['createdAt'],
                    pci: data['data']['PCI'],
                    isScreenshoted: true
                }, () => {
                    console.log(this.state.screenshotImage);

                })

            } else {
                this.setState({ isScreenshoted: false })
            }
        })
    }

    render() {
        return (

            <div>
                <div className="webonly">
                    <div className='row m-t-10'>
                        <div className='col-md-12'>

                            <ul className="nav nav-tabs sectab font12" id="netsecTab" role="tablist">
                                {this.state.sectorIds.map((sector: any, index: any) => {
                                    var wid = (100 / this.state.sectorIds.length).toString() + "%"
                                    return <div style={{ width: wid }}>
                                        <li className="nav-item" onClick={() => this.getNetworkStatusDetails(sector.sector_id)}>
                                            <a className={`nav-link p-l-0 ${this.state.sector_id === sector["sector_id"] ? 'active' : ''} p-l-0`} style={{ padding: '0.5rem 0rem' }} id="netsec1-tab" data-toggle="tab" href="#netsec1" role="tab" aria-controls="netsec1"
                                                aria-selected="true">SEC{index + 1}</a>
                                        </li>
                                        {(this.state.sectorIds.length - 1) === index ?
                                            null :
                                            <div>
                                                <div style={{ borderRight: '1px solid #C1C8CE80', height: '8px', marginTop: '-30px' }}></div>
                                                <div style={{ borderRight: '1px solid #C1C8CE80', height: '8px', marginTop: '5px' }}></div>
                                            </div>
                                        }
                                    </div>

                                })}

                            </ul>
                        </div>
                    </div>
                    <div className="tab-content" id="secTabContent">
                        <div className="tab-pane fade show active" id="netsec1" role="tabpanel" aria-labelledby="netsec1-tab">
                            <div className="row">
                                <div className="col-md-12" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 165px)' }}>
                                    {this.state.isScreenshoted ?
                                        <div className="col-12 m-t-10">
                                            <span className='distance' style={{ fontSize: '13px' }}>Device ID :</span>
                                            <span className='distance apinfo p-l-10' >{this.state.device_id}</span>
                                        </div> : null}
                                    {this.state.isScreenshoted ?
                                        <div className="col-12 m-t-10">
                                            <span className='distance' style={{ fontSize: '13px' }}>PCI :</span>
                                            <span className='distance apinfo p-l-10' >{this.state.pci}</span>
                                        </div> : null}
                                    {this.state.isScreenshoted ?
                                        <div className="col-12 m-t-10">
                                            <span className='distance' style={{ fontSize: '13px' }}>Device Technology :</span>
                                            <span className='distance apinfo p-l-10' >{this.state.tech}</span>
                                        </div> : null}
                                    {this.state.isScreenshoted ?
                                        <div className="col-12 m-t-10">
                                            <span className='distance' style={{ fontSize: '13px' }}>Latitude :</span>
                                            <span className='distance apinfo p-l-10' >{this.state.latitude}º</span>
                                        </div> : null}
                                    {this.state.isScreenshoted ?
                                        <div className="col-12 m-t-10">
                                            <span className='distance' style={{ fontSize: '13px' }}>Longitude :</span>
                                            <span className='distance apinfo p-l-10' >{this.state.longitude}º</span>
                                        </div> : null}
                                    {this.state.isScreenshoted ?
                                        <div className="col-12 m-t-10">
                                            <span className='distance' style={{ fontSize: '13px' }}>Taken At :</span>
                                            <span className='distance apinfo p-l-10' >{this.state.takenAt}</span>
                                        </div> : null}

                                    <div className="col-12 m-t-10">
                                        <span className="apheader" style={{ textTransform: 'none' }}>Testing Screenshot</span>
                                    </div>
                                    {this.state.isScreenshoted ?
                                        <div className="col-12 p-r-0 m-t-10">
                                            <div className="card screenshotcard" style={{ height: "auto" }}>
                                                <div className="card-body text-center" style={{ padding: '10px' }}>
                                                    <img src={this.state.screenshotImage} width="120px" style={{ width: '120px' }} />
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12 p-r-0 m-t-10">
                                            <div className="card screenshotcard" style={{ height: "auto" }}>
                                                <div className="card-body text-center" style={{ padding: '10px' }}>
                                                    <span>No screenshot provided</span>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="netsec2" role="tabpanel" aria-labelledby="netsec2-tab">

                        </div>
                        <div className="tab-pane fade " id="netsec3" role="tabpanel" aria-labelledby="netsec3-tab">

                        </div>
                    </div>

                </div>

            </div>

        );
    }
}