import React, { Component } from "react";
import Back from "../assets/img/left-arrow.svg";
import Heat from "../assets/img/heaticon.png";
//import Demo from './demo.js'
import { IonTextarea } from "@ionic/react";
import Dropz from "./DropZ";
import BackIcon from "../assets/img/go-back-icon-img.png";
import geo from "../assets/img/target-icon.png";
import Dropzone from "react-dropzone";
import { requestAPICall } from "../service/apiHandler";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAlert,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonFab,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
} from "@ionic/react";
import { isMobile, isBrowser } from "react-device-detect";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import { BarcodeScanner } from "@ionic-native/barcode-scanner";
import { Camera } from "@capacitor/core";
import store from "../store";
import camicon from "../assets/img/camera-icon-img.png";

var is_submit_form = false;
export default class DASnode extends Component {
  state: any = {
    files: [],
    ap_name: "",
    disable_button: true,
    field_disable: true,
    showLoading: false,
    serial_id: "",
    properties: [],
  };
  serialFocus: any;
  props: any = {};
  dasFocus: any = [];
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);
    this.dasFocus = [];
    this.serialFocus = React.createRef();
    this.submit_parameters = this.submit_parameters.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validate_request = this.validate_request.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  componentDidMount() {
    console.log("=======");
    this.setState({
      serial_id: this.props.serial_id,
    });
    this.getProperties();
  }
  setShowLoading = (st: Boolean) => {
    this.setState({ is_loader: st });
  };
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  openScanner = async () => {
    const data = await BarcodeScanner.scan();
    this.setState({ serial_id: data.text });
    this.onChange({ target: { name: "cpe_serial_id", value: data.text } });
    this.onChange({ target: { name: "cpe_serial_id", value: data.text } });
  };

  takePicture = async () => {
    console.log("opening camera");
    var image: any = await Camera.getPhoto({
      quality: 50,
      allowEditing: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.DataUrl,
    });
    // var imageUrl = image.webPath;
    console.log(image);
    image["fileName"] = "camera_upload";
    image["uploadType"] = "capture";
    image["name"] = "IMG." + image["format"];
    this.onDrop([image]);
  };

  getProperties = () => {
    let requestBody = {};
    var typeId = 5;
    if (this.reduxStore["assetAction"] === "edit") {
      requestAPICall(
        "get_asset_editable_data",
        { asset_type: 5, asset_id: this.props.selectedAssetId },
        requestBody
      ).then((data) => {
        try {
          var name = data["properties"].filter((property: any) => property.propertyName === "cpe_name");
          // var requestList = data["properties"].filter((property: any) => property.propertyName !== "cpe_serial_id")
          if (data.responseCode == 200) {
            this.setState(
              {
                properties: data["properties"],
                asset_id: this.props.selectedAssetId,
                field_disable: false,
                cpe_name: name[0].value,
              },
              () => {
                // this.setDefaults()
              }
            );
          }
        } catch (error) {}
      });
    } else {
      var DAS_Data = localStorage.getItem("DAS_Data");
      console.log(DAS_Data);
      if (DAS_Data == null) {
        requestAPICall("list_asset_properties", { asset_type_id: typeId }, requestBody).then((data) => {
          console.log(data);
          if (data.responseCode == 200) {
            this.setState({
              properties: data["properties"],
            });
            localStorage.setItem("DAS_Data", JSON.stringify(data["properties"]));
            if (isMobile) {
              //*for adding lat lng
              // var properties = data.properties.map((property: any) => {
              //     if (property.propertyName === 'latitude') {
              //         property.value = this.reduxStore['coordinates']['latitude']
              //     } else if (property.propertyName === 'longitude') {
              //         property.value = this.reduxStore['coordinates']['longitude']
              //     }
              //     return property
              // })
              this.setState({
                properties: data["properties"],
              });
            }
          }
        });
      } else {
        let parse_data = JSON.parse(DAS_Data);
        this.setState({
          properties: parse_data,
        });
      }
    }
    console.log(this.state.properties);
  };

  onDrop = (files: any) => {
    this.setState({ files: files });
    console.log(this.state.files);
    this.state.properties.forEach((property: any) => {
      if (property.propertyName == "image") {
        property.value = this.state.files;
      }
    });
    this.validate_request();
  };

  disableButton = () => {
    console.log(this.props.latlong.length);
    if (this.props.latlong.length === 0) {
      this.setState({
        field_disable: true,
      });
    } else {
      this.setState({
        field_disable: false,
      });
    }
    console.log(this.props.latlong.length);
  };
  validate_request() {
    let check = false;
    this.state.properties.forEach((property: any) => {
      if (property.value === "") {
        check = true;
      }
    });
    if (check == false) {
      this.setState({
        disable_button: check,
      });
    }
  }

  onChange(e: any) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(this.state.properties);
    this.state.properties.forEach((property: any) => {
      if (property.propertyName == e.target.name) {
        property.value = e.target.value;
        console.log(property);
      }
      if (property.propertyName == "name" && e.target.name == "name") {
        this.setState({
          ap_name: e.target.value,
        });
      }
    });
    console.log(this.state);
    this.validate_request();
  }
  validationRequest() {
    console.log(this.state.properties);
    let check = false;
    this.state.properties.forEach((property: any, key: any) => {
      if (!check) {
        if (
          property.propertyName === "name" ||
          property.propertyName === "serial_id" ||
          property.propertyName === "access_point_id" ||
          property.propertyName == "latitude" ||
          property.propertyName == "longitude"
        ) {
          this.dasFocus[key].focus();
        }
        if (property.propertyName == "name" && property.value == "") {
          check = true;
        } else if (property.propertyName == "access_point_id" && property.value == "") {
          check = true;
        } else if (property.propertyName == "select_parent_node" && property.value == "") {
          check = true;
          this.setState({
            warnMessage: "Please enter " + property.displayName,
            alertbox: true,
          });
        } else if (property.propertyName == "serial_id" && property.value == "") {
          check = true;
        } else if (property.propertyName == "latitude" && property.value == "" && property.value == 0) {
          check = true;
        } else if (property.propertyName == "longitude" && property.value == "" && property.value == 0) {
          check = true;
        }
      }
    });
    if (!check) {
      if (this.props.assetAction === "edit" && this.state.serial_id === "") {
        check = true;
        this.serialFocus.current.focus();
      }
    }

    if (check == false) {
      is_submit_form = true;
    } else {
      is_submit_form = false;
    }
  }

  submit_parameters() {
    this.validationRequest();
    let request_list: any = [];
    let new_request_list: any = [];
    request_list = this.state.properties.filter((property: any) => property.propertyName !== "serial_id");
    if (is_submit_form) {
      new_request_list = request_list.map((property: any) => {
        // *set redux coordinates */
        if (property.propertyName === "latitude") {
          property.value = this.props.latlong[0];
        } else if (property.propertyName === "longitude") {
          property.value = this.props.latlong[1];
        }
        return property;
      });

      this.setState({
        showLoading: true,
      });
      if (this.state.files.length > 0) {
        var picReader = new FileReader();
        let filerequestBody = {};
        let fileName = this.state.files[0].name;
        let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
        picReader.onload = (e) => {
          filerequestBody = {
            fileName: fileName,
            fileExtension: fileExt,
            fileValue: picReader.result,
          };
          requestAPICall("upload_file", {}, filerequestBody).then((data) => {
            if (data["responseCode"] == 200) {
              // let asset_type = this.props.typeId
              this.state.properties.forEach((property: any) => {
                if (property.propertyName == "image") {
                  property.value = data.file_id;
                }
              });
              let requestBody = {
                assetName: this.state.ap_name, //* change to  dynamic
                assetTypeId: 5,
                serialId: this.state.serial_id,
                // "latitude": this.props.latlong[0],
                // "longitude": this.props.latlong[1],
                properties: new_request_list,
                building_id: this.reduxStore["buildingId"],
                floor_id: this.reduxStore["floorId"],
              };
              console.log(requestBody);
              requestAPICall("create_asset", { asset_type_id: 5 }, requestBody).then((data) => {
                console.log(data);
                this.setState({
                  is_loader: 2,
                  warnMessage: data.message,
                  alertbox: true,
                  showLoading: false,
                });
              });
            }
          });
        };
        picReader.readAsDataURL(this.state.files[0]);
      } else {
        let requestBody = {
          assetName: this.state.ap_name,
          serialId: this.state.serial_id,
          assetTypeId: 5,
          properties: new_request_list,
          building_id: this.props.buildid,
          floor_id: this.props.select_floor_id,
        };
        requestAPICall("create_asset", { asset_type_id: 5 }, requestBody).then((data) => {
          console.log(data);
          this.setState({
            is_loader: 2,
            warnMessage: data.message,
            alertbox: true,
            showLoading: false,
          });
        });
      }
    }
  }
  saveChanges() {
    this.validationRequest();
    let request_list: any = [];
    request_list = this.state.properties.filter((property: any) => property.propertyName !== "serial_id");
    let requestBody = {
      assetName: this.state.ap_name,
      serialId: this.state.serial_id,
      assetTypeId: this.props.typeId,
      // "latitude": this.props.latlong[0],
      // "longitude": this.props.latlong[1],
      properties: request_list,
      building_id: this.props.buildid,
      status: "Offline",
    };

    request_list.forEach((property: any) => {
      if (property.propertyName == "name") {
        this.setState({ ap_name: property.value });
      }
      // if (property.propertyName == "latitude") {
      //     requestBody['latitude'] = property.value
      // }
      // if (property.propertyName == "longitude") {
      //     requestBody['longitude'] = property.value
      // }

      if (property.propertyName == "image") {
        if (property.value === "" || this.state.files.length > 0) {
          if (is_submit_form) {
            var picReader = new FileReader();
            let filerequestBody = {};
            let fileName = this.state.files[0].name;
            let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
            picReader.onload = (e) => {
              filerequestBody = {
                fileName: fileName,
                fileExtension: fileExt,
                fileValue: picReader.result,
              };
              requestAPICall("upload_file", {}, filerequestBody).then((data) => {
                console.log(data);
                if (data["responseCode"] == 200) {
                  property.value = data.file_id;
                  this.editAssetChanges(requestBody);
                }
              });
            };
            picReader.readAsDataURL(this.state.files[0]);
          }
        }
      } else {
        if (is_submit_form) {
          this.editAssetChanges(requestBody);
        }
      }
    });
  }

  editAssetChanges = (requestBody: any) => {
    requestAPICall(
      "edit_asset_details",
      { asset_type_id: this.props.typeId, asset_id: this.props.selectedAssetId },
      requestBody
    ).then((data) => {
      this.setState({
        is_loader: false,
      });
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
        this.props.markAssetsOnMap("create-list");
      } else {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
        });
      }
    });
  };

  render() {
    let files = this.state.files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let classDName = files.length ? "dropzone-uploaded" : "dropzone";
    let classImageUpload = files.length ? "-uploaded" : "";
    return (
      <div>
        {isBrowser ? (
          <div className="webonly">
            <div className="row">
              <div className="col-md-9">
                <div className="card apcard">
                  <div className="card-body" style={{ padding: "0px" }}>
                    <div className="row">
                      <div className="col-md-3">
                        <img className="backicon" src={BackIcon} onClick={this.props.back} />
                        <button type="button" className="backbutton"></button>
                      </div>
                      <div className="col-md-6" style={{ padding: "13px 0" }}>
                        <span className="apheader">
                          {this.props.assetAction != "edit" ? "ADDING DAS NODE" : "EDIT DAS NODE"}
                        </span>
                      </div>
                    </div>
                    <div className="row" style={{ padding: "0px 35px" }}>
                      <div className="col-md-12 apborder">
                        <span className="apnote">Click where you'd like to drop the Node</span>
                        {/* <span className='calculations'> <i className="fas fa-map-pin calculateicon"></i></span> */}
                      </div>
                    </div>
                    {this.state.properties.map((property: any, key: any) => {
                      if (property.isShowable === true) {
                        if (property.dataType === "string" || property.dataType === "float") {
                          if (property.isRequired == true) {
                            return (
                              <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                                <div className="col-md-12 p-0">
                                  <div className="form-label-group m-0">
                                    <input
                                      ref={(ref) => {
                                        this.dasFocus[key] = ref;
                                        return true;
                                      }}
                                      type="text"
                                      className="accesspointname"
                                      id={property.propertyName}
                                      value={property.value}
                                      name={property.propertyName}
                                      placeholder={property.displayName}
                                      disabled={this.state.field_disable}
                                      onChange={this.onChange.bind(this)}
                                    />
                                    <label className="pages" htmlFor={property.propertyName}>
                                      {property.displayName}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        } else if (property.dataType === "textArea") {
                          return (
                            <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                              <IonTextarea
                                className="description"
                                rows={3}
                                id={property.propertyName}
                                name={property.propertyName}
                                placeholder={property.displayName}
                                disabled={this.state.field_disable}
                                onIonInput={this.onChange.bind(this)}
                              />
                            </div>
                          );
                        } else if (property.dataType === "image") {
                          return (
                            <Dropzone onDrop={this.onDrop} disabled={this.state.field_disable}>
                              {({ getRootProps, getInputProps }) => (
                                <section className="dropzone-set col-md-12 m-t-10">
                                  <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                                    {property.value !== "" ? (
                                      <span style={{ fontSize: "14px" }}> Image uploaded already</span>
                                    ) : files.length ? (
                                      <span style={{ color: "#00D56E", display: "contents" }}>
                                        {files}
                                        {/* <i className="material-icons imgclose-file" onClick={(e: any) => { e.preventDefault(); files = [] }}>clear</i> */}
                                      </span>
                                    ) : (
                                      <span style={{ fontSize: "14px" }}>+ Add Image</span>
                                    )}
                                  </div>
                                  <div></div>
                                </section>
                              )}
                            </Dropzone>
                          );
                        } else if (property.dataType == "list") {
                          return (
                            <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                              <div className="col-md-12 p-0">
                                <div className="form-label-group m-0">
                                  <select
                                    name={property.propertyName}
                                    value={property.value}
                                    className="form-control paramtext-web"
                                    disabled={this.state.field_disable}
                                    onChange={this.onChange.bind(this)}
                                    style={{ border: "0.5px solid #CED7DF" }}
                                  >
                                    <option value="" hidden>
                                      Select Parent Node
                                    </option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                  </select>
                                  {/* <input type="text" className="accesspointname" id="Parent" placeholder="Select Parent Node   " /> */}
                                  {/* <label className="pages" htmlFor="Parent">Select Parent Node </label> */}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }
                    })}

                    {this.props.assetAction === "edit" ? (
                      <div className="col-md-12 p-0 m-t-7">
                        <div className="form-label-group m-0">
                          <input
                            ref={this.serialFocus}
                            type="text"
                            style={{ height: "38px" }}
                            name="serial_id"
                            disabled={this.state.field_disable}
                            className="accesspointname"
                            id="serial"
                            placeholder="serial"
                            value={this.state.serial_id}
                            onChange={this.onChange.bind(this)}
                          />
                          <label className="pages" htmlFor="serial">
                            Serial
                          </label>
                        </div>{" "}
                      </div>
                    ) : null}

                    <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                      <button
                        className="btn savebtn"
                        onClick={this.props.assetAction != "edit" ? this.submit_parameters : this.saveChanges}
                      >
                        {this.props.assetAction != "edit" ? "Save Changes" : "Update Changes"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="col-md-12 justify-content-flex-end">
                  <div className="heaticon text-center" style={{ display: "none" }}>
                    <img className="heat" src={Heat} />
                  </div>
                </div>

                <div className="col-md-12 float-right">
                  <div className="geoicon text-center">
                    <img style={{ padding: "5px" }} src={geo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mobonly">
            <IonPage>
              <IonContent>
                <div className="param-info-mob " style={{ height: "100vh" }}>
                  <div className="col-12 text-center searchborder" style={{ padding: "10px" }}>
                    <img
                      className="backicon-mob"
                      style={{ width: "43px", paddingTop: "6px" }}
                      onClick={() => {
                        this.props.hideOverlay();
                      }}
                      src={BackIcon}
                    />
                    <span className="apheader">ADD DAS Node</span>
                  </div>
                  <div className="container-fluid apdash">
                    <div className="card apeditcard">
                      <div className="card-body" style={{ overflowY: "auto" }}>
                        {this.state.properties.map((property: any, key: any) => {
                          if (property.isShowable === true) {
                            var isRequired = "";
                            if (property.isRequired) isRequired = "*";
                            if (property.isShowable === true) {
                              if (property.dataType === "string") {
                                return (
                                  <div className="row m-t-10">
                                    <div className="col-12">
                                      <div className="form-label-group m-0">
                                        <input
                                          type="text"
                                          ref={(ref) => {
                                            this.dasFocus[key] = ref;
                                            return true;
                                          }}
                                          className="accesspointname font14"
                                          id={property.propertyName}
                                          style={{ width: "100%" }}
                                          placeholder={property.displayName}
                                          name={property.propertyName}
                                          onChange={this.onChange.bind(this)}
                                        />
                                        <label className="pages" htmlFor={property.propertyName}>
                                          {property.displayName + isRequired}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else if (property.dataType === "float" || property.dataType === "int") {
                                if (property.propertyName === "latitude") {
                                  if (this.reduxStore["assetAction"] === "edit") {
                                    return (
                                      <div className="row m-t-10">
                                        <div className="col-12">
                                          <div className="form-label-group m-0">
                                            <input
                                              type="number"
                                              ref={(ref) => {
                                                this.dasFocus[key] = ref;
                                                return true;
                                              }}
                                              className="accesspointname font14"
                                              name={property.propertyName}
                                              id={property.propertyName}
                                              style={{ width: "100%" }}
                                              placeholder={property.displayName}
                                              value={
                                                this.state.assetAction === "edit"
                                                  ? property.value
                                                  : this.reduxStore["coordinates"]["latitude"]
                                              }
                                              onChange={this.onChange.bind(this)}
                                            />
                                            <label className="pages" htmlFor={property.propertyName}>
                                              {property.displayName + isRequired}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="row m-t-10">
                                        <div className="col-12">
                                          <div className="form-label-group m-0">
                                            <input
                                              type="number"
                                              ref={(ref) => {
                                                this.dasFocus[key] = ref;
                                                return true;
                                              }}
                                              className="accesspointname font14"
                                              name={property.propertyName}
                                              id={property.propertyName}
                                              style={{ width: "100%" }}
                                              placeholder={property.displayName}
                                              value={
                                                property.value === 0
                                                  ? this.reduxStore["coordinates"]["latitude"]
                                                  : property.value
                                              }
                                              onChange={this.onChange.bind(this)}
                                            />
                                            <label className="pages" htmlFor={property.propertyName}>
                                              {property.displayName + isRequired}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                } else if (property.propertyName === "longitude") {
                                  if (this.reduxStore["assetAction"] === "edit") {
                                    return (
                                      <div className="row m-t-10">
                                        <div className="col-12">
                                          <div className="form-label-group m-0">
                                            <input
                                              type="number"
                                              ref={(ref) => {
                                                this.dasFocus[key] = ref;
                                                return true;
                                              }}
                                              className="accesspointname font14"
                                              name={property.propertyName}
                                              id={property.propertyName}
                                              style={{ width: "100%" }}
                                              placeholder={property.displayName}
                                              value={
                                                this.state.assetAction === "edit"
                                                  ? property.value
                                                  : this.reduxStore["coordinates"]["longitude"]
                                              }
                                              onChange={this.onChange.bind(this)}
                                            />
                                            <label className="pages" htmlFor={property.propertyName}>
                                              {property.displayName + isRequired}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className="row m-t-10">
                                        <div className="col-12">
                                          <div className="form-label-group m-0">
                                            <input
                                              type="number"
                                              ref={(ref) => {
                                                this.dasFocus[key] = ref;
                                                return true;
                                              }}
                                              className="accesspointname font14"
                                              name={property.propertyName}
                                              id={property.propertyName}
                                              style={{ width: "100%" }}
                                              placeholder={property.displayName}
                                              value={
                                                property.value === 0
                                                  ? this.reduxStore["coordinates"]["longitude"]
                                                  : property.value
                                              }
                                              onChange={this.onChange.bind(this)}
                                            />
                                            <label className="pages" htmlFor={property.propertyName}>
                                              {property.displayName + isRequired}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                } else {
                                  return (
                                    <div className="row m-t-5">
                                      <div className="col-12">
                                        <div className="form-label-group m-0">
                                          <input
                                            type="number"
                                            ref={(ref) => {
                                              this.dasFocus[key] = ref;
                                              return true;
                                            }}
                                            className="accesspointname font14"
                                            value={property.value}
                                            name={property.propertyName}
                                            id={property.propertyName}
                                            style={{ width: "100%" }}
                                            placeholder={property.displayName}
                                            onChange={this.onChange.bind(this)}
                                          />
                                          <label className="pages" htmlFor={property.propertyName}>
                                            {property.displayName + isRequired}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              } else if (property.dataType === "textArea") {
                                return (
                                  <div className="row m-t-10">
                                    <div className="col-12">
                                      <textarea
                                        className="description"
                                        style={{ padding: "7px" }}
                                        rows={5}
                                        placeholder="Description"
                                        name={property.propertyName}
                                        onChange={this.onChange.bind(this)}
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (property.dataType === "image") {
                                return (
                                  <div className="row m-t-10">
                                    <div className="col-10 p-r-0" style={{ fontSize: "14px" }}>
                                      <Dropzone onDrop={this.onDrop} disabled={false}>
                                        {({ getRootProps, getInputProps }) => (
                                          <section className="dropzone-set">
                                            <div {...getRootProps({ className: classDName + "-mob" })}>
                                              <input {...getInputProps()} accept="image/png,image/jpeg,image/png" />
                                              {files.length ? (
                                                <span style={{ color: "#00D56E", display: "contents" }}>{files}</span>
                                              ) : (
                                                <div>
                                                  <span style={{ fontSize: "14px" }}>+ Add Image</span>
                                                </div>
                                              )}
                                            </div>
                                          </section>
                                        )}
                                      </Dropzone>
                                    </div>
                                    <div className="col-2 p-l-0">
                                      <div className={"v-h-center image-field-action" + classImageUpload}>
                                        {files.length ? (
                                          <i
                                            className="material-icons imgclose-file"
                                            onClick={(e: any) => {
                                              e.preventDefault();
                                              files = [];
                                              this.onDrop([]);
                                            }}
                                          >
                                            clear
                                          </i>
                                        ) : (
                                          <img src={camicon} style={{ width: "21px" }} onClick={this.takePicture}></img>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          }
                        })}
                        <div className="row m-t-10 ">
                          <div className="col-12 ">
                            <button
                              className="lightbtnmob "
                              onClick={() => {
                                this.submit_parameters();
                              }}
                              disabled={false}
                            >
                              {" "}
                              Save Changes{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </IonContent>
            </IonPage>
          </div>
        )}
        <IonAlert
          isOpen={this.state.alertbox}
          onDidDismiss={() => this.setShowAlert(false)}
          message={this.state.warnMessage}
          buttons={["OK"]}
        />
        <IonLoading
          isOpen={this.state.showLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          message={"Loading..."}
          duration={10000}
        />
      </div>
    );
  }
}
