import ConfigJSON from "../config/config";
import Cookies from "js-cookie";
import { isBrowser, isMobile } from "react-device-detect";

export const requestAPICall = (api, params, body) => {
  //console.log("API CALL ================= ", api)
  var baseUrl = "";
  if (isMobile) {
    if (
      [undefined, null, "undefined", "null", ""].includes(
        localStorage.getItem("baseUrl")
      )
    ) {
      baseUrl = ConfigJSON["basic"]["base_url"];
    } else {
      baseUrl = localStorage.getItem("baseUrl");
    }
  } else {
    baseUrl = ConfigJSON["basic"]["base_url"];
  }

  var url = getParsedUrlByJson(ConfigJSON["api"][api]["url"], params);
  var headersObj = {
    "Content-type": "application/json; charset=UTF-8",
    Accept: "application/json",
  };
  if (ConfigJSON["exclude_authorization"].indexOf(api) < 0) {
    headersObj["Authorization"] = localStorage.getItem("idToken");
  }
  if (ConfigJSON["api"][api]["method"] !== "GET") {
    return fetch(baseUrl + url, {
      method: ConfigJSON["api"][api]["method"],
      headers: headersObj,
      body: JSON.stringify(body),
    })
      .then((response) => {
        //console.log(response.status);
        if (response.status === 404) {
          return { message: "Found Invalid Endpoint", responseCode: 400 };
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  } else {
    return fetch(baseUrl + url, {
      method: ConfigJSON["api"][api]["method"],
      headers: headersObj,
    })
      .then((response) => {
        if (response.status === 404) {
          return { message: "Found Invalid Endpoint", responseCode: 400 };
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }
};

function getParsedUrlByJson(str, val) {
  for (let key in val) {
    var k = "{" + key + "}";
    do {
      str = str.replace(k, val[key]);
    } while (str.includes(k));
  }
  return str;
}