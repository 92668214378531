import React, { Component } from 'react'
import Back from '../assets/img/left-arrow.svg'
import { useDropzone } from 'react-dropzone'
import Floordropz from './Floordropz';
import BackIcon from '../assets/img/go-back-icon-img.png'
import geo from '../assets/img/target-icon.png'
import align from '../assets/img/align-icon.png'
import Dropzone from 'react-dropzone';
import { requestAPICall } from "../service/apiHandler";
import { IonTextarea, IonContent, IonPage, IonAlert, IonLoading } from '@ionic/react';
import store from '../store';

// const BuildingInfo3: React.FC = () => {
var is_submit_form = false
export default class BuildingInfo3 extends Component {
    state: any = {};
    props: any = {};
    floorPlanFile: any
    dpzone: any;
    reduxStore: any = store.getState()['general']
    constructor(props: any) {
        super(props)
        this.floorPlanFile = React.createRef();
        this.dpzone = React.createRef();
        this.state = {
            floor_property: [],
            files: [],
            number_of_floors: "",
            warnMessage: "",
            floorPlanFileImage: "",
            addFloors: 0
        }
        this.create_floor = this.create_floor.bind(this)
        this.save_changes = this.save_changes.bind(this)
        this.goback = this.goback.bind(this)
        this.gobackFromEdit = this.gobackFromEdit.bind(this)
        this.browseClick = this.browseClick.bind(this)

    }
    componentDidMount() {
        console.log(this.reduxStore)
        console.log(this.props.action)
        console.log(this.props.prevData)
        console.log(this.props.floor_no)
        this.setState({
            floor_property: this.props.prevData,
            number_of_floors: this.props.prevData["number_of_floors"],
            addFloors: this.props.floor_no,
            floorPlanFileImage: this.props.prevData['floorPlanFileImage'],
            updatedData: this.props.prevData

        })
        console.log(this.state.addFloors)
        this.setDeafult(this.props.action)
    }
    setDeafult = (action: any) => {
        // if (action == "EditFloor" || action == "back3FromEdit") {
        if (action == "back3FromEdit") {
            console.log("Edit mode")
            console.log(this.props.back_pop_req_body)
            console.log(this.props.back_pop_req_body['floorPlanFileImage'])
            this.setState({
                floorPlanFileImage: this.props.back_pop_req_body['floorPlanFileImage'],
                updatedData: this.props.back_pop_req_body
            })
        }
    }
    browseClick = (e: any) => {
        $('#dropzone').click();
    }
    onDrop = (files: any) => {
        this.setState({ files: files })
    };
    next4 = () => {
        this.props.next4()
    }
    setShowLoading = (st: boolean) => {
        this.setState({ is_loader: st })
    }
    validateRequest() {
        if (this.state.files.length == 0) {
            is_submit_form = false
            // this.floorPlanFile.current.focus()
            this.setState({
                warnMessage: "Please upload Floor plan file",
                alertbox: true
            })
        } else {
            is_submit_form = true
        }
    }
    setShowAlert = (st: any) => {
        this.setState({ alertbox: st })
    }
    create_floor = (action: any) => {
        this.validateRequest()
        console.log(is_submit_form, action)

        var picReader = new FileReader();
        let filerequestBody = {}
        if (is_submit_form) {
            this.setState({
                is_loader: true
            })
            $('#btna').attr('disabled', 'true')
            $('#btnb').attr('disabled', 'true')
            $('#btnc').attr('disabled', 'true')
            let fileName = this.state.files[0].name
            let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
            picReader.onload = (e) => {
                filerequestBody = {
                    'fileName': fileName,
                    'fileExtension': fileExt,
                    'fileValue': picReader.result

                }
                requestAPICall("upload_file", {}, filerequestBody).then(data => {
                    let building_id
                    if (data['responseCode'] == 200) {
                        if (this.props.action == "AddFloor" || this.props.action == "AddFloorBack") {
                            building_id = this.props.select_id
                        } else {
                            building_id = this.props.prevData.building_id
                        }
                        let requestBody = this.state.floor_property
                        requestBody["floor_plan_file_id"] = data.file_id
                        requestAPICall("create_floor", { buildingId: building_id }, requestBody).then(data => {
                            this.setState({
                                is_loader: false
                            })
                            if (data.responseCode == "200") {
                                if (action == "complete") {

                                    this.props.b3_back_action("complete", "")
                                    // this.next1()

                                } else {
                                    this.props.b3_back_action("next", "")
                                    this.reduce_on_floor()

                                }

                                this.setState({
                                    warnMessage: data.message,
                                    alertbox: true
                                })
                            }
                        })
                    }
                })
            }
            picReader.readAsDataURL(this.state.files[0])
        }
    }
    reduce_on_floor() {

        if (this.props.action == "AddFloor") {
            this.props.next1(0)

        } else {
            this.props.next1()
        }
    }
    save_changes() {
        let requestBody: any = {}
        console.log(this.state.floor_property)
        requestBody["properties"] = this.state.floor_property['properties'][0]

        if (this.state.files.length > 0) {
            var picReader = new FileReader();
            let filerequestBody = {}
            let fileName = this.state.files[0].name
            let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1]
            picReader.onload = (e) => {
                filerequestBody = {
                    'fileName': fileName,
                    'fileExtension': fileExt,
                    'fileValue': picReader.result

                }
                requestAPICall("upload_file", {}, filerequestBody).then(data => {
                    if (data['responseCode'] == 200) {
                        requestBody["floor_plan_file_id"] = data.file_id
                        this.editFloorChanges(requestBody)
                    }
                })
            }
            picReader.readAsDataURL(this.state.files[0])
        } else {
            this.editFloorChanges(requestBody)
        }

    }
    editFloorChanges = (requestBody: any) => {
        console.log(requestBody)
        this.setState({
            is_loader: true
        })
        requestAPICall("update_floor", { building_id: this.reduxStore['buildingId'], "floor_id": this.reduxStore['floorId'] }, requestBody).then(data => {
            if (data.responseCode == "200") {

                this.setState({
                    is_loader: false,
                    warnMessage: data.message,
                    alertbox: true
                })
                this.props.next6()
            }
        })
    }
    goback() {
        console.log(this.props.action)
        console.log(this.props.prevData)
        if (this.props.action == "AddFloor" || this.props.action == "AddFloorBack") {
            this.props.b3_back_action("AddFloorBack", this.props.prevData)
        }
        else {
            this.props.b3_back_action("back3", this.props.prevData)
        }

        // this.props.next4()
    }
    gobackFromEdit() {
        this.props.b3_back_action("back3FromEdit", this.state.updatedData)
    }
    render() {
        let files = this.state.files.map((file: any) => (
            <span className="ellipsistooltip90" key={file.name}>
                {file.name}
            </span>
        ));
        let classDName = files.length ? 'dropzone-setheight-uploaded' : 'dropzone-setheight'
        return (<div>
            <div className='row'>
                <div className='col-md-9'>
                    <div className="card apcard">
                        <div className="card-body" style={{ padding: '0px' }}>

                            <div className='row'>
                                <div className='col-md-3'>
                                    {this.props.action == "Edit" || this.props.action == "EditFloor" || this.props.action == 'back3FromEdit' ?
                                        <img className='backicon' src={BackIcon} onClick={this.gobackFromEdit} /> : null
                                    }
                                    {this.props.action == "Add" || this.props.action == "AddFloor" || this.props.action == "AddFloorBack" || this.props.action == "back3" || this.props.action == "back" || this.props.action == "next" ?
                                        <img className='backicon' src={BackIcon} onClick={this.goback} /> : null
                                    }

                                    <button type='button' className='backbutton' ></button>
                                    {/* <img className='backicon' src={Back} onClick={this.props.back}/>
                                <button type='button' className='backbutton'></button> */}
                                </div>
                                <div className='col-md-8' style={{ padding: "13px 0" }}>
                                    {/* <span className='apheader m-r-m'>NEW BUILDING INFO</span> */}
                                    {this.props.action == "Add" || this.props.action == "AddFloor" || this.props.action == "AddFloorBack" || this.props.action == "back3" || this.props.action == "back" || this.props.action == "next" ?
                                        <span className='apheader m-r-m'>NEW FLOOR INFO</span> : null
                                    }
                                    {this.props.action == "Edit" || this.props.action == "EditFloor" || this.props.action == 'back3FromEdit' ?
                                        <span className='apheader m-r-m'>EDIT FLOOR INFO</span> : null
                                    }
                                    {/* {this.props.action == "AddFloor" ?
                                        <span className='apheader m-r-m'>ADD FLOOR</span> : null
                                    } */}
                                    <span className='apnote float-right' style={{ fontSize: '12px', margin: '5px' }} >3/3</span>
                                </div>
                            </div>

                            <div className="row m-t-10" style={{ padding: "0px 35px" }}>
                                <span className="apnote" style={{ fontSize: '14px' }}>Add zip files for the floor <span style={{ fontWeight: 'bold' }}>{this.props.floor_name}</span></span>
                            </div>
                            <div className="row m-t-10" style={{ padding: "0px 35px", height: '248px' }}>
                                <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className='dropzone-set'>
                                            <div id="dropzone" {...getRootProps({ className: classDName })}>

                                                <input ref={input => this.floorPlanFile = input} {...getInputProps()} accept='.geojson' />
                                                {typeof (this.state.floorPlanFileImage) !== undefined && this.state.floorPlanFileImage !== undefined && this.state.floorPlanFileImage !== "" && files.length == 0 ? <span style={{ fontSize: '14px' }}> Floor plan file uploaded Already</span>
                                                    : files.length ?
                                                        <div>
                                                            <div className="m-t-50">
                                                                <span ref={input => this.floorPlanFile = input} style={{ color: "#00D56E", display: 'flex' }}>

                                                                    {files}

                                                                    {/* <i className="material-icons imgclose-file" style={{ position: 'absolute', right: '25px' }} onClick={(e: any) => { e.preventDefault(); files = [] }}>clear</i> */}

                                                                </span>
                                                            </div>
                                                            {files.length > 0 ?
                                                                <div className="m-t-25">
                                                                    <span><a style={{ color: "#8F9FAC", fontSize: "14px" }}>Change File</a></span>
                                                                </div> : null}
                                                        </div>
                                                        :
                                                        <div>
                                                            <div style={{ textAlign: "center" }}>
                                                                <i className="fas fa-plus-circle text-center" style={{ padding: '10px', fontSize: '40px' }}></i>
                                                            </div>
                                                            <div style={{ textAlign: "center" }}>
                                                                <span className='text-center' style={{ fontSize: '14px' }}>Drag and Drop files</span>
                                                            </div>
                                                        </div>
                                                    // <span style={{ fontSize: '14px' }}>Drag and drop files here</span>
                                                }
                                            </div>
                                            <div>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                                {files.length === 0 ?
                                    <span className="font14 cursor" style={{ position: 'absolute', paddingBottom: '10px', alignSelf: 'flex-end', color: '#0059b2', left: '40%' }} onClick={(e: any) => this.browseClick(e)} >Browse</span> : null}
                            </div>
                            <div className="row m-t-10" style={{ padding: "0px 35px" }}>

                                {/* {(this.props.action === "Add" || this.props.action === "back3" || this.props.action === "back" || this.props.action === "next") && this.props.floor_no > 1 ? */}
                                {(this.props.action === "Add" || this.props.action === "back3" || this.props.action === "back" || this.props.action === "next") ?
                                    < button id='btna' className="btn savebtn" onClick={() => this.create_floor("next")} >Add Next Floor</button> : null
                                }
                                {this.props.action === "Add" || this.props.action === "back3" || this.props.action == "back" || this.props.action == "next" ?
                                    <button id='btnb' className="btn btn-secondary-c3 savebtn" style={{ bottom: '70px' }} onClick={() => this.create_floor("complete")} >Complete Floor Setup</button> : null
                                }
                                {this.props.action === "AddFloor" || this.props.action == "AddFloorBack" ?
                                    <button id='btnc' className="btn savebtn" onClick={() => this.create_floor("complete")}>Complete Floor Setup</button> : null}

                                {this.props.action === "EditFloor" || this.props.action === "Edit" || this.props.action == 'back3FromEdit' ?
                                    <button className="btn savebtn" onClick={this.save_changes}>Save Changes</button> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3' >
                    <div className="col-md-12 float-right">
                        <div className="geoicon text-center">
                            <img style={{ padding: '5px' }} src={geo} />
                        </div>
                    </div>
                    <div className="col-md-12 float-right">
                        <div className="geoicon text-center">
                            <img style={{ padding: '6.5px' }} onClick={this.props.calculate} src={align} />
                        </div>
                    </div>
                </div>
            </div>
            <IonAlert
                isOpen={this.state.alertbox}
                onDidDismiss={() => this.setShowAlert(false)}
                message={this.state.warnMessage}
                buttons={['CLOSE']}
            />


            <IonLoading
                isOpen={this.state.is_loader}
                onDidDismiss={() => this.setShowLoading(false)}
                message={'Loading...'}
            // duration={5000}
            />

        </div >
        );
    }
}
// export default BuildingInfo3;