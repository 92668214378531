import React, { Component } from "react";
import sort from "../assets/img/checked.png";
import { requestAPICall } from "../service/apiHandler";
import Dropzone from "react-dropzone";
import Modal from "./modal";
import { saveAs } from "file-saver";
import { IonTextarea, IonContent, IonPage, IonAlert, IonLoading } from "@ionic/react";
const INITIAL_STATE = {
  card: "",
  show: false,
  status_show: false,
  select_coverage: 0,
};
var status_list: any = [];
var selected_coverage_list: any = [];
export default class Coverage extends Component {
  props: any = {};
  state: any = {};
  constructor(props: any) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.state = {
      files: [],
      technology_name: "",
      band_name: "",
      layer_name: "",
      heatmap_list: [],
      selectAll: false,
      alertbox: false,
      warnMessage: "",
      selected_coverage_id: 0,
    };
    this.import_files = this.import_files.bind(this);
    this.update_heatmap = this.update_heatmap.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  showModal = (e: any) => {
    e.preventDefault();
    this.setState({
      card: e.target.id,
      show: true,
      technology_name: "",
      band_name: "",
      layer_name: "",
    });
  };
  componentWillMount() {
    this.list_heatmap();
  }
  hideModal = () => {
    this.setState({
      card: "",
      files: [],
      show: false,
    });
  };
  status_hideModal = () => {
    this.setState({
      status_show: false,
    });
    // console.log(this.state.status_show , 'ssssssssssss')
  };
  onDrop = (files: any) => {
    this.setState({ files: files });
  };
  hideModal1 = () => {
    this.setState({
      card1: false,
    });
  };
  import_files() {
    var picReader = new FileReader();
    let filerequestBody = {};
    let fileName = this.state.files[0].name;
    let fileExt = fileName.split(/\.(?=[^\.]+$)/)[1];
    console.log(fileExt, "fileeeeeeeeeeeee");
    picReader.onload = (e) => {
      filerequestBody = {
        fileName: fileName,
        fileExtension: fileExt,
        fileValue: picReader.result,
        type: "heatmap",
      };
      this.setState({
        isLoading: true,
      });
      requestAPICall("upload_file", {}, filerequestBody).then((data) => {
        let requestBody = {
          file_path_id: data.file_id,
          technology_name: this.state.technology_name,
          band_name: this.state.band_name,
          layer_name: this.state.layer_name,
        };
        requestAPICall("import_coverage_layers", {}, requestBody).then((data) => {
          console.log(data);
          this.setState(
            {
              isLoading: false,
            },
            () => {
              this.setState({
                show: false,
                warnMessage: data.message,
                alertbox: true,
              });
              this.list_heatmap();
            }
          );
        });
      });
    };
    picReader.readAsDataURL(this.state.files[0]);
    this.hideModal();
  }
  download_coverage_layer = () => {
    let requestBody = {
      json_file: "coverage_layers.zip",
    };

    requestAPICall("download_zip_files", {}, requestBody).then((data) => {
      console.log(data["file_content"]);
      data["file_content"] = "suganthi";
      var exportedFilenmae = "coverage_layers.ghx";
      var blob = new Blob([data["file_content"]]);
      saveAs(blob, exportedFilenmae);
    });
  };
  onChange(e: any, type: any) {
    console.log(e.target.name, e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.state.heatmap_list.map((heatmap: any) => {
      if (heatmap.heatmapId == parseInt(e.target.name)) {
        if (type === "technologyName") {
          this.setState({
            technology_name: e.target.value,
          });
          heatmap.technologyName = e.target.value;
        } else if (type === "bandName") {
          this.setState({
            band_name: e.target.value,
          });
          heatmap.bandName = e.target.value;
        } else if (type === "layerName") {
          this.setState({
            layer_name: e.target.value,
          });
          heatmap.layerName = e.target.value;
        }
      }
    });
  }
  list_heatmap() {
    requestAPICall("list_coverage_layers", {}, {}).then((data) => {
      console.log(data);
      this.setState({
        heatmap_list: data.heatmapList,
      });
    });
    console.log(this.state.heatmap_list);
  }
  update_heatmap = (id: number) => {
    console.log(id);

    let requestBody = {
      technologyName: this.state.technology_name,
      bandName: this.state.band_name,
      layerName: this.state.layer_name,
    };
    requestAPICall("update_coverage_layers", { heatmap_id: id }, requestBody).then((data) => {
      console.log(data);
      this.setState({
        showButton: false,
      });
      this.list_heatmap();
    });
  };
  showButton(heatmapId: number) {
    this.setState({
      showButton: true,
      select_coverage: heatmapId,
    });
  }
  setShowAlert = (st: any) => {
    this.setState({ alertbox: st });
  };

  coverage_layers_checked = (e: any) => {
    let floor_index = selected_coverage_list.indexOf(e.target.id);
    if (e.target.checked == true) {
      selected_coverage_list.push(parseInt(e.target.id));
    } else {
      if (floor_index <= 0) {
        selected_coverage_list.splice(floor_index, 1);
      }
    }
    console.log(selected_coverage_list, "ssssssssssssssssssssss");
  };
  selectAllCheckbox = (e: any) => {
    if (e.target.checked == true) {
      this.state.heatmap_list.forEach((heatmap: any) => {
        selected_coverage_list.push(heatmap.heatmapId);
      });
      this.setState({
        selectAll: true,
      });
    } else {
      this.setState({
        selectAll: false,
      });
      selected_coverage_list = [];
    }
    console.log(selected_coverage_list, "hhhhhhhhhhhhhhhh");
  };
  delete_coverage_layer = () => {
    let requestBody = {
      heatmap_id: this.state.selected_coverage_id,
    };
    requestAPICall("delete_coverage_layers", {}, requestBody).then((data) => {
      console.log(data);
      if (data.responseCode == 200) {
        this.setState({
          warnMessage: data.message,
          alertbox: true,
          showButton: false,
          card1: false,
        });
        this.list_heatmap();
      } else {
        this.setState({
          warnMessage: "Error while delete Coverage layers",
          alertbox: true,
          card1: false,
        });
      }
    });
  };

  sortByValueAsc = (key: any) => {
    let sortedProductsAsc;
    sortedProductsAsc = this.state.heatmap_list.sort((a: any, b: any) => {
      return parseInt(a.key) - parseInt(b.key);
    });
    let asset_list = this.asecnding(key);
    this.setState({
      heatmap_list: sortedProductsAsc,
    });
  };
  sortByValueDesc = (key: any) => {
    let sortedProductsDesc;
    sortedProductsDesc = this.state.heatmap_list.sort((a: any, b: any) => {
      return parseInt(a.key) - parseInt(b.key);
    });
    this.setState({
      heatmap_list: sortedProductsDesc,
    });
    let list = this.decending(key);
  };

  asecnding = (key: any) => {
    return this.state.heatmap_list.sort(function (a: any, b: any) {
      if (a[key] > b[key]) {
        return 1;
      }
      if (b[key] > a[key]) {
        return -1;
      }
      return 0;
    });
  };
  decending = (key: any) => {
    return this.state.heatmap_list.sort(function (a: any, b: any) {
      if (b[key] > a[key]) {
        return 1;
      }
      if (a[key] > b[key]) {
        return -1;
      }
      return 0;
    });
  };
  showmodal1 = (id: number) => {
    this.setState({
      card1: true,
      selected_coverage_id: id,
      technology_name: "",
      band_name: "",
      layer_name: "",
    });
  };
  clear_value = (id: number) => {
    requestAPICall("list_coverage_layers", {}, {}).then((data) => {
      console.log(data);
      this.setState(
        {
          heatmap_list: data.heatmapList,
        },
        () => {
          this.setState({
            showButton: false,
          });
        }
      );
    });
  };
  setShowLoading = (st: any) => {
    this.setState({ isLoading: st });
  };
  render() {
    let files = this.state.files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let classDName = files.length ? "importdropzuploaded" : "importdropz";
    return (
      <div className="card importcard">
        <div className="card-body">
          <div className="row" style={{ padding: "0px 10px" }}>
            <div className="col-md-8">
              <span style={{ fontSize: "13px" }}>LISTING</span>
            </div>
            <div className="col-md-4" style={{ display: "flex", justifyContent: "flex-end" }}>
              <i className="material-icons" style={{ color: "#0073FF", cursor: "pointer" }}>
                get_app
              </i>
              <span
                className="p-l-5 m-r-30 font13"
                style={{ color: "#0073FF", paddingTop: "2px", cursor: "pointer" }}
                onClick={this.download_coverage_layer}
              >
                Download Sample File
              </span>
              <i
                className="material-icons p-l-5"
                style={{ color: "#0073FF", transform: "rotate(180deg)", cursor: "pointer" }}
              >
                get_app
              </i>
              <span
                className="font13"
                style={{ color: "#0073FF", paddingTop: "2px", cursor: "pointer" }}
                id="heat"
                onClick={this.showModal}
              >
                Import File
              </span>
            </div>
          </div>
          <div className="row m-t-10" style={{ padding: "0px 10px" }}>
            <div className="col-md-12" style={{ overflowY: "auto", maxHeight: "calc(100vh - 275px)" }}>
              <table className="table sitetable">
                <thead className="sitehead" style={{ background: "none", display: "table" }}>
                  <tr>
                    {/* <th className="w5 table-bg-hdr-color" > </th> */}
                    <th className="w20 table-bg-hdr-color" style={{ borderRadius: "5px 0px 0px 5px" }}>
                      File Name
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("heatmapFile")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("heatmapFile")}
                      />
                    </th>
                    <th className="w15 table-bg-hdr-color">
                      Technology Name
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("technologyName")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("technologyName")}
                      />
                    </th>
                    <th className="w15 table-bg-hdr-color">
                      Band Name
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("bandName")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("bandName")}
                      />
                    </th>
                    <th className="w20 table-bg-hdr-color">
                      Layer Name
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("layerName")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("layerName")}
                      />
                    </th>
                    <th className="w20 table-bg-hdr-color">
                      Uploaded Date
                      <img
                        className="sortdown"
                        src={sort}
                        style={{ width: "10px" }}
                        onClick={(e) => this.sortByValueDesc("createdAt")}
                      />
                      <img
                        src={sort}
                        style={{ width: "10px", marginLeft: "10px", marginBottom: "-10px" }}
                        onClick={(e) => this.sortByValueAsc("createdAt")}
                      />
                    </th>
                    <th className="w10 table-bg-hdr-color" style={{ borderRadius: "0px 5px 5px 0px" }}></th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    display: "block",
                    overflow: "hidden scroll",
                    maxHeight: "calc(100vh - 325px)",
                    width: "calc(100% + 8px)",
                  }}
                >
                  {this.state.heatmap_list.map((heatmap: any) => {
                    if (this.state.showButton && this.state.select_coverage === heatmap.heatmapId) {
                      return (
                        <tr id={heatmap.heatmapId} style={{ height: "55px", display: "flex" }}>
                          <td className="w20 buildinglistellipsis p-t-20" title={heatmap.heatmapFile}>
                            {heatmap.heatmapFile}{" "}
                          </td>
                          <td className="w15">
                            <input
                              className="accesspointname"
                              style={{ height: "32px" }}
                              name={heatmap.heatmapId}
                              value={heatmap.technologyName}
                              onChange={(e) => {
                                this.onChange(e, "technologyName");
                              }}
                            />
                          </td>
                          <td className="w15">
                            <input
                              className="accesspointname"
                              style={{ height: "32px" }}
                              name={heatmap.heatmapId}
                              value={heatmap.bandName}
                              onChange={(e) => {
                                this.onChange(e, "bandName");
                              }}
                            />
                          </td>
                          <td className="w20">
                            <input
                              className="accesspointname"
                              style={{ height: "32px" }}
                              name={heatmap.heatmapId}
                              value={heatmap.layerName}
                              onChange={(e) => {
                                this.onChange(e, "layerName");
                              }}
                            />
                          </td>
                          <td className="w20 buildinglistellipsis p-t-20">
                            {new Intl.DateTimeFormat("en-GB", {
                              month: "long",
                              day: "2-digit",
                              year: "numeric",
                            }).format(new Date(heatmap.createdAt))}
                          </td>
                          <td className="w10 text-center p-t-20">
                            <i
                              className="far fa-check-circle p-r-15"
                              style={{ color: "#0272fb", fontSize: "22px" }}
                              title="Save"
                              onClick={() => this.update_heatmap(heatmap.heatmapId)}
                            ></i>
                            <i
                              className="far fa-times-circle"
                              style={{ color: "#0272fb", fontSize: "22px" }}
                              title="Clear"
                              onClick={() => this.clear_value(heatmap.heatmapId)}
                            ></i>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr style={{ height: "55px", display: "flex" }}>
                          <td className="w20 buildinglistellipsis p-t-20" title={heatmap.heatmapFile}>
                            {heatmap.heatmapFile}{" "}
                          </td>
                          <td className="w15 buildinglistellipsis p-t-20" title={heatmap.technologyName}>
                            {heatmap.technologyName}
                          </td>
                          <td className="w15 buildinglistellipsis p-t-20" title={heatmap.bandName}>
                            {heatmap.bandName}
                          </td>
                          <td className="w20 buildinglistellipsis p-t-20" title={heatmap.layerName}>
                            {heatmap.layerName}
                          </td>
                          <td className="w20 buildinglistellipsis p-t-20">
                            {new Intl.DateTimeFormat("en-GB", {
                              month: "long",
                              day: "2-digit",
                              year: "numeric",
                            }).format(new Date(heatmap.createdAt))}
                          </td>
                          <td className="w10">
                            <div className="col-3">
                              <a
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  className="fas fa-ellipsis-v "
                                  style={{ color: "#B4B4B4", marginTop: "10px", height: "30px" }}
                                ></i>
                              </a>
                              <div
                                className="dropdown-menu indoorfloor"
                                style={{ backgroundColor: "black" }}
                                aria-labelledby="dropdownMenuLink"
                              >
                                <a
                                  className="dropdown-item cursor"
                                  style={{ color: "white" }}
                                  onClick={() => this.showButton(heatmap.heatmapId)}
                                >
                                  Edit Coverage Layer
                                </a>
                                <a
                                  className="dropdown-item cursor"
                                  style={{ color: "white" }}
                                  id="delete"
                                  onClick={(e) => this.showmodal1(heatmap.heatmapId)}
                                >
                                  {" "}
                                  Delete Coverage Layer
                                </a>
                              </div>
                            </div>
                            {/* <i className="fas fa-pen p-r-20" style={{ color: '#0272fb', fontSize: '16px' }} title="Edit" onClick={() => this.showButton(heatmap.heatmapId)}  ></i>
                                                    <i className="fas fa-trash " style={{ color: '#E22B2B', fontSize: '20px' }} title="Delete" onClick={(e) => this.showmodal1(heatmap.heatmapId)}  ></i> */}
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row m-t-5" style={{ padding: "0px 10px" }}>
            <div className="col-md-8" style={{ bottom: "10px", position: "absolute" }}>
              <i className="fas fa-info-circle p-r-10" style={{ color: "#9b9b9b" }}></i>
              <span style={{ fontSize: "13px", color: "#9b9b9b" }}>
                The uploaded file will be processed and heatmap will be generated within one business day.
              </span>
            </div>
          </div>
        </div>

        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    IMPORT DATA
                  </h6>
                  <i
                    className="material-icons modalclose imgclose-file m-l-5"
                    onClick={this.hideModal}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-10" style={{ padding: "0 10px" }}>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        type="text"
                        id="Technology"
                        name="technology_name"
                        className="accesspointname"
                        placeholder="Technology Name"
                        value={this.state.technology_name}
                        onChange={(e: any) => this.onChange(e, "technologyName")}
                      />
                      <label className="pages" htmlFor="Technology">
                        Technology Name
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row m-t-10" style={{ padding: "0 10px" }}>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        type="text"
                        id="Layer"
                        name="layer_name"
                        className="accesspointname"
                        placeholder="Layer Name"
                        value={this.state.layer_name}
                        onChange={(e: any) => this.onChange(e, "layerName")}
                      />
                      <label className="pages" htmlFor="Layer">
                        Layer Name
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row m-t-10" style={{ padding: "0 10px" }}>
                  <div className="col-md-12 p-0">
                    <div className="form-label-group m-0">
                      <input
                        type="text"
                        id="Band"
                        name="band_name"
                        className="accesspointname"
                        placeholder="Band Name"
                        value={this.state.band_name}
                        onChange={(e: any) => this.onChange(e, "bandName")}
                      />
                      <label className="pages" htmlFor="Band">
                        Band Name
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-12 p-0">
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone-set">
                          <div style={{ height: "160px" }} {...getRootProps({ className: classDName })}>
                            <input {...getInputProps()} />
                            {files.length ? (
                              <div>
                                <div className="m-t-50">
                                  <span
                                    className="justify-content-center"
                                    style={{ color: "#00D56E", display: "flex" }}
                                  >
                                    {files}
                                    {/* <i className="material-icons imgclose-file m-l-5" onClick={(e: any) => { e.preventDefault(); files = [] }}>clear</i> */}
                                  </span>
                                </div>
                                <div className="m-t-25">
                                  <span>
                                    <a style={{ color: "#8F9FAC", fontSize: "14px" }}>Change File</a>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div style={{ textAlign: "center" }}>
                                  <i
                                    className="fas fa-plus-circle text-center"
                                    style={{ padding: "10px", fontSize: "40px", color: "#ccc" }}
                                  ></i>
                                </div>
                                <div className="m-t-10" style={{ textAlign: "center" }}>
                                  <span className="text-center" style={{ fontSize: "14px", color: "#9b9b9b" }}>
                                    Drag and drop files here
                                  </span>
                                </div>
                                <div className="m-t-20" style={{ textAlign: "center" }}>
                                  <span className="font14 cursor" style={{ color: "#0059b2" }}>
                                    Browse
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>
                {files.length ? (
                  <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                    <div className="col-md-12 p-0">
                      <button
                        className="btn cardbtn"
                        style={{ width: "100%" }}
                        onClick={() => {
                          this.import_files();
                          this.hideModal();
                        }}
                      >
                        IMPORT
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                    <div className="col-md-12 p-0">
                      <button className="btn cardbtn" style={{ width: "100%" }} disabled={true}>
                        IMPORT
                      </button>
                    </div>
                  </div>
                )}
                {/* <div className='row m-t-5 justify-content-center' style={{ padding: '0 10px' }}>
                                    <button className='btn cardbtn' style={{ width: '268px' }} onClick={this.import_files}>IMPORT</button>
                                </div> */}
              </div>
            </div>
          </div>
        </Modal>
        <IonAlert
          isOpen={this.state.alertbox}
          onDidDismiss={() => this.setShowAlert(false)}
          message={this.state.warnMessage}
          buttons={["CLOSE"]}
        />
        <IonLoading
          isOpen={this.state.isLoading}
          onDidDismiss={() => this.setShowLoading(false)}
          message={"Uploading..."}
        />
        <Modal show={this.state.card1} handleClose={this.hideModal1}>
          {/* <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px" }}>
                    CONFIRMATION
                  </h6>
                  <i
                    className="material-icons modalclose imgclose-file"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal1();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  {this.state.delete_type === "asset" ? (
                    <span className="modaltext">Are you sure you want to delete this CBSD?</span>
                  ) : (
                    <span className="modaltext">Are you sure you want to delete this Coverage Layer?</span>
                  )}
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-6 p-r-5 p-l-0">
                    <button
                      className="btn modalbtn btn-secondary-c3"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideModal1();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-6 p-l-5">
                    {
                      <button
                        className="btn modalbtn"
                        onClick={(e: any) => {
                          e.preventDefault();
                          this.delete_coverage_layer();
                        }}
                      >
                        Yes
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
