import React, { Component } from "react";
import Heat from "../assets/img/heaticon.png";
import calc from "../assets/img/calculate.svg";
import calcmob from "../assets/img/align-icon.png";
import SearchIcon from "../assets/img/search_icon.png";
import Back from "../assets/img/left-arrow.svg";
import AP from "../assets/img/add-cpe-icon-img-blue.png";
import measure from "../assets/img/Measureicon.png";
import "./building.css";
import Modal from "./modal";
import BackIcon from "../assets/img/go-back-icon-img.png";
import { IonGrid, IonRow, IonCol, IonFab, IonFabButton, IonAlert, IonLoading } from "@ionic/react";
import addCPEIcon from "../assets/img/add-cpe-icon-img.png";
import addDASIcon from "../assets/img/add-das-icon-img.png";

import checkSuccess from "../assets/img/check-success.png";
import heat from "../assets/img/heaticon.png";
import heat2 from "../assets/img/google-icon-img1-red.png";
import geo from "../assets/img/target-icon.png";
import align from "../assets/img/align-icon.png";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { requestAPICall } from "../service/apiHandler";
import Dropzone from "react-dropzone";
import APMap from "../assets/img/add-cpe-icon-img-blue.png";
import { connect } from "react-redux";
import { CURRENT_MAP_CLICK_INSTANCE, COORDINATES, ASSET, CURRENT_TAB } from "../constants/actionTypes";
import { isMobile, isBrowser } from "react-device-detect";
import store from "../store";
import measureIcon from "../assets/img/Measure-icon-img.png";
import apicon from "../assets/img/apicon_white.png";
import heatIcon from "../assets/img/google-icon-img1-red.png";

const INITIAL_STATE = {
  card: "",
  show: false,
  heatMap: false,
  isGAAGenerated: false,
  isParametersSubmitted: false,
  overrideMadatory: false,
  cpiId: "",
  cpiName: "",
  floor_details: [],
  files: [],
  selected_floor_id: 0,
  selected_building_id: 0,
  alertbox: false,
  warnMessage: "",
  button: false,
  button1: false,
  predictionsList: [],
  search_list: [],
  ap_cpe_list: [],
  lat_search: 0,
  lng_search: 0,
  bar: true,
  selected_floor_number: "",
  searches: "",
  selected_floor_type: "",
  is_loader: false,
  selected_floor_asset_count: 0,
};
var addressPredictions: any = {};
let floor_det: any;
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
}));

const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
  updateFloorId: (value: any) => dispatch({ type: ASSET, key: "floorId", value }),
  // updateBuildingId: (value: any) =>
  //     dispatch({ type: CURRENT_TAB, key: 'buildingId', value }),
  upadateSearchMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "asset_search", value }),
  upadateMapCoordinates: (value: any) => dispatch({ type: COORDINATES, key: "coordinates", value }),
});

interface Iprops {
  building_name: any;
  next4: any;
  next2: any;
  back: any;
  next3: any;
  addAP: any;
  addCPE: any;
  search: any;
  measure: any;
  floor_no: any;
  calculate: any;
  select_id: any;
  selected_building: any;
  build_action: any;
  listAssets: any;
  select_floor_id: any;
  floor_id: any;
  floorplan_layer: any;
  enableAssetDrop: any;
  set_subcard: any;
  changeMapCenter: any;
  updateSearchText: any;
  setAddress: any;
  addDAS: any;
  building_delete_confirm: any;
  setCurrentPosition: any;
  latlong_apcpe: any;
}

class Floor extends Component<Iprops> {
  state: any = {
    floor_id: 0,
  };
  props: any = {};
  autocompleteInput: any;
  reduxStore: any = store.getState()["general"];
  constructor(props: any) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.edit_floor = this.edit_floor.bind(this);
    this.next2 = this.next2.bind(this);
    this.addAP = this.addAP.bind(this);
    // this.search = this.search.bind(this)
    this.addCPE = this.addCPE.bind(this);
    this.measure = this.measure.bind(this);
    this.calculate = this.calculate.bind(this);
    this.setPlace = this.setPlace.bind(this);
    this.delete_building = this.delete_building.bind(this);
    this.getFloorDetails = this.getFloorDetails.bind(this);
    this.delete_floor_modal = this.delete_floor_modal.bind(this);
    this.delete_floor_modal_mob = this.delete_floor_modal_mob.bind(this);
  }
  componentDidMount() {
    console.log(this.props);
    console.log(this.reduxStore);
    this.getFloorDetails();
    // this.props.markAssetsOnMap('create-list', this.reduxStore['floorId'])
  }
  componentDidUpdate() {
    this.reduxStore = store.getState()["general"];
  }
  componentWillUnmount() {}

  onDrop = (files: any) => {
    this.setState({ files: files });
  };
  setCurrentPosition = () => {
    this.props.setCurrentPosition();
  };
  getFloorDetails() {
    setTimeout(() => {
      $(".gm-bundled-control").css("left", "95.6%");
      $(".gm-bundled-control").show();
    }, 1);
    let requestBody = {};
    var buildingId = 0;
    console.log(this.reduxStore);
    console.log(this.props["select_floor_id"]);
    buildingId = this.reduxStore["buildingId"];
    requestAPICall("get_floor_details", { building_id: buildingId }, requestBody).then((data) => {
      if (data.responseCode == "200") {
        console.log(data);
        if (data["floordetails"].length > 0) {
          if ([undefined, null, "undefined", "null"].includes(this.reduxStore["floorId"])) {
            console.log("=============================");
            floor_det = data["floordetails"][0];
            this.props.updateFloorId(floor_det["floorId"]);
          } else {
            data["floordetails"].forEach((element: any) => {
              if (element.floorId == this.reduxStore["floorId"]) {
                floor_det = element;
              }
            });
          }
          this.setState(
            {
              floor_details: data["floordetails"],
            },
            () => {
              if (data.floor_count !== 0) {
                this.set_selected_floor_id(floor_det);
              }
            }
          );
        } else {
          console.log("=================");
          this.props.updateFloorId(null);
          this.setState({
            floor_details: [],
            selected_floor_id: 0,
            selected_floor_number: "",
            selected_floor_type: "",
            selected_floor_asset_count: 0,
          });
        }
      }
    });
  }
  setShowLoading = (st: boolean) => {
    this.setState({ is_loader: st });
  };
  set_selected_floor_id(floor: any) {
    console.log("====================", floor);
    this.setState({
      selected_floor_id: floor["floorId"],
      selected_floor_number: floor["floor_number"],
    });

    console.log(this.state.selected_floor_id);
    if (floor["floor_type"] == "Basement") {
      this.setState({
        selected_floor_type: "B",
      });
    }
    if (floor["floor_type"] == "Parking") {
      this.setState({
        selected_floor_type: "P",
      });
    }
    if (floor["floor_type"] == "Floor") {
      this.setState({
        selected_floor_type: "F",
      });
    }
    this.props.updateFloorId(floor["floorId"]);
    try {
      var base64result = floor["floorPlanFileImage"].split(",")[1];
    } catch (error) {}

    // setTimeout(() => {

    try {
      var actual = JSON.parse(atob(base64result));
      this.props.floorplan_layer(actual);
      this.props.floor_id(floor["floorId"]);
      this.props.markAssetsOnMap("create-list", floor["floorId"]);

      // this.props.upadateMapCoordinates({ "latitude": parseFloat(this.reduxStore['floor_coordinates']['latitude']), "longitude": parseFloat(this.reduxStore['floor_coordinates']['longitude']) })
    } catch (error) {
      if (floor["floorPlanFileId"] == null) {
        this.setState({
          alertbox: true,
          warnMessage: "Please upload a floor plan",
        });
      } else {
        this.setState({
          alertbox: true,
          warnMessage: "The uploaded floor plan should be of geojson format",
        });
      }
    }

    // }, 2000)
  }
  set_floor_id = (e: number) => {
    this.setState({
      floor_id: e,
    });
    console.log(this.state.floor_id);
  };
  showModal = (e: any) => {
    this.setState({
      card: e.target.id,
      show: true,
    });
    console.log(this.state.show);
  };
  hideModal = () => {
    this.setState({
      card: "",
      show: false,
    });
  };
  showDeleteModal = (e: any) => {
    this.setState({
      deletecardweb: true,
      show: true,
    });
  };
  showDeleteModalMob = (e: any) => {
    this.setState({
      deletecardmob: true,
      show: true,
    });
  };
  hideDeleteModal = () => {
    this.setState({
      deletecardweb: false,
    });
  };
  hideDeleteModalMob = () => {
    this.setState({
      deletecardmob: false,
    });
  };
  hidefloorDeleteModalweb() {
    this.setState({
      deletefloorcard: false,
    });
  }
  delete_building = () => {
    this.setState({ is_loader: true });
    let requestbody = {
      is_active: 0,
    };
    requestAPICall("delete_manual_building", { building_id: this.reduxStore["buildingId"] }, requestbody).then(
      (data) => {
        this.setState({ is_loader: false });
        if (data.responseCode == 200) {
          this.setState({
            warnMessage: data.message,
            alertbox: true,
          });

          this.props.building_delete_confirm();
          this.props.floorplan_layer(null);
          this.props.markAssetsOnMap("create-list", this.state.selected_floor_id);
        } else {
          this.setState({
            warnMessage: data.message,
            alertbox: true,
          });
        }
      }
    );
    this.hideDeleteModalMob();
    this.hideDeleteModal();
  };
  button_change = (e: any) => {
    this.setState({
      button: !this.state.button,
    });
  };
  button1_change = (e: any) => {
    this.setState({
      button1: !this.state.button1,
    });
  };
  next2 = (e: any) => {
    e.preventDefault();
    this.props.next2();
  };
  addAP = (e: any) => {
    e.preventDefault();
    this.props.enableAssetDrop(true);
    console.log(this.props.select_floor_id);
    if (isBrowser) {
      if (this.props.select_floor_id === 0) {
        this.setState({
          alertbox: true,
          warnMessage: "Please select a floor",
        });
      } else {
        this.props.addAP();
      }
    }
    if (isMobile) {
      this.props.addAP();
    }
  };
  // search = (e: any) => {
  //     e.preventDefault();
  //     this.props.search();
  // }
  addCPE = (e: any) => {
    e.preventDefault();
    if (isBrowser) {
      console.log(this.props.select_floor_id);
      if (this.props.select_floor_id === 0) {
        this.setState({
          alertbox: true,
          warnMessage: "Please select a floor",
        });
      } else {
        this.props.addCPE();
      }
    }
    if (isMobile) {
      this.props.addCPE();
    }
  };
  measure = (e: any) => {
    e.preventDefault();
    this.props.measure();
  };
  addDAS = (e: any) => {
    if (isMobile) {
      this.props.addDAS();
    }
    if (isBrowser) {
      this.props.history.push("/AddDAS");
    }
  };
  calculate = (e: any) => {
    e.preventDefault();
    this.props.calculate();
  };
  generateGAA = () => {
    this.setState({ isGAAGenerated: true });
    let requestBody = {};
    requestAPICall(
      "genrate_gaa",
      { building_id: this.props.select_id, floor_id: this.state.floor_id },
      requestBody
    ).then((data) => {
      if (data.responseCode == "200") {
        console.log(data);
      }
    });

    this.hideModal();
  };
  submitParameters = () => {
    this.setState({ isParametersSubmitted: true });
    this.hideModal();
  };
  overrideMadatory = (e: any) => {
    console.log(e.target.checked);

    this.setState({ overrideMadatory: e.target.checked });
  };
  changeCpiId = (e: any) => {
    this.setState({
      cpiId: e.target.value,
    });
  };
  changeCpiName = (e: any) => {
    this.setState({
      cpiName: e.target.value,
    });
  };

  toggleHeatMap() {
    this.setState({
      heatMap: !this.state.heatMap,
    });
  }
  edit_building = () => {
    this.props.build_action("Edit");
    this.props.next3();
  };
  add_floor = () => {
    this.props.build_action("AddFloor");
    this.props.floor_no(1);
    this.props.next4();
  };
  setShowAlert = (st: any) => {
    this.setState({
      alertbox: st,
      warnMessage: "",
    });
  };
  edit_floor() {
    this.props.build_action("EditFloor");
    this.props.next4();
  }

  delete_floor_modal() {
    let requestBody = {};
    requestAPICall(
      "get_floor_asset_count",
      { building_id: this.reduxStore["buildingId"], floor_id: this.state.selected_floor_id },
      requestBody
    ).then((data) => {
      console.log(data);
      this.setState({
        selected_floor_asset_count: data.floor_asset_count,
        deletefloorcard: true,
        show: true,
      });
    });
  }
  delete_floor_modal_mob() {
    let requestBody = {};
    requestAPICall(
      "get_floor_asset_count",
      { building_id: this.reduxStore["buildingId"], floor_id: this.state.selected_floor_id },
      requestBody
    ).then((data) => {
      console.log(data);
      this.setState({
        selected_floor_asset_count: data.floor_asset_count,
        deletefloorcardmob: true,
        show: true,
      });
    });
  }
  hidefloorDeleteModalMob() {
    this.setState({
      deletefloorcardmob: false,
      show: false,
    });
  }
  delete_floor() {
    let requestBody = {};
    if (this.state.selected_floor_asset_count > 0) {
      requestBody = {
        asset_delete: true,
      };
    } else {
      requestBody = {
        asset_delete: false,
      };
    }

    let build_id = this.reduxStore["buildingId"];
    requestAPICall("delete_floor", { building_id: build_id, floor_id: this.state.selected_floor_id }, requestBody).then(
      (data) => {
        if (data.responseCode == 200) {
          this.props.updateFloorId(null);
          this.props.floorplan_layer(null);
          this.props.markAssetsOnMap("create-list", this.state.selected_floor_id);
          this.getFloorDetails();
          this.setState({
            status_show: false,
            warnMessage: data.message,
            alertbox: true,
          });
        } else {
          this.setState({
            warnMessage: "Please Select Correct floor number",
            alertbox: true,
          });
        }
        this.hidefloorDeleteModalweb();
        this.hidefloorDeleteModalMob();
      }
    );
  }

  onChange(e: any) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.list_asset_search(this.state.searches);
        console.log(this.state.searches);
      }
    );
  }
  onSearch = (e: any) => {
    console.log(e.target.name, e.target.value, "ddfdf");
    this.setState({
      searches: e.target.value,
    });
    console.log(this.autocompleteInput, "ggggggg");
    const predict = this.getQuery();
    this.setState({
      predictionsList: predict,
    });
    console.log(this.state.predictionsList, "list");
  };
  getQuery = () => {
    const service = new google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: this.state.searches }, this.predictionCallback);
    return addressPredictions;
  };
  predictionCallback = (predictions: any, status: any) => {
    if (status != google.maps.places.PlacesServiceStatus.OK) return;

    addressPredictions = predictions;
  };
  switch = () => {
    this.setState(
      {
        bar: !this.state.bar,
        searches: "",
        predictionsList: [],
      },
      () => {
        console.log("setting the switch....");
        if (this.state.bar == true) {
          console.log("setting null in switch");
          this.props.set_subcard(null);
        } else {
          console.log("setting search in switch....");
          this.props.set_subcard("search");
        }
        if (!this.state.bar) {
          this.list_asset();
        }
      }
    );
  };
  list_asset = () => {
    let requestBody = {};
    requestAPICall("list_asset", {}, requestBody).then((data: any) => {
      var list: any = [];
      data["assets"].forEach((element: any) => {
        if (element["assetTypeId"] === 2 || element["assetTypeId"] === 5) {
          list.push(element);
        }
      });
      this.setState({
        asset_list: list,
        ap_cpe_list: list,
      });
      console.log(this.state.ap_cpe_list);
    });
  };
  list_asset_search = (searches: any) => {
    this.setState({
      search_list: this.state.asset_list.filter((asset: any) =>
        asset.name.toUpperCase().includes(this.state.searches.toUpperCase())
      ),
    });
  };
  details = (details: any) => {
    this.setState({
      searches: details.name,
    });
    var coordinates = {
      latitude: details.latitude,
      longitude: details.longitude,
    };
    details["coOrdinates"] = coordinates;
    this.setState(
      {
        lat_search: details["latitude"],
        lng_search: details["longitude"],
      },
      () => {
        var locmark = true;
        this.props.upadateSearchMapCoordinates({
          lat: parseFloat(details["latitude"]),
          long: parseFloat(details["longitude"]),
        });
        this.props.upadateMapCoordinates({
          latitude: parseFloat(details["latitude"]),
          longitude: parseFloat(details["longitude"]),
        });
        this.props.latlong_apcpe(this.state.lat_search, this.state.lng_search, locmark, details);
        this.props.changeMapCenter();
      }
    );
  };
  setPlace = (value: any) => {
    this.setState(
      {
        searches: value,
        bar: true,
      },
      () => {
        console.log(this.state.searches);
        this.props.updateSearchText(this.state.searches);
        this.props.setAddress(value, true);
      }
    );
  };

  render() {
    let files = this.state.files.map((file: any) => (
      <span className="ellipsistooltip90" key={file.name}>
        {file.name}
      </span>
    ));
    let classDName = files.length ? "dropzone-uploaded" : "dropzone";
    const isEnabled = this.state.floor_id > 0;
    return (
      <div>
        <div className="webonly">
          <div className="row">
            <div className="col-md-9">
              <div className="card apcard">
                <div className="row">
                  <div className="col-md-3">
                    <img className="backicon" src={BackIcon} onClick={this.props.back} />
                    <button type="button" className="backbutton"></button>
                    {/* <img className='backicon' src={Back} onClick={this.props.back} />
                                            <button type='button' className='backbutton'></button> */}
                  </div>
                  <div className="col-md-6" style={{ padding: "13px 0" }}>
                    <span className="apheader m-r-m">{this.props.building_name}</span>
                  </div>

                  {/* <div className='col-md-3'>
                                        <i className="fas fa-pen editpen" onClick={this.edit_building} ></i>
                                    </div> */}
                  <div className="col-md-2" style={{ padding: "13px 10px" }}>
                    <a
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-ellipsis-v float-right" style={{ color: "#B4B4B4", marginTop: "2px" }}></i>
                    </a>
                    <div
                      className="dropdown-menu outdoorfloor"
                      style={{ backgroundColor: "black" }}
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item cursor" style={{ color: "white" }} onClick={this.edit_building}>
                        Edit Building
                      </a>
                      <a
                        className="dropdown-item cursor"
                        style={{ color: "white" }}
                        id="delete"
                        onClick={this.showDeleteModal}
                      >
                        {" "}
                        Delete Building
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body" style={{ padding: "1rem" }}>
                  <div className="row">
                    {/* <div className='col-md-6 p-r-5' onClick={this.addCPE}>
                                            <button type='button' className='cardbutton-web'>
                                                <img className='calcicon-web' style={{ height: "15px" }} src={addCPEIcon} />
                                                Add Small Cell</button>
                                        </div> */}
                    <div className="col-md-6 p-r-5" style={{}} onClick={this.addAP}>
                      <button type="button" className="cardbutton-web">
                        <i
                          className="fa fa-circle"
                          style={{ color: "#0073FF", marginRight: "5px", fontSize: "12px" }}
                        ></i>
                        Add CBSD
                      </button>
                    </div>
                    <div className="col-md-6 p-l-5">
                      <button type="button" className="cardbutton-web" onClick={this.next2}>
                        <i
                          className="fa fa-circle "
                          style={{ color: "#E22B2B", padding: "0px 4px", fontSize: "12px" }}
                        ></i>
                        Add DAS Node
                      </button>
                    </div>
                  </div>
                  <div className="row m-t-10">
                    <div className="col-md-6 p-r-5" style={{}} onClick={this.calculate}>
                      <button type="button" className="cardbutton-web">
                        <img className="calcicon-web" src={calc} />
                        Calculate
                      </button>
                    </div>

                    <div className="col-md-6 p-l-5">
                      <button type="button" className="cardbutton-web" onClick={this.measure}>
                        <img className="measureicon-web" style={{ height: "20px" }} src={measureIcon} />
                        Measure
                      </button>
                    </div>
                  </div>
                  <div className="row " style={{ margin: "12px 0px" }}>
                    <div className="col-md-8">
                      <span className="apheader" style={{ fontSize: "12px" }}>
                        FLOOR {this.state.selected_floor_type}
                        {this.state.selected_floor_number}
                      </span>
                    </div>
                    <div className="col-md-4 p-r-5 text-right">
                      {this.state.selected_floor_number !== "" ? (
                        <div>
                          <a
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              className="fas fa-ellipsis-v float-right"
                              style={{ color: "#B4B4B4", marginTop: "10px" }}
                            ></i>
                          </a>
                          <div
                            className="dropdown-menu floormenu"
                            style={{ backgroundColor: "black" }}
                            aria-labelledby="dropdownMenuLink"
                          >
                            <a className="dropdown-item cursor" style={{ color: "white" }} onClick={this.edit_floor}>
                              Edit Floor
                            </a>
                            <a
                              className="dropdown-item cursor"
                              style={{ color: "white" }}
                              id="delete"
                              onClick={this.delete_floor_modal}
                            >
                              {" "}
                              Delete Floor
                            </a>
                          </div>
                        </div>
                      ) : null}
                      {/* {this.state.selected_floor_number !== "" ?
                                                <i className="fas fa-pen editpen p-r-15 font17" style={{ margin: '0px', verticalAlign: 'middle' }} title="Edit" onClick={this.edit_floor} ></i>
                                                : null}
                                            {this.state.selected_floor_number !== "" ?
                                                <i className="fas fa-trash font17" style={{ color: '#E22B2B', verticalAlign: 'middle' }} title="Delete" onClick={this.delete_floor_modal}></i>
                                                : null} */}
                    </div>
                  </div>
                  <div className="row " style={{ margin: "0px" }}>
                    <div className="buildcard">
                      <div className="row" style={{ margin: "0px" }}>
                        <div className="addfloor-web" onClick={this.add_floor}>
                          <i className="fas fa-plus plusicon"></i>
                        </div>
                        {this.state.floor_details.map((floor: any) => {
                          if (floor["floor_type"] == "Parking") {
                            return (
                              <div
                                className={`floor-web text-center ${
                                  floor["floorId"] === this.state.selected_floor_id ? "floor-card-active" : ""
                                }`}
                              >
                                <span
                                  onClick={() => {
                                    this.set_selected_floor_id(floor);
                                  }}
                                >
                                  P{floor["floor_number"]}
                                </span>
                              </div>
                            );
                          }
                          if (floor["floor_type"] == "Basement") {
                            return (
                              <div
                                className={`floor-web text-center ${
                                  floor["floorId"] === this.state.selected_floor_id ? "floor-card-active" : ""
                                }`}
                              >
                                <span onClick={() => this.set_selected_floor_id(floor)}>B{floor["floor_number"]}</span>
                              </div>
                            );
                          }
                          if (floor["floor_type"] == "Floor") {
                            return (
                              <div
                                className={`floor-web text-center ${
                                  floor["floorId"] === this.state.selected_floor_id ? "floor-card-active" : ""
                                }`}
                              >
                                <span onClick={() => this.set_selected_floor_id(floor)}>F{floor["floor_number"]}</span>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="row " style={{ margin: "0px" }}>
                    <div className="col-md-6 p-l-0" style={{ marginTop: "10px" }}>
                      {this.state.isGAAGenerated ? (
                        <button className="btn" style={{ background: "#F5F5F7", width: "134px", color: "black" }}>
                          <img className="btn-img" src={checkSuccess} />
                          <span style={{ marginLeft: "-5px" }}></span>
                          Generated GAA
                        </button>
                      ) : (
                        <button
                          className="btn indoorbtn btn-secondary-c3"
                          id="gaa"
                          style={{ width: "134px", fontSize: "14px" }}
                          onClick={this.showModal}
                        >
                          Generate GAA
                        </button>
                      )}
                    </div>
                    <div className="col-md-6 p-l-5" style={{ marginTop: "10px" }}>
                      {this.state.isGAAGenerated ? (
                        <button className="btn indoorbtn btn-secondary-c3" style={{ width: "134px", fontSize: "14px" }}>
                          Submit GAA
                        </button>
                      ) : (
                        <button
                          className="btn indoorbtn btn-secondary-c3"
                          style={{ width: "134px", fontSize: "14px" }}
                          disabled
                        >
                          Submit GAA
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <div className='row ' style={{ margin: "0px" }}>
                                        <div className="col-12 p-0 m-t-10">
                                            <i className="fas fa-trash indoordlt"></i>
                                            <button className='deletebtn distance' style={{ color: '#E22B2B' }} onClick={this.showDeleteModal}>Delete Building</button>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div style={{ display: "none" }} className="col-md-12 justify-content-flex-end">
                {this.state.heatMap ? (
                  <div className="heaticon-selected text-center">
                    <img className="heat invert-white" src={heat} onClick={() => this.toggleHeatMap()} />
                  </div>
                ) : (
                  <div
                    className="heaticon text-center"
                    id="propagationSettings"
                    onClick={(e: any) => {
                      this.toggleHeatMap();
                      e.preventDefault();
                      this.showModal({ target: { id: "propagationSettings" } });
                    }}
                  >
                    <img className="heat" src={heat} />
                  </div>
                )}
              </div>
              <div className="col-md-12 float-right">
                <div className="geoicon text-center">
                  <img style={{ padding: "5px" }} onClick={this.setCurrentPosition} src={geo} />
                </div>
              </div>
              <div className="col-md-12 float-right">
                <div className="geoicon text-center">
                  <img style={{ padding: "6.5px" }} onClick={this.props.calculate} src={align} />
                </div>
              </div>
            </div>
            {/* <div className='col-md-3' >

                            <div className="heaticon text-center">
                                <img className='heat' id='heat' onClick={this.showModal} src={Heat} />
                            </div>
                        </div> */}
          </div>
        </div>

        <div className="mobonly">
          <IonGrid>
            <div style={{ position: "fixed", bottom: "305px", right: "0px" }}>
              <IonFab horizontal="end" style={{ position: "relative", display: "none" }}>
                <IonFabButton color="light" size="small" style={{ marginBottom: "10px" }}>
                  <img className="heat" style={{ marginTop: "3px", padding: "0px", marginLeft: "0px" }} src={Heat} />
                </IonFabButton>
              </IonFab>
              <IonFab horizontal="end" style={{ position: "relative" }}>
                <IonFabButton color="light" size="small" style={{ marginBottom: "10px" }}>
                  <img style={{ padding: "5px" }} onClick={this.setCurrentPosition} src={geo} />
                </IonFabButton>
              </IonFab>
              <IonFab horizontal="end" style={{ position: "relative" }}>
                <IonFabButton color="light" size="small" style={{ marginBottom: "10px" }}>
                  <img className="calcicon" onClick={this.calculate} src={calcmob} />
                </IonFabButton>
              </IonFab>
              {this.state.bar ? (
                <IonFab horizontal="end" style={{ position: "relative" }}>
                  <IonFabButton color="light" onClick={this.switch} size="small" style={{ marginBottom: "10px" }}>
                    <img className="search-mob" src={SearchIcon} />
                  </IonFabButton>
                </IonFab>
              ) : (
                <div className="dashbtn" style={{ bottom: "311px" }}>
                  <div style={{ padding: "0px 7px", transition: "9s all ease" }}>
                    <div>
                      <img
                        className=""
                        style={{ position: "absolute", padding: "8px", filter: "brightness(0.5)", left: "7px" }}
                        src={SearchIcon}
                      />
                    </div>
                    <div>
                      <span className="searchclose" onClick={this.switch}>
                        <span className="material-icons" style={{ margin: "7px", color: "#9c9c9c" }}>
                          {" "}
                          clear
                        </span>
                      </span>
                      {/* <img className='' style={{ position: "absolute", margin: "1px", width: "38px", right: '7px' }} onClick={this.switch} src={close} /> */}
                    </div>
                    <input
                      name={this.state.searches}
                      id="autocomplete"
                      onChange={(e: any) => {
                        this.onSearch(e);
                        this.onChange(e);
                      }}
                      value={this.state.searches}
                      className="searchnew"
                      type="text"
                      placeholder="Search Address"
                      ref={this.autocompleteInput}
                    />
                    {!this.state.bar ? (
                      <div>
                        <div className=" searchcardmob">
                          <div className="row">
                            {/* <div className='col-md-12'>
                                                            <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                                                <li className="nav-item">
                                                                    <a className="nav-link active" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address"
                                                                        aria-selected="true" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>ADDRESS</a>
                                                                </li>
                                                                <li className="nav-item">
                                                                    <a className="nav-link" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "", predictionsList: "" }) }; }} id="ap-cpe-tab" data-toggle="tab" href="#apcpe" role="tab" aria-controls="apcpe"
                                                                        aria-selected="false" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>CBSD/Small Cell</a>
                                                                </li>
                                                            </ul>
                                                        </div> */}
                          </div>

                          <div className="tab-content" id="searchTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="address"
                              role="tabpanel"
                              aria-labelledby="address-tab"
                            >
                              {this.state.predictionsList.length > 0 ? (
                                <div className="card-body searchdetails">
                                  {this.state.predictionsList.map((predict: any) => {
                                    return (
                                      <div className="row" style={{ paddingRight: "18px" }}>
                                        <div className="col-2 p-r-10">
                                          <i
                                            className="fas fa-map-marker-alt searchcontenticon  float-right"
                                            style={{ fontSize: "20px" }}
                                          ></i>
                                        </div>
                                        <div className="col-10 searchborder" style={{ padding: "10px 0px" }}>
                                          <span
                                            onClick={() => this.setPlace(predict.description)}
                                            className="searchcontent"
                                          >
                                            {predict.description}
                                          </span>
                                          <br />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>

                            {/* <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                                            {this.state.search_list.length > 0 ?
                                                                <div className="card-body searchdetails" >
                                                                    {this.state.search_list.map((index: any) => (
                                                                        <div id="indexname" onClick={() => this.details(index)}>

                                                                            <div className='row m-t-10 col-12 p-r-0' >
                                                                                {index.assetType === "AP-outdoor" ?
                                                                                    <div className='col-2'>
                                                                                        <img className='searchapicon' src={apicon} />
                                                                                    </div> :
                                                                                    <div className='col-2'>
                                                                                        <i className="far fa-circle searchcontenticon-mob "></i>
                                                                                    </div>}
                                                                                <div className='col-10 searchborder'>
                                                                                    <div className="font14" style={{ color: '#353435' }}>{index.name}</div>
                                                                                    <div className="font14" style={{ color: '#8F9FAC' }}>  {index.assetIdentifierId}</div>
                                                                                </div>
                                                                            </div>


                                                                        </div>))}
                                                                </div> : <div className="card-body searchdetails" >
                                                                    {this.state.ap_cpe_list.map((index: any) => (
                                                                        <div id="indexname" onClick={() => this.details(index)}>

                                                                            <div className='row m-t-10 col-12 p-r-0'>
                                                                                {index.assetType === "AP-outdoor" ?
                                                                                    <div className='col-2'>
                                                                                        <img className='searchapicon' src={apicon} />
                                                                                    </div> :
                                                                                    <div className='col-2'>
                                                                                        <i className="far fa-circle searchcontenticon-mob "></i>
                                                                                    </div>}
                                                                                <div className='col-10 searchborder'>
                                                                                    <div className="font14" style={{ color: '#353435' }}>{index.name}</div>
                                                                                    <div className="font14" style={{ color: '#8F9FAC' }}>  {index.assetIdentifierId}</div>
                                                                                </div>
                                                                            </div>


                                                                        </div>))}
                                                                </div>}
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>

            <IonRow className="bottom-menu" style={{ bottom: "258px" }}>
              {/* {this.state.button == false ?
                                <IonCol size='4' style={{ padding: '5px' }}>
                                    <img className='cpeicon' src={addCPEIcon} style={{ width: '38px' }} />
                                    {this.state.button1 ?
                                        <button type='button' className='cardbutton p-l-25 ' style={{ background: '#61d76f', color: '#fff', border: "none" }} onClick={(e: any) => { this.button1_change(e); this.addCPE(e); this.props.enableAssetDrop(false) }}>Create</button>
                                        :
                                        <button type='button' className='cardbutton p-l-25 ' onClick={(e: any) => { this.button1_change(e); this.props.enableAssetDrop(true) }} >Add Small Cell</button>
                                    }
                                </IonCol>
                                :
                                <IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)' }}>
                                    <img className='cpeicon' src={addCPEIcon} style={{ width: '38px', filter: "brightness(0) invert(0.9)" }} />
                                    <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none" }} disabled >Add Small Cell</button>

                                </IonCol>
                            } */}
              {this.state.button1 == false ? (
                <IonCol size="4" style={{ padding: "5px" }}>
                  {this.state.button ? (
                    <img
                      className="apicon"
                      src={AP}
                      style={{
                        filter: "brightness(0) invert(0.9)",
                        height: "40px",
                        width: "35px",
                        padding: "10px 4px 10px 10px",
                      }}
                    />
                  ) : (
                    <img
                      className="apicon"
                      style={{ height: "40px", width: "35px", padding: "10px 4px 10px 10px" }}
                      src={AP}
                    />
                  )}
                  {this.state.button ? (
                    <button
                      type="button"
                      className="cardbutton p-l-25 "
                      style={{ background: "#0372fb", color: "#fff", border: "none" }}
                      onClick={(e: any) => {
                        this.button_change(e);
                        this.addAP(e);
                        this.props.enableAssetDrop(false);
                      }}
                    >
                      Create
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="cardbutton p-l-25 "
                      onClick={(e: any) => {
                        this.button_change(e);
                        this.props.enableAssetDrop(true);
                      }}
                    >
                      Add CBSD
                    </button>
                  )}
                </IonCol>
              ) : (
                <IonCol size="4" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                  <img
                    className="apicon"
                    src={AP}
                    style={{
                      filter: "brightness(0) invert(0.9)",
                      height: "40px",
                      width: "35px",
                      padding: "10px 4px 10px 10px",
                    }}
                  />
                  <button type="button" className="cardbutton p-l-25 b-none" style={{ border: "none" }} disabled>
                    Add CBSD
                  </button>
                </IonCol>
              )}
              {this.state.button == false ? (
                <IonCol size="4" style={{ padding: "5px" }}>
                  {/* <i className="fa fa-circle " style={{ color: '#E22B2B', padding: '11px 15px', fontSize: "18px", position:'absolute' }}></i> */}
                  <img className="dasicon" src={addDASIcon} style={{ width: "41px" }} />
                  {this.state.button1 ? (
                    <button
                      type="button"
                      className="cardbutton p-l-25 "
                      style={{ background: "#e22b2b", color: "#fff", border: "none" }}
                      onClick={(e: any) => {
                        this.button1_change(e);
                        this.addDAS(e);
                        this.props.enableAssetDrop(false);
                      }}
                    >
                      Create
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="cardbutton p-l-25 "
                      onClick={(e: any) => {
                        this.button1_change(e);
                        this.props.enableAssetDrop(true);
                      }}
                    >
                      Add DAS
                    </button>
                  )}
                </IonCol>
              ) : (
                <IonCol size="4" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                  {/* <i className="fa fa-circle " style={{ color: '#E22B2B', padding: '11px 15px', fontSize: "18px", position:'absolute'  }}></i> */}
                  <img
                    className="cpeicon"
                    src={addDASIcon}
                    style={{ width: "41px", filter: "brightness(0) invert(0.9)" }}
                  />
                  <button type="button" className="cardbutton p-l-25 b-none" style={{ border: "none" }} disabled>
                    Add DAS
                  </button>
                </IonCol>
              )}
              {this.state.button == true || this.state.button1 == true ? (
                <IonCol size="4" style={{ padding: "5px", filter: "contrast(0.9)" }}>
                  <img className="measureiconmob" src={measureIcon} style={{ filter: "grayscale(1)" }} />
                  <button
                    type="button"
                    className="cardbutton p-l-25"
                    style={{ border: "none" }}
                    disabled
                    onClick={this.measure}
                  >
                    Measure
                  </button>
                </IonCol>
              ) : (
                <IonCol size="4" style={{ padding: "5px" }}>
                  <img className="measureiconmob" src={measureIcon} />
                  <button
                    type="button"
                    className="cardbutton p-l-25 b-none"
                    style={{ border: "none" }}
                    onClick={this.measure}
                  >
                    Measure
                  </button>
                </IonCol>
              )}

              {/* {this.state.button == true || this.state.button1 == true ?
                                <IonCol size='4' style={{ padding: '5px', filter: 'contrast(0.9)', width: '38px' }}>
                                    <img className='measureiconmob' src={heatIcon} style={{ filter: "grayscale(1)" }} />
                                    <button type='button' className='cardbutton p-l-25' style={{ border: "none" }} disabled >Heat</button>
                                </IonCol>
                                :

                                <IonCol size='4' style={{ padding: '5px' }}>
                                    <img className='measureiconmob' src={heatIcon} />
                                    <button type='button' className='cardbutton p-l-25 b-none' style={{ border: "none" }} >Heat</button>
                                </IonCol>
                            } */}
            </IonRow>
            <div className="submit-btn-wrapper bottom-actions">
              <div className="row" style={{ height: "27px" }}>
                <div className="col-2" style={{ marginRight: "inherit", fontWeight: "bold" }}>
                  <img
                    className="backicon"
                    style={{ position: "absolute", padding: "8px 17px 7px 11px" }}
                    src={BackIcon}
                    onClick={this.props.back}
                  />
                </div>
                <div
                  className="col-7 buildinglistellipsis"
                  style={{ padding: "0px 0px 13px 0px", fontWeight: "normal" }}
                >
                  <span className="apheader font18" style={{ textTransform: "capitalize" }}>
                    {this.reduxStore["buildingName"]} {this.state.selected_floor_type}
                    {this.state.selected_floor_number}
                  </span>
                </div>
                {this.state.selected_floor_number !== "" ? (
                  <div className="col-3">
                    <a
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fas fa-ellipsis-v float-right" style={{ color: "#B4B4B4", marginTop: "10px" }}></i>
                    </a>
                    <div
                      className="dropdown-menu indoorfloor"
                      style={{ backgroundColor: "black" }}
                      aria-labelledby="dropdownMenuLink"
                    >
                      <a className="dropdown-item cursor" style={{ color: "white" }} onClick={this.edit_floor}>
                        Edit Floor
                      </a>
                      <a
                        className="dropdown-item cursor"
                        style={{ color: "white" }}
                        id="delete"
                        onClick={this.delete_floor_modal_mob}
                      >
                        {" "}
                        Delete Floor
                      </a>
                    </div>
                  </div>
                ) : null}
                {/* {this.state.selected_floor_number !== "" ?
                                    <div className='col-2'>
                                        <i className="fas fa-trash" style={{ paddingTop: '23px', paddingLeft: '45px', color: '#E22B2B', fontSize: '14px' }} title="Delete" onClick={this.delete_floor_modal_mob}></i>

                                    </div> : null}
                                {this.state.selected_floor_number !== "" ?
                                    <div onClick={this.edit_floor} className='col-2' style={{ paddingTop: '7px', textAlign: 'center', fontSize: '14px', color: '#1969b9' }}>
                                        <i className="fas fa-pen editpen" ></i>

                                    </div> : null} */}
              </div>

              <div className="row m-t-10" style={{ padding: "0 25px 4px" }}>
                <div className="addfloor-mob">
                  <span
                    className="material-icons"
                    onClick={this.add_floor}
                    style={{ fontSize: "21px", color: "#5ed66f", paddingTop: "8px", paddingLeft: "9px" }}
                  >
                    add
                  </span>
                  {/* <i className="fas fa-plus plusicon" style={{ color: '#5ed66f' }}></i> */}
                </div>
                {this.state.floor_details.map((floor: any) => {
                  if (floor["floor_type"] == "Parking") {
                    return (
                      <div
                        className={`floor-web text-center ${
                          floor["floorId"] === this.state.selected_floor_id ? "floor-card-active" : ""
                        }`}
                      >
                        <span
                          onClick={() => {
                            this.set_selected_floor_id(floor);
                          }}
                        >
                          P{floor["floor_number"]}
                        </span>
                      </div>
                    );
                  }
                  if (floor["floor_type"] == "Basement") {
                    return (
                      <div
                        className={`floor-web text-center ${
                          floor["floorId"] === this.state.selected_floor_id ? "floor-card-active" : ""
                        }`}
                      >
                        <span onClick={() => this.set_selected_floor_id(floor)}>B{floor["floor_number"]}</span>
                      </div>
                    );
                  }
                  if (floor["floor_type"] == "Floor") {
                    return (
                      <div
                        className={`floor-web text-center ${
                          floor["floorId"] === this.state.selected_floor_id ? "floor-card-active" : ""
                        }`}
                      >
                        <span onClick={() => this.set_selected_floor_id(floor)}>F{floor["floor_number"]}</span>
                      </div>
                    );
                  }
                })}
              </div>

              <IonRow className="m-t-20">
                <div className="col-12">
                  <button className="btn " id="gaa" onClick={this.showModal}>
                    Generate GAA
                  </button>
                </div>

                <div className="col-6 p-r-5 m-t-15 m-b-10">
                  <button className="btn btn-secondary-c3 indooredittext" id="gaa" onClick={this.edit_building}>
                    <i className="fas fa-pen editpen" style={{ margin: "10px" }}></i>
                    Edit Building
                  </button>
                </div>
                <div className="col-6 p-l-5 m-t-15 m-b-10">
                  <button
                    className="btn deletebtn distance indoordlttext"
                    style={{ color: "#E22B2B" }}
                    id="gaa"
                    onClick={this.showDeleteModalMob}
                  >
                    <i className="fas fa-trash floordlt"></i>
                    Delete Building
                  </button>
                </div>
              </IonRow>
            </div>
          </IonGrid>
        </div>
        <Modal show={this.state.show && this.state.card === "heat"} handleClose={this.hideModal}>
          {/* <div className="modal fade" id="heatModal" role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "13px" }}>
                    PROPAGATION SETTINGS
                  </h6>
                  <i
                    className="material-icons imgclose-file resetcloseicon"
                    onClick={this.hideModal}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <div className="form-group" style={{ width: "100%" }}>
                    {/* <select className="form-control paramtext" style={{ borderTop: '0.5px solid #8F9FAC', borderRadius: '6px 6px 0px 0px' }}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select> */}
                    <div className="form-label-group m-0">
                      <input
                        type="text"
                        className="paramtext"
                        placeholder="Propagation Model"
                        id="propagatemob"
                        style={{
                          border: "0.5px solid #CED7DF",
                          width: "100%",
                          borderRadius: "6px 6px 0px 0px",
                          borderBottom: "0px",
                          color: "#353435",
                        }}
                      />
                      {/* <input type="text" className="accesspointname font14" id="FCCidmob" style={{ width: "100%" }} placeholder="FCC ID" /> */}
                      <label className="pages" htmlFor="propagatemob">
                        Propagation Model
                      </label>
                    </div>
                    <div className="form-label-group m-0">
                      <input
                        type="text"
                        className="paramtext"
                        placeholder="Transmit Power"
                        id="transmitmob"
                        style={{ width: "100%", border: "0.5px solid #CED7DF", borderBottom: "0px", color: "#353435" }}
                      />
                      {/* <input type="text" className="accesspointname font14" id="FCCidmob" style={{ width: "100%" }} placeholder="FCC ID" /> */}
                      <label className="pages" htmlFor="transmitmob">
                        Transmit Power
                      </label>
                    </div>
                    <div className="form-label-group m-0">
                      <input
                        type="text"
                        className="paramtext"
                        placeholder="Cable Loss"
                        id="cablemob"
                        style={{ width: "100%", border: "0.5px solid #CED7DF", borderBottom: "0px", color: "#353435" }}
                      />
                      {/* <input type="text" className="accesspointname font14" id="FCCidmob" style={{ width: "100%" }} placeholder="FCC ID" /> */}
                      <label className="pages" htmlFor="cablemob">
                        Cable Loss
                      </label>
                    </div>
                    <div className="form-label-group m-0">
                      <input
                        type="text"
                        className="paramtext"
                        placeholder="No. of Transmitters"
                        id="countmob"
                        style={{
                          borderRadius: "0px 0px 6px 6px",
                          width: "100%",
                          border: "0.5px solid #CED7DF",
                          color: "#353435",
                        }}
                      />
                      {/* <input type="text" className="accesspointname font14" id="FCCidmob" style={{ width: "100%" }} placeholder="FCC ID" /> */}
                      <label className="pages" htmlFor="countmob">
                        No.of Transmitters
                      </label>
                    </div>
                    {/* <select className="form-control paramtext" style={{ borderRadius: '0px 0px 6px 6px' }}>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select> */}
                  </div>
                </div>
                <div className="row m-t-5 justify-content-center" style={{ padding: "0 10px" }}>
                  <button className="btn cardbtn" style={{ width: "100%" }}>
                    Generate Heat Map
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>
        <Modal show={this.state.show && this.state.card === "gaa"} handleClose={this.hideModal}>
          {/* <div className="modal fade" id="generateModal" role="dialog" aria-hidden="true" tabIndex={-1}> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "13px" }}>
                    GENERATE GAA COEXISTENCE FILE
                  </h6>
                  <i
                    className="material-icons imgclose-file resetcloseicon"
                    onClick={this.hideModal}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-5" style={{ padding: "0 10px" }}>
                  <span className="distance" style={{ fontSize: "14px" }}>
                    Please select one
                  </span>
                </div>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="gender" name="gender2">
                    {this.state.floor_details.map((floor: any) => {
                      return (
                        <FormControlLabel
                          onClick={() => {
                            this.set_floor_id(floor["floorId"]);
                          }}
                          value={floor["floor_number"]}
                          control={<Radio color="primary" />}
                          label={floor["floor_number"]}
                        />
                      );
                    })}
                    <FormControlLabel
                      onClick={() => {
                        this.set_floor_id(100);
                      }}
                      value="male"
                      control={<Radio color="primary" />}
                      label="Entire Building"
                    />
                  </RadioGroup>
                </FormControl>
                <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <button className="btn cardbtn" style={{ width: "100%" }} id="generate" onClick={this.showModal}>
                    Generate GAA
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>

        <Modal show={this.state.show && this.state.card === "propagationSettings"} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    PROPAGATION SETTINGS
                  </h6>
                  <i
                    className="material-icons imgclose-file resetcloseicon"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="col-md-12 p-0 m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <div className="form-group">
                    <select
                      className="form-control-mob paramtext"
                      style={{ border: "1px solid #8F9FAC", borderRadius: "6px 6px 0px 0px", color: "#9B9B9B" }}
                    >
                      <option value="" hidden>
                        Propagation Model
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          type="text"
                          className="paramtext-web b-t-0"
                          id="Transmit"
                          style={{ border: "1px solid #8F9FAC" }}
                          placeholder="Transmit Power"
                        />
                        <label className="acpdetails" htmlFor="Transmit">
                          Transmit Power
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          type="text"
                          className="paramtext-web b-t-0"
                          id="Cable"
                          style={{ border: "1px solid #8F9FAC" }}
                          placeholder="Cable Loss"
                        />
                        <label className="acpdetails" htmlFor="Cable">
                          Cable Loss
                        </label>
                      </div>
                    </div>
                    <select
                      id=""
                      className="form-control-mob paramtext b-t-0"
                      style={{ border: "1px solid #8F9FAC", borderRadius: "0px 0px 6px 6px", color: "#9B9B9B" }}
                    >
                      <option value="" hidden>
                        No. of Transmitters
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                </div>
                <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <button className="btn cardbtn" style={{ width: "268px" }}>
                    Generate Heat Map
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.card === "propagationSettingsmob"} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px", fontSize: "15px" }}>
                    PROPAGATION SETTINGS
                  </h6>
                  <i
                    className="material-icons imgclose-file resetcloseicon"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="col-md-12 p-0 m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <div className="form-group">
                    <select
                      className="form-control-mob paramtext"
                      style={{
                        border: "1px solid #8F9FAC",
                        borderRadius: "6px 6px 0px 0px",
                        color: "#9B9B9B",
                        padding: "13px",
                      }}
                    >
                      <option value="" hidden>
                        Propagation Model
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          type="text"
                          className="paramtext-web b-t-0"
                          id="Transmitmob"
                          style={{ border: "1px solid #8F9FAC" }}
                          placeholder="Transmit Power"
                        />
                        <label className="acpdetails" htmlFor="Transmitmob">
                          Transmit Power
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 p-0">
                      <div className="form-label-group m-0">
                        <input
                          type="text"
                          className="paramtext-web b-t-0"
                          id="Cablemob"
                          style={{ border: "1px solid #8F9FAC" }}
                          placeholder="Cable Loss"
                        />
                        <label className="acpdetails" htmlFor="Cablemob">
                          Cable Loss
                        </label>
                      </div>
                    </div>
                    <select
                      id=""
                      className="form-control-mob paramtext b-t-0"
                      style={{
                        border: "1px solid #8F9FAC",
                        borderRadius: "0px 0px 6px 6px",
                        color: "#9B9B9B",
                        padding: "13px",
                      }}
                    >
                      <option value="" hidden>
                        No. of Transmitters
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>
                </div>
                <div className="row m-t-10 justify-content-center" style={{ padding: "0 10px" }}>
                  <button className="btn cardbtn" style={{ width: "268px" }}>
                    Generate Heat Map
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.card === "generate"} handleClose={this.hideModal}>
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="col-12 p-0">
                  <h6 className="modal-title font13 d-inline-flex" style={{ marginTop: "0px" }}>
                    GENERATE GAA COEXISTENCE FILE
                  </h6>
                  <i
                    className="material-icons imgclose-file resetcloseicon d-inline-flex"
                    style={{ right: "0px" }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideModal();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row justify-content-center m-t-20">
                  <div className="pwdsuccess"></div>
                  <span className="pwdsuccesstick" style={{ padding: "16px", fontSize: "14px" }}>
                    100%
                  </span>
                </div>

                <div className="row justify-content-center m-t-10">
                  <span className="font14">GAA Generated & Submitted Successfully!</span>
                </div>
                <div className="col-12 p-0 m-t-10">
                  <button className="lightbtnmob modalbtn" onClick={() => this.generateGAA()}>
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal show={this.state.show && this.state.deletecardweb} handleClose={this.hideDeleteModal}>
          <div
            className="modal-dialog center-modal modal-dialog-centered modal-sm"
            role="document"
            style={{ maxWidth: "335px" }}
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 15px" }}>
                  <span className="modal-title" style={{ marginTop: "0px", fontSize: "14px", fontWeight: 500 }}>
                    CONFIRMATION
                  </span>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideDeleteModal();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 justify-content-center text-center" style={{ padding: "0 10px" }}>
                  <span className="modaltext">Are you sure you want to delete this Building?</span>
                </div>

                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn btn-secondary-c3"
                      style={{ width: "95%" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideDeleteModal();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn modalbtn"
                      style={{ width: "98%", marginLeft: "2px" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.delete_building();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.deletecardmob} handleClose={this.hideDeleteModalMob}>
          {/* <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px" }}>
                    CONFIRMATION
                  </h6>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hideDeleteModalMob();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                <div className="row m-t-20 text-center justify-content-center" style={{ padding: "0 10px" }}>
                  <span className="modaltext">Are you sure you want to delete this Building?</span>
                </div>
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 col-6 p-r-5">
                    <button
                      className="lightbtnmob modalbtn"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hideDeleteModalMob();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 col-6 p-l-5">
                    <button
                      className="btn modalbtn"
                      onClick={() => {
                        this.delete_building();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.deletefloorcardmob} handleClose={this.hidefloorDeleteModalMob}>
          {/* <div className="modal fade" id="deleteModal" tabIndex={-1} role="dialog" aria-hidden="true"> */}
          <div className="modal-dialog center-modal modal-dialog-centered modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 10px" }}>
                  <h6 className="modal-title" style={{ marginTop: "0px" }}>
                    CONFIRMATION
                  </h6>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hidefloorDeleteModalMob();
                    }}
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                {this.state.selected_floor_asset_count > 0 ? (
                  <div className="row m-t-20 text-center justify-content-center" style={{ padding: "0 10px" }}>
                    <span className="modaltext">
                      Deleting the floor will also delete the assets mapped to it. Are you sure you want to continue?
                    </span>
                  </div>
                ) : (
                  <div className="row m-t-20 text-center justify-content-center" style={{ padding: "0 10px" }}>
                    <span className="modaltext">Are you sure you want to delete this Floor?</span>
                  </div>
                )}
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 col-6 p-r-5">
                    <button
                      className="lightbtnmob modalbtn"
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hidefloorDeleteModalMob();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 col-6 p-l-5">
                    <button
                      className="btn modalbtn"
                      onClick={() => {
                        this.delete_floor();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal show={this.state.show && this.state.deletefloorcard} handleClose={this.hidefloorDeleteModalweb}>
          <div
            className="modal-dialog center-modal modal-dialog-centered modal-sm"
            role="document"
            style={{ maxWidth: "335px" }}
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="row" style={{ padding: "0 15px" }}>
                  <span className="modal-title" style={{ marginTop: "0px", fontSize: "14px", fontWeight: 500 }}>
                    CONFIRMATION
                  </span>
                  <i
                    className="material-icons resetcloseicon imgclose-file"
                    data-dismiss="modal"
                    onClick={(e: any) => {
                      e.preventDefault();
                      this.hidefloorDeleteModalweb();
                    }}
                    aria-label="Close"
                  >
                    clear
                  </i>
                </div>
                {this.state.selected_floor_asset_count > 0 ? (
                  <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                    <span className="modaltext">
                      Deleting the floor will also delete the assets mapped to it. Are you sure you want to continue?
                    </span>
                  </div>
                ) : (
                  <div className="row m-t-20 justify-content-center" style={{ padding: "0 10px" }}>
                    <span className="modaltext">Are you sure you want to delete this Floor?</span>
                  </div>
                )}
                <div className="row m-t-20 text-center" style={{ padding: "0 10px" }}>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn btn-secondary-c3"
                      style={{ width: "95%" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.hidefloorDeleteModalweb();
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-md-6 p-0">
                    <button
                      className="btn modalbtn"
                      style={{ width: "98%", marginLeft: "2px" }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        this.delete_floor();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <IonAlert
          isOpen={this.state.alertbox}
          onDidDismiss={() => this.setShowAlert(false)}
          message={this.state.warnMessage}
          buttons={["CLOSE"]}
        />
        <IonLoading
          isOpen={this.state.is_loader}
          onDidDismiss={() => this.setShowLoading(false)}
          message={"Loading..."}
          // duration={5000}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Floor);
