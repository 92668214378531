import AP from "../assets/img/Apicon.png";
//import floorPlan from '../assets/json/cc_floorplan.json'
import APSec1 from "../assets/img/blue_leaf_1.png";
import APSec2 from "../assets/img/blue_leaf_2.png";
import APSec3 from "../assets/img/blue_leaf_3.png";
import APSec4 from "../assets/img/blue_leaf_4.png";
import APSec5 from "../assets/img/blue_leaf_5.png";
import APSec6 from "../assets/img/blue_leaf_6.png";
import CPEMap from "../assets/img/CPE_ICON.png";
import APMap from "../assets/img/add-cpe-icon-img-blue.png";
import DASMap from "../assets/img/dark-pink_leaf_1.png";
import AP1DGray from "../assets/img/status-img/access-point-1.png";
import AP2DGray from "../assets/img/status-img/access-point-2.png";
import AP3DGray from "../assets/img/status-img/access-point-3.png";
import AP4DGray from "../assets/img/status-img/access-point-4.png";
import AP5DGray from "../assets/img/status-img/access-point-5.png";
import AP6DGray from "../assets/img/status-img/access-point-6.png";
import APBLUE from "../assets/img/status-img/ap_blue.svg";
import APPURPLE from "../assets/img/status-img/ap_yellow.svg";
import APGREEN from "../assets/img/status-img/ap_green.svg";
import APORANGE from "../assets/img/status-img/ap_orange.svg";
import CPEBLUE from "../assets/img/status-img/cpe_blue.svg";
import CPEORANGE from "../assets/img/status-img/cpe_orange.svg";
import CPEGREEN from "../assets/img/status-img/cpe_green.svg";
import CPEPURPLE from "../assets/img/status-img/cpe_yellow.svg";
import CPEDGray from "../assets/img/status-img/cpe-dark-gray.png";
import APSECTOR_BLUE from "../assets/img/status-img/ap_sector.png";
import APSECTOR_DISABLED from "../assets/img/status-img/ap_sector_disabled.png";
import APSECTOR_ORANGE from "../assets/img/status-img/ap_sector_orange.png";
import CPE_DISABLED from "../assets/img/status-img/cpe_disabled.png";
import CPE_LIST from "../assets/img/status-img/cpe_list.png";
import CPE_BLACK from "../assets/img/status-img/cpe_black.png";
import CPE_MEASURE from "../assets/img/status-img/cpe_measure.png";
import OMNIBlue from "../assets/img/status-img/blue_circle.png";
import $ from "jquery";

export const getSectorImageMeasure = (props, callback) => {
  //////ANGLE ROTATION FUNCTIONS//////////
  let RotateIcon = function (options) {
    this.options = options || {};
    this.rImg = options.img || new Image();
    this.rImg.src = this.rImg.src || this.options.url || "";
    this.options.width = this.options.width || this.rImg.width || 52;
    this.options.height = this.options.height || this.rImg.height || 60;
    var canvas = document.createElement("canvas");
    canvas.width = this.options.width;
    canvas.height = this.options.height;
    this.context = canvas.getContext("2d");
    this.canvas = canvas;
  };
  RotateIcon.makeIcon = function (url) {
    return new RotateIcon({ url: url });
  };
  RotateIcon.prototype.setRotation = function (options) {
    var canvas = this.context,
      angle = options.deg ? (options.deg * Math.PI) / 180 : options.rad,
      centerX = this.options.width / 2,
      centerY = this.options.height / 2;

    canvas.clearRect(0, 0, this.options.width, this.options.height);
    canvas.save();
    canvas.translate(centerX, centerY);
    canvas.rotate(angle);
    canvas.translate(-centerX, -centerY);
    canvas.drawImage(this.rImg, 0, 0);
    canvas.restore();
    return this;
  };
  RotateIcon.prototype.getUrl = async function () {
    return await this.canvas.toDataURL("image/png");
  };

  var url = AP;
  var height = 10;
  var width = 10;
  var assetType = props["assetType"];
  var sectors = props["sectors"];
  var status = props["status"];
  var azimuth = props["azimuth"][1];
  if (assetType === "AP-outdoor") {
    switch (status) {
      case "disabled":
        {
          switch (sectors) {
            case 1:
              url = AP1DGray;
              height = 18;
              width = 20;
              break;
            case 2:
              url = AP2DGray;
              height = 25;
              width = 25;
              break;
            case 3:
              url = AP3DGray;
              height = 25;
              width = 25;
              break;
            case 4:
              url = AP4DGray;
              height = 25;
              width = 25;
              break;
            case 5:
              url = AP5DGray;
              height = 25;
              width = 25;
              break;
            case 6:
              url = AP6DGray;
              height = 25;
              width = 25;
              break;
            default:
              url = AP1DGray;
              height = 25;
              width = 25;
              break;
          }
        }
        break;
      case "create-list":
        {
          switch (sectors) {
            case 1:
              url = APSec1;
              height = 18;
              width = 20;
              break;
            case 2:
              url = APSec2;
              height = 25;
              width = 18;
              break;
            case 3:
              url = APSec3;
              height = 25;
              width = 25;
              break;
            case 4:
              url = APSec4;
              height = 25;
              width = 25;
              break;
            case 5:
              url = APSec5;
              height = 25;
              width = 25;
              break;
            case 6:
              url = APSec6;
              height = 25;
              width = 25;
              break;
            default:
              url = APSec1;
              height = 25;
              width = 25;
              break;
          }
        }
        break;
      case "create-list-angle":
        {
          switch (sectors) {
            default:
              url = APSECTOR_BLUE;
              height = 60;
              width = 60;
              break;
          }
        }
        break;
      case "measure-angle":
        {
          switch (sectors) {
            default:
              url = APSECTOR_ORANGE;
              height = 60;
              width = 60;
              break;
          }
        }
        break;
      case "calculate":
        {
          switch (sectors) {
            case 1:
              url = APSec1;
              height = 18;
              width = 20;
              break;
            case 2:
              url = APSec2;
              height = 25;
              width = 16;
              break;
            case 3:
              url = APSec3;
              height = 25;
              width = 25;
              break;
            case 4:
              url = APSec4;
              height = 25;
              width = 25;
              break;
            case 5:
              url = APSec5;
              height = 25;
              width = 25;
              break;
            case 6:
              url = APSec6;
              height = 25;
              width = 25;
              break;
            default:
              url = APSec1;
              height = 25;
              width = 25;
              break;
          }
        }
        break;
      case "disabled-angle":
        url = APSECTOR_DISABLED;
        height = 60;
        width = 60;
        break;
    }
  } else if (assetType == "CPE-outdoor") {
    switch (status) {
      case "disabled":
        url = CPEDGray;
        height = 18;
        width = 18;
        break;
      case "disabled-angle":
        url = CPE_DISABLED;
        height = 40;
        width = 40;
        break;
      case "create-list-angle":
        url = CPE_LIST;
        height = 40;
        width = 40;
        break;
      case "measure-angle":
        url = CPE_MEASURE;
        height = 40;
        width = 40;
        break;
      case "create-list":
        url = CPEMap;
        height = 18;
        width = 18;
        break;
      case "calculate":
        url = CPEMap;
        height = 18;
        width = 18;
        break;
    }
  }
  if (status.includes("angle")) {
    url = RotateIcon.makeIcon(url)
      .setRotation({ deg: parseFloat(azimuth), height: height, width: width })
      .getUrl()
      .then((data) => {
        callback({
          url: data,
          scaledSize: new window.google.maps.Size(width, height),
          anchor: new window.google.maps.Point(width / 2, height / 2),
        });
      });
  } else {
    //     callback({
    //         "url": url,
    //         "scaledSize": new window.google.maps.Size(width, height),
    //    })
    return {
      url: url,
      scaledSize: new window.google.maps.Size(width, height),
      anchor: new window.google.maps.Point(width / 2, height / 2),
    };
  }
};

export const getSectorImage = (props, callback) => {
  //////ANGLE ROTATION FUNCTIONS//////////
  var url = AP;
  var height = 10;
  var width = 10;
  var assetType = props["assetType"];
  var pciCardShow = props["pciCardShow"];
  var sectors = props["sectors"];
  var status = props["status"];
  var azimuth = props["azimuth"][1];
  var sectorID = props["azimuth"][0];
  var sasStatus = props["sasStatus"];
  if (assetType === "AP-outdoor") {
    switch (status) {
      case "disabled":
        {
          switch (sectors) {
            case 0:
              url = AP1DGray;
              height = 18;
              width = 20;
              break;
            case 1:
              url = AP1DGray;
              height = 18;
              width = 20;
              break;
            case 2:
              url = AP2DGray;
              height = 25;
              width = 25;
              break;
            case 3:
              url = AP3DGray;
              height = 25;
              width = 25;
              break;
            case 4:
              url = AP4DGray;
              height = 25;
              width = 25;
              break;
            case 5:
              url = AP5DGray;
              height = 25;
              width = 25;
              break;
            case 6:
              url = AP6DGray;
              height = 25;
              width = 25;
              break;
            default:
              url = AP1DGray;
              height = 25;
              width = 25;
              break;
          }
        }
        break;
      case "create-list":
        {
          switch (sectors) {
            case 0:
              url = AP1DGray;
              height = 18;
              width = 20;
              break;
            case 1:
              url = APSec1;
              height = 18;
              width = 20;
              break;
            case 2:
              url = APSec2;
              height = 25;
              width = 18;
              break;
            case 3:
              url = APSec3;
              height = 25;
              width = 25;
              break;
            case 4:
              url = APSec4;
              height = 25;
              width = 25;
              break;
            case 5:
              url = APSec5;
              height = 25;
              width = 25;
              break;
            case 6:
              url = APSec6;
              height = 25;
              width = 25;
              break;
            default:
              url = APSec1;
              height = 25;
              width = 25;
              break;
          }
        }
        break;
      case "create-list-angle":
        {
          switch (sectors) {
            case 0:
              url = CPE_BLACK;
              height = 25;
              width = 25;
              break;
            default:
              url = APSECTOR_BLUE;
              height = 60;
              width = 60;
              break;
          }
        }
        break;
      case "measure-angle":
        {
          switch (sectors) {
            default:
              url = APSECTOR_ORANGE;
              height = 60;
              width = 60;
              break;
          }
        }
        break;
      case "calculate":
        {
          switch (sectors) {
            case 0:
              url = APSec1;
              height = 18;
              width = 20;
              break;
            case 1:
              url = APSec1;
              height = 18;
              width = 20;
              break;
            case 2:
              url = APSec2;
              height = 25;
              width = 16;
              break;
            case 3:
              url = APSec3;
              height = 25;
              width = 25;
              break;
            case 4:
              url = APSec4;
              height = 25;
              width = 25;
              break;
            case 5:
              url = APSec5;
              height = 25;
              width = 25;
              break;
            case 6:
              url = APSec6;
              height = 25;
              width = 25;
              break;
            default:
              url = APSec1;
              height = 25;
              width = 25;
              break;
          }
        }
        break;
      case "disabled-angle":
        url = APSECTOR_DISABLED;
        height = 60;
        width = 60;
        break;
      case "sas-status":
        {
          // console.log("🚀 ~ getSectorImage ~ sasStatus", sasStatus)
          // console.log("🚀 ~ getSectorImage ~ sectorID", sectorID)
          if (sasStatus != undefined && sasStatus.hasOwnProperty(sectorID)) {
            switch (parseInt(sasStatus[sectorID].split(",")[0])) {
              case 1:
                url = APBLUE;
                height = 60;
                width = 60;
                break;
              case 6:
                url = APGREEN;
                height = 60;
                width = 60;
                break;
              case 3:
                url = APGREEN;
                height = 60;
                width = 60;
                break;
              case 7:
                url = APBLUE;
                height = 60;
                width = 60;
                break;
              case 8:
                url = APPURPLE;
                height = 60;
                width = 60;
                break;
              case 9:
                url = APBLUE;
                height = 60;
                width = 60;
                break;
              case 10:
                url = APBLUE;
                height = 60;
                width = 60;
                break;
              case 11:
                url = APBLUE;
                height = 60;
                width = 60;
                break;
              case 12:
                url = APORANGE;
                height = 60;
                width = 60;
                break;
              case 4:
                url = APGREEN;
                height = 60;
                width = 60;
                break;
              // case 6: url = APSECTOR_GREEN; height = 60; width = 60; break;
              case 2:
                url = APORANGE;
                height = 60;
                width = 60;
                break;
              case 5:
                url = APGREEN;
                height = 60;
                width = 60;
                break;
              case 13:
                url = APBLUE;
                height = 60;
                width = 60;
                break;
              case 14:
                url = APPURPLE;
                height = 60;
                width = 60;
                break;
              default:
                url = APBLUE;
                height = 60;
                width = 60;
                break;
            }
          } else {
            url = APBLUE;
            height = 60;
            width = 60;
          }
        }
        break;
    }
  } else if (assetType == "CPE-outdoor") {
    switch (status) {
      case "disabled":
        url = CPEDGray;
        height = 18;
        width = 18;
        break;
      case "disabled-angle":
        url = CPE_DISABLED;
        height = 40;
        width = 40;
        break;
      case "create-list-angle":
        url = CPE_LIST;
        height = 40;
        width = 40;
        break;
      case "measure-angle":
        url = CPE_MEASURE;
        height = 40;
        width = 40;
        break;
      case "create-list":
        url = CPEMap;
        height = 18;
        width = 18;
        break;
      case "calculate":
        url = CPEMap;
        height = 18;
        width = 18;
        break;
      case "sas-status":
        {
          if (sasStatus != undefined && sasStatus.hasOwnProperty(sectorID)) {
            switch (parseInt(sasStatus[sectorID].split(",")[0])) {
              case 4:
                url = CPEGREEN;
                height = 33;
                width = 33;
                break;
              case 2:
                url = CPEORANGE;
                height = 33;
                width = 33;
                break;
            }
          } else {
            url = CPEBLUE;
            height = 33;
            width = 33;
          }
        }
        break;
    }
  } else if (assetType == "AP-omni") {
    switch (status) {
      case "disabled":
        url = CPEDGray;
        height = 18;
        width = 18;
        break;
      case "disabled-angle":
        url = CPE_DISABLED;
        height = 40;
        width = 40;
        break;
      case "create-list-angle":
        url = OMNIBlue;
        height = 40;
        width = 40;
        break;
      case "measure-angle":
        url = CPE_MEASURE;
        height = 40;
        width = 40;
        break;
      case "create-list":
        url = OMNIBlue;
        height = 18;
        width = 18;
        break;
      case "calculate":
        url = OMNIBlue;
        height = 18;
        width = 18;
        break;
      case "sas-status":
        {
          if (sasStatus != undefined && sasStatus.hasOwnProperty(sectorID)) {
            switch (parseInt(sasStatus[sectorID].split(",")[0])) {
              case 1:
                url = CPEBLUE;
                height = 30;
                width = 30;
                break;
              case 6:
                url = CPEGREEN;
                height = 30;
                width = 30;
                break;
              case 3:
                url = CPEGREEN;
                height = 30;
                width = 30;
                break;
              case 7:
                url = CPEBLUE;
                height = 30;
                width = 30;
                break;
              case 8:
                url = CPEORANGE;
                height = 30;
                width = 30;
                break;
              case 9:
                url = CPEBLUE;
                height = 30;
                width = 30;
                break;
              case 10:
                url = CPEBLUE;
                height = 30;
                width = 30;
                break;
              case 11:
                url = CPEBLUE;
                height = 30;
                width = 30;
                break;
              case 12:
                url = CPEORANGE;
                height = 30;
                width = 30;
                break;
              case 4:
                url = CPEGREEN;
                height = 30;
                width = 30;
                break;
              case 2:
                url = CPEORANGE;
                height = 30;
                width = 30;
                break;
              case 5:
                url = CPEGREEN;
                height = 30;
                width = 30;
                break;
              case 13:
                url = CPEBLUE;
                height = 30;
                width = 30;
                break;
              case 14:
                url = CPEPURPLE;
                height = 30;
                width = 30;
                break;
              default:
                url = CPEBLUE;
                height = 30;
                width = 30;
                break;
            }
          } else {
            url = CPEBLUE;
            height = 30;
            width = 30;
          }
        }
        break;
    }
  } else if (assetType == "AP-indoor") {
    switch (status) {
      case "disabled":
        url = CPEDGray;
        height = 18;
        width = 18;
        break;
      case "create-list":
        url = APMap;
        height = 18;
        width = 18;
        break;
      case "calculate":
        url = CPEMap;
        height = 18;
        width = 18;
        break;
    }
  }
  if (assetType == "CPE-indoor") {
    switch (status) {
      case "disabled":
        url = CPEDGray;
        height = 18;
        width = 18;
        break;
      case "create-list":
        url = CPEMap;
        height = 18;
        width = 18;
        break;
      case "calculate":
        url = CPEMap;
        height = 18;
        width = 18;
        break;
    }
  } else if (assetType == "DAS") {
    switch (status) {
      case "disabled":
        url = CPEDGray;
        height = 18;
        width = 18;
        break;
      case "create-list":
        url = DASMap;
        height = 18;
        width = 18;
        break;
      case "calculate":
        url = CPEMap;
        height = 18;
        width = 18;
        break;
    }
  }
  if (localStorage.getItem("browser") == "Safari") {
    var resUrl = url;
    var pointer = "asset" + props["assetId"] + azimuth;
    var rotate_deg = azimuth;
    if (props["action"] === "rotate") {
      $(`div[title="${pointer}"] img`).css({
        "-webkit-transform": "rotate(" + rotate_deg + "deg)",
        "-moz-transform": "rotate(" + rotate_deg + "deg)",
        "-ms-transform": "rotate(" + rotate_deg + "deg)",
        transform: "rotate(" + rotate_deg + "deg)",
      });
      $(`div[title="${pointer}"]`).css({
        opacity: "1",
      });
    }
  } else {
    var resUrl = url + "#" + props["assetId"] + azimuth;
    var rotate_deg = azimuth;
    if (props["action"] === "rotate") {
      $(`img[src="${resUrl}"]`).css({
        "-webkit-transform": "rotate(" + rotate_deg + "deg)",
        "-moz-transform": "rotate(" + rotate_deg + "deg)",
        "-ms-transform": "rotate(" + rotate_deg + "deg)",
        transform: "rotate(" + rotate_deg + "deg)",
      });
    }
  }

  var label_origin = new window.google.maps.Point(width / 2, height / 0.8);

  if (pciCardShow && (assetType === "AP-outdoor" || assetType === "AP-omni")) {
    rotate_deg = rotate_deg - 90;
    let x = width / 2 + Math.cos((rotate_deg * Math.PI) / 180).toFixed(1) * 50;
    let y = height / 2 + Math.sin((rotate_deg * Math.PI) / 180).toFixed(1) * 45;
    // y = y / 0.98 ? props['noOfSectors'] === 3 : y / 0.8 ? (props['noOfSectors'] === 2 || props['noOfSectors'] === 4 || props['noOfSectors'] === 6) : y / 0.9 ? props['noOfSectors'] === 5 : y / 1.5 ? props['noOfSectors'] === 1 : y / 0.9
    label_origin = new window.google.maps.Point(x, y);
  } else {
    if (props["noOfSectors"] === 3) {
      label_origin = new window.google.maps.Point(width / 2, height / 0.98);
    } else if (props["noOfSectors"] === 2 || props["noOfSectors"] === 4 || props["noOfSectors"] === 6) {
      label_origin = new window.google.maps.Point(width / 2, height / 0.8);
    } else if (props["noOfSectors"] === 5) {
      label_origin = new window.google.maps.Point(width / 2, height / 0.9);
    } else if (props["noOfSectors"] === 1) {
      label_origin = new window.google.maps.Point(width / 2, height / 0.8);
    } else {
      label_origin = new window.google.maps.Point(width / 2, height / 0.7);
    }
  }

  // console.log("assetIcon", {
  //   url: resUrl,
  //   scaledSize: new window.google.maps.Size(width, height),
  //   anchor: new window.google.maps.Point(width / 2, height / 2),
  //   labelAnchor: new window.google.maps.Point(width, height),
  //   labelOrigin: label_origin,
  // });

  return {
    url: resUrl,
    scaledSize: new window.google.maps.Size(width, height),
    anchor: new window.google.maps.Point(width / 2, height / 2),
    labelAnchor: new window.google.maps.Point(width, height),
    labelOrigin: label_origin,
  };
  // if (status.includes("angle") ) {
  //         callback({
  //             "url": data,
  //             "scaledSize": new window.google.maps.Size(width, height),
  //             "anchor": new window.google.maps.Point(width/2, height/2)
  //        })
  // } else {
  // //     callback({
  // //         "url": url,
  // //         "scaledSize": new window.google.maps.Size(width, height),
  // //    })
  //     return {
  //         "url": url,
  //         "scaledSize": new window.google.maps.Size(width, height),
  //         "anchor": new window.google.maps.Point(width/2, height/2)
  //     }
  // }
};
