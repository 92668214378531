export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const USER_ROLE = 'USER_ROLE';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const CURRENT_TAB = 'CURRENT_TAB';
export const CURRENT_MAP_CLICK_INSTANCE = 'CURRENT_MAP_CLICK_INSTANCE';
export const COORDINATES = 'COORDINATES';
export const PROPERTIES = 'PROPERTIES';
export const ASSET = 'ASSET';