import React, { Component } from 'react'
import { StandaloneSearchBox } from '@react-google-maps/api'
import Heat from '../assets/img/heaticon.png'
import "./search.css"
import Back from '../assets/img/left-arrow.svg'
import { IonPage, IonContent } from '@ionic/react'
import apicon from '../assets/img/Apicon.png'
import { connect } from 'react-redux';
import { COORDINATES } from '../constants/actionTypes';
import { requestAPICall } from "../service/apiHandler";



var predict: any = [];
var addressPredictions: any = {}

const mapStateToProps = (state: any) => ({ ...state.general });

const mapDispatchToProps = (dispatch: any) => ({
    updateSearchText: (value: any) =>
        dispatch({ type: COORDINATES, key: 'search', value }),
    upadateSearchMapCoordinates: (value: any) =>
        dispatch({ type: COORDINATES, key: 'asset_search', value }),
});

class search extends Component {
    state: any = {};
    props: any = {};
    autocompleteInput: any
    autocomplete: any
    constructor(props: any) {
        super(props)
        this.autocompleteInput = React.createRef();
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
        this.list_asset = this.list_asset.bind(this)
        this.list_asset()


        this.state = {
            asset_list: [],
            searches: '',
            predictionsList: [],
            search_list: [],
            ap_cpe_list: [],
            lat_search: 0,
            lng_search: 0


        };
    }
    componentDidMount() {
        window.addEventListener('load', this.list_asset);

        console.log(this.autocomplete, "fdfd")
        // this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
        //     { "types": ["geocode"] });

        // this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
        // const place = this.autocomplete.getPlace();
        console.log(this.autocompleteInput, "place")
        console.log(this.autocompleteInput['value'], "dfddf")
    }
    handlePlaceChanged() {
        const place = this.autocomplete.getPlace();
        console.log(place, "place")
        // this.props.onPlaceLoaded(place);
        console.log(this.autocomplete, "dffdfdfd")

    }
    onSearch = (e: any) => {

        console.log(e.target.name, e.target.value, "ddfdf")
        this.setState({
            searches: e.target.value

        })

        console.log(this.autocompleteInput, "ggggggg")

        const predict = this.getQuery()

        this.setState({

            predictionsList: predict,

        })

        console.log(this.state.predictionsList, "list")
    }
    onChange(e: any) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.list_asset_search(this.state.searches)
            console.log(this.state.searches)
        })
    }
    list_asset = () => {
        let requestBody = {
        }
        requestAPICall("list_asset", {}, requestBody).then(data => {
            this.setState({
                asset_list: data['assets'],
                ap_cpe_list: data["assets"].filter((asset: any) => asset.assetType !== "DAS")
            })
            console.log(this.state.ap_cpe_list)


        })

    }
    list_asset_search = (searches: any) => {
        this.setState({
            search_list: this.state.asset_list.filter((asset: any) => asset.name.toUpperCase().includes(this.state.searches.toUpperCase()))
        })

    }
    details = (name: any) => {
        this.setState({
            searches: name,


        })
        let list_filter = this.state.ap_cpe_list.filter((asset: any) => asset.name === name)
        this.setState({
            lat_search: list_filter[0]['latitude'],
            lng_search: list_filter[0]['longitude']


        }, () => {

            this.props.upadateSearchMapCoordinates({ "lat": this.state.lat_search, "long": this.state.lng_search })
            this.props.history.push('/map')

        })

    }
    getQuery = () => {
        const service = new google.maps.places.AutocompleteService();


        service.getQueryPredictions({ input: this.state.searches }, this.predictionCallback);
        return addressPredictions
    }
    setPlace = (value: any) => {
        this.setState({
            searches: value

        }, () => {
            this.props.updateSearchText(this.state.searches)
            this.props.history.push('/map')
        })
    }
    predictionCallback = (predictions: any, status: any) => {
        if (status != google.maps.places.PlacesServiceStatus.OK) return;

        addressPredictions = predictions;
    }
    Bind_AssetList = () => {
        let peopleToReturn = [];
        for (let i = 0; i < this.state.asset_list.length; i++) {
            console.log(this.state.asset_list[i]['name'])
            peopleToReturn.push(
                <div>
                    <span className='searchcontent'>{this.state.asset_list[i]['name']}</span>
                    <span className='apnote'>{this.state.asset_list[i]['streetAddress']}</span>
                </div>
            );
        }
        return peopleToReturn;
    };
    render() {
        return (
            <div>


                <div className="mobonly">
                    <IonPage>
                        <IonContent>
                            <div className='param-info-mob' style={{ height: '100vh' }}>
                                <div className='col-12 searchborder' style={{ padding: '10px' }}>
                                    <i className="fa fa-search searchicon" aria-hidden="true"></i>
                                    <i className="fa fa-times closeicon" onClick={() => { this.props.history.push('/map') }}></i>
                                    {/* <StandaloneSearchBox>
                                        <input className='search' type='text' placeholder='Search Address' />
                                    </StandaloneSearchBox> */}
                                    <input name={this.state.searches} className='search' id="autocomplete" onChange={(e: any) => { this.onSearch(e); this.onChange(e) }} value={this.state.searches} placeholder="Enter your address" type="text" ref={this.autocompleteInput}></input>
                                    {/* <input name={this.state.searches} id="autocomplete" className='search' onChange={(e: any) => this.onChange(e)} value={this.state.searches} placeholder="Enter your address" type="text" ref={this.autocompleteInput}></input> */}

                                </div>

                                <div className='container-fluid apdash'>
                                    <div className="card profilecard" style={{ height: 'calc(100vh - 78px)' }}>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <ul className="nav nav-tabs searchtab" id="searchTab" role="tablist">
                                                    <li className="nav-item">
                                                        <a className="nav-link active" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "" }) }; }} id="address-tab" data-toggle="tab" href="#address" role="tab" aria-controls="address"
                                                            aria-selected="true" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>ADDRESS</a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="nav-link" onClick={(e: any) => { e.preventDefault(); { this.setState({ searches: "" }) }; }} id="ap-cpe-tab" data-toggle="tab" href="#apcpe" role="tab" aria-controls="apcpe"
                                                            aria-selected="false" style={{ fontWeight: "bold", padding: '.5rem 1rem .5rem .8rem;' }}>CBSD/CPE</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="tab-content" id="searchTabContent">

                                            <div className="tab-pane fade show active" id="address" role="tabpanel" aria-labelledby="address-tab">

                                                {this.state.predictionsList.length > 0 ?
                                                    <div className="card-body" style={{ overflowY: 'auto', padding: "10px 5px", overflowX: 'hidden' }}>
                                                        {this.state.predictionsList.map((predict: any) => {
                                                            return <div className='row' style={{ paddingRight: '18px' }}>
                                                                <div className='col-2 p-r-10'>
                                                                    <i className="fas fa-map-marker-alt searchcontenticon  float-right" style={{ fontSize: '20px' }}></i>
                                                                </div>
                                                                <div className='col-10 searchborder' style={{ padding: '10px 0px' }}>
                                                                    <span onClick={() => this.setPlace(predict.description)} className='searchcontent'>{predict.description}</span><br />
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                    : null}
                                            </div>

                                            <div className="tab-pane fade" id="apcpe" role="tabpanel" aria-labelledby="ap-cpe-tab">
                                                {this.state.search_list.length > 0 ?
                                                    <div>
                                                        {this.state.search_list.map((index: any) => (
                                                            <div id="indexname" onClick={() => this.details(index.name)}>

                                                                <div className='row m-t-10 col-12 p-r-0' >
                                                                    {index.assetType === "AP-outdoor" ?
                                                                        <div className='col-2'>
                                                                            <img className='searchapicon' src={apicon} />
                                                                        </div> :
                                                                        <div className='col-2'>
                                                                            <i className="far fa-circle searchcontenticon-mob "></i>
                                                                        </div>}
                                                                    <div className='col-10 searchborder'>
                                                                        <div className="font14" style={{ color: '#353435' }}>{index.name}</div>
                                                                        <div className="font14" style={{ color: '#8F9FAC' }}>  {index.assetIdentifierId}</div>
                                                                    </div>
                                                                </div>


                                                            </div>))}
                                                    </div> : <div>
                                                        {this.state.ap_cpe_list.map((index: any) => (
                                                            <div id="indexname" onClick={() => this.details(index.name)}>

                                                                <div className='row m-t-10 col-12 p-r-0'>
                                                                    {index.assetType === "AP-outdoor" ?
                                                                        <div className='col-2'>
                                                                            <img className='searchapicon' src={apicon} />
                                                                        </div> :
                                                                        <div className='col-2'>
                                                                            <i className="far fa-circle searchcontenticon-mob "></i>
                                                                        </div>}
                                                                    <div className='col-10 searchborder'>
                                                                        <div className="font14" style={{ color: '#353435' }}>{index.name}</div>
                                                                        <div className="font14" style={{ color: '#8F9FAC' }}>  {index.assetIdentifierId}</div>
                                                                    </div>
                                                                </div>


                                                            </div>))}
                                                    </div>}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>




                        </IonContent>
                    </IonPage>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(search);
